'use strict'

module.exports = {
    CONTROLLER_STAGE_DATA: {
        VISIBILITY: {
            DEV: 'DEV',
            NEVER: 'NEVER',
            EDITOR: 'EDITOR'
        }
    }
}
