export const wixCodeApp = '675bbcef-18d8-41f5-800e-131ec9e08762'
export const formBuilderApp = '14ce1214-b278-a7e4-1373-00cebd1bef7c'

export const POLICIES_MODEL_KEYS = ['termsOfUse', 'privacyPolicy', 'codeOfConduct']

export const INTERACTIONS = {
    SOCIAL_APP_LOGIN: 'members-social-app-login',
    DEFAULT_LOGIN: 'members-default-login',
    CODE_LOGIN: 'members-code-login',
    CODE_SIGNUP: 'members-code-signup',
    DEFAULT_SIGNUP: 'members-default-signup',
    RESET_PASSWORD: 'members-reset-password'
}

export const DIALOGS = {
    Login: 'login',
    SignUp: 'register',
    ResetPasswordEmail: 'resetPasswordEmail',
    ResetPasswordNewPassword: 'resetPasswordNewPassword',
    Notification: 'notification',
    Credits: 'credits',
    PasswordProtected: 'enterPassword',
    EmailVerification: 'emailVerification',
    SentConfirmationEmail: 'sentConfirmationEmail',
    Welcome: 'welcome',
    NoPermissionsToPage: 'noPermissionsToPage'
}

export const NOTIFICATIONS = {
    Template: 'template',
    SiteOwner: 'siteowner',
    SignUp: 'register',
    ResetPasswordEmail: 'resetPasswordEmail',
    ResetPasswordNewPassword: 'resetPasswordNewPassword'
}

export const COOKIES = {
    SM_SESSION: 'smSession',
    WIX_CLIENT: 'wixClient',
    SM_EF: 'sm_ef'
}

export const APPS = {
    MEMBER_AREA: '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9',
    MEMBER_INFO: '14cffd81-5215-0a7f-22f8-074b0e2401fb'
}

export const DIALOGS_SKINS = {
    [DIALOGS.Login]: {
        themeStyled: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.memberLoginDialogSkinNew',
        socialMobileThemeStyled: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.memberLoginDialogSkinSocialMobile',
        themeStyledMaterial: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.memberLoginDialogSkinMaterial',
        socialMobileThemeStyledMaterial: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.memberLoginDialogSkinMaterial'
    },
    [DIALOGS.SignUp]: {
        themeStyled: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.signUpDialogSkinNew',
        socialMobileThemeStyled: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.signUpDialogSkinSocialMobile',
        themeStyledMaterial: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.signUpDialogSkinMaterial',
        socialMobileThemeStyledMaterial: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.signUpDialogSkinMaterial'
    },
    [DIALOGS.ResetPasswordEmail]: {
        themeStyled: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.requestPasswordResetDialogSkinNew',
        themeStyledMaterial: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.requestPasswordResetDialogSkinMaterial'
    },
    [DIALOGS.ResetPasswordNewPassword]: {
        themeStyled: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.resetPasswordDialogSkinNew',
        themeStyledMaterial: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.resetPasswordDialogSkinMaterial'
    },
    [DIALOGS.Notification]: {
        themeStyled: 'wysiwyg.viewer.skins.dialogs.notificationDialogSkinNew',
        themeStyledMaterial: 'wysiwyg.viewer.skins.dialogs.notificationDialogSkinMaterial'
    },
    [DIALOGS.PasswordProtected]: {
        themeStyled: 'wysiwyg.viewer.skins.dialogs.enterPasswordDialogSkinNew',
        themeStyledMaterial: 'wysiwyg.viewer.skins.dialogs.enterPasswordDialogSkinMaterial'
    },
    [DIALOGS.EmailVerification]: {
        themeStyled: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.emailVerificationDialogSkinNew',
        fixedStyle: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.emailVerificationDialogSkinFixed',
        themeStyledMaterial: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.emailVerificationDialogSkinMaterial'
    },
    [DIALOGS.SentConfirmationEmail]: {
        themeStyled: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.sentConfirmationEmail',
        themeStyledMaterial: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.sentConfirmationEmailMaterial'
    },
    [DIALOGS.Welcome]: {
        themeStyled: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.welcomeDialogSkinNew',
        fixedStyle: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.welcomeDialogSkinFixed',
        themeStyledMaterial: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.welcomeDialogSkinMaterial'
    },
    [DIALOGS.NoPermissionsToPage]: {
        themeStyled: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.noPermissionsToPage',
        themeStyledMaterial: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.noPermissionsToPageMaterial'
    }
}

const VIEWER_DIALOG_PREFIX = 'wysiwyg.viewer.components.dialogs'
const SITE_MEMBERS_DIALOG_PREFIX = `${VIEWER_DIALOG_PREFIX}.siteMemberDialogs`

export const DIALOG_STRUCTURE = {
    [DIALOGS.Login]: {
        viewerName:
            `${SITE_MEMBERS_DIALOG_PREFIX}.MemberLoginDialog`,
        id: 'memberLoginDialog'
    },
    [DIALOGS.SignUp]: {
        viewerName:
            `${SITE_MEMBERS_DIALOG_PREFIX}.SignUpDialog`,
        id: 'signUpDialog'
    },
    [DIALOGS.ResetPasswordEmail]: {
        viewerName:
            `${SITE_MEMBERS_DIALOG_PREFIX}.RequestPasswordResetDialog`,
        id: 'requestResetPasswordDialog'
    },
    [DIALOGS.ResetPasswordNewPassword]: {
        viewerName:
            `${SITE_MEMBERS_DIALOG_PREFIX}.ResetPasswordDialog`,
        id: 'resetPasswordDialog'
    },
    [DIALOGS.Notification]: {
        viewerName:
            `${VIEWER_DIALOG_PREFIX}.NotificationDialog`,
        id: 'notificationDialog'
    },
    [DIALOGS.Credits]: 'credits',
    [DIALOGS.PasswordProtected]: {
        viewerName:
            `${VIEWER_DIALOG_PREFIX}.EnterPasswordDialog`,
        id: 'enterPasswordDialog'
    },
    [DIALOGS.EmailVerification]: {
        viewerName:
            `${SITE_MEMBERS_DIALOG_PREFIX}.EmailVerificationDialog`,
        id: 'emailVerificationDialog'
    },
    [DIALOGS.SentConfirmationEmail]: {
        viewerName:
            `${SITE_MEMBERS_DIALOG_PREFIX}.SentConfirmationEmailDialog`,
        id: 'sentConfirmationEmailDialog'
    },
    [DIALOGS.Welcome]: {
        viewerName:
            `${SITE_MEMBERS_DIALOG_PREFIX}.WelcomeDialog`,
        id: 'welcomeDialog'
    },
    [DIALOGS.NoPermissionsToPage]: {
        viewerName:
            `${SITE_MEMBERS_DIALOG_PREFIX}.NoPermissionsToPageDialog`,
        id: 'noPermissionsToPageDialog'
    }
}
