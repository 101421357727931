'use strict'
const React = require('react')
const PropTypes = require('prop-types')
const {ASPECT_COMPS_CONTAINER_ID} = require('../utils/constants')

const COMP_TYPE = 'ASPECT_COMPS_CONTAINER'

/**
 * @class components.AspectCompsContainer
 */
class AspectCompsContainer extends React.Component {
    render() {
        return (
            <div
                key={ASPECT_COMPS_CONTAINER_ID}
                id={ASPECT_COMPS_CONTAINER_ID}
                className='siteAspectsContainer'
            >
                {this.props.children}
            </div>
        )
    }
}

AspectCompsContainer.displayName = 'AspectCompsContainer'
AspectCompsContainer.compType = COMP_TYPE
AspectCompsContainer.propTypes = {
    children: PropTypes.node
}

module.exports = AspectCompsContainer
