define([
    'componentsCore/activities/tpaActivity',
    'componentsCore/activities/contactFormActivity',
    'componentsCore/activities/dynamicContactFormActivity',
    'componentsCore/activities/subscribeFormActivity'
], function (
    TPAActivity,
    ContactFormActivity,
    DynamicContactFormActivity,
    SubscribeFormActivity
) {
    'use strict';

    return {
        TPAActivity,
        ContactFormActivity,
        DynamicContactFormActivity,
        SubscribeFormActivity
    };
});
