'use strict'
const _ = require('lodash')
const {ASPECT_COMPS_CONTAINER_ID} = require('./constants')
const rendererFunctionLibrary = require('../aspects/ComponentsModelAspect/carmi/functionLibrary')

const actionBehaviorsExtension = require('../aspects/BehaviorsAspect/actionBehaviorsExtension')
const aspectCompsContainerExtension = require('../aspects/AspectCompsContainerExtension/aspectCompsContainerExtension')
const runtimeDalExtension = require('../aspects/ComponentsModelAspect/runtimeDal/runtimeDALExtension')
const componentsRenderExtension = require('../aspects/ComponentsRenderAspect/componentsRenderExtension')
const rendererRuntimes = [
    runtimeDalExtension,
    actionBehaviorsExtension,
    componentsRenderExtension,
    aspectCompsContainerExtension
]

const buildDefaultFunclib = (eventsManager, schemes, renderingFunctionsLib, hostApiFunctionLib) => _.assign(rendererFunctionLibrary.create(eventsManager, schemes), renderingFunctionsLib, hostApiFunctionLib)

const buildFuncLib = (eventsManager, runtimeModules, schemes = {}, renderingFunctionsLib, hostApiFunctionLib, functionLibraryOverrides) =>
    _.chain(runtimeModules)
        .filter('functionLibrary')
        .reduce((acc, {functionLibrary}) => _.assign(acc, functionLibrary), buildDefaultFunclib(eventsManager, schemes, renderingFunctionsLib, hostApiFunctionLib))
        .assign(functionLibraryOverrides)
        .value()


const buildDefaultModel = runtimeModules => _(rendererRuntimes)
    .concat(runtimeModules)
    .filter('defaultModel')
    .reduce((acc, {name, defaultModel}) => _.assign(acc, {[name]: defaultModel}), {})

const initInstance = (carmiInstance, eventsManager, runtimeModules, logger, exceptionHandlingApi) => {
    exceptionHandlingApi = exceptionHandlingApi || {throwException: e => {throw e}}

    _(rendererRuntimes)
        .concat(runtimeModules)
        .filter('init')
        .forEach(({init, name}) => init(carmiInstance, {eventsManager, initialData: carmiInstance.initMethodsData[name], logger, exceptionHandlingApi}))

    return carmiInstance
}

module.exports = {
    buildFuncLib,
    buildDefaultModel,
    constants: {
        ASPECT_COMPS_CONTAINER_ID
    },
    initInstance
}
