define(['lodash', 'warmupUtils/core/layoutUtils', 'warmupUtilsLib'],
    function (_, layoutUtils, warmupUtilsLib) {
        'use strict';

        function getPositionAndSizeAPI(shouldRoundDock) {
            function getParentWidth(layout, parentDimension, clientSize, siteWidth) {
                if (layout.fixedPosition) {
                    return clientSize.width;
                }
                if (warmupUtilsLib.dockUtils.isHorizontalDockToScreen(layout)) {
                    return _.max([clientSize.width, siteWidth]);
                }
                return parentDimension.width;
            }

            function getParentHeight(layout, parentDimension, clientSize) {
                if (layout.fixedPosition || warmupUtilsLib.dockUtils.isVerticallyDockToScreen(layout)) {
                    return clientSize.height;
                }

                return parentDimension.height;
            }

            function getHorizontalDockInPixels(horizontalDock, parentWidth, clientWidth, siteWidth) { // eslint-disable-line complexity
                clientWidth = _.max([clientWidth, siteWidth]);

                const percentVal = horizontalDock && horizontalDock.pct && horizontalDock.pct / 100 * parentWidth || 0; // eslint-disable-line no-mixed-operators
                const pixelVal = horizontalDock && horizontalDock.px || 0; // eslint-disable-line no-mixed-operators
                const viewportWidthVal = horizontalDock && horizontalDock.vw && horizontalDock.vw / 100 * clientWidth || 0; // eslint-disable-line no-mixed-operators

                const horizontalDockInPixels = percentVal + pixelVal + viewportWidthVal;

                return shouldRoundDock ? Math.ceil(horizontalDockInPixels) : horizontalDockInPixels;
            }

            function getVerticalDockInPixels(verticalDock, parentHeight, clientHeight) {
                const percentVal = verticalDock.pct && verticalDock.pct / 100 * parentHeight || 0; // eslint-disable-line no-mixed-operators
                const pixelVal = verticalDock.px || 0;
                const viewportHeightVal = verticalDock.vh && verticalDock.vh / 100 * clientHeight || 0; // eslint-disable-line no-mixed-operators

                const verticalDockInPixels = percentVal + pixelVal + viewportHeightVal;

                return shouldRoundDock ? Math.ceil(verticalDockInPixels) : verticalDockInPixels;
            }

            function getWidthInPixels(layout, parentDimension, clientSize, siteWidth) {
                if (layoutUtils.isHorizontallyStretched(layout)) {
                    const parentWidth = getParentWidth(layout, parentDimension, clientSize, siteWidth);
                    const docked = layout.docked;
                    const leftDock = getHorizontalDockInPixels(docked.left, parentWidth, clientSize.width, siteWidth);
                    const rightDock = getHorizontalDockInPixels(docked.right, parentWidth, clientSize.width, siteWidth);

                    return parentWidth - (leftDock + rightDock);
                }

                return layout.width;
            }

            function getHeightInPixels(layout, parentDimension, clientSize, widthInPixels) {
                if (layoutUtils.isAspectRatioOn(layout)) {
                    return widthInPixels * layout.aspectRatio;
                }

                if (layoutUtils.isVerticallyStretched(layout)) {
                    const parentHeight = getParentHeight(layout, parentDimension, clientSize);
                    const docked = layout.docked;
                    const topDock = getVerticalDockInPixels(docked.top, parentHeight, clientSize.height);
                    const bottomDock = getVerticalDockInPixels(docked.bottom, parentHeight, clientSize.height);
                    return parentHeight - (topDock + bottomDock);
                }

                return layout.height;
            }

            function getHeightInPixelsPublic(layout, parentDimension, clientSize, siteWidth) {
                const widthInPixels = getWidthInPixels(layout, parentDimension, clientSize, siteWidth);

                return getHeightInPixels(layout, parentDimension, clientSize, widthInPixels);
            }

            function getSiteX(clientWidth, siteWidth) {
                return _.max([(clientWidth - siteWidth) / 2, 0]);
            }

            function getXInPixels(layout, parentDimension, clientSize, siteWidth, rootLeft, widthInPixels) {
                const docked = layout.docked;
                if (docked) {
                    const parentWidth = getParentWidth(layout, parentDimension, clientSize, siteWidth);
                    if (docked.left) {
                        let leftRelativeToParent = getHorizontalDockInPixels(docked.left, parentWidth, clientSize.width, siteWidth);

                        if (warmupUtilsLib.dockUtils.isHorizontalDockToScreen(layout)) {
                            const siteX = _.isUndefined(rootLeft) ? getSiteX(clientSize.width, siteWidth) : rootLeft;
                            leftRelativeToParent -= siteX;
                        }

                        return leftRelativeToParent;
                    }

                    if (docked.right) {
                        const rightDock = getHorizontalDockInPixels(docked.right, parentWidth, clientSize.width, siteWidth);
                        return parentWidth - (widthInPixels + rightDock);
                    }

                    if (docked.hCenter) {
                        const centerDock = getHorizontalDockInPixels(docked.hCenter, parentWidth, clientSize.width, siteWidth);
                        return (parentWidth - widthInPixels) / 2 + centerDock; // eslint-disable-line no-mixed-operators
                    }
                }

                return layout.x;
            }

            function getYInPixels(layout, parentDimension, clientSize, heightInPixels) {
                const docked = layout.docked;
                if (docked) {
                    const parentHeight = getParentHeight(layout, parentDimension, clientSize);

                    if (layoutUtils.isVerticallyStretchedToScreen(layout)) {
                        return layout.y;
                    }

                    if (docked.top) {
                        return getVerticalDockInPixels(docked.top, parentHeight, clientSize.height);
                    }

                    if (docked.bottom) {
                        const bottomDock = getVerticalDockInPixels(docked.bottom, parentHeight, clientSize.height);
                        return parentHeight - (heightInPixels + bottomDock);
                    }

                    if (docked.vCenter) {
                        const centerDock = getVerticalDockInPixels(docked.vCenter, parentHeight, clientSize.height);
                        return (parentHeight - heightInPixels) / 2 + centerDock; // eslint-disable-line no-mixed-operators
                    }
                }

                return layout.y;
            }

            function isVerbsLayout(layout) {
                return layout && !!(layout.docked || layout.aspectRatio);
            }


            function getAbsolutePositionAndSize(layout) {
                return _.pick(layout, ['x', 'y', 'width', 'height']);
            }

            function getVerbsPositionAndSize(layout, parentDimension, clientSize, siteWidth, rootLeft) {
                const widthInPixels = getWidthInPixels(layout, parentDimension, clientSize, siteWidth);
                const heightInPixels = getHeightInPixels(layout, parentDimension, clientSize, widthInPixels);

                return {
                    x: getXInPixels(layout, parentDimension, clientSize, siteWidth, rootLeft, widthInPixels),
                    y: getYInPixels(layout, parentDimension, clientSize, heightInPixels),
                    width: widthInPixels,
                    height: heightInPixels
                };
            }

            function getPositionAndSize(layout, parentDimension, clientSize, siteWidth, rootLeft) {
                if (!isVerbsLayout(layout)) {
                    return getAbsolutePositionAndSize(layout);
                }

                return getVerbsPositionAndSize(layout, parentDimension, clientSize, siteWidth, rootLeft);
            }

            return {
                isVerbsLayout,
                getVerbsPositionAndSize,
                getAbsolutePositionAndSize,
                getYInPixels,
                'getHeightInPixels': getHeightInPixelsPublic,
                getPositionAndSize
            };
        }

        const normalAPI = getPositionAndSizeAPI(false);
        const roundingAPI = getPositionAndSizeAPI(true);

        Object.freeze(normalAPI);
        Object.freeze(roundingAPI);

        return {
            getYInPixelsRounded: roundingAPI.getYInPixels,
            getHeightInPixelsRounded: roundingAPI.getHeightInPixels,
            getPositionAndSizeRounded: roundingAPI.getPositionAndSize,
            getYInPixels: normalAPI.getYInPixels,
            getHeightInPixels: normalAPI.getHeightInPixels,
            isVerbsLayout: normalAPI.isVerbsLayout,
            getVerbsPositionAndSize: normalAPI.getVerbsPositionAndSize,
            getAbsolutePositionAndSize: normalAPI.getAbsolutePositionAndSize,
            getPositionAndSize: normalAPI.getPositionAndSize
        };
    });
