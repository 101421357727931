const constants = require('santa-renderer/src/utils/constants')
const {
    ID,
    DATA_QUERY,
    DESIGN_QUERY
} = constants.pointers.components.PROPERTY_TYPES

export const defaultLayoutProps = {
    // TPA
    'wysiwyg.viewer.components.tpapps.TPAWidget': [DATA_QUERY],
    'wysiwyg.viewer.components.tpapps.TPASection': [DATA_QUERY],
    'wysiwyg.viewer.components.tpapps.TPAMultiSection': [DATA_QUERY],
    'wysiwyg.viewer.components.tpapps.TPAGluedWidget': [DATA_QUERY],

    // Images
    'wysiwyg.viewer.components.mobile.TinyMenu': [DATA_QUERY],
    'wysiwyg.common.components.rssbutton.viewer.RSSButton': [DATA_QUERY],
    'wysiwyg.viewer.components.SliderGallery': [DATA_QUERY],
    'wysiwyg.viewer.components.SlideShowGallery': [DATA_QUERY],
    'wysiwyg.viewer.components.documentmedia.DocumentMedia': [DATA_QUERY],
    'wysiwyg.viewer.components.BgImageStrip': [DATA_QUERY],
    'wysiwyg.common.components.imagebutton.viewer.ImageButton': [DATA_QUERY],
    'wysiwyg.viewer.components.WPhoto': [DATA_QUERY],
    'wysiwyg.viewer.components.ClipArt': [DATA_QUERY],
    'wysiwyg.viewer.components.LoginSocialBar': [DATA_QUERY],
    'wysiwyg.viewer.components.MatrixGallery': [DATA_QUERY],
    'wysiwyg.viewer.components.PaginatedGridGallery': [DATA_QUERY],
    'wysiwyg.components.imageZoom': [DATA_QUERY],

    // Balata
    'wysiwyg.viewer.components.StripContainerSlideShow': [DESIGN_QUERY],
    'wysiwyg.viewer.components.StripContainerSlideShowSlide': [DESIGN_QUERY],
    'wysiwyg.viewer.components.BoxSlideShow': [DESIGN_QUERY],
    'wysiwyg.viewer.components.BoxSlideShowSlide': [DESIGN_QUERY],
    'wysiwyg.viewer.components.Column': [DESIGN_QUERY],
    'wysiwyg.viewer.components.MediaBox': [DESIGN_QUERY],
    'wysiwyg.viewer.components.HoverBox': [DESIGN_QUERY],
    'wysiwyg.viewer.components.MediaPlayer': [DESIGN_QUERY],
    'wysiwyg.viewer.components.MediaContainer': [DESIGN_QUERY],
    'wysiwyg.viewer.components.MenuContainer': [DESIGN_QUERY],
    'wysiwyg.viewer.components.StripColumnsContainer': [DESIGN_QUERY],
    'wysiwyg.viewer.components.background.bgImage': [DATA_QUERY, DESIGN_QUERY],
    'wysiwyg.viewer.components.PopupContainer': [DESIGN_QUERY],

    // pages
    'mobile.core.components.Page': [ID, DESIGN_QUERY],
    'wixapps.integration.components.AppPage': [DATA_QUERY],
    'mobile.core.components.MasterPage': [DATA_QUERY]
}
