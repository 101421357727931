/* Autogenerated file. Do not modify */
define([], function() {
    'use strict';
    var skins = {};
     skins['skins.viewer.bgImage.bgImageSkin'] = {
  "react": [
    [
      "div",
      "image",
      [],
      {}
    ]
  ],
  "css": {}
}
 skins['skins.viewer.bgMedia.bgMediaSkin'] = {
  "react": [],
  "css": {}
}
 skins['skins.viewer.bgOverlay.bgOverlaySkin'] = {
  "react": [
    [
      "div",
      "overlay",
      [],
      {}
    ]
  ]
}
 skins['skins.viewer.bgVideo.html5VideoSkin'] = {
  "react": [
    [
      "video",
      "video",
      [],
      {
        "role": "presentation"
      }
    ],
    [
      "div",
      "poster",
      [],
      {}
    ]
  ],
  "exports": {
    "poster": {
      "skin": "skins.core.ImageNewSkinZoomable"
    }
  },
  "css": {}
}
 skins['skins.viewer.bgVideo.iframeVideoSkin'] = {
  "react": [
    [
      "div",
      "video",
      [],
      {}
    ],
    [
      "div",
      "poster",
      [],
      {}
    ]
  ],
  "exports": {
    "poster": {
      "skin": "skins.core.ImageNewSkinZoomable"
    }
  },
  "css": {}
}
 skins['skins.viewer.bgVideo.webglMediaSkin'] = {
  "react": [
    [
      "canvas",
      "canvas",
      [],
      {}
    ]
  ],
  "css": {}
}

    return skins;
});
