import {withActions} from '../withActions'

export const name = 'SiteVisibilityAspect'

export const VISIBILITY_STATE = 'state'

export const defaultModel = {
    [VISIBILITY_STATE]: {
        hidden: true
    }
}

export const functionLibrary = {
    updateVisibilityState: withActions(({setDocumentHidden}, windowObject) => {
        if (windowObject && windowObject.document) {
            setDocumentHidden(windowObject.document.hidden)
        }
    })
}

export function init({}, {eventsManager, initialData: {updateVisibilityState}}) {
    eventsManager.on('visibilityChange', updateVisibilityState)
}
