import {withActions} from 'carmi-host-extensions/src/aspects/withActions'

export const name = 'platformDSCommunicationAspect'
export const defaultModel = {
    notifyApplicationRequestCallbacks: []
}

export const functionLibrary = {
    registerToNotifyApplicationRequestFromViewerWorker: withActions(({setNotifyApplicationRequestCb}, getNotifyApplicationRequestCallbacksArr, cb) => {
        setNotifyApplicationRequestCb(getNotifyApplicationRequestCallbacksArr().length, 0, cb)
    }),
    triggerNotifyApplicationRequest: (getNotifyApplicationRequestCallbacksArr, appDefinitionId, data) => {
        getNotifyApplicationRequestCallbacksArr().forEach(cb => cb(appDefinitionId, data))
    }
}
