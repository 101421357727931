define(['lodash', 'warmupUtils/pointers/pointerGeneratorsRegistry', 'warmupUtils/pointers/DataAccessPointers'], function (_, pointerGeneratorsRegistry, DataAccessPointers) {
    'use strict';

    const type = 'platform';
    const pointers = new DataAccessPointers(); // eslint-disable-line santa/no-module-state

    pointerGeneratorsRegistry.registerPointerType(type, _.constant(null), _.constant(true));

    function getPlatformPointer(getItemAt, cache) {
        return cache.getPointer('platform', type, ['platform']);
    }

    function getAppStatePointer(getItemAt, cache) {
        const platformPointer = getPlatformPointer(getItemAt, cache);
        return pointers.getInnerPointer(platformPointer, 'appState');
    }

    function getControllerStatePointer(getItemAt, cache, controllerId) {
        return cache.getPointer(`${controllerId} state`, type, ['platform', 'appState', controllerId]);
    }

    function getAppManifestPointer(getItemAt, cache, appId) {
        return cache.getPointer(`appManifest ${appId}`, type, ['platform', 'appManifest', appId]);
    }

    function appPublicApiNamePointer(getItemAt, cache, appId) {
        return cache.getPointer(`appPublicApiName ${appId}`, type, ['platform', 'appPublicApiName', appId]);
    }

    function getControllerStageDataPointer(getItemAt, cache, appId, controllerType, controllerState) {
        return cache.getPointer(`controllerStageData ${appId}${controllerType}${controllerState}`, type, ['platform', 'appManifest', appId, 'controllersStageData', controllerType, controllerState]);
    }

    function getPagesPlatformApplicationsPointer(getItemAt, cache) {
        return cache.getPointer('pagesPlatformApplications', type, ['pagesPlatformApplications']);
    }

    function getPagesPlatformApplicationPointer(getItemAt, cache, appId) {
        return cache.getPointer(`${appId} pages`, type, ['pagesPlatformApplications', appId]);
    }

    const getterFunctions = Object.freeze({
        getPlatformPointer,
        getAppStatePointer,
        getControllerStatePointer,
        getControllerStageDataPointer,
        getAppManifestPointer,
        appPublicApiNamePointer,
        getPagesPlatformApplicationsPointer,
        getPagesPlatformApplicationPointer
    });

    pointerGeneratorsRegistry.registerDataAccessPointersGenerator(type, getterFunctions);
});
