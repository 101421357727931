import React from 'react'
import ReactDOM from 'react-dom'
import {Renderer} from 'santa-renderer'

const createBoltSiteElement = boltInstance => <Renderer instance={boltInstance} />

const markRenderDone = measureName => {
    console.log(`finished ${measureName}`, performance.now())
    performance.mark(`${measureName} complete`)
}

const renderClientSide = (boltInstance, rootElement, hydrate, serverMarkup, indicator, logger) => {
    const renderType = hydrate ? 'serverRenderFlow' : 'clientRenderFlow'
    logger.appLoadingPhaseStart(renderType)
    const boltSiteElement = createBoltSiteElement(boltInstance)

    return new Promise(resolve => {
        if (hydrate) {
            ReactDOM.hydrate(boltSiteElement, rootElement,
                () => {
                    markRenderDone('hydrate')
                    boltInstance.setIsFirstRenderAfterSSR(false)
                    boltInstance.setDidHydrate(true)
                    // console.log(isWithoutDiverges(serverMarkup, clientMarkup))
                    window.sssr.success = true
                    indicator.updateState(indicator.STATES.SUCCESS)
                    resolve()
                })
        } else {
            ReactDOM.render(boltSiteElement, rootElement,
                () => {
                    markRenderDone('render')
                    resolve()
                })
        }
    }).then(() => {
        logger.appLoadingPhaseFinish(renderType)
    }).catch(e => {
        logger.captureError(e, {tags: {renderType}})
    })
}

export {
    createBoltSiteElement,
    renderClientSide
}
