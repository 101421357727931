'use strict'

const _ = require('lodash')
const constants = require('../../utils/constants')
const runtimeUtil = require('../../utils/runtime')

const {
    PROPERTY_QUERY
} = constants.pointers.components.PROPERTY_TYPES

const {
    DATA,
    DESIGN,
    PROPERTIES
} = constants.pointers.data.DATA_MAPS

const DISPLAYED_ONLY_DELIMITER = '__'
const getDisplayedId = (originalId, itemId) => `${originalId}${DISPLAYED_ONLY_DELIMITER}${itemId}`

const createRepeaterRuntimeHandler = (model, addActionsAndBehaviors, removeActionsAndBehaviors) => {
    const getChildrenRecursively = compId => {
        const comp = model.full.structure[compId]
        return comp.components ? [...comp.components, ..._.flatMap(comp.components, getChildrenRecursively)] : []
    }

    function handleAddedRepeaterItems(repeaterId, newItems) {
        if (!newItems.length) {
            return
        }

        const repeaterTemplateComponents = getChildrenRecursively(repeaterId)
        _.forEach(newItems, newItemId => {
            _.forEach(repeaterTemplateComponents, templateCompId => {
                const displayedCompId = getDisplayedId(templateCompId, newItemId)

                const templateComp = model.full.structure[templateCompId]
                if (templateComp.dataQuery) {
                    const originalDataId = templateComp.dataQuery.replace('#', '')
                    const templateDataItem = model.full.data[DATA][originalDataId]
                    const templateOverridesId = runtimeUtil.getRuntimeId(templateCompId, originalDataId)
                    const templateOverrides = model.runtime.data[DATA][templateOverridesId]
                    const displayedItemDataId = getDisplayedId(originalDataId, newItemId)
                    const runtimeId = runtimeUtil.getRuntimeId(displayedCompId, displayedItemDataId)
                    const itemDataItem = {...templateDataItem, ...templateOverrides, id: runtimeId}
                    model.updateRuntimeDataOverrides(DATA, runtimeId, itemDataItem)
                }

                if (templateComp.designQuery) {
                    const originalDesignId = templateComp.designQuery.replace('#', '')
                    const templateDesignItem = model.full.data[DESIGN][originalDesignId]
                    const templateOverridesId = runtimeUtil.getRuntimeId(templateCompId, originalDesignId)
                    const templateOverrides = model.runtime.data[DESIGN][templateOverridesId]
                    const displayedItemDesignId = getDisplayedId(originalDesignId, newItemId)
                    const runtimeId = runtimeUtil.getRuntimeId(displayedCompId, displayedItemDesignId)
                    const itemDesignItem = {...templateDesignItem, ...templateOverrides, id: runtimeId}
                    model.updateRuntimeDataOverrides(DESIGN, runtimeId, itemDesignItem)
                }

                const templateCompRuntimeBehaviors = model.runtimeActionBehaviorsForComponent[templateComp.id]
                if (!_.isEmpty(templateCompRuntimeBehaviors)) {
                    _.forEach(templateCompRuntimeBehaviors, actionBehavior => {
                        addActionsAndBehaviors(displayedCompId, actionBehavior)
                    })
                }

                if (templateComp[PROPERTY_QUERY]) {
                    const originalPropsId = templateComp[PROPERTY_QUERY].replace('#', '')
                    const templatePropsOverridesId = runtimeUtil.getRuntimeId(templateCompId, originalPropsId)
                    const templatePropsOverrides = model.runtime.data[PROPERTIES][templatePropsOverridesId]

                    if (templatePropsOverrides) {
                        const templatePropsItem = model.full.data[PROPERTIES][originalPropsId]
                        const itemPropsId = runtimeUtil.getRuntimeId(displayedCompId, originalPropsId)
                        const itemPropsItem = {...templatePropsItem, ...templatePropsOverrides, id: itemPropsId}

                        model.updateRuntimeDataOverrides(PROPERTIES, itemPropsId, itemPropsItem)
                    }
                }
            })
        })
    }

    function handleRemovedRepeaterItems(repeaterId, removedItems) {
        if (!removedItems.length) {
            return
        }

        const repeaterTemplateComponents = getChildrenRecursively(repeaterId)
        _.forEach(removedItems, removedItemId => {
            _.forEach(repeaterTemplateComponents, templateCompId => {
                const displayedCompId = getDisplayedId(templateCompId, removedItemId) // comp1__item2

                const templateComp = model.full.structure[templateCompId]
                if (templateComp.dataQuery) {
                    const originalDataId = templateComp.dataQuery.replace('#', '') // data1
                    const displayedItemDataId = getDisplayedId(originalDataId, removedItemId) // data1__item2
                    const runtimeId = runtimeUtil.getRuntimeId(displayedCompId, displayedItemDataId)
                    model.updateRuntimeDataOverrides(DATA, runtimeId)
                }

                if (templateComp.designQuery) {
                    const originalDesignId = templateComp.designQuery.replace('#', '')
                    const itemDesignId = getDisplayedId(originalDesignId, removedItemId)
                    model.updateRuntimeDataOverrides(DESIGN, itemDesignId)
                }

                /*const currentCompRuntimeBehaviors = model.runtimeActionBehaviorsForComponent[displayedCompId]
                _.forEach(currentCompRuntimeBehaviors, actionBehavior => {
                    removeActionsAndBehaviors(displayedCompId, actionBehavior.action.name)
                })*/

                if (templateComp[PROPERTY_QUERY]) {
                    const originalPropsId = templateComp[PROPERTY_QUERY].replace('#', '')
                    const templatePropsOverridesId = runtimeUtil.getRuntimeId(templateCompId, originalPropsId)
                    const templatePropsOverrides = model.runtime.data[PROPERTIES][templatePropsOverridesId]

                    if (templatePropsOverrides) {
                        const itemPropsId = runtimeUtil.getRuntimeId(displayedCompId, originalPropsId)
                        model.updateRuntimeDataOverrides(PROPERTIES, itemPropsId)
                    }
                }
            })
        })
    }

    const handleRepeaterDataChange = (repeaterId, originalRepeaterData, currentOverrides, newRepeaterOverrides) => {
        if (!newRepeaterOverrides.items) {
            return
        }

        const currentItems = currentOverrides && currentOverrides.items || originalRepeaterData.items
        const newItems = _.difference(newRepeaterOverrides.items, currentItems)
        const removedItems = _.difference(currentItems, newRepeaterOverrides.items)

        handleAddedRepeaterItems(repeaterId, newItems, addActionsAndBehaviors)
        handleRemovedRepeaterItems(repeaterId, removedItems, removeActionsAndBehaviors)
    }

    return {
        handleRepeaterDataChange
    }
}

module.exports = {
    createRepeaterRuntimeHandler
}
