const KEYWORD = 'keyword'
const LENGTH_OR_PERCENTAGE = 'length_or_percentage'
const COLOR_RGBA = 'rgbcolor'
const BR_WIDTH = 'br_width'
const BORDER_WIDTH = 'border_width'
const BORDER_STYLE = 'border_style'
const BORDER_COLOR = 'border_color'
export {
    KEYWORD,
    LENGTH_OR_PERCENTAGE,
    COLOR_RGBA,
    BR_WIDTH,
    BORDER_WIDTH,
    BORDER_STYLE,
    BORDER_COLOR
}

