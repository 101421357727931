/**
 * Created by noamr on 14/03/2017.
 */
define(['lodash'], function (_) {
    'use strict';

    const objects = []; // eslint-disable-line santa/no-module-state
    function freeze(object) {
        objects.push(object);
    }

    function start() {
        _.forEach(objects, Object.freeze);
    }

    return {
        freeze,
        start
    };
});
