define([
    'lodash',
    'coreUtils/core/wixUserApi'
], function (
    _,
    wixUserApi
) {
    'use strict';
    const FACEBOOK_SDK_VERSION = 'v2.4';
    const FB_APP_ID = '304553036307597';

    const facebookSDKTemplate = _.template('//connect.facebook.net/<%= lang %>/sdk.js#xfbml=1&appId=<%= appId %>&version=<%= sdkVersion %>'); // eslint-disable-line santa/no-module-state

    function getFacebookSdkLanguage(context) {
        const languageDecode = {
            en: 'en_US',
            es: 'es_ES',
            pt: 'pt_BR',
            ru: 'ru_RU',
            fr: 'fr_FR',
            de: 'de_DE',
            ja: 'ja_JP',
            ko: 'ko_KR',
            it: 'it_IT',
            pl: 'pl_PL',
            tr: 'tr_TR',
            nl: 'nl_NL',
            sv: 'sv_SE',
            da: 'da_DK',
            no: 'nn_NO'
        };

        return languageDecode[wixUserApi.getLanguage(context.cookie, context.currentUrl, context.userLanguage)] || languageDecode.en;
    }

    function getScriptDescription(scriptName, context) {
        if (scriptName === 'FACEBOOK') {
            return {
                NAME: 'FacebookSDK',
                SRC: facebookSDKTemplate({lang: getFacebookSdkLanguage(context), sdkVersion: FACEBOOK_SDK_VERSION, appId: FB_APP_ID})
            };
        }
        if (scriptName === 'GOOGLE') {
            return {
                NAME: 'GoogleApi',
                SRC: '//apis.google.com/js/plusone.js',
                actionBefore() {
                    window.___gcfg = {
                        lang: wixUserApi.getLanguage(context.cookie, context.currentUrl, context.userLanguage)
                    };
                }
            };
        }
        if (scriptName === 'LINE') {
            return {
                NAME: 'LineApi',
                SRC: 'https://d.line-scdn.net/r/web/social-plugin/js/thirdparty/loader.min.js'
            };
        }
    }

    return {
        getScriptDescription
    };
});
