import _ from 'lodash'
import {withActions} from '../withActions'
export const name = 'AudioAspect'

export const defaultModel = {
    currentPlayingComp: '',
    soundManagerInstance: null,
    retryTimeoutRef: null,
    isSoundManagerOnResetup: false,
    isSoundManagerReady: false,
    shouldLoadSoundManager: false
}

const getSoundManagerBaseUrl = scriptsDomainUrl => {
    const normalizedUrl = !scriptsDomainUrl.endsWith('/') ? `${scriptsDomainUrl}/` : scriptsDomainUrl
    return `${normalizedUrl}services/third-party/soundmanager2/V2.97a.20150601/`
}

const getSoundManagerSetupConfig = (scriptsDomainUrl, customSettings) => _.assign({
    url: getSoundManagerBaseUrl(scriptsDomainUrl),
    flashVersion: 9,
    preferFlash: false,
    useHTML5Audio: true,
    forceUseGlobalHTML5Audio: false,
    html5PollingInterval: 100
}, customSettings || {})

const setupFallBackSoundManager = (aspectActions, scriptsDomainUrl, soundManagerInstance) => {
    const setupConfig = getSoundManagerSetupConfig(scriptsDomainUrl, {
        preferFlash: true,
        useHTML5Audio: false,
        onready: () => {
            aspectActions.setSoundManagerOnResetup(false)
            aspectActions.setIsSoundManagerReady(true)
        }
    })
    soundManagerInstance.destruct()
    soundManagerInstance.reset().setup(setupConfig)
    soundManagerInstance.beginDelayedInit()
}

export const functionLibrary = {
    soundManagerLoaded: withActions((aspectActions, scriptsDomainUrl, soundManagerPackage) => {
        let onReadyCalled = false

        const soundManagerInstance = soundManagerPackage.getInstance()
        aspectActions.updateSoundManagerInstance(soundManagerInstance)

        const onready = () => {
            onReadyCalled = true
            aspectActions.setIsSoundManagerReady(true)
        }

        const setupConfig = getSoundManagerSetupConfig(scriptsDomainUrl, {onready})

        const setupSoundManager = () => {
            soundManagerInstance.setup(setupConfig)
        }

        const retrySetup = (retryNumber = 1, msSinceFirstSetup = 0) => {
            const nextRetryInMs = 10 * Math.pow(1.5, retryNumber - 1)
            const shouldStopRetrying = msSinceFirstSetup + nextRetryInMs > 10000

            if (shouldStopRetrying) {
                console.log('failed to setup SoundManager') //todo: should send bi instead
                return
            }

            const setupRetryTimeout = setTimeout(() => {
                if (!onReadyCalled) {
                    setupSoundManager()
                    retrySetup(retryNumber + 1, msSinceFirstSetup + nextRetryInMs)
                }
            }, nextRetryInMs)

            aspectActions.setRetryTimeoutRef(setupRetryTimeout)
        }

        setupSoundManager()
        retrySetup()
    }),
    onHTMLAudioLoadError: withActions((aspectActions, scriptsDomainUrl, soundManagerInstance, setupRetryTimeout) => {
        aspectActions.setSoundManagerOnResetup(true)
        aspectActions.setIsSoundManagerReady(false)

        clearTimeout(setupRetryTimeout)
        aspectActions.setRetryTimeoutRef(null)

        setupFallBackSoundManager(aspectActions, scriptsDomainUrl, soundManagerInstance)
    }),
    updatePlayingComp: withActions((aspectActions, isInSSR, comp) => {
        const compId = _.get(comp, ['props', 'id'], '')
        aspectActions.updatePlayingComp(compId)

        if (!isInSSR) {
            aspectActions.setShouldLoadSoundManager(true)
        }
    }),
    createAudioObj: withActions((aspectActions, soundManagerInstance, isInSSR, config) => {
        if (!soundManagerInstance && !isInSSR) {
            aspectActions.setShouldLoadSoundManager(true)
            return false
        }

        if (soundManagerInstance) {
            if (config.id && soundManagerInstance.getSoundById(config.id)) {
                soundManagerInstance.destroySound(config.id)
            }

            return soundManagerInstance.createSound(config)
        }
        return false
    })
}

