define(['lodash'], function (_) {
    'use strict';

    const BOX_SLIDE_SHOW_TYPE = 'wysiwyg.viewer.components.BoxSlideShow';
    const BOX_SLIDE_SHOW_SLIDE_TYPE = 'wysiwyg.viewer.components.BoxSlideShowSlide';

    const STRIP_SLIDE_SHOW_TYPE = 'wysiwyg.viewer.components.StripContainerSlideShow';
    const STRIP_SLIDE_SHOW_SLIDE_TYPE = 'wysiwyg.viewer.components.StripContainerSlideShowSlide';

    function getChildComponentTypeByStructure(child) {
        return child.componentType;
    }

    return {
        getNextSlideIndex(slidesIndexes, currentIndex) {
            let nextIndex = currentIndex + 1;
            if (nextIndex >= slidesIndexes.length) {
                nextIndex = 0;
            }
            return nextIndex;
        },

        getPrevSlideIndex(slidesIndexes, currentIndex) {
            let prevIndex = currentIndex - 1;
            if (prevIndex < 0) {
                prevIndex = slidesIndexes.length - 1;
            }
            return prevIndex;
        },

        getSlidesFromChildrenByStructure(children) {
            return _.filter(children, function (child) {
                return getChildComponentTypeByStructure(child) === BOX_SLIDE_SHOW_SLIDE_TYPE || getChildComponentTypeByStructure(child) === STRIP_SLIDE_SHOW_SLIDE_TYPE;
            });
        },

        getShownOnAllSlidesFromChildrenByStructure(children) {
            return _.reject(children, function (child) {
                return getChildComponentTypeByStructure(child) === BOX_SLIDE_SHOW_SLIDE_TYPE || getChildComponentTypeByStructure(child) === STRIP_SLIDE_SHOW_SLIDE_TYPE;
            });
        },

        isBoxOrStripSlideShowComponent(compType) {
            return compType === BOX_SLIDE_SHOW_TYPE || compType === STRIP_SLIDE_SHOW_TYPE;
        },

        isBoxOrStripSlideShowSlideComponent(compType) {
            return compType === BOX_SLIDE_SHOW_SLIDE_TYPE || compType === STRIP_SLIDE_SHOW_SLIDE_TYPE;
        },

        getMatchingChildSlideType(compType) {
            if (!this.isBoxOrStripSlideShowComponent(compType)) {
                throw new Error('invalid comp type, not a box or strip slide show component');
            }
            return compType === BOX_SLIDE_SHOW_TYPE ? BOX_SLIDE_SHOW_SLIDE_TYPE : STRIP_SLIDE_SHOW_SLIDE_TYPE;
        }
    };
});
