'use strict'
const {
    getPositionParams,
    getElementsAsArray
} = require('../../../utils/definitionsUtils')

const name = 'ModesMotionNoScale'
const properties = {}

function register({engine, factory}) {
    /**
     * Shift animation object
     * @param {Array<HTMLElement>|HTMLElement} elements DOM element to animate
     * @param {Number} duration
     * @param {Number} delay
     * @param {Object} params
     * @param {object} params.from
     * @param {number} params.from.left
     * @param {number} params.from.right
     * @param {number} params.from.width
     * @param {number} params.from.height
     * @param {number} params.from.rotation
     * @returns {TimelineMax}
     */
    function animation(elements, duration, delay, params) {
        elements = getElementsAsArray(elements)

        const sequence = factory.sequence(params)
        const {width, height} = params.from
        elements.forEach(element => {
            const elementViewPortDim = engine.getBoundingRect(element)
            const positionParams = getPositionParams(elementViewPortDim, params)
            const dimensionParams = {width, height}

            sequence.add(factory.animate('BasePosition', element, duration, delay, {
                from: positionParams,
                ease: 'Cubic.easeInOut'
            }), 0)
            sequence.add(factory.animate('BaseDimensions', element, duration, delay, {
                from: dimensionParams,
                ease: 'Cubic.easeInOut'
            }), 0)
            sequence.add(factory.animate('BaseRotate', element, duration, delay, {
                from: {rotation: params.from.rotation},
                ease: 'Cubic.easeInOut'
            }), 0)
        })

        return sequence.get()
    }

    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}
