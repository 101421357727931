const _ = require('lodash')
const errorPagesIds = {
    FORBIDDEN: '__403__dp',
    NOT_FOUND: '__404__dp',
    INTERNAL_ERROR: '__500__dp',
    UKNOWN_ERROR: '__uknown_error__dp'
}

const FORBIDDEN = 403
const NOT_FOUND = 404

const getResponseValues = ({page: pageId, data: pageData, head: pageHeadData, status, message, tpaInnerRoute, publicData, redirectUrl}) =>
    _.pickBy({pageId, pageData, pageHeadData, status, message, tpaInnerRoute, publicData, redirectUrl}, value => !_.isUndefined(value))

const getErrorPageId = ({status, page, redirectUrl}, exception) => {
    if (exception) {
        return errorPagesIds.INTERNAL_ERROR
    }

    if (status === FORBIDDEN) {
        return errorPagesIds.FORBIDDEN
    }

    if (status === NOT_FOUND) {
        return errorPagesIds.NOT_FOUND
    }

    if (!page && !redirectUrl) {
        return errorPagesIds.UKNOWN_ERROR
    }

    return null
}

const parseRoutePageDataResponse = ({result, exception}) => {
    const responseValues = getResponseValues(result)

    const errorPageId = getErrorPageId(result, exception)
    if (errorPageId) {
        return {
            ...responseValues,
            pageId: errorPageId
        }
    }

    return responseValues
}

module.exports = {parseRoutePageDataResponse, errorPagesIds}
