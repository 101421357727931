define([
    'coreUtils',
    'components/behaviors/animationBehaviorHandler'
], function (
    coreUtils,
    animationBehaviorHandler
) {
    'use strict';

    coreUtils.behaviorHandlersFactory.registerHandler('animation', animationBehaviorHandler);
});
