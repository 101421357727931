import {aspectNames} from 'common-types'

export const STYLABLE_CONTAINER = 'STYLABLE_CONTAINER'
export const stylableModuleName = 'stylable-santa-flatten'

export interface StylableStore {
    pagesStylableCss: {
        [id: string]: string
    }
}

export const name = aspectNames.StylableAspect

export const defaultModel: StylableStore = {
    pagesStylableCss: {}
}
