/**
 * @author andreys (Andrew Shustariov)
 */
define(['zepto', 'lodash', 'layout/util/layout', 'image-client-api', 'layout/specificComponents/imageLayout'], function ($, _, /** layout.layout */ layout, imageClientLib, imageLayout) {
    'use strict';

    /**
     *
     * Since image is not only element in component's container - we need to update
     * its size after other elements (only label) will be rendered
     *
     * @param id
     * @param nodesMap
     * @param measureMap
     * @param {core.SiteData} siteData
     * @param {layout.structureInfo} structureInfo
     */
    function documentMediaPatcher(id, patchers, measureMap, structureInfo, siteData) {
        const compData = _.defaults({displayMode: imageClientLib.fittingTypes.LEGACY_FULL}, structureInfo.dataItem);
        imageLayout.patchNodeImage(`${id}img`, patchers, measureMap, siteData, compData, measureMap.custom[id].containerSize);

        // Ensure, that container's width won't be smaller, then the title
        patchers.css(id, {width: measureMap.width[id]});
    }

    function documentMediaMeasure(id, measureMap, nodesMap /**, siteData, parentStructureInfo **/) {
        const $wrapper = $(nodesMap[id]);
        const labelId = `${id}label`;
        const labelWidth = measureMap.width[labelId];
        const labelHeight = measureMap.height[labelId];
        const wrapperWidth = Math.max(measureMap.width[id], labelWidth);
        const paddingLeft = $wrapper.data('content-padding-left');
        const paddingRight = $wrapper.data('content-padding-right');
        const paddingTop = $wrapper.data('content-padding-top');
        const imageHeight = $wrapper.data('content-image-height');

        const containerSize = {
            width: wrapperWidth - paddingLeft - paddingRight,
            height: Math.max(imageHeight - paddingTop - labelHeight, 1)
        };

        measureMap.custom[id] = {
            containerSize
        };
        measureMap.width[id] = wrapperWidth;

        measureMap.minWidth[id] = labelWidth;
    }

    layout.registerRequestToMeasureChildren('wysiwyg.viewer.components.documentmedia.DocumentMedia', [
        ['label'],
        {pathArray: ['img'], type: 'core.components.Image'},
        ['link']
    ]);

    layout.registerCustomMeasure('wysiwyg.viewer.components.documentmedia.DocumentMedia', documentMediaMeasure);

    layout.registerPatcher('wysiwyg.viewer.components.documentmedia.DocumentMedia', documentMediaPatcher);
});
