/**
 * Created by eitanr on 6/24/14.
 */
define(['lodash', 'layout/util/layout'], function (_, /** layout.layout */ layout) {
    'use strict';
    const richTextCompName = 'wysiwyg.viewer.components.WRichText';

    function measureWRichText(id, measureMap, nodesMap) {
        const el = nodesMap[id];

        const savedMinHeight = _.toNumber(el.dataset.minHeight) || 0;
        const isPacked = el.dataset.packed === 'true';
        const contentHeight = el.offsetHeight;

        const actualMinHeight = Math.max(savedMinHeight, contentHeight);

        measureMap.minHeight[id] = actualMinHeight;
        if (isPacked || savedMinHeight) {
            measureMap.height[id] = actualMinHeight;
        }
    }

    function patchWRichText(id, patchers) {
        patchers.css(id, {height: ''});
    }

    layout.registerRequestToMeasureDom(richTextCompName);
    layout.registerCustomMeasure(richTextCompName, measureWRichText);
    layout.registerPatcher(richTextCompName, patchWRichText);

    return {richTextCompName, measureWRichText, patchWRichText};
});
