define([
    'galleriesCommon/utils/galleriesHelperFunctions',
    'galleriesCommon/mixins/galleryAutoPlayMixin'
], function (galleriesHelperFunctions, galleryAutoPlayMixin) {
    'use strict';

    return {
        utils: {
            galleriesHelperFunctions
        },
        mixins: {
            galleryAutoPlayMixin
        }
    };
});
