define([
    'lodash',
    'reactDOM',
    'componentsCore/utils/accessibility/accessibility'
], function (
    _,
    reactDOM,
    accessibilityUtils
) {
    'use strict';

    return {
        preventTabbingOut(evt) {
            if (evt.key === 'Tab') {
                evt.stopPropagation();
                const tabbaleElements = accessibilityUtils.getTabbaleElements(reactDOM.findDOMNode(this));

                if (_.isEmpty(tabbaleElements)) {
                    evt.preventDefault();
                } else if (evt.shiftKey && window.document.activeElement === _.head(tabbaleElements)) {
                    evt.preventDefault();
                    _.last(tabbaleElements).focus();
                } else if (!evt.shiftKey && window.document.activeElement === _.last(tabbaleElements)) {
                    evt.preventDefault();
                    _.head(tabbaleElements).focus();
                }
            }
        }
    };
});
