'use strict'

const React = require('react')
const PropTypes = require('prop-types')
const {Provider} = require('carmi-react')
const RootComponent = require('../components/RootComponent')
const ComponentStyleNode = require('../components/ComponentStyleNode')
const StylesContainer = require('../components/StylesContainer')
const UploadedFontsStyleNode = require('../components/UploadedFontsStyleNode')
const {FontRulersContainer} = require('../components/FontRulersContainer')

// TODO: System fonts links are a temporary hack until these resources are embedded in the VM
// On a later stage site-assets-server will go over the JSONs and return the font-faces of only the used fonts

const Renderer = ({instance}) =>
    <Provider value={instance} compsLib={{
        RootComponent,
        ComponentStyleNode,
        StylesContainer,
        UploadedFontsStyleNode,
        FontRulersContainer
    }}>
        {() => instance.content}
    </Provider>


Renderer.propTypes = {
    instance: PropTypes.object.isRequired
}

module.exports = Renderer
