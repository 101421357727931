define([
    'lodash',
    'experiment',
    'coreUtils'
], function (_,
             experiment,
             coreUtils) {
    'use strict';

    function isComponentUrlExists(clientSpecMap, applicationId, widgetId, queryParams) {
        if (!clientSpecMap || !clientSpecMap[applicationId] || !clientSpecMap[applicationId].widgets || !clientSpecMap[applicationId].widgets[widgetId]) {
            return false;
        }
        const widgetData = clientSpecMap[applicationId].widgets[widgetId];
        return _.get(widgetData, 'componentFields.componentUrl', false) ||
            coreUtils.scriptsOverridesService.getUrlOverrideForWidget(widgetId, queryParams.widgetsUrlOverride);
    }

    function getClientSpecMap(siteModel) {
        const clientSpecMap = siteModel && _.isFunction(siteModel.getClientSpecMap) ? siteModel.getClientSpecMap() : _.get(siteModel, 'rendererModel.clientSpecMap');
        return clientSpecMap && _.isFunction(clientSpecMap.toJS) ? clientSpecMap.toJS() : clientSpecMap;
    }

    function isPreviewMode(siteModel) {
        return _.get(siteModel, ['rendererModel', 'previewMode']);
    }

    function shouldModifyStructure(siteModel = {}, structureInfo) {
        const clientSpecMap = getClientSpecMap(siteModel);
        const queryParams = _.isFunction(siteModel.getQueryParams) ? siteModel.getQueryParams() : {};
        const widgetId = _.get(structureInfo, 'dataItem.widgetId');
        const applicationId = _.get(structureInfo, 'dataItem.applicationId');

        if (!widgetId) {
            return false;
        }

        if (!experiment.isOpen('sv_editorNativeComponents', siteModel) && isPreviewMode(siteModel)) {
            return false;
        }

        if (!experiment.isOpen('sv_nativeComponents', siteModel)) {
            return false;
        }

        if (!isComponentUrlExists(clientSpecMap, applicationId, widgetId, queryParams)) {
            return false;
        }

        return true;
    }

    return (compStructure, getStructureInfo, pointersMapApi, siteModel) => {
        if (shouldModifyStructure(siteModel, getStructureInfo(compStructure))) {
            return _.assign({}, compStructure, {
                componentType: 'wysiwyg.viewer.components.tpapps.TPAWidgetNative',
                skin: 'wysiwyg.viewer.skins.TPAWidgetNativeSkin'
            });
        }
        return compStructure;
    };
});
