define([
    'lodash',
    'warmupUtils/siteData/dataRefs.json',
    'wix-ui-santa/dataRefs.bundle' //eslint-disable-line santa/enforce-package-access
], function (
    _,
    dataRefs,
    externalDataRefs
) {
    'use strict';

    return _.merge({}, dataRefs, externalDataRefs.default);
});
