import {withActions} from '../withActions'
import {
    aspectNames,
    ScrollDirection,
    ScrollPosition
} from 'common-types'

export const name = aspectNames.ScrollScrubAspect

export const defaultModel = {
    prevScrollPosition: {x: 0, y: 0},
    prevScrollDirection: '',
    accumulatedScroll: 0
}

const SCROLL_DOWN_THRESHOLDS = {
    HeaderFadeOut: 200, // px
    HeaderHideToTop: 400 // px
}
const SCROLL_UP_THRESHOLD = 100 //px

const sequenceDefaults = {
    suppressReactRendering: false,
    forgetSequenceOnComplete: false,
    paused: true
}

type ScrubParams = {direction: ScrollDirection, position: ScrollPosition, prevPosition: ScrollPosition, accumulatedScroll: number}
type AnimationParams = {name: string, element: Element, duration: number, delay: number, params: any, targetId: string}

const limitBetweenZeroAndOne = num => Math.max(Math.min(num, 1), 0)
const limitScrollValue = scrollValue => Math.max(scrollValue, 0)

function getSequenceProgress(direction, position, prevPosition, lastSequenceProgress, height) {
    const top = limitScrollValue(position.y)
    const prevTop = limitScrollValue(prevPosition.y)
    const scrollPercentage = Math.abs(prevTop - top) / height // scrollDelta, relative to header's height
    const sign = direction === 'DOWN' ? 1 : -1
    const sequenceDelta = sign * scrollPercentage * 0.5
    return limitBetweenZeroAndOne(lastSequenceProgress + sequenceDelta)
}

interface AnimationSequence {}

const sequences = new WeakMap()

export const functionLibrary = {
    setPrevious: withActions(({setPrevPosition, setPrevDirection, setAccumulatedScroll}, position, direction, accumulatedScroll) => {
        setPrevPosition(position)
        setPrevDirection(direction)
        setAccumulatedScroll(accumulatedScroll)
    }),

    createSequence(animation : AnimationParams, animator) {
        const {name: animationName, element, duration, delay, params} = animation
        if (!sequences.has(element)) {
            const sequence = animator.sequence(sequenceDefaults)

            sequence.add(animator.animate(animationName, element, duration, delay, params))
            sequences.set(element, sequence.get())
        }
        return animation
    },

    killSequence(element, animator) {
        const sequence = sequences.get(element)

        sequences.delete(element)
        animator.kill(sequence, 0)

        return null
    },


    scrubSequenceWithScroll(animation, {direction, position, prevPosition} : ScrubParams) {
        const {element, params} = animation
        const sequence = sequences.get(element)

        const progress = sequence.progress()
        const newProgress = getSequenceProgress(direction, position, prevPosition, progress, params.compMeasures.height)
        if (newProgress !== progress) {
            sequence.progress(newProgress)
        }

        return animation
    },

    animateAfterScroll(animation, {direction, accumulatedScroll} : ScrubParams) {
        const {element, name: animationName} = animation
        const sequence = sequences.get(element)

        const isScrollingDown = direction === 'DOWN'
        const sequenceProgress = sequence.progress()
        const shouldAnimateForward = isScrollingDown && sequenceProgress < 1 &&
            accumulatedScroll > SCROLL_DOWN_THRESHOLDS[animationName]

        const shouldAnimateReverse = !isScrollingDown && sequenceProgress > 0 &&
            accumulatedScroll > SCROLL_UP_THRESHOLD

        if (shouldAnimateForward) {
            sequence.play()
        } else if (shouldAnimateReverse) {
            sequence.reverse()
        }

        return animation
    }

}

