define(['lodash',
    'tpaComponents/utils/gluedWidgetMeasuringUtils'
], function (
    _,
    gluedWidgetMeasuringUtils
) {
    'use strict';


    function addAutoPositionIfNeeded(measurements) {
        if (measurements.right === 0) {
            measurements.left = 'auto';
        }

        if (measurements.bottom === 0) {
            measurements.top = 'auto';
        }

        if (measurements.left === 0) {
            measurements.right = 'auto';
        }

        if (measurements.top === 0) {
            measurements.bottom = 'auto';
        }

        return measurements;
    }



    function measureGluedWidget(id, measureMap, nodesMap, flatStructure, {getClientSpecMap, isMobileView}) {
        const compData = flatStructure.dataItem;
        const compNode = nodesMap[id];
        const props = {
            placement: compNode.dataset.placement,
            horizontalMargin: +(compNode.dataset.horizontalMargin || 0),
            verticalMargin: +(compNode.dataset.verticalMargin || 0)
        };
        const compLayout = _.assign(flatStructure.layout, {
            height: compNode.offsetHeight,
            width: compNode.offsetWidth
        });
        const windowWidth = measureMap.width.screen;
        const windowHeight = measureMap.height.screen;

        const compStructure = {
            props,
            data: compData,
            layout: compLayout
        };

        let measurements = gluedWidgetMeasuringUtils.getGluedWidgetMeasurements(getClientSpecMap(), compStructure, windowWidth, windowHeight, measureMap.siteMarginBottom, isMobileView());
        measurements = addAutoPositionIfNeeded(measurements);

        // Rename property names for top/left not being overridden
        measurements.fixedTop = measurements.top;
        measurements.fixedLeft = measurements.left;
        delete measurements.top;
        delete measurements.left;

        _.forEach(measurements, function (val, key) {
            measureMap[key] = measureMap[key] || {};
            measureMap[key][id] = val;
        });

        _.set(measureMap.custom, [id, 'has-ads'], Boolean(compNode.dataset.hasAds));
        measureMap.width[id] = compLayout.width;
    }

    return measureGluedWidget;
});
