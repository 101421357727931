define([
    'lodash',
    'warmupUtilsLib'
], function (
    _,
    warmupUtilsLib
) {
    'use strict';

    function buildDeepStructure(comp, map) {
        const children = comp.children || comp.components;
        if (!_.isEmpty(children)) {
            const components = _.map(children, child => buildDeepStructure(map[child], map));
            return _(comp)
                .omit(['components', 'parent'])
                .defaults({[comp.id === 'masterPage' ? 'children' : 'components']: components})
                .value();
        }

        return _.omit(_.clone(comp), 'parent');
    }

    function getDeepStructureForComp(siteData, pageId, compId, viewMode = siteData.getViewMode()) { // eslint-disable-line complexity
        compId = compId || pageId;

        if (siteData.pagesDataRaw && !_.isEmpty(siteData.pagesDataRaw)) {
            if (compId === pageId) {
                const desktopComponentsMap = _.get(siteData, ['pagesDataRaw', 'pagesData', pageId, 'structure', 'DESKTOP']);
                const desktop = buildDeepStructure(desktopComponentsMap[compId], desktopComponentsMap);
                const mobileComponentsMap = _.get(siteData, ['pagesDataRaw', 'pagesData', pageId, 'structure', 'MOBILE']);
                if (mobileComponentsMap) {
                    const mobile = buildDeepStructure(mobileComponentsMap[compId], mobileComponentsMap);
                    desktop.mobileComponents = mobile.children || mobile.components || [];
                }
                return desktop;
            }

            const map = _.get(siteData, ['pagesDataRaw', 'pagesData', pageId, 'structure', viewMode]);
            return buildDeepStructure(map[compId], map);
        }

        // deep structure
        const pageData = siteData.getPageData(pageId, ['structure']);
        return warmupUtilsLib.dataUtils.findCompInStructure(pageData, viewMode, ({id}) => compId === id);
    }

    return {
        getDeepStructureForComp,
        buildDeepStructure
    };
});

