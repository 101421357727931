define(['warmupUtils/tpa/bi/events.json', 'lodash', 'warmupUtils/loggingUtils/logger/logger'], function (events, _, logger) {
    'use strict';

    /**
     * Please ctrl/cmd + click on biEvents to see the schema :)
     * @type {Object.<String, biEvent>}
     */

    logger.register('tpaWarmup', 'event', events);

    return events;
});
