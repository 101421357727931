/**
 * Created by eitanr on 6/25/14.
 */
define(['layout/specificComponents/svgShape/svgBasicScaler'], function (basic) {
    'use strict';
    return {
        scale(circleElement, scaleX, scaleY) {
            const cxAttribute = circleElement.getAttribute('cx'),
                cyAttribute = circleElement.getAttribute('cy'),
                rAttribute = circleElement.getAttribute('r'),
                actualScale = Math.min(scaleX, scaleY);


            return cxAttribute && cyAttribute ? {
                cx: basic.scaleSingleValue(cxAttribute, actualScale),
                cy: basic.scaleSingleValue(cyAttribute, actualScale),
                r: basic.scaleSingleValue(rAttribute, actualScale)
            } : {};
        }
    };
});
