define(['lodash', 'warmupUtils/core/SiteDataPrivates'], function (_, SiteDataPrivates) {
    'use strict';

    const dalPrivates = new SiteDataPrivates(); //eslint-disable-line santa/no-module-state

    function validatePath(path, json, validateValueInPathExist) {
        if (!_.isArray(path)) {
            throw new Error(`path type is not an array - ${path}`);
        }
        if (!isPathExist(json, path, validateValueInPathExist)) {
            throw new Error(`path does not exist - ${path}`);
        }
        return true;
    }

    function validatePathExistsAndCorrect(json, path, itemValidationPredicate) {
        if (!path) {
            return false;
        }
        const item = getItemInPath(json, path, path.length);
        if (_.isUndefined(item)) {
            return false;
        }
        return !itemValidationPredicate || itemValidationPredicate(item);
    }

    function isPathExist(json, path, validateValueInPathExist) {
        if (!path || !_.isArray(path)) {
            return false;
        }
        const pathSuffix = path[path.length - 1];
        const pathLengthToValidate = validateValueInPathExist ? path.length : path.length - 1;
        const item = getItemInPath(json, path, pathLengthToValidate);
        if (_.isUndefined(item)) {
            return false;
        }
        return validateValueInPathExist ? true : validatePathSuffix(pathSuffix, item);
    }

    function getItemFromJson(json, pathLengthToValidate, path) {
        let item = json;
        for (let i = 0; i < pathLengthToValidate; i++) {
            item = item[path[i]];
            if (_.isUndefined(item)) {
                break;
            }
        }
        return item;
    }

    function getItemInPath(json, path, pathLengthToValidate) {
        const pathToValidate = _.take(path, pathLengthToValidate);
        if (path && path[0] === 'pagesData' && !_.isEmpty(json.pagesDataRaw)) {
            return pathToValidate.length === 0 ? json.pagesDataRaw : _.get(json.pagesDataRaw, pathToValidate);
        }
        const privates = dalPrivates.get(json);
        if (_.get(privates, 'mobxDataHandlers.getObservableByPath')) {
            return privates.mobxDataHandlers.getObservableByPath(json, pathToValidate);
        }

        return getItemFromJson(json, pathLengthToValidate, path);
    }

    function validatePathSuffix(pathSuffix, jsonEntry) {
        if (_.isArray(jsonEntry)) {
            return /^\d+$/.test(pathSuffix);
        }

        if (isPlainObject(jsonEntry)) {
            return _.isString(pathSuffix);
        }
        return false;
    }

    function isPlainObject(collection) {
        return Object.prototype.toString.call(collection) === '[object Object]';
    }

    function setMobxDataHandlers(siteData, dataHandlers) {
        const privates = dalPrivates.get(siteData);
        dalPrivates.set(siteData, _.assign(privates, {mobxDataHandlers: dataHandlers}));
    }

    return {
        setMobxDataHandlers,
        validatePath,
        validatePathExist: isPathExist,
        validatePathExistsAndCorrect
    };
});
