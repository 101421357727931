define([
    'lodash',
    'santa-components',
    'textCommon',
    'componentsCore'
], function (
    _,
    santaComponents,
    textCommon,
    componentsCore
) {
    'use strict';

    function getTextInputFontSize() {
        const fontStyle = this.getFontSize('fnt');
        if (this.props.isMobileView) {
            const size = _.max([13, Number(fontStyle.fontSize.replace('px', ''))]);
            fontStyle.fontSize = `${size}px`;
        }
        return fontStyle;
    }

    const labelUtils = textCommon.labelUtils;

    /**
     * @class components.TextInput
     * @extends {components.BaseTextInput}
     */
    const textInput = {
        displayName: 'TextInput',
        mixins: [textCommon.baseTextInput,
            textCommon.textScaleMixin,
            santaComponents.mixins.inputFocusMixin],
        propTypes: {
            compProp: santaComponents.santaTypesDefinitions.Component.compProp.isRequired,
            isMobileView: santaComponents.santaTypesDefinitions.isMobileView
        },
        statics: {
            behaviors: _.assign({}, textCommon.baseTextInput.BASE_TEXT_BEHAVIORS, santaComponents.mixins.inputFocusMixin.INPUT_FOCUS_BEHAVIORS)
        },
        focus() {
            this.refs.input.focus();
        },
        blur() {
            this.refs.input.blur();
        },
        measureComponent(node) {
            const {label} = this.refs;
            const labelHeight = labelUtils.measureComponentWithLabel(this.props.compProp, label, this.props.compData);
            if (labelHeight) {
                return [{node, type: 'css', changes: {height: labelHeight}}];
            }
        },
        getSkinProperties() {
            const {compProp, isMobileView, compData, compTheme} = this.props;
            const selectedClassSet = {
                'with-validation-indication': this.shouldShowValidityIndication(),
                'required': labelUtils.showRequired(compData, compProp, compTheme)
            };
            selectedClassSet[`${this.props.compProp.textAlignment}-direction`] = true;
            const id = {compId: this.props.id, inputId: 'input'};
            const baseTextInputSkinProperties = _.merge(this.getBaseTextInputSkinProperties(), labelUtils.getBaseInputWithLabelSkinProperties({compProp, rawFontSyle: this.getFontSize('fntlbl'), isMobileView, compData, id}));
            baseTextInputSkinProperties[''] = {
                className: this.classSet(selectedClassSet),
                'data-disabled': !!this.props.compProp.isDisabled,
                'data-preview': _.isFunction(this.getComponentPreviewState) && this.getComponentPreviewState()
            };

            const paddingDirection = `padding${_.capitalize(this.props.compProp.textAlignment)}`;
            baseTextInputSkinProperties.input = _.merge({style: getTextInputFontSize.call(this)}, baseTextInputSkinProperties.input, {
                'data-preview': _.isFunction(this.getComponentPreviewState) && this.getComponentPreviewState(),
                className: 'has-custom-focus'
            });
            baseTextInputSkinProperties.input.style[paddingDirection] = this.props.compProp.textPadding;
            return baseTextInputSkinProperties;
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.inputs.TextInput', textInput);

    return textInput;
});
