define(['lodash',
    'coreUtils/core/linkRenderer',
    'warmupUtils'],
function (_,
          linkRenderer,
          warmupUtils) {
    'use strict';

    const warmupMenuUtils = warmupUtils.warmupMenuUtils;

    function getMenuItems(dataItems, siteData, renderLinks, navInfo, includeHiddenItems, resolvedSiteData) {
        return warmupMenuUtils.getMenuItems(dataItems, siteData, renderLinks, navInfo, includeHiddenItems, resolvedSiteData, linkRenderer);
    }

    function getMenuById(menuId, siteData, dontRenderLinks, navInfo, includeHiddenItems) {
        return warmupMenuUtils.getMenuById(menuId, siteData, dontRenderLinks, navInfo, includeHiddenItems, linkRenderer);
    }

    function getSiteMenuWithRender(siteData, navInfo, includeHiddenItems) {
        return warmupMenuUtils.getSiteMenu(siteData, false, navInfo, includeHiddenItems, linkRenderer);
    }

    function getSiteMenuWithoutRenderedLinks(siteData, includeHiddenItems) {
        return warmupMenuUtils.getSiteMenu(siteData, true, {}, includeHiddenItems, linkRenderer);
    }

    return {
        getDropDownWidthIfOk: warmupMenuUtils.getDropDownWidthIfOk,
        getMaxWidth: warmupMenuUtils.getMaxWidth,
        getMinWidth: warmupMenuUtils.getMinWidth,
        removeAllElementsWithWidthZero: warmupMenuUtils.removeAllElementsWithWidthZero,
        getSiteMenuWithRender,
        getMenuById,
        getMenuItems,
        getSiteMenuWithoutRenderedLinks,
        getActiveAnchorInPage: warmupMenuUtils.getActiveAnchorInPage,
        shouldHighlightAnchorInPage: warmupMenuUtils.shouldHighlightAnchorInPage,
        convertOldMenuToNewMenu: warmupMenuUtils.convertOldMenuToNewMenu
    };
});
