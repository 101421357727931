'use strict'
const _ = require('lodash')

const name = 'Fade'
const properties = {}

function register({factory}) {
    /**
     * Fade animation object
     * Defaults to fade in to opacity 1 with Sine.easeIn
     * @param {Array<HTMLElement>|HTMLElement} elements DOM element to animate
     * @param {Number} [duration]
     * @param {Number} [delay]
     * @param {Object} [params] Timeline optional parameters.
     * @param {Object} [params.to.opacity] Timeline optional parameters.
     * @returns {TimelineMax}
     */
    function animation(elements, duration, delay, params) {
        const sequence = factory.sequence(params)
        const defaults = {ease: 'Sine.easeIn'}
        if (!_.get(params, ['to', 'opacity'], _.get(params, ['to', 'autoAlpha']))) {
            defaults.to = {autoAlpha: 1}
        }
        const animationParams = _.defaultsDeep(defaults, params)

        sequence.add(factory.animate('BaseFade', elements, duration, delay, animationParams))
        return sequence.get()
    }

    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}

