/**
 * Created by eitanr on 6/24/14.
 */
define([
    'layout/util/layout',
    'zepto',
    'lodash',
    'warmupUtilsLib',
    'image-client-api',
    'layout/specificComponents/imageLayout'
], function (
    layout /** layout.layout */,
    $,
    _,
    warmupUtilsLib,
    imageClientLib,
    imageLayout
) {
    'use strict';

    const matrixCalculations = warmupUtilsLib.matrixCalculations;

    const isMesh = domNode => domNode.getAttribute('data-is-mesh') === 'true';

    const getItemsPositionAndSize = (itemsDataArr, itemWidth, itemHeight, margin, numCols) => _.map(itemsDataArr, (imageItemData, index) =>
        _.assign({
            width: itemWidth,
            height: itemHeight
        }, matrixCalculations.getItemPosition(index, itemWidth, itemHeight, margin, numCols))
    );

    const patchImages = (itemsData, itemsPositionsAndSize, structureInfo, patchers, measureMap, siteData) => {
        _.forEach(itemsData, function ({imageIndex, imageItemId}, index) {
            const itemPositionAndSize = itemsPositionsAndSize[index];

            patchers.css(imageItemId, {
                width: itemPositionAndSize.width,
                height: itemPositionAndSize.height,
                left: itemPositionAndSize.left,
                top: itemPositionAndSize.top
            });

            const imageData = _.assign({}, structureInfo.dataItem.items[imageIndex], {displayMode: imageClientLib.fittingTypes.SCALE_TO_FILL});
            imageLayout.patchNodeImage(imageItemId, patchers, measureMap, siteData, imageData, {width: itemPositionAndSize.width, height: itemPositionAndSize.height});
        });
    };

    const patchRolloverHolder = (itemsData, itemsPositionsAndSize, imageItemId, widthDiff, galleryId, patchers) => {
        const hoveredImageIndex = _.findIndex(itemsData, {imageItemId});

        if (hoveredImageIndex !== -1) {
            const positionAndSize = itemsPositionsAndSize[hoveredImageIndex];

            patchers.css(`${galleryId}rolloverHolder`, {
                width: positionAndSize.width,
                height: positionAndSize.height,
                left: positionAndSize.left + (widthDiff / 2),
                top: positionAndSize.top
            });
        }
    };

    function getChildrenIdToMeasure(id, nodesMap, structureInfo) {
        const res = [
            ['itemsContainer']
        ];

        if (isMesh(nodesMap[id])) {
            res.push(['rolloverHolder']);
        }
        _.forEach(structureInfo.dataItem.items, function (image) {
            res.push({pathArray: [image.id], type: 'core.components.Image'});
        });
        return res;
    }

    function getItemsData(itemsArr) {
        return _.map(itemsArr, function (imageItem) {
            return {
                imageItemId: imageItem.id,
                imageIndex: $(imageItem).data('image-index')
            };
        });
    }

    function customMeasurePaginatedGridGalleryLayout(id, measureMap, nodesMap) {
        const customMeasure = measureMap.custom[id] = {};
        const $node = $(nodesMap[id]);
        customMeasure.heightDiff = parseInt($node.data('heightDiff'), 10);
        customMeasure.widthDiff = parseInt($node.data('widthDiff'), 10);
        customMeasure.numCols = parseInt($node.data('numCols'), 10);
        customMeasure.margin = parseInt($node.data('margin'), 10);
        customMeasure.maxRows = parseInt($node.data('maxRows'), 10);

        const itemsContainer = $(nodesMap[`${id}itemsContainer`]);

        customMeasure.nextItemsData = getItemsData(itemsContainer.children('div[data-page-desc="next"]'));
        customMeasure.prevItemsData = getItemsData(itemsContainer.children('div[data-page-desc="prev"]'));
        customMeasure.currItemsData = getItemsData(itemsContainer.children('div[data-page-desc="curr"]'));

        if (isMesh(nodesMap[id])) {
            customMeasure.isMesh = true;
            const hoveredImageId = nodesMap[`${id}rolloverHolder`].dataset.hoveredImageId;
            customMeasure.hoveredImageId = hoveredImageId ? `${id}${hoveredImageId}` : '';
        }
    }

    function patchPaginatedGridGallery(id, patchers, measureMap, structureInfo, siteData) {
        const galleryHeight = measureMap.height[id];
        const galleryWidth = measureMap.width[id];

        const customMeasure = measureMap.custom[id];
        const props = customMeasure;

        patchers.css(`${id}itemsContainer`, {
            width: galleryWidth - customMeasure.widthDiff,
            height: galleryHeight - customMeasure.heightDiff
        });

        const itemWidth = matrixCalculations.getItemWidth(props.margin, props.numCols, galleryWidth, customMeasure.widthDiff);
        const rowNum = matrixCalculations.getAvailableRowsNumber(props.maxRows, props.numCols, structureInfo.dataItem.items.length);
        const itemHeight = matrixCalculations.getItemHeight(props.margin, galleryHeight, rowNum, customMeasure.heightDiff);

        patchImages(customMeasure.prevItemsData, getItemsPositionAndSize(customMeasure.prevItemsData, itemWidth, itemHeight, props.margin, props.numCols), structureInfo, patchers, measureMap, siteData);
        patchImages(customMeasure.nextItemsData, getItemsPositionAndSize(customMeasure.nextItemsData, itemWidth, itemHeight, props.margin, props.numCols), structureInfo, patchers, measureMap, siteData);

        const currItemsPositionsAndSize = getItemsPositionAndSize(customMeasure.currItemsData, itemWidth, itemHeight, props.margin, props.numCols);
        patchImages(customMeasure.currItemsData, currItemsPositionsAndSize, structureInfo, patchers, measureMap, siteData);
        if (customMeasure.isMesh && customMeasure.hoveredImageId) {
            patchRolloverHolder(customMeasure.currItemsData, currItemsPositionsAndSize, customMeasure.hoveredImageId, customMeasure.widthDiff, id, patchers);
        }
    }

    layout.registerPatcher('wysiwyg.viewer.components.PaginatedGridGallery', patchPaginatedGridGallery);
    layout.registerRequestToMeasureChildren('wysiwyg.viewer.components.PaginatedGridGallery', getChildrenIdToMeasure);
    layout.registerCustomMeasure('wysiwyg.viewer.components.PaginatedGridGallery', customMeasurePaginatedGridGalleryLayout);


    return {patchPaginatedGridGallery, getChildrenIdToMeasure, customMeasurePaginatedGridGalleryLayout};
});
