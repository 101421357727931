'use strict'
const AspectCompsContainer = require('../../components/AspectCompsContainer')
const {aspectNames} = require('common-types')


const name = aspectNames.AspectCompsContainer

const defaultModel = {
    compClasses: {[AspectCompsContainer.compType]: AspectCompsContainer}
}

module.exports = {
    name,
    defaultModel
}
