(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("lodash"));
	else if(typeof define === 'function' && define.amd)
		define(["lodash"], factory);
	else if(typeof exports === 'object')
		exports["santa-animations"] = factory(require("lodash"));
	else
		root["santa-animations"] = factory(root["lodash"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__1__) {
return 