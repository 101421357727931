define([
    'lodash',
    'warmupUtilsLib',
    'layout/util/rootLayoutUtils',
    'layout/util/layout',
    'layout/specificComponents/bgImageLayout'
], function (_,
             warmupUtilsLib,
             rootLayoutUtils,
             /** layout.layout */ layout,
             bgImageLayout) {
    'use strict';

    const stretchInCenteredContainer = warmupUtilsLib.layoutUtils.stretchInCenteredContainer;

    function calculateScreenWidthDimensions(measureMap, siteWidth, rootId) {
        const containerWidth = rootLayoutUtils.getRootWidth(siteWidth, measureMap, rootId);
        const compWidth = Math.max(measureMap.width.screen, containerWidth);
        return stretchInCenteredContainer(containerWidth, compWidth);
    }

    function measureBgImageStrip(id, measureMap, nodesMap, structureInfo, {siteWidth}) {
        const {width, left} = calculateScreenWidthDimensions(measureMap, siteWidth, structureInfo.rootId);
        measureMap.width[id] = width;
        measureMap.left[id] = left;

        if (hasImageData(structureInfo)) {
            bgImageLayout.measureLegacyBgImageStrip(id, measureMap, nodesMap);
        }
    }

    function patchBgImageStrip(id, patchers, measureMap, structureInfo, siteData) {
        patchers.css(id, {
            width: `${measureMap.width[id]}px`,
            left: `${measureMap.left[id]}px`
        });
        if (hasImageData(structureInfo)) {
            const parentDimensions = {width: measureMap.width[id], height: measureMap.height[id]};
            bgImageLayout.patchLegacyBgImageStrip(id, patchers, measureMap, structureInfo, siteData, parentDimensions);
        } else {
            const legacyBgImageId = `${id}bg`;
            patchers.css(legacyBgImageId, {backgroundImage: 'none'});
        }
    }

    function hasImageData(structureInfo) {
        return _.get(structureInfo, ['dataItem', 'type']) === 'Image';
    }

    // const LEGACY_STRIP_CONTAINER = 'wysiwyg.viewer.components.StripContainer';
    const LEGACY_BG_STRIP = 'wysiwyg.viewer.components.BgImageStrip';

    // layout.registerRequestToMeasureDom(LEGACY_STRIP_CONTAINER);
    // layout.registerRequestToMeasureChildren(LEGACY_STRIP_CONTAINER, [['inlineContent'], ...balataLayout.BALATA_PATHS_TO_REQUEST_MEASURE]);
    // layout.registerCustomMeasure(LEGACY_STRIP_CONTAINER, measureStripContainer);
    // layout.registerPatcher(LEGACY_STRIP_CONTAINER, patchStripContainer);

    layout.registerCustomMeasure(LEGACY_BG_STRIP, measureBgImageStrip);
    layout.registerPatcher(LEGACY_BG_STRIP, patchBgImageStrip);

    // return {
    //     measure: measureStripContainer,
    //     patch: patchStripContainer
    // };
});
