export const getRunUserFunctionMessage = (contextPageId, params, compId, event) => ({
    intent: 'WIX_CODE',
    type: 'wix_code_run_user_function',
    contextId: contextPageId,
    callbackId: params.callbackId,
    compId,
    event
})

export const getNativeTpaEventMessage = (contextPageId, callbackId, compId, eventArgs) => ({
    intent: 'TPA_NATIVE_EVENT',
    contextId: contextPageId,
    callbackId,
    compId,
    eventArgs
})
