define([
    'lodash',
    'warmupUtils/pointers/pointerGeneratorsRegistry'
], function (_, pointerGeneratorsRegistry) {
    'use strict';

    const POINTER_TYPE = 'changedByRelayout';

    pointerGeneratorsRegistry.registerPointerType(POINTER_TYPE, _.constant(null), _.constant(true));

    const getterFunctions = {
        getCompChangedByRelayout(getItemAt, cache, compId) {
            return cache.getPointer(`${POINTER_TYPE}_${compId}`, POINTER_TYPE, ['postUpdateOperationsRenders', compId]);
        }
    };

    pointerGeneratorsRegistry.registerDataAccessPointersGenerator(POINTER_TYPE, getterFunctions);
});
