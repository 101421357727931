define(['lodash', 'coreUtils'], function (_, coreUtils) {
    'use strict';
    const TPA_PUB_SUB_PREFIX = 'TPA_PUB_SUB_';
    const MULTI_SECTION_DELIMITER = '$TPA$';
    const IFRAME_CROSS_DOMAIN_PERMISSIONS = {
        AUTOPLAY: 'autoplay',
        CAMERA: 'camera',
        MICROPHONE: 'microphone',
        LOCATION: 'geolocation',
        VR: 'vr'
    };
    const ECOM_APP_DEF_ID = '1380b703-ce81-ff05-f115-39571d94dfcd';
    const PRODUCT_PAGE_WIDGET_ID = '13a94f09-2766-3c40-4a32-8edb5acdd8bc';
    const GALLERY_WIDGET_ID = '1380bba0-253e-a800-a235-88821cf3f8a4';
    const GRID_GALLERY_WIDGET_ID = '13afb094-84f9-739f-44fd-78d036adb028';
    const CART_ICON_WIDGET_ID = '1380bbc4-1485-9d44-4616-92e36b1ead6b';

    const BLOG_APP_DEF_ID = '14bcded7-0066-7c35-14d7-466cb3f09103';
    const SOCIAL_BLOG_WIDGET_ID = '14c1462a-97f2-9f6a-7bb7-f5541f23caa6';
    const CUSTOM_FEED_WIDGET_ID = '14e5b39b-6d47-99c3-3ee5-cee1c2574c89';

    const getAppData = function (siteAPI, compId) {
        const comp = siteAPI.getComponentById(compId);
        const compData = _.get(comp, 'props.compData');
        const spec = comp && compData && siteAPI.getSiteData().getClientSpecMapEntry(compData.applicationId);

        return spec;
    };

    const getAppDataByAppDefinitionId = function (siteAPI, appDefinitionId) {
        const cms = getClientSpecMap(siteAPI);
        return _.find(cms, {appDefinitionId});
    };

    const getClientSpecMap = function (siteAPI) {
        return siteAPI.getSiteData().getClientSpecMap();
    };

    const getAppDefId = function (siteAPI, compId) {
        const spec = getAppData(siteAPI, compId);

        return spec ? spec.appDefinitionId : null;
    };

    const stripPubSubPrefix = function (str) {
        const prefixRegex = new RegExp(`^${TPA_PUB_SUB_PREFIX}`);
        return str.replace(prefixRegex, '');
    };

    const addPubSubEventPrefix = function (str) {
        return TPA_PUB_SUB_PREFIX.concat(str);
    };

    const isTPASection = function (comp) {
        const componentType = _.get(comp, 'props.structure.componentType') || _.get(comp, 'componentType');
        return componentType === 'wysiwyg.viewer.components.tpapps.TPASection' ||
            componentType === 'wysiwyg.viewer.components.tpapps.TPAMultiSection';
    };

    const isBlog = function (comp) {
        const appData = comp.getAppData();
        return appData.appDefinitionId === BLOG_APP_DEF_ID;
    };

    const isEcom = function (comp) {
        const appData = comp.getAppData();
        return appData.appDefinitionId === ECOM_APP_DEF_ID;
    };

    const isGluedWidget = function (comp) {
        const componentType = _.get(comp, 'props.structure.componentType') || _.get(comp, 'componentType');
        return componentType === 'wysiwyg.viewer.components.tpapps.TPAGluedWidget';
    };

    const sdkVersionIsAtLeast = function (currentVersion, requiredVersion) {
        currentVersion = currentVersion || '0.0.0';
        requiredVersion = requiredVersion || '1.41.0';

        const currentSplited = _.map(currentVersion.split('.'), function (digit) {
            return parseInt(digit, 10);
        });

        const requiredSplited = _.map(requiredVersion.split('.'), function (digit) {
            return parseInt(digit, 10);
        });

        if (currentSplited.length === 3 && requiredSplited.length === 3) {
            return currentSplited[0] >= requiredSplited[0] &&
                currentSplited[1] >= requiredSplited[1] &&
                currentSplited[2] >= requiredSplited[2];
        }
        return false;
    };

    const getVisitorUuid = function (u) {
        let vuuid = u.cookieUtils.getCookie('_wixUIDX') || '';
        vuuid = vuuid.slice(_.lastIndexOf(vuuid, '|') + 1); //remove anything before any pipe, including the pipe.
        vuuid = vuuid.replace(/^(null-user-id|null)$/g, ''); //replace invalid values with empty string.
        return vuuid;
    };

    const getInstanceFromClientSpecMap = (appsData, appId) => getInstanceFromAppData(appsData[appId]);

    const getInstanceFromAppData = function (appData) {
        const base64Instance = appData && appData.instance.split('.')[1];
        return base64Instance && JSON.parse(window.atob(base64Instance));
    };

    let appCounter = 0; //eslint-disable-line no-unused-vars
    const incAppCounter = function () {
        ++appCounter;
    };
    const decAppCounter = function (reportBeatEvent, pageId) {
        --appCounter;
        reportAppCounter(reportBeatEvent, pageId);
    };
    const reportAppCounter = function (/* reportBeatEvent, pageId */) {
        if (appCounter === 0) {
            appCounter = 1000; // TODO: temporarily sending only for first page
            //reportBeatEvent(16, pageId);
        }
    };

    const isPageMarkedAsHideFromMenu = function (appData, tpaPageId) {
        if (appData && tpaPageId) {
            if (_.includes(tpaPageId, MULTI_SECTION_DELIMITER)) {
                tpaPageId = tpaPageId.substr(0, tpaPageId.indexOf(MULTI_SECTION_DELIMITER));
            }
            const section = _.find(appData.widgets, function (widget) {
                return _.get(widget, 'appPage.id') === tpaPageId;
            });
            if (section) {
                return _.get(section, 'appPage.hideFromMenu');
            }
            return false;
        }
        return false;
    };

    const getDebuggingParamsFromUrl = function (url) {
        const params = {};
        const petri_ovr = _.get(url, 'query.petri_ovr');
        if (petri_ovr) {
            params.petri_ovr = petri_ovr;
        }
        const debugApp = _.get(url, 'query.debugApp');
        if (debugApp) {
            params.debugApp = debugApp;
        }
        return params;
    };

    const appendProtocolToUrlIfNeeded = function (baseUrl, parsedCurrentUrl) {
        const isProtocolExist = (/^https?/).test(baseUrl);

        if (!isProtocolExist) {
            const protocol = _.get(parsedCurrentUrl, 'protocol', 'http:');
            return coreUtils.urlUtils.addProtocolIfMissing(baseUrl, protocol);
        }
        return baseUrl;
    };

    const getIFramePermissions = function () {
        return _(IFRAME_CROSS_DOMAIN_PERMISSIONS).values().join('; ');
    };

    return {
        Constants: {
            TPA_PUB_SUB_PREFIX,
            MULTI_SECTION_DELIMITER,
            TOP_PAGE_ANCHOR_PREFIX: 'TOP_PAGE_',
            ECOM: {
                APP_DEF_ID: ECOM_APP_DEF_ID,
                PRODUCT_PAGE_WIDGET_ID,
                GALLERY_WIDGET_ID,
                GRID_GALLERY_WIDGET_ID,
                CART_ICON_WIDGET_ID
            },
            SOCIAL_BLOG: {
                APP_DEF_ID: BLOG_APP_DEF_ID,
                SOCIAL_BLOG_WIDGET_ID,
                CUSTOM_FEED_WIDGET_ID
            }
        },
        getCacheKiller: coreUtils.urlUtils.persistentCacheKiller,
        getAppData,
        getAppDataByAppDefinitionId,
        getAppDefId,
        stripPubSubPrefix,
        addPubSubEventPrefix,
        isTPASection,
        isGluedWidget,
        sdkVersionIsAtLeast,
        getVisitorUuid,
        getInstanceFromClientSpecMap,
        getInstanceFromAppData,
        getClientSpecMap,
        incAppCounter,
        decAppCounter,
        reportAppCounter,
        isPageMarkedAsHideFromMenu,
        getDebuggingParamsFromUrl,
        appendProtocolToUrlIfNeeded,
        getIFramePermissions,
        isBlog,
        isEcom
    };
});
