define([], function () {
    'use strict';

    function getDefaultPlacement(compData, clientSpecMap) {
        const applicationId = compData.applicationId;
        const widgetId = compData.widgetId;

        const appData = clientSpecMap[applicationId];
        if (appData) {
            const widgetData = appData.widgets[widgetId];
            if (widgetData) {
                const widgetDataGluedOptions = widgetData.gluedOptions || {
                    horizontalMargin: 0,
                    placement: 'BOTTOM_RIGHT',
                    verticalMargin: 0
                };
                return widgetDataGluedOptions.placement;
            }
        }
        //TODO - report data error bi
    }


    return {
        getDefaultPlacement
    };
});
