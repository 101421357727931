import _ from 'lodash'
import {withActions} from '../../../../carmi-host-extensions/src/aspects/withActions'
import tpaComponents from 'tpaComponents'

export const tpaPageNavigationFunctionLibrary = {
    registerToPageChanged: withActions((aspectActions, compListeners, compId, comp, type) => {
        const listener = {
            comp,
            type
        }
        if (!compListeners) {
            aspectActions.setCompListeners(compId, [listener])
        } else {
            aspectActions.spliceCompListener(compId, compListeners.length, 0, listener)
        }
    }),
    unregisterToPageChanged: withActions((aspectActions, compId) => {
        aspectActions.setCompListeners(compId, undefined)
    }),
    getSupportedSiteEvents: eventName => tpaComponents.common.supportedSiteEvents[eventName],
    listenerSendPostMessage: (listener, getExistingRootNavigationInfoFn, origParams) => {
        if (origParams.toPage !== origParams.fromPage) {
            const params = _.clone(origParams)
            const newPageNavigationInfo = getExistingRootNavigationInfoFn(params.toPage)
            const routerPublicData = _.get(newPageNavigationInfo, 'routerPublicData')
            if (routerPublicData) {
                params.routerData = routerPublicData
            }
            listener.comp.sendPostMessage({
                intent: 'addEventListener',
                eventType: listener.type,
                params
            })
        }
    },
    setCurrentPageId: withActions((aspectActions, pageId) => {
        aspectActions.setCurrentPageId(pageId)
        return true
    })
}
