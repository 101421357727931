define([
    'lodash',
    'santa-components',
    'santa-core-utils',
    'tpaComponents/mixins/tpaCompBaseMixin',
    'tpaComponents/mixins/tpaUrlBuilderMixin',
    'tpaComponents/mixins/tpaCompApiMixin',
    'tpaComponents/mixins/tpaSectionMixin',
    'tpaComponents/mixins/tpaResizeWindowMixin',
    'componentsCore'
], function (
    _,
    santaComponents,
    coreUtilsLib,
    tpaCompBaseMixin,
    tpaUrlBuilderMixin,
    tpaCompApiMixin,
    tpaSectionMixin,
    tpaResizeWindowMixin,
    componentsCore
) {
    'use strict';

    const compRegistrar = componentsCore.compRegistrar;

    /**
     * @class components.TPAMultiSection
     * @extends {tpa.mixins.tpaCompBase}
     * @extends {tpa.mixins.tpaUrlBuilder}
     * @extends {tpa.mixins.tpaCompAPI}
     * @extends {ReactCompositeComponent}
     * @property {comp.properties} props
     */
    const TPAMultiSection = {
        displayName: 'TPAMultiSection',
        propTypes: {
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired
        },
        mixins: [componentsCore.mixins.skinBasedComp, coreUtilsLib.timersMixins.timeoutsMixin, tpaCompBaseMixin, tpaUrlBuilderMixin, tpaCompApiMixin, tpaSectionMixin, tpaResizeWindowMixin],
        getBaseUrl() {
            const appData = this.getAppData();
            let sectionUrl = appData.sectionUrl;

            const widget = appData.widgets[this.props.compData.widgetId];
            let sectionDefaultPage = appData.sectionDefaultPage;

            if (widget) {
                if (this.isUnderMobileView() && this.isMobileReady()) {
                    sectionUrl = widget.mobileUrl;
                } else {
                    sectionUrl = widget.widgetUrl;
                }

                sectionDefaultPage = widget.appPage.defaultPage;
            }

            if (sectionDefaultPage && !_.isEmpty(sectionDefaultPage)) {
                if (sectionUrl.slice(-1) !== '/') {
                    sectionUrl += '/';
                }

                sectionUrl += sectionDefaultPage;
            }


            return sectionUrl;
        }
    };

    compRegistrar.register('wysiwyg.viewer.components.tpapps.TPAMultiSection', TPAMultiSection);
    return TPAMultiSection;
});
