define([
    'lodash',
    'react',
    'prop-types',
    'coreUtils',
    'santa-components',
    'wixFreemiumBanner/utils/translationUtils',
    'wixFreemiumBanner/css/wixAdsCss'
], function (
    _,
    React,
    PropTypes,
    coreUtils,
    santaComponents,
    translationsUtils,
    wixAdsCss
) {
    'use strict';
    const createReactElement = santaComponents.utils.createReactElement;

    function getAdBottomProps(isPreview, href, wixAdsId) {
        const props = {
            'className': !isPreview ? 'desktop-bottom' : 'desktop-bottom preview',
            'id': 'bottom',
            'key': 'desktopBottom',
            'data-aid': `${wixAdsId}desktopBottom`
        };
        if (!isPreview) {
            _.assign(props, {
                onClick() {
                    window.open(href, '_blank');
                }
            });
        }
        return props;
    }

    function getAdMobileProps(isPreview, href, wixAdsId, isHeaderFixedPosition) {
        const classes = ['mobile-top'];

        if (!isPreview) {
            classes.push('not-preview');
        }

        if (isHeaderFixedPosition) {
            classes.push('fixed-ad-mobile');
        }

        const props = {
            className: classes.join(' '),
            id: 'top',
            key: 'mobileTop',
            'data-aid': `${wixAdsId}mobileTop`,
            style: {height: 38, display: 'block'}
        };

        if (!isPreview) {
            _.assign(props, {
                href,
                'target': '_blank',
                'rel': 'nofollow'
            });
        }

        return props;
    }

    function getDesktopTopBanner(wixAdsId, adsTranslation) {
        const bannerProps = {
            className: 'desktop-top',
            id: 'top',
            key: 'desktopTop',
            'data-aid': `${wixAdsId}desktopTop`,
            href: adsTranslation.desktopUrl,
            target: '_blank',
            rel: 'nofollow',
            style: {width: 160, height: 26, display: 'block'}
        };

        return createReactElement('a', bannerProps, adsTranslation.topBanner1, createReactElement('div', {
            'className': 'hover1'
        }, adsTranslation.topBannerHover1), createReactElement('div', {
            'className': 'hover2'
        }, adsTranslation.topBannerHover2), createReactElement('svg', {
            'className': 'arrow',
            'viewBox': '0 0 6.62 11.25'
        }, createReactElement('path', {'d': 'M.77.64l4.5 5.4-4.5 4.5'}))
        );
    }

    function getDesktopBottomBanner(wixAdsId, adsTranslation, isPreview) {
        const ArrowRight = createReactElement('path', {'d': 'M.77.64l4.5 5.4-4.5 4.5'});

        return createReactElement('div', getAdBottomProps(isPreview, adsTranslation.desktopUrl, wixAdsId),
            createReactElement('span',
                {'className': 'contents'},
                adsTranslation.bottomBanner1,
                createReactElement('span', {'className': 'second'}, adsTranslation.bottomBanner3),
                createReactElement('svg', {'className': 'arrow', 'viewBox': '0 0 6.62 11.25'}, ArrowRight)
            )
        );
    }

    function getMobileTopBanner(wixAdsId, adsTranslation, isPreview, isHeaderFixedPosition) {
        const mobileBannerProps = getAdMobileProps(isPreview, adsTranslation.mobileUrl, wixAdsId, isHeaderFixedPosition);
        return createReactElement(isPreview ? 'div' : 'a', mobileBannerProps, adsTranslation.mobileBanner1);
    }

    class Banner extends React.Component {
        render() {
            const {wixAdsId, isMobileView, isPreview, isWixAdsAllowed, metaSiteId, isHeaderFixedPosition, translations} = this.props;

            const adsTranslation = translationsUtils.getWixFreemiumTranslations(translations, isPreview, metaSiteId);
            //TODO: remove when merging sv_newAds (ask BI first)
            adsTranslation.desktopUrl = `${adsTranslation.desktopUrl}&orig_msid=${metaSiteId}`;
            adsTranslation.mobileUrl = `${adsTranslation.mobileUrl}&orig_msid=${metaSiteId}`;

            const mobileTopBanner = getMobileTopBanner(wixAdsId, adsTranslation, isPreview, isHeaderFixedPosition);
            const desktopTopBanner = getDesktopTopBanner(wixAdsId, adsTranslation);
            const desktopBottomBanner = getDesktopBottomBanner(wixAdsId, adsTranslation, isPreview);
            const adsStyleNode = santaComponents.utils.styleNodeUtils.generateStyleNode('wixAds-style', wixAdsCss);

            const wrapperClasses = {
                'wrapper': true,
                visible: isWixAdsAllowed,
                hidden: !isWixAdsAllowed
            };

            const wrapperProps = {
                id: wixAdsId,
                ref: 'wrapper',
                className: coreUtils.classNames(wrapperClasses)
            };

            const children = isMobileView ?
                [adsStyleNode, mobileTopBanner] :
                [adsStyleNode, desktopTopBanner, desktopBottomBanner];

            return createReactElement('div', wrapperProps, children);
        }
    }
    Banner.displayName = 'WixFreemiumBanner';
    Banner.propTypes = {
        wixAdsId: PropTypes.string,
        isMobileView: PropTypes.bool,
        isPreview: PropTypes.bool,
        isWixAdsAllowed: PropTypes.bool,
        language: PropTypes.string,
        metaSiteId: PropTypes.string,
        isHeaderFixedPosition: PropTypes.bool,
        translations: PropTypes.object
    };
    return Banner;
});
