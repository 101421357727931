import {aspectNames} from 'common-types'

export interface StubStore {
    componentsToKeep: {[key: string]: true}
}

export const name = aspectNames.StubAspect

export const defaultModel: StubStore = {
    componentsToKeep: {}
}
