define([], function () {
    'use strict';
    return {
        'buttonClose': {
            'svg': {
                'viewBox': '0 0 180 180',
                'className': 'svgButtonClose'
            },
            'content': '<path d="M5 5 L175 175 M175 5 L5 175"/>'
        },
        'buttonPrevious': {
            'svg': {
                'viewBox': '0 0 180 310',
                'className': 'svgNavButton'
            },
            'content': '<path d="M170 10 L10 161 M10 150 L170 300"/>'
        },
        'buttonNext': {
            'svg': {
                'viewBox': '0 0 180 310',
                'className': 'svgNavButton'
            },
            'content': '<path d="M10 10 L170 161 M170 150 L10 300"/>'
        }
    };
});
