'use strict'
const _ = require('lodash')
const React = require('react')

const staticsToAdd = ['getCompCss', 'getCompFonts', 'displayName', 'compType', 'santaTypesDefinitions', 'isQaExtension']

const forwardRefHOC = wrapperFunc => Component => {
    const Wrapper = wrapperFunc(Component)
    // eslint-disable-next-line react/display-name
    const forwardRef = React.forwardRef((props, ref) => <Wrapper {...props} forwardedRef={ref} />)
    forwardRef.propTypes = _.assign({}, Component.propTypes, Wrapper.propTypes)

    _.forEach(staticsToAdd, staticName => {
        if (!_.isUndefined(Wrapper[staticName] || Component[staticName])) {
            forwardRef[staticName] = Wrapper[staticName] || Component[staticName]
        }
    })

    return forwardRef
}

module.exports = forwardRefHOC
