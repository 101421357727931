import {withActions} from 'carmi-host-extensions/src/aspects/withActions'
import _ from 'lodash'

export const name = 'wixCodeMessageChannelAspect'
export const defaultModel = {
    appsMessagesChannel: {}
}

const MESSAGE_INTENT = {
    SANTA_APPLICATION_CHANNEL_MESSAGE_RESPONSE: 'SANTA_APPLICATION_CHANNEL_MESSAGE_RESPONSE',
    SANTA_APPLICATION_CHANNEL_MESSAGE: 'SANTA_APPLICATION_CHANNEL_MESSAGE'
}

const MESSAGE_TYPE = {
    OPEN_MESSAGE_CHANNEL: 'openMessageChannel',
    SANTA_READY: 'santaReady',
    SANTA_PAGE_CHANGE: 'santaPageChange'
}

export const functionLibrary = {
    getAppMessageChannel: withActions(({setAppMessageChannel}, appsMessagesChannel, windowObject, appDefinitionId) => {
        if (appsMessagesChannel[appDefinitionId]) {
            return appsMessagesChannel[appDefinitionId]
        }
        const appMassageChannel = new windowObject.MessageChannel()
        setAppMessageChannel(appDefinitionId, appMassageChannel)
        return appMassageChannel
    }),

    clearAppsMessageChannelOnPageChange: withActions(({setAppsMessageChannel}, getWindowObject, pageDiff) => {
        const windowObject = getWindowObject()
        const pageChanged = didPageChange(pageDiff)
        if (pageChanged && windowObject && windowObject.parent !== windowObject) {
            setAppsMessageChannel({})
            const message = {
                intent: MESSAGE_INTENT.SANTA_APPLICATION_CHANNEL_MESSAGE_RESPONSE,
                type: MESSAGE_TYPE.SANTA_PAGE_CHANGE
            }
            windowObject.parent.postMessage(message, '*')
        }
    }),
    openMessageChannel: ({getAppMessageChannel, isViewerMode, getWindowObject}, msg, callback, fromApp) => {
        const windowObj = getWindowObject()
        if (windowObj && windowObj.parent !== windowObj && isViewerMode) {
            let messageData = msg.data
            if (_.isString(messageData)) {
                messageData = JSON.parse(msg.data)
            }
            const appDefinitionId = messageData.appDefinitionId
            const channel = getAppMessageChannel(appDefinitionId)

            const ports = fromApp ? [channel.port2] : [channel.port1]
            callback(null, {appDefinitionId}, ports)
        } else {
            callback('DID NOT CREATE MESSAGE CHANNEL', null)
        }
    },
    sendSiteReadyToParent: windowObject => {
        if (!windowObject || windowObject.parent === windowObject) {
            return
        }

        const message = {
            intent: MESSAGE_INTENT.SANTA_APPLICATION_CHANNEL_MESSAGE_RESPONSE,
            type: MESSAGE_TYPE.SANTA_READY
        }
        windowObject.parent.postMessage(message, '*')
    }
}

function didPageChange({next, previous}) {
    const hasPrevPage = !!previous.pageId
    const pageChanged = hasPrevPage && previous.pageId !== next.pageId
    const innerRouteChanged = previous.innerRoute !== next.innerRoute
    const tpaInnerRouteChanged = previous.tpaInnerRoute !== next.tpaInnerRoute
    return pageChanged || innerRouteChanged || tpaInnerRouteChanged
}

function isMessageFromParent(msgIntent) {
    return msgIntent === MESSAGE_INTENT.SANTA_APPLICATION_CHANNEL_MESSAGE
}

function generateResponseFunction(windowObject, msg) {
    return function (error, {appDefinitionId}, ports) {
        const message = {
            intent: MESSAGE_INTENT.SANTA_APPLICATION_CHANNEL_MESSAGE_RESPONSE,
            type: msg.data.type,
            appDefinitionId,
            error,
            callbackId: msg.data.callbackId
        }
        windowObject.parent.postMessage(message, '*', ports)
    }
}

function handleMessageChannelMessages(msg, {openMessageChannel, windowObj}) {
    let messageData = msg.data
    if (_.isString(messageData)) {
        try {
            messageData = JSON.parse(msg.data)
        } catch (e) {
            return
        }
    }
    if (!isMessageFromParent(messageData.intent)) {
        return
    }
    if (messageData.type === MESSAGE_TYPE.OPEN_MESSAGE_CHANNEL) {
        const callback = generateResponseFunction(windowObj, msg)
        openMessageChannel(msg, callback, true)
    }
}

export function init(aspectActions, {eventsManager, initialData}) {
    const {isViewerMode, isInSSr, getWindowObject, openMessageChannel} = initialData
    const windowObj = getWindowObject()
    if (!isInSSr && windowObj && windowObj.parent !== windowObj && isViewerMode) {
        eventsManager.on('windowMessage', event => {
            handleMessageChannelMessages(event, {openMessageChannel, windowObj})
        })
    }
}
