define(['lodash', 'coreUtils'], function (_, coreUtils) {
    'use strict';
    const componentFetchers = {};
    coreUtils.sessionFreezer.freeze(componentFetchers);

    /**
     * @class santaTypesFetchersRegistry
     */
    return {
        registerFetchersNamespace(ns, fetchers) {
            componentFetchers[ns] = fetchers;
        },

        getRegisteredFetchers() {
            return componentFetchers;
        }
    };
});


