define([
    'lodash',
    'coreUtils',
    'componentsCore'
], function (_,
             coreUtils,
             componentsCore) {
    'use strict';

    function registerCommonLanguageRequirement(compType, languagesGetter) {
        componentsCore.dataRequirementsCheckerRegistrar.registerCheckerForCompType(compType, (siteData, compInfo) => {
            const requiredLangs = languagesGetter(siteData, compInfo);
            return _.compact([coreUtils.translationsLoader.getRequest(siteData, requiredLangs, {initiator: compType})]);
        });
    }

    return {
        registerCommonLanguageRequirement
    };
});
