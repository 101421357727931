define([
    'lodash',
    'componentsCore/activities/activity'
], function (
    _,
    Activity
) {
    'use strict';

    const MAX_PHONE_FIELD_CHARS = 29;

    function extractField(fields, fieldName) {
        const list = fields.filter(field => field.name === fieldName);
        return list.length > 0 ? list[0].value : '';
    }

    const getActivityDetails = function (fields) {
        const subject = extractField(fields, 'subject');
        const message = extractField(fields, 'message');

        let summary = '';

        summary += subject ? `<strong>${subject}</strong>` : '';

        if (message) {
            summary += subject ? '<br>' : '';
            summary += message;
        }

        return {
            'additionalInfoUrl': null,
            'summary': !_.isEmpty(summary) ? summary : 'No message was received'
        };
    };

    function getActivityInfo(fields) {
        const info = {};
        info.fields = _.cloneDeep(fields);
        return info;
    }

    const getContactUpdate = function (allFields) {
        function getFieldsOfType(type) {
            return allFields.filter(field => field.name === type);
        }

        const fieldTypes = [
            {
                type: 'email',
                fields: getFieldsOfType('secondaryEmail'),
                contactField: 'emails'
            },
            {
                type: 'phone',
                fields: getFieldsOfType('phone'),
                contactField: 'phones'
            },
            {
                type: 'address',
                fields: getFieldsOfType('address'),
                contactField: 'addresses'
            }
        ];

        const contactUpdateInfo = fieldTypes.reduce(function (contactAcc, fieldType) {
            contactAcc[fieldType.contactField] =
                fieldType.fields.reduce(function (acc, val) {
                    if (val.value) {
                        const fieldObj = {tag: val.label};
                        fieldObj[fieldType.type] = val.name === 'phone' ? handlePhonesInfo(val.value) : val.value;
                        acc.push(fieldObj);
                    }
                    return acc;
                }, []);
            return contactAcc;
        }, {});

        const primaryEmails = getFieldsOfType('email');
        if (primaryEmails && primaryEmails.length > 0) {
            const primaryEmail = primaryEmails[0];
            contactUpdateInfo.emails.unshift({tag: primaryEmail.label, email: primaryEmail.value});
        }

        const name = getName(allFields);
        if (name) {
            contactUpdateInfo.name = name;
        }

        return contactUpdateInfo;
    };

    function handlePhonesInfo(phone) {
        return phone.substring(0, MAX_PHONE_FIELD_CHARS);
    }

    function getName(allFields) {
        const names = _.filter(allFields, {name: 'name'});
        if (names.length > 0) {
            const name = names[0].value;
            if (!name) {
                return '';
            }
            const spaceIndex = name.indexOf(' ');
            if (spaceIndex > 0) {
                return {
                    first: name.substr(0, name.indexOf(' ')),
                    last: name.substr(name.indexOf(' ') + 1)
                };
            }
            return {first: name};
        }
        return '';
    }

    const DynamicContactFormActivity = function (activityInfo, fields) {
        Activity.call(this, activityInfo, fields);
    };

    DynamicContactFormActivity.prototype = new Activity();

    DynamicContactFormActivity.prototype.getParams = function (params) {
        const baseParams = Activity.prototype.getParams.call(this, params);
        let contactFormParams = {
            'component-name': 'ContactForm'
        };

        contactFormParams = _.assign(baseParams, contactFormParams);

        return contactFormParams;
    };

    DynamicContactFormActivity.prototype.getPayload = function (payload) {
        const basePayload = Activity.prototype.getPayload.call(this, payload);
        const contactUpdate = getContactUpdate(this._fields);
        let contactFormPayload = {
            contactUpdate,
            activityInfo: getActivityInfo(this._fields),
            activityDetails: getActivityDetails(this._fields),
            activityType: 'contact/contact-form'
        };

        contactFormPayload = _.assign(basePayload, contactFormPayload);

        return contactFormPayload;
    };

    return DynamicContactFormActivity;
});
