'use strict'
const imageLayout = require('./imageLayout')
const ImageLoader = require('./imageLoader')

function wixImageWrapper(WixElement, services, environmentConsts) {
    if (!services.imageLoader) {
        services.imageLoader = new ImageLoader(services.mutationService)
    }

    class WixImage extends WixElement {
        constructor() { // eslint-disable-line no-useless-constructor
            super()
        }

        reLayout() {
            const domNodes = {}
            const measures = {}

            const imageId = this.getAttribute('id')
            const imageInfo = JSON.parse(this.dataset.imageInfo)
            domNodes[imageId] = this
            domNodes[imageInfo.containerId] = document.querySelector(`#${imageInfo.containerId}`)

            services.mutationService.measure(() => {
                imageLayout.measure(imageId, measures, domNodes, imageInfo.containerId)
            })

            services.mutationService.mutate(() => {
                imageLayout.patch(imageId, measures, domNodes, imageInfo.imageData, imageInfo.alignType, services, environmentConsts)
            })
        }

        disconnectedCallback() {
            super.disconnectedCallback()
            services.imageLoader.onImageDisconnected(this)
        }

        static get observedAttributes() { return ['data-image-info'] }
    }

    return WixImage
}

module.exports = wixImageWrapper