define([
    'coreUtils',
    'components/behaviors/compBehaviorHandler'
], function (
    coreUtils,
    compBehaviorHandler
) {
    'use strict';

    coreUtils.behaviorHandlersFactory.registerHandler('comp', compBehaviorHandler);
});
