'use strict'

const isIntersectionObserverSupported = () => window && 
        'IntersectionObserver' in window &&
        'IntersectionObserverEntry' in window &&
        'intersectionRatio' in window.IntersectionObserverEntry.prototype &&
        'isIntersecting' in window.IntersectionObserverEntry.prototype

const getViewPortIntersectionHandler = mutationService => (entries, observer) => {
    entries.filter(entry => entry.isIntersecting).forEach(function (entry) {
        const lazyImage = entry.target
        setImageSource(lazyImage, mutationService)
        observer.unobserve(lazyImage)
    })
}

const setImageSource = (wixImageNode, mutationService) => {
    const imageNode = wixImageNode.querySelector('img') || wixImageNode.querySelector('image')
    mutationService.mutate(() => {
        if (wixImageNode.dataset.isSvg === 'true') {
            imageNode.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', wixImageNode.dataset.src)
        } else {
            imageNode.src = wixImageNode.dataset.src
        }
    })
}

const isImageInViewPort = imageToLoad => {
    if (!window) {
        return true
    }

    const viewPortTop = window.scrollY
    const viewPortBottom = viewPortTop + window.innerHeight
    const imageTop = imageToLoad.absoluteTop
    const imageBottom = imageTop + imageToLoad.height
    return imageTop > viewPortTop && imageTop < viewPortBottom ||
        imageBottom > viewPortTop && imageBottom < viewPortBottom
}

class ImageLoader {
    constructor(mutationService) {
        this.mutationService = mutationService

        if (isIntersectionObserverSupported()) {
            const options = {rootMargin: '50% 0px'}
            this.intersectionObserver = new IntersectionObserver(getViewPortIntersectionHandler(mutationService), options)
        }
    }

    loadImage(wixImageNode, imageToLoad) {
        if (imageToLoad.currentSrc === imageToLoad.src) {
            return
        }

        if (isImageInViewPort(imageToLoad) || !this.intersectionObserver) {
            setImageSource(wixImageNode, this.mutationService)
        } else {
            this.intersectionObserver.unobserve(wixImageNode)
            this.intersectionObserver.observe(wixImageNode)
        }
    }

    onImageDisconnected(wixImageNode) {
        if (this.intersectionObserver) {
            this.intersectionObserver.unobserve(wixImageNode)
        }
    }
}

module.exports = ImageLoader