define([
    'coreUtils',
    'components/components/bootstrap/tpa/tpaStructurePlugin'
], function (
    coreUtils,
    tpaStructurePlugin
) {
    'use strict';

    const tpaWidgetCompType = 'wysiwyg.viewer.components.tpapps.TPAWidget';
    const tpaSectionCompType = 'wysiwyg.viewer.components.tpapps.TPASection';
    const tpaGluedWidgetCompType = 'wysiwyg.viewer.components.tpapps.TPAGluedWidget';
    const tpaMultiSection = 'wysiwyg.viewer.components.tpapps.TPAMultiSection';
    coreUtils.jsonUpdaterRegistrar.registerPlugin(tpaWidgetCompType, tpaStructurePlugin);
    coreUtils.jsonUpdaterRegistrar.registerPlugin(tpaSectionCompType, tpaStructurePlugin);
    coreUtils.jsonUpdaterRegistrar.registerPlugin(tpaGluedWidgetCompType, tpaStructurePlugin);
    coreUtils.jsonUpdaterRegistrar.registerPlugin(tpaMultiSection, tpaStructurePlugin);
});
