import React from 'react'
import PropTypes from 'prop-types'
import {utils} from 'santa-components'
import forwardRefHOC from 'santa-renderer/src/utils/forwardRefHOC'

export const pageSantaTypesDefinitions = utils.createSantaTypesDefinitions({
    key: PropTypes.string
}, 'BoltPageHOC')

const withPageKey = Page => {
    const PageKeyHOC = props => <Page {...props} ref={props.forwardedRef} />

    PageKeyHOC.propTypes = {
        key: pageSantaTypesDefinitions.key,
        forwardedRef: PropTypes.any
    }
    PageKeyHOC.displayName = `withPageKey(${Page.displayName})`

    return PageKeyHOC
}

export const boltPageHOC = forwardRefHOC(withPageKey)
