import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {guidUtils} from 'santa-core-utils'

class Animate extends React.Component {
    constructor(props) {
        super(props)
        this.uniqueId = guidUtils.getUniqueId('ooi-animation-')
    }

    componentDidMount() {
        this.registerAnimation()
    }

    registerAnimation() {
        const {
            duration,
            delay,
            effect,
            power,
            angle,
            distance,
            cycles,
            addRuntimeCompAnimationStub,
            compId
        } = this.props
        let {
            direction
        } = this.props

        if (effect === 'BounceIn') {
            const validDirections = ['top left', 'top right', 'bottom left', 'bottom right', 'center']
            if (!_.includes(validDirections, direction)) {
                direction = 'top left'
            }
        } else if (effect === 'ArcIn' || effect === 'TurnIn') {
            const validDirections = ['left', 'right']
            if (!_.includes(validDirections, direction)) {
                direction = 'left'
            }
        } else if (effect === 'Reveal' || effect === 'FoldIn' || effect === 'FlipIn' || effect === 'FloatIn' || effect === 'SlideIn') {
            const validDirections = ['left', 'right', 'bottom', 'top']
            if (!_.includes(validDirections, direction)) {
                direction = 'left'
            }
        } else if (effect === 'SpinIn') {
            const validDirections = ['cw', 'ccw']
            if (!_.includes(validDirections, direction)) {
                direction = 'cw'
            }
        }

        addRuntimeCompAnimationStub(this.uniqueId, {
            action: 'screenIn',
            root: compId,
            power,
            direction,
            distance,
            angle,
            cycles,
            duration: Number(duration),
            delay: Number(delay),
            name: effect
        })
    }


    render() {
        const {children} = this.props
        return (
            <React.Fragment> {
                React.cloneElement(children, {
                    'data-ooi-animate': true,
                    id: this.uniqueId
                }, null)
            }
            </React.Fragment>
        )
    }
}

Animate.propTypes = {
    direction: PropTypes.string,
    duration: PropTypes.number.isRequired,
    delay: PropTypes.number.isRequired,
    power: PropTypes.number,
    angle: PropTypes.number,
    distance: PropTypes.number,
    cycles: PropTypes.number,
    addRuntimeCompAnimationStub: PropTypes.func.isRequired,
    compId: PropTypes.string.isRequired,
    effect: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired
}

export {
    Animate
}
