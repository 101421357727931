define(['lodash', 'warmupUtils/pointers/pointerGeneratorsRegistry'], function (_, pointerGeneratorsRegistry) {
    'use strict';

    const POINTER_TYPE = 'svgShapes';

    pointerGeneratorsRegistry.registerPointerType(POINTER_TYPE, _.constant(null), _.constant(true));

    const getterFunctions = {
        getSvgShapePointer(getItemAt, cache, svgId) {
            // Inner pointers: 'content', 'info', 'boxBoundaries', 'viewport'
            return cache.getPointer(`svgShapes${svgId}`, POINTER_TYPE, ['svgShapes', svgId]);
        }
    };

    pointerGeneratorsRegistry.registerDataAccessPointersGenerator(POINTER_TYPE, getterFunctions);
});
