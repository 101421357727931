define([
    'lodash',
    'coreUtils',
    'santa-components'
], function (_, coreUtils, santaComponents) {
    'use strict';
    const {balataConsts} = coreUtils.mediaConsts;

    const posterQualityOverride = {
        unsharpMask: {
            radius: 0.33,
            amount: 1.0,
            threshold: 0.0
        }
    };

    return {
        mixins: [santaComponents.mixins.animationsMixin],
        // API
        externalAPI: ['play', 'pause', 'stop', 'seek', 'setVolume', 'mute', 'unMute', 'setRate', 'setSrc'],

        /**
         * export API function
         * @param {String} command
         * @param {Array} params
         */
        mediaAPI(command, params = []) {
            const apiCall = this[command];
            if (apiCall && _.includes(this.externalAPI, command)) {
                apiCall(...params);
            }
        },
        getPosterImageComp(imageData, showMedia, extraProps) {
            const hasBgScrollEffect = this.props.hasBgScrollEffect;
            const style = {position: 'absolute', top: 0, width: '100%', height: '100%'};
            if (showMedia && this.props.compProp.animatePoster === 'fade') {
                this.animate(this.refs.poster, 'BaseFade', 1.6, 0, {opacity: 0, ease: 'Quad.easeOut'});
            } else {
                style.opacity = showMedia ? 0 : 1;
            }

            return this.createChildComponent(imageData, 'core.components.Image', balataConsts.POSTER, _.assign({
                ref: balataConsts.POSTER,
                key: style.backgroundColor ? 'posterImagePng' : 'posterImage',
                id: this.props.id + balataConsts.POSTER,
                imageData: _.assign({}, imageData, {quality: posterQualityOverride, devicePixelRatio: 1}),
                hasBgScrollEffect,
                displayMode: this.props.fittingType,
                alignType: this.props.alignType,
                autoLayout: true,
                wixImageLayout: this.props.isExperimentOpen('bv_wixImagePhaseTwo'),
                style
            }, extraProps));
        }
    };
});
