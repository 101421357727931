/**
 * Created by eitanr on 8/20/14.
 */
define(['lodash', 'skins/util/skinsMap', 'skins/util/evals', 'skinUtils'], function (_, skinsMap, defaultEvaluators, skinUtils) {
    'use strict';

    function renderParam(paramName, skinData, styleData, colors) {
        return skinUtils.renderParam(paramName, skinData, styleData, colors, defaultEvaluators);
    }

    function getSkinDefaultParams(skinName) {
        const skinData = skinsMap.get(skinName);
        return skinData.paramsDefaults;
    }

    function getSkinExports(skinName) {
        const skinData = skinsMap.get(skinName);
        return _.get(skinData, 'exports');
    }

    return {
        /***
         *
         * @param paramName
         * @param skinData
         * @param styleData
         * @param themeData
         * @param evals
         * @returns {*}
         */
        renderParam,
        /**
         * @param skinName
         * @returns {*}
         */
        getSkinDefaultParams,
        /**
         * @param skinName
         * @returns {*}
         */
        getSkinExports
    };
});
