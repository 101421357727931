define(['coreUtils'], function (coreUtils) {
    'use strict';

    const urlUtils = coreUtils.urlUtils,
        ajaxUtils = coreUtils.ajaxLibrary;

    const API_HUB_PATH = '/_api/app-integration-bus-web/v1/activities';


    function reportActivity(baseUrl, activity, onSuccess, onError) {
        const query = `?${urlUtils.toQueryString(activity.getParams())}`,
            url = baseUrl + API_HUB_PATH + query;

        ajaxUtils.ajax({
            type: 'POST',
            url,
            data: activity.getPayload(),
            dataType: 'json',
            contentType: 'application/json',
            success: onSuccess,
            error: onError
        });
    }

    return {
        reportActivity
    };
});
