define(['santa-components', 'lodash', 'coreUtils', 'color'], function (santaComponents, _, coreUtils, Color) {
    'use strict';

    function getBgData(props) {
        return _.get(props.compDesign, 'background') || _.get(props.compData, 'background', {});
    }

    function updateBackgroundBrightness(imageData) {
        const imageUrl = imageData && imageData.uri;

        if (imageUrl) {
            const imageTransformSource = {
                id: imageUrl,
                width: imageData.width,
                height: imageData.height
            };
            const imageTransformTarget = {
                width: 1,
                height: 1
            };
            const imageClientApi = coreUtils.imageClientApi;
            const uri = imageClientApi.getData(imageClientApi.fittingTypes.SCALE_TO_FILL, imageTransformSource, imageTransformTarget).uri;
            const thumbnailImageUrl = this.props.getMediaFullStaticUrl(uri);

            this.props.updateImageInfo(this.props.id, this.props.rootId, thumbnailImageUrl);
        } else {
            const bgColor = getBackgroundColor.call(this);
            this.props.updateColorInfo(this.props.id, this.props.rootId, {brightness: bgColor.values.hsv[2], alpha: bgColor.values.alpha});
        }
    }

    function getBackgroundColor() {
        const bgData = getBgData(this.props);
        return new Color(coreUtils.colorParser.getColor(this.props.colorsMap, bgData.color, bgData.colorOpacity));
    }

    function handleBgChange(imageData, bgColor) { // eslint-disable-line complexity
        const newImageUrl = imageData && imageData.uri;
        const didImageChange = newImageUrl !== this.lastBackgroundImageUrl;
        const bgColorHex = bgColor && bgColor.hexString();
        const lastBgColorHex = this.lastBackgroundBgColor && this.lastBackgroundBgColor.hexString();
        const didColorChangeAndNoImage = !newImageUrl && bgColorHex !== lastBgColorHex;
        const didAlphaChange = bgColor && bgColor.values.alpha !== this.lastAlpha;

        if (didImageChange || didColorChangeAndNoImage || didAlphaChange) {
            updateBackgroundBrightness.call(this, imageData);
        }

        this.lastBackgroundImageUrl = newImageUrl;
        this.lastBackgroundBgColor = bgColor;
        this.lastAlpha = bgColor.values.alpha;
    }

    return {
        propTypes: {
            id: santaComponents.santaTypesDefinitions.Component.id,
            compDesign: santaComponents.santaTypesDefinitions.Component.compDesign,
            compData: santaComponents.santaTypesDefinitions.Component.compData,
            colorsMap: santaComponents.santaTypesDefinitions.Theme.colorsMap.isRequired,
            isMobileView: santaComponents.santaTypesDefinitions.isMobileView.isRequired,
            updateColorInfo: santaComponents.santaTypesDefinitions.VerticalAnchorsMenu.updateInformation.isRequired,
            updateImageInfo: santaComponents.santaTypesDefinitions.VerticalAnchorsMenu.updateImageInfo.isRequired,
            getMediaFullStaticUrl: santaComponents.santaTypesDefinitions.ServiceTopology.getMediaFullStaticUrl.isRequired
        },

        componentDidMount() {
            if (!this.props.isMobileView) {
                const bgColor = getBackgroundColor.call(this);
                const imageData = this.getMediaImageData();

                handleBgChange.call(this, imageData, bgColor);
            }
        },
        componentDidUpdate() { // eslint-disable-line complexity
            if (!this.props.isMobileView) {
                const bgColor = getBackgroundColor.call(this);
                const bgColorHex = bgColor && bgColor.hexString();
                const lastBgColorHex = this.lastBackgroundBgColor && this.lastBackgroundBgColor.hexString();
                const imageData = this.getMediaImageData();
                const imageUrl = imageData && imageData.uri;
                const didColorChange = bgColorHex !== lastBgColorHex;
                const didImageChange = imageUrl !== this.lastBackgroundImageUrl;
                const didAlphaChange = bgColor && bgColor.values.alpha !== this.lastAlpha;

                if (didColorChange || didImageChange || didAlphaChange) {
                    handleBgChange.call(this, imageData, bgColor);
                }
            }
        },

        getMediaImageData() {
            const bgData = getBgData(this.props);
            const media = bgData.mediaRef;
            if (media) {
                switch (media.type) {
                    case 'Image':
                        return media;
                    case 'WixVideo':
                        return media.posterImageRef;
                }
            }

            return null;
        }

    };
});
