define(['lodash', 'santa-components', 'prop-types', 'reactDOM', 'componentsCore', 'coreUtils', 'image-client-api', 'skins', 'displayer/skins/skins.json'], function (_, santaComponents, PropTypes, ReactDOM, componentsCore, coreUtils, imageClientLib, skinsPackage, skinsJson) {
    'use strict';

    const linkRenderer = coreUtils.linkRenderer;

    function getTextAlignment(textAlignment) {
        if (textAlignment) {
            switch (textAlignment) {
                case 'left':
                    return 'alignLeft';
                case 'center':
                    return 'alignCenter';
                case 'right':
                    return 'alignRight';
                default:
                    return 'alignLeft';
            }
        }
    }

    function createImageDataFromCompData(compData) {
        const imageData = _.assign({itemProp: 'contentUrl'}, compData);

        if (compData.title) {
            imageData.alt = compData.title;
        }

        return imageData;
    }

    /**
     * @class components.Displayer
     * @extends {core.skinBasedComp}
     * @extends {componentsCore.skinInfo}
     */
    const displayer = {
        displayName: 'Displayer',

        mixins: [componentsCore.mixins.skinBasedComp, componentsCore.mixins.skinInfo, componentsCore.mixins.createChildComponentMixin],

        propTypes: _.assign({
            browser: santaComponents.santaTypesDefinitions.Browser.browser.isRequired,
            rootId: santaComponents.santaTypesDefinitions.Component.rootId.isRequired,
            rootNavigationInfo: santaComponents.santaTypesDefinitions.Component.rootNavigationInfo.isRequired,
            linkRenderInfo: santaComponents.santaTypesDefinitions.Link.renderInfo.isRequired,
            isMobileView: santaComponents.santaTypesDefinitions.isMobileView,
            isMobileDevice: santaComponents.santaTypesDefinitions.Device.isMobileDevice,
            isAndroidOldBrowser: santaComponents.santaTypesDefinitions.mobile.isAndroidOldBrowser,
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen,

            imageIndex: PropTypes.number.isRequired,
            compProp: PropTypes.object.isRequired,
            compData: PropTypes.object.isRequired,
            imageWrapperSize: PropTypes.object.isRequired,

            heightDiff: PropTypes.number,
            widthDiff: PropTypes.number,
            bottomGap: PropTypes.number,
            galleryId: PropTypes.string,
            isSelected: PropTypes.bool,
            galleryDataId: PropTypes.string,
            skin: PropTypes.string,
            id: PropTypes.string,
            showPanelState: PropTypes.string,
            zoom: santaComponents.santaTypesDefinitions.NonPageItemZoom.zoom,
            onClick: PropTypes.func
        }, santaComponents.utils.santaTypesUtils.getSantaTypesFromPropTypes(santaComponents.components.Image.propTypes)),

        getInitialState() {
            return {
                $showPanel: 'defaultPanelState',
                $displayDevice: this.props.isMobileView ? 'mobileView' : 'desktopView',
                $textAlignmentState: getTextAlignment(this.props.compProp.alignText),
                $selected: this.props.isSelected ? 'selected' : 'unselected',
                $scaling: this.props.compProp.imageMode || 'clipImage',
                $transitionPhase: 'noTransition',
                $general: 'normal',
                $linkableComponent: this.props.compData.link ? 'link' : 'noLink'
            };
        },
        _getImageClickAction() {
            const compProp = this.props.compProp;
            let imageClickAction = compProp.galleryImageOnClickAction;
            if (!imageClickAction) {
                imageClickAction = compProp.expandEnabled === true ? 'zoomMode' : 'disabled';
            }
            return imageClickAction;
        },
        componentWillUnmount() {
            this._isMounted = false;
        },
        componentDidMount() {
            this._isMounted = true;
            setTimeout(function () {
                let classList;
                const ANDROID_FIX_CLASS = 'androidNativeBrowserFix';
                if (this._isMounted) {
                    this.setState({$showPanel: this.props.showPanelState || 'notShowPanel'}); //eslint-disable-line react/no-did-mount-set-state
                    /* CLNT-1377 | Fix for mobile Android native browser - it doesn't apply existing styles.
                     So we add and remove dummy class to make a browser redraw styles. */
                    if (this.props.isAndroidOldBrowser && this.props.isMobileDevice) {//eslint-disable-line react/no-did-mount-callbacks-from-props
                        classList = ReactDOM.findDOMNode(this).classList;
                        classList.add(ANDROID_FIX_CLASS);
                        classList.remove(ANDROID_FIX_CLASS);
                    }
                }
            }.bind(this), 0);
        },


        componentWillReceiveProps(nextProps) {
            this.setState({
                $selected: nextProps.isSelected ? 'selected' : 'unselected'
            });
        },
        getContainerSize() {
            let containerWidth = this.props.imageWrapperSize.imageWrapperWidth - this.getDisplayerDefaultParam(this.props.skin, 'imageWrapperRight') - this.getDisplayerDefaultParam(this.props.skin, 'imageWrapperLeft');
            let containerHeight = this.props.imageWrapperSize.imageWrapperHeight - this.getDisplayerDefaultParam(this.props.skin, 'imageWrapperBottom') - this.getDisplayerDefaultParam(this.props.skin, 'imageWrapperTop');
            if (this.getFromExports('addMarginToContainer')) {
                containerWidth += this.props.imageWrapperSize.imageWrapperMarginLeft + this.props.imageWrapperSize.imageWrapperMarginRight;
                containerHeight += this.props.imageWrapperSize.imageWrapperMarginTop + this.props.imageWrapperSize.imageWrapperMarginBottom;
            }
            return {
                containerWidth,
                containerHeight
            };
        },

        getSkinProperties() { // eslint-disable-line complexity
            const compData = this.props.compData;
            const compProp = this.props.compProp;
            const textAlign = compProp.alignText || 'left';
            const imageClassName = 'core.components.Image';
            const containerSize = this.getContainerSize();
            const containerWidth = containerSize.containerWidth;
            const containerHeight = containerSize.containerHeight;
            const imageStyleProps = {position: 'relative', overflow: 'hidden'};

            if (this.props.browser.ie && this.props.browser.version <= 10) {
                _.merge(imageStyleProps, {'border': '1px solid transparent'});
            }
            const descriptionNodeId = `${this.props.id}Description`;
            return {
                '': {
                    onClick: this.props.onClick,
                    onMouseEnter: this.onMouseEnter,
                    onMouseLeave: this.onMouseLeave,
                    onKeyDown: componentsCore.utils.accessibility.keyboardInteractions.activateBySpaceOrEnterButton,
                    'data-image-index': this.props.imageIndex,
                    //todo: CLNT-5323 , wixapp sildergallery temporary workwaround
                    //todo: remove width/height/uri from dom data when wixapps will add support for structureInfo->dataItem->items
                    'data-displayer-width': compData.width,
                    'data-displayer-height': compData.height,
                    'data-displayer-uri': compData.uri,
                    'data-height-diff': this.props.heightDiff,
                    'data-width-diff': this.props.widthDiff,
                    'data-bottom-gap': this.props.bottomGap,
                    'data-image-wrapper-right': this.getDisplayerDefaultParam(this.props.skin, 'imageWrapperRight'),
                    'data-image-wrapper-left': this.getDisplayerDefaultParam(this.props.skin, 'imageWrapperLeft'),
                    'data-image-wrapper-top': this.getDisplayerDefaultParam(this.props.skin, 'imageWrapperTop'),
                    'data-image-wrapper-bottom': this.getDisplayerDefaultParam(this.props.skin, 'imageWrapperBottom'),
                    'data-margin-to-container': this.getFromExports('addMarginToContainer'),
                    itemScope: true,
                    itemType: 'http://schema.org/ImageObject'

                },
                'imageWrapper': {
                    style: {
                        'height': this.props.imageWrapperSize.imageWrapperHeight,
                        'width': this.props.imageWrapperSize.imageWrapperWidth,
                        'marginLeft': this.props.imageWrapperSize.imageWrapperMarginLeft,
                        'marginRight': this.props.imageWrapperSize.imageWrapperMarginRight,
                        'marginTop': this.props.imageWrapperSize.imageWrapperMarginTop,
                        'marginBottom': this.props.imageWrapperSize.imageWrapperMarginBottom
                    }
                },
                'title': {
                    'aria-hidden': true,
                    children: compData.title || '',
                    style: {textAlign},
                    itemProp: 'name'
                },
                'description': {
                    id: descriptionNodeId,
                    children: this.parseTextIntoLinesArray(compData.description) || '',
                    style: {textAlign},
                    itemProp: 'description'
                },
                'image': this.createChildComponent(
                    compData,
                    imageClassName,
                    'image',
                    {
                        ref: 'image',
                        id: `${this.props.id}image`,
                        describedById: descriptionNodeId,
                        imageData: createImageDataFromCompData(compData),
                        containerWidth: containerWidth > 0 ? Math.round(containerWidth) : 16,
                        containerHeight: containerHeight > 0 ? Math.round(containerHeight) : 16,
                        displayMode: imageClientLib.fittingTypes.SCALE_TO_FILL,
                        style: imageStyleProps
                    }),
                'zoom': {
                    style: {'cursor': this.getCursor()}
                    // addChildBefore: [
                    //     this.generateZoomNode(), "link"
                    // ]
                },
                'link': this.getLinkSkinPartDescriptor()
            };
        },

        parseTextIntoLinesArray(text) {
            if (!_.isString(text)) {
                return undefined;
            }
            const textLinesArray = text.split(/(?:\r\n|\r|\n)/);

            if (textLinesArray.length > 1) {
                const parsedArr = [];
                _.forEach(textLinesArray, function addBrElements(textLine, index) {
                    parsedArr.push(textLine);

                    if (index < textLinesArray.length - 1) {
                        parsedArr.push(santaComponents.utils.createReactElement('br', null));
                    }
                });
                return parsedArr;
            }
            return text;
        },

        onMouseEnter() {
            this.setState({$general: 'rollover'});
        },
        onMouseLeave() {
            this.setState({$general: 'normal'});
        },

        /**
         * @private
         * @returns {string}
         */
        getCursor() {
            const compData = this.props.compData;
            const clickAction = this._getImageClickAction();

            if (clickAction === 'zoomMode' || compData.link && clickAction === 'goToLink') { // eslint-disable-line no-mixed-operators
                return 'pointer';
            }
            return 'default';
        },

        /**
         * @private
         * @returns {*}
         */
        getLinkData() {
            return linkRenderer.renderLink(
                this.props.compData.link,
                this.props.linkRenderInfo,
                this.props.rootNavigationInfo);
        },

        /**
         * @private
         * @param skinName
         * @param paramName
         * @returns {number}
         */
        getDisplayerDefaultParam(skinName, paramName) {
            const skinExports = this.getSkinExports();
            const skinData = skinsPackage.skinsMap.get(skinName, this.props.isExperimentOpen);
            const val = skinData.paramsDefaults ? skinData.paramsDefaults[paramName] : '';
            if (!val) {
                const exportsVal = skinExports[paramName];
                return exportsVal ? Math.abs(parseInt(exportsVal, 10) || 0) : 0;
            }
            if (Array.isArray(val)) {
                return _.sumBy(val, function (item) {
                    return Math.abs(parseInt(this.getParamFromDefaultSkin(item).value, 10));
                }.bind(this));
            }
            return Math.abs(parseInt(val, 10)) || 0;
        },

        /**
         * @private
         * @returns {*}
         */
        getLinkSkinPartDescriptor() {
            const compData = this.props.compData;
            const clickAction = this._getImageClickAction();
            const skinPartDescriptor = {
                draggable: false,
                style: _.assign({
                    cursor: this.getCursor(),
                    height: '100%',
                    width: '100%',
                    position: 'absolute',
                    top: '0px',
                    left: '0px'
                }, coreUtils.style.prefix({
                    userSelect: 'none',
                    userDrag: 'none',
                    userModify: 'read-only'
                })),
                'data-page-item-context': this.props.galleryDataId,
                'data-gallery-id': this.props.galleryId,
                onDragStart(e) {
                    e.preventDefault();
                    return false;
                }
            };

            let additionalParamsToMerge = {};
            if (clickAction === 'zoomMode') {
                if (this.props.compData.galleryData) {
                    additionalParamsToMerge = {
                        onClick: function () {
                            this.props.zoom(this.props.compData, this.props.compData.galleryData);
                        }.bind(this)
                    };
                } else {
                    additionalParamsToMerge = linkRenderer.renderImageZoomLink(
                        this.props.linkRenderInfo,
                        this.props.rootNavigationInfo,
                        compData,
                        this.props.galleryDataId,
                        undefined,
                        this.props.galleryId);
                }
            } else if (compData.link && clickAction === 'goToLink') {
                additionalParamsToMerge = this.getLinkData();
            } else {
                additionalParamsToMerge = {
                    onClick: function (e) {
                        function createEvent(expandedImageData, imageIndex) {
                            return {
                                item: expandedImageData,
                                timeStamp: coreUtils.loggingUtils.performance.now(),
                                imageIndex,
                                name: coreUtils.siteConstants.ACTION_TYPES.ITEM_CLICKED
                            };
                        }

                        this.props.handleAction({
                            'type': 'comp',
                            'name': coreUtils.siteConstants.ACTION_TYPES.ITEM_CLICKED,
                            'sourceId': this.props.galleryId,
                            'pageId': this.props.rootId
                        }, createEvent(_.get(this.props, 'compData'), _.get(this.props, 'imageIndex')));
                        e.preventDefault();
                        e.stopPropagation();
                    }.bind(this)
                };
            }

            _.merge(skinPartDescriptor, additionalParamsToMerge);

            if (clickAction === 'disabled') {
                skinPartDescriptor.parentConst = santaComponents.utils.createReactElement.bind(null, 'div');
            }

            return skinPartDescriptor;
        },
        setPanelState(newPanelState) {
            this.setState({
                $showPanel: newPanelState
            });
        },
        getPanelState() {
            return this.state.$showPanel;
        },
        setTransitionPhase(transPhase) {
            this.setState({
                $transitionPhase: transPhase
            });
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.Displayer', displayer);
    skinsPackage.skinsMap.addBatch(skinsJson);

    return displayer;
});
