define(['lodash', 'layout/util/layout', 'experiment'], function (_, layout, experiment) {
    'use strict';

    function measureWixAds(id, measureMap, nodesMap, structureInfo, {isMobileView, isExperimentOpen}) {
        const MEASURES_TO_MODIFY = ['height', 'width', 'top', 'left'];
        let children;
        if (isExperimentOpen('displayWixAdsNewVersion')) {
            children = ['top'];
        } else {
            children = isMobileView() ? ['top'] : ['top', 'bottom'];
        }
        const boundingClientRect = nodesMap.WIX_ADStop.getBoundingClientRect();

        measureMap.height.WIX_ADStop = boundingClientRect.height;

        _.forEach(children, function (child) {
            const childId = id + child;
            const currentChildRect = nodesMap[childId].getBoundingClientRect();
            measureMap.custom[childId] = currentChildRect;

            _.forEach(MEASURES_TO_MODIFY, function (key) {
                measureMap[key][childId] = Math.ceil(currentChildRect[key]);
            });
        });

        if (isMobileView()) {
            measureMap.top.WIX_ADStop = 0;
        }
    }

    function patchWixAds(id, patchers, measureMap, structureInfo, siteData) {
        if (!experiment.isOpen('displayWixAdsNewVersion', siteData)) {
            patchers.css(id, {visibility: ''});
        }
    }

    layout.registerRequestToMeasureChildren('wysiwyg.viewer.components.WixAds', [['top'], ['bottom']]);
    layout.registerCustomMeasure('wysiwyg.viewer.components.WixAds', measureWixAds);
    layout.registerPatcher('wysiwyg.viewer.components.WixAds', patchWixAds);
});
