import _ from 'lodash'
import * as CSSItem from './cssItem/cssItem'

function renderDesign(cssStyle) {
    return _(cssStyle)
        .keys()
        .filter(key => !_.isNull(cssStyle[key]) && _.has(CSSItem, key))
        .reduce((style, key) => CSSItem[key](style, cssStyle[key]), {})
}

export const name = 'MediaContainerAspect'
export const functionLibrary = {
    renderDesign
}
