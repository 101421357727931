(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("lodash"), require("color"), require("coreUtilsLib"));
	else if(typeof define === 'function' && define.amd)
		define(["lodash", "color", "coreUtilsLib"], factory);
	else if(typeof exports === 'object')
		exports["skin-utils"] = factory(require("lodash"), require("color"), require("coreUtilsLib"));
	else
		root["skin-utils"] = factory(root["lodash"], root["color"], root["coreUtilsLib"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__7__) {
return 