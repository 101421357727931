const _ = require('lodash')
const React = require('react')
const PropTypes = require('prop-types')

const id = 'STYLABLE_CONTAINER'

/**
 * @class components.StylableContainer
 */
class StylableContainer extends React.Component {
    render() {
        const {stylableCss} = this.props
        return (
            <div id={id} key={id}>
                {_.map(stylableCss, (css, pageId) =>
                    <style id={`${pageId}_stylable`} key={pageId}>{css}</style>
                )}
            </div>
        )
    }
}

StylableContainer.compType = id
StylableContainer.displayName = 'StylableContainer'

StylableContainer.propTypes = {
    stylableCss: PropTypes.object
}

module.exports = StylableContainer
