define(['lodash', 'warmupUtils'], function (_, warmupUtils) {
    'use strict';

    const handlers = {};
    const preprocessors = {};

    warmupUtils.sessionFreezer.freeze(handlers);
    warmupUtils.sessionFreezer.freeze(preprocessors);

    const EMPTY_HANDLER = {
        handle: _.noop
    };

    function getHandler(type) {
        if (!_.has(handlers, type)) {
            warmupUtils.log.warn(`there is no behavior handler for type ${type}`);
            return EMPTY_HANDLER;
        }

        return handlers[type];
    }

    function registerHandler(type, handler) {
        handlers[type] = handler;
    }

    function registerBehaviorPreprocessor(type, preprocessor) {
        preprocessors[type] = preprocessor;
    }

    function getBehaviorPreprocessor(type) {
        return preprocessors[type] || _.identity;
    }

    return {
        getHandler,
        registerHandler,
        registerBehaviorPreprocessor,
        getBehaviorPreprocessor
    };
});
