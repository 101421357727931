'use strict'
const React = require('react')
const PropTypes = require('prop-types')

class StylesContainer extends React.Component {
    static propTypes = {
        shouldComponentUpdate: PropTypes.bool,
        children: PropTypes.node
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.shouldComponentUpdate
    }

    render() {
        return <div data-aid="stylesContainer">{this.props.children}</div>
    }
}

module.exports = StylesContainer