/**
 * Created by eitanr on 6/24/14.
 */
define(['lodash', 'layout/specificComponents/svgShape/svgPathParser', 'layout/specificComponents/svgShape/svgBasicScaler', 'warmupUtilsLib'], function (_, parser, basic, warmupUtilsLib) {
    'use strict';
    const emptyString = function () {
            return '';
        },
        scaleAngleValue = function (value, scaleX, scaleY) {
            return basic.scaleSingleValue(value, scaleY / (scaleX * 360));
        },

        scaleArcString = function (commandValues, scaleX, scaleY) {
            if (commandValues.length !== 7) {
                warmupUtilsLib.log.verbose(`incorrect arc string, should have exactly 7 parameters. (value was ${commandValues.join(' ')}`);
                return commandValues.join(' ');
            }

            commandValues[0] = basic.scaleSingleValue(commandValues[0], scaleX); // eslint-disable-line santa/no-side-effects
            commandValues[1] = basic.scaleSingleValue(commandValues[1], scaleY); // eslint-disable-line santa/no-side-effects
            commandValues[2] = scaleAngleValue(commandValues[2], scaleX, scaleY); // eslint-disable-line santa/no-side-effects
            commandValues[5] = basic.scaleSingleValue(commandValues[5], scaleX); // eslint-disable-line santa/no-side-effects
            commandValues[6] = basic.scaleSingleValue(commandValues[6], scaleY); // eslint-disable-line santa/no-side-effects

            return commandValues.join(' ');
        },

        scaleMultipleArcString = function (arcString, scaleX, scaleY) {
            const commandValues = arcString.split(/[\s,]+/);
            const commandChunks = _.chunk(commandValues, 7);
            const commandMultipleValues = [];

            _.forEach(commandChunks, function (commandValueArr) {
                commandMultipleValues.push(scaleArcString(commandValueArr, scaleX, scaleY));
            });

            return commandMultipleValues.join(' ');
        },

        scale = function (pathElement, scaleX, scaleY) {
            const parsedPath = parser.getParsedPath(pathElement.getAttribute('d'));
            _.forEach(parsedPath, function (commandValueArr) {
                const args = [commandValueArr[1]],
                    pathFunction = commandValueArr[0].toUpperCase();

                if (pathFunction === 'V') {
                    args.push(scaleY);
                } else if (pathFunction === 'H') {
                    args.push(scaleX);
                } else {
                    args.push(scaleX);
                    args.push(scaleY);
                }
                commandValueArr[1] = scalers[commandValueArr[0].toUpperCase()].apply(null, args); // eslint-disable-line santa/no-side-effects
            });
            return {d: parser.stringifyParsedPath(parsedPath)};
        },
        scalers = {
            M: basic.scaleMultiplePairStrings,
            L: basic.scaleMultiplePairStrings,
            H: basic.scaleMultipleSingleStrings,
            V: basic.scaleMultipleSingleStrings,
            Z: emptyString,
            C: basic.scaleMultiplePairStrings,
            S: basic.scaleMultiplePairStrings,
            Q: basic.scaleMultiplePairStrings,
            T: basic.scaleMultiplePairStrings,
            A: scaleMultipleArcString
        };

    return {
        scale
    };
});
