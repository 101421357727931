'use strict'

const createReactClass = require('create-react-class')
const skinBasedComp = require('../../mixins/skinBasedComp')

const wixSkinOnly = (displayName, getComponentSkins, compType) => createReactClass({
    displayName,

    mixins: [skinBasedComp(getComponentSkins())],

    statics: {
        useSantaTypes: true,
        getComponentSkins,
        compType
    },

    getSkinProperties() {
        return {}
    }
})

module.exports = wixSkinOnly