define([
    'lodash',
    'zepto',
    'layout/util/layout',
    'layout/specificComponents/balataLayout'
], function (_, $, /** layout.layout */ layout, balataLayout) {
    'use strict';

    const MIN_HEIGHT = 50;

    function measureBoxSlideShow(id, measureMap) {
        measureMap.height[id] = Math.max(MIN_HEIGHT, measureMap.height[id]);
        measureMap.minHeight[id] = MIN_HEIGHT;
    }

    function measureBoxSlideShowSlide(id, measureMap, nodesMap, structureInfo) {
        measureMap.top[id] = 0;
        measureMap.left[id] = 0;

        const slideNode = $(nodesMap[id]);
        const slideParentId = slideNode.data('parent-id');
        measureMap.minHeight[id] = slideNode.data('min-height');
        measureMap.height[id] = measureMap.height[slideParentId];

        if (!structureInfo.designDataItem.background) {
            return;
        }

        balataLayout.measure(id, measureMap, nodesMap, structureInfo);
    }

    function patchBoxSlideShowSlide(id, patchers, measureMap, structureInfo, siteData) {
        if (!structureInfo.designDataItem.background) {
            return;
        }

        balataLayout.patch(id, patchers, measureMap, structureInfo, siteData);
    }

    //the default flow is that the parent is measured first
    layout.registerCustomMeasure('wysiwyg.viewer.components.BoxSlideShow', measureBoxSlideShow);
    layout.registerCustomMeasure('wysiwyg.viewer.components.BoxSlideShowSlide', measureBoxSlideShowSlide);


    layout.registerRequestToMeasureDom('wysiwyg.viewer.components.BoxSlideShow');
    layout.registerRequestToMeasureDom('wysiwyg.viewer.components.BoxSlideShowSlide');
    layout.registerRequestToMeasureChildren('wysiwyg.viewer.components.BoxSlideShowSlide', [['inlineContentParent'], ['inlineContent']].concat(balataLayout.BALATA_PATHS_TO_REQUEST_MEASURE));
    layout.registerPatcher('wysiwyg.viewer.components.BoxSlideShowSlide', patchBoxSlideShowSlide);
});
