const {getFunctionsLibrary} = require('carmi-react')
import modelFactory from './client/bolt.carmi'
import {boltInit} from './bolt-runtime'
import {createBoltSiteElement, renderClientSide} from './main-base'

const createBoltInstance = (siteModel, batchingStrategy, renderingPlugins, hostApi = {}, logger, functionLibraryOverrides = {}, exceptionHandlingApi) =>
    boltInit(modelFactory, siteModel, batchingStrategy, getFunctionsLibrary(renderingPlugins), hostApi, logger, functionLibraryOverrides, exceptionHandlingApi)

export default {
    createBoltSiteElement,
    renderClientSide,
    createBoltInstance
}
