define([
    'lodash',
    'coreUtils',
    'santa-core-utils',
    'santa-components',
    'components/components/bootstrap/infoTip/utils/infoTipUtils',
    'reactDOM',
    'componentsCore'
], function (
    _,
    coreUtils,
    coreUtilsLib,
    santaComponents,
    infoTipUtils,
    ReactDOM,
    componentsCore
) {
    'use strict';

    const assignStyle = coreUtils.style.assignStyle;

    const closeTipTimeout = 150;
    const showTimer = 500;
    const closeByTimeoutTimer = 3000;


    function closeTipByTimeout() {
        closeTip.call(this);
    }

    function startCloseByTimeoutTimer() {
        this.setTimeoutNamed('closeTipByTimeout', closeTipByTimeout.bind(this), closeByTimeoutTimer);
    }

    function callTip(params, source) {
        showTip.call(this, ReactDOM.findDOMNode(source.source));
    }

    function closeTip() {
        this.clearTimeoutNamed('openTip');
        this.setState({
            $hidden: 'hidden',
            runTimer: true
        });
    }

    function showTip(caller) {
        this.setState({
            $hidden: '',
            isShown: true,
            caller
        });
        startCloseByTimeoutTimer.call(this);
    }

    function updateDOMPositionIfNeeded() {
        let tipNode,
            pos;

        if (this.state.isShown) {
            tipNode = ReactDOM.findDOMNode(this);
            pos = infoTipUtils.getPosition(this.state.caller, tipNode);
            assignStyle(tipNode, _.pick(pos, ['top', 'left', 'right']));
        }
    }

    function isEmptyTip(sourceCompData) {
        return sourceCompData && _.isEmpty(sourceCompData.description);
    }


    /**
     * @class components.infoTip
     * @extends {core.skinBasedComp}
     */
    const infoTip = {
        displayName: 'InfoTip',
        mixins: [componentsCore.mixins.skinBasedComp, coreUtilsLib.timersMixins.timeoutsMixin],
        propTypes: {
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired
        },

        onMouseEnter() {
            this._isMouseInside = true;
        },

        onMouseLeave() {
            this._isMouseInside = false;
            closeTip.call(this);
        },

        showToolTip(params, source) {
            if (isEmptyTip(source.source.props.compData)) {
                return;
            }
            this.clearTimeoutNamed('hideTipByClose');
            this.setTimeoutNamed('openTip', function () {
                callTip.call(this, params, source);
            }.bind(this), showTimer);
        },

        closeToolTip() {
            this.setTimeoutNamed('hideTipByClose', function () {
                if (!this._isMouseInside) {
                    closeTip.call(this);
                }
            }.bind(this), closeTipTimeout);
        },

        getSkinProperties() {
            return {
                content: {
                    children: [this.props.compData.content]
                }
            };
        },

        componentDidUpdate() {
            // we call updateDOMPositionIfNeeded only in componentDidUpdate because position will be updated only by
            // mouse events so no sense to handle componentDidMount
            updateDOMPositionIfNeeded.call(this);
        },

        getInitialState() {
            return {
                '$hidden': 'hidden',
                isMouseInside: false
            };
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.common.components.InfoTip', infoTip);

    return infoTip;
});
