export default {
    VIEWPORT_ENTER_ACTION: 'viewportEnter',
    VIEWPORT_LEAVE_ACTION: 'viewportLeave',
    VIEWPORT_ACTION_NS: 'ViewportAction',
    HANDLERS_NS: 'handlers',
    INTERSECTIONS: 'intersections',
    VIEWPORT_STATE: 'state',
    VIEWPORT_STATE_REQUESTS: 'stateRequests',
    DEFAULT_THRESHOLD: [0.01],
    HANDLER_KEY_DELIMITER: '__'
}
