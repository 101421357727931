define(['lodash', 'warmupUtils/pointers/DataAccessPointers', 'warmupUtils/pointers/pointerGeneratorsRegistry'], function (_, DataAccessPointers, pointerGeneratorsRegistry) {
    'use strict';
    const pointers = new DataAccessPointers(); // eslint-disable-line santa/no-module-state

    const getterFunctions = {
        getModes(getItemAtPath, cache, pointer) {
            return pointers.getInnerPointer(pointer, ['modes']);
        },

        getModesDefinitions(getItemAtPath, cache, pointer) {
            return pointers.getInnerPointer(pointer, ['modes', 'definitions']);
        },

        getModesOverrides(getItemAtPath, cache, pointer) {
            return pointers.getInnerPointer(pointer, ['modes', 'overrides']);
        }
    };

    pointerGeneratorsRegistry.registerDataAccessPointersGenerator('componentStructure', getterFunctions);
});
