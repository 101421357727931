define([
    'lodash',
    'componentsCore',
    'santa-components',
    'santa-core-utils',
    'tpaComponents/mixins/tpaCompBaseMixin',
    'tpaComponents/mixins/tpaUrlBuilderMixin',
    'tpaComponents/mixins/tpaCompApiMixin',
    'tpaComponents/mixins/tpaWidgetMixin',
    'tpaComponents/mixins/tpaResizeWindowMixin'
], function (
    _,
    componentsCore,
    santaComponents,
    coreUtilsLib,
    tpaCompBaseMixin,
    tpaUrlBuilderMixin,
    tpaCompApiMixin,
    tpaWidgetMixin,
    tpaResizeWindowMixin
) {
    'use strict';

    const compRegistrar = componentsCore.compRegistrar;

    /**
     * @class components.TPAWidget
     * @extends {tpa.mixins.tpaCompBase}
     * @extends {tpa.mixins.tpaCompApiMixin}
     * @extends {tpa.mixins.tpaUrlBuilder}
     */
    const TPAWidget = {
        displayName: 'TPAWidget',
        propTypes: {
            structure: santaComponents.santaTypesDefinitions.Component.structure.isRequired
        },
        mixins: [componentsCore.mixins.skinBasedComp, coreUtilsLib.timersMixins.timeoutsMixin, tpaCompBaseMixin, tpaUrlBuilderMixin, tpaCompApiMixin, tpaWidgetMixin, tpaResizeWindowMixin],
        mutateIframeUrlQueryParam(queryParamsObj) {
            queryParamsObj.width = this.state.initialWidth; // eslint-disable-line santa/no-side-effects

            const originCompId = this.props.structure.originCompId;
            if (originCompId && !_.isEmpty(originCompId)) {
                queryParamsObj.originCompId = originCompId;
            }
            return queryParamsObj;
        }
    };

    compRegistrar.register('wysiwyg.viewer.components.tpapps.TPAWidget', TPAWidget);
    return TPAWidget;
});
