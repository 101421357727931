define(['lodash'], function (_) {
    'use strict';

    const trackingReporter = {
        register(tr) {
            _.assign(trackingReporter, tr);
        },

        reportDuration() {}
    };

    return trackingReporter;
});
