'use strict'
const rendererHOC = require('../../components/RendererHOC')
const {withActions} = require('carmi-host-extensions')

const name = 'ComponentsRenderAspect'

const defaultModel = {
    compRefs: {}
}

const functionLibrary = {
    wrapWithRendererHOC: component => rendererHOC(component),
    handleComponentRender: (compId, registerLayoutPendingForComp) => registerLayoutPendingForComp(compId),
    updateCompRef: withActions((aspectActions, id, element) => {
        if (element === null) {
            aspectActions.setCompRef(id, undefined)
        } else {
            aspectActions.setCompRef(id, element)
        }
    })
}

module.exports = {
    name,
    defaultModel,
    functionLibrary
}
