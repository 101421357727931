import {fonts} from 'santa-core-utils'
import {aspectNames} from 'common-types'
const {HostSpecificFontsAspect} = aspectNames
export const name = HostSpecificFontsAspect

export const defaultModel = {}

// Runtime
export const functionLibrary = {
    getCurrentSelectableFontsWithParams: fonts.getCurrentSelectablefontsWithParams
}

export function init() {
    // hello
}
