define([
    'lodash',
    'coreUtils'
], function (
    _,
    coreUtils
) {
    'use strict';

    const ALIGNMENTS = coreUtils.constants.COMP_LAYOUT_OPTIONS.REPEATER.ALIGNMENTS;
    function calculateItemAlignmentOffset(alignment, rowLeftoverWidth, column, itemsInRow) {
        switch (alignment) {
            case ALIGNMENTS.LEFT: return 0;
            case ALIGNMENTS.RIGHT: return rowLeftoverWidth;
            case ALIGNMENTS.CENTER: return rowLeftoverWidth / 2;
            case ALIGNMENTS.JUSTIFY:
                const itemsToJustify = itemsInRow > 1 ? itemsInRow - 1 : 1;
                return rowLeftoverWidth / itemsToJustify * column;
        }
    }

    function getAlignmentOffset(alignment, rowLeftoverWidth, column, itemsInRow) {
        if (alignment) {
            return calculateItemAlignmentOffset(alignment, rowLeftoverWidth, column, itemsInRow);
        }
        return 0;
    }

    function getHorizontalGapOffset(alignment, column, horizontalGap) {
        if (alignment !== ALIGNMENTS.JUSTIFY) {
            return horizontalGap * column;
        }
        return 0;
    }

    function getNumOfItemsToShow(repeaterWidth, itemWidth, horizontalGap) {
        if (repeaterWidth < itemWidth) {
            return 1;
        }
        return Math.floor((repeaterWidth - itemWidth) / (itemWidth + horizontalGap)) + 1;
    }

    const layoutersByType = {
        CardsLayoutProperties(pointers, displayedDal, layoutPointers, newTemplateLayout, repeaterPointer, repeaterProperties, newRepeaterDimensions) {
            const templateLayout = _.assign({}, displayedDal.get(layoutPointers[0]), newTemplateLayout);
            const repeaterLayoutPointer = pointers.getInnerPointer(repeaterPointer, ['layout']);
            const repeaterLayout = displayedDal.get(repeaterLayoutPointer);
            const horizontalGap = repeaterProperties.alignment !== ALIGNMENTS.JUSTIFY ? repeaterProperties.gap.horizontal : 0;
            const verticalGap = repeaterProperties.gap.vertical;
            let repeaterWidth = repeaterLayout.width;
            if (newRepeaterDimensions) {
                repeaterWidth = newRepeaterDimensions.width;
            }

            const numOfItemsToFitInRow = getNumOfItemsToShow(repeaterWidth, templateLayout.width, horizontalGap);
            const maxActualItemsInRow = Math.min(numOfItemsToFitInRow, layoutPointers.length);
            const rowLeftoverWidth = repeaterWidth - horizontalGap * (maxActualItemsInRow - 1) - templateLayout.width * maxActualItemsInRow; // eslint-disable-line no-mixed-operators

            const {direction} = repeaterProperties;
            _.transform(layoutPointers, function (columnsTop, layoutPointer, index) {
                const column = index % numOfItemsToFitInRow;

                const isAfterFirstRow = index >= numOfItemsToFitInRow;
                if (isAfterFirstRow) {
                    const heightOfItemAbove = verticalGap + (_.get(newTemplateLayout, 'height') || displayedDal.get(layoutPointers[index - numOfItemsToFitInRow]).height);
                    columnsTop[column] += heightOfItemAbove;
                }

                const itemAlignmentOffset = getAlignmentOffset(repeaterProperties.alignment, rowLeftoverWidth, column, maxActualItemsInRow);
                const itemHorizontalGapOffset = getHorizontalGapOffset(repeaterProperties.alignment, column, horizontalGap);

                const x = column * templateLayout.width + itemAlignmentOffset + itemHorizontalGapOffset; // eslint-disable-line no-mixed-operators
                const itemPosition = {
                    y: columnsTop[column],
                    x: direction !== 'rtl' ? x : repeaterWidth - templateLayout.width - x
                };
                displayedDal.merge(layoutPointer, _.defaults(itemPosition, newTemplateLayout));
            }, _.fill(Array(numOfItemsToFitInRow), 0));

            if (repeaterWidth < templateLayout.width) {
                const newRepeaterLayout = _.defaults({width: Math.max(repeaterWidth, templateLayout.width)}, repeaterLayout);
                displayedDal.set(repeaterLayoutPointer, newRepeaterLayout);
            }
        }
    };

    return {
        setLayout(pointers, displayedDal, repeaterPointer, newTemplateLayout, newRepeaterDimensions) {
            const repeaterPropertiesQuery = displayedDal.get(pointers.getInnerPointer(repeaterPointer, ['propertyQuery']));
            const repeaterProperties = displayedDal.get(pointers.data.getPropertyItem(repeaterPropertiesQuery, pointers.components.getPageOfComponent(repeaterPointer).id));
            const children = pointers.components.getChildren(repeaterPointer);
            if (_.isEmpty(children) || !repeaterProperties) {
                return;
            }
            const childrenLayoutPointers = _.map(children, childPointer => pointers.getInnerPointer(childPointer, ['layout']));
            layoutersByType[repeaterProperties.type](pointers, displayedDal, childrenLayoutPointers, newTemplateLayout, repeaterPointer, repeaterProperties, newRepeaterDimensions);
        }
    };
});
