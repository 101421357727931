define(['lodash', 'warmupUtils/dal/DALFactory'], function (_, DALFactory) {
    'use strict';

    function setRootNavigationInfo(siteData, navInfo, isOverridingAllRoots) {
        const displayedJsonDal = DALFactory.getInstance(siteData);
        const routersRendererIndexPath = ['dynamicPages', 'routersRendererIndex'];

        const pageId = navInfo.pageId;
        const isPopup = siteData.isPopupPage(pageId);

        const currentPageIds = {};
        const currentRootInfos = {};

        if (isOverridingAllRoots) {
            if (!isPopup) {
                currentPageIds.primaryPage = null;
            }
            currentPageIds.popupPage = null;
        }

        //if popups will have urls we'll have to do more here
        currentRootInfos[pageId] = navInfo;
        if (siteData.isDynamicPage(pageId)) {
            const currentRoutersRendererIndex = displayedJsonDal.getByPath(routersRendererIndexPath);
            currentRootInfos[pageId].routersRendererIndex = navInfo.pageAdditionalData + currentRoutersRendererIndex;
            displayedJsonDal.setByPath([routersRendererIndexPath], currentRoutersRendererIndex + 1);
        }
        if (!isPopup) {
            currentRootInfos[siteData.MASTER_PAGE_ID] = navInfo;
            currentPageIds.primaryPage = navInfo.pageId;
        } else {
            currentPageIds.popupPage = navInfo.pageId;
        }

        displayedJsonDal.mergeByPath(['_currentRootInfos'], currentRootInfos);
        displayedJsonDal.mergeByPath(['_currentPageIds'], currentPageIds);
    }

    function getBrowserFlag(siteData, flagName) {
        const displayedJsonDal = DALFactory.getInstance(siteData);
        return displayedJsonDal.getByPath(['_browserFlags', flagName]);
    }

    function isEditingMode(siteData) {
        const displayedJsonDal = DALFactory.getInstance(siteData);
        const componentViewMode = displayedJsonDal.getByPath(['renderFlags', 'componentViewMode']);
        return componentViewMode === 'editor';
    }

    function isWixAdsAllowed(siteData) {
        const displayedJsonDal = DALFactory.getInstance(siteData);
        return displayedJsonDal.getByPath(['renderFlags', 'isWixAdsAllowed']);
    }

    function isHeaderFixedPosition(siteData) {
        const viewMode = siteData.getViewMode();
        const pointers = DALFactory.getPointersInstance(siteData);
        const masterPagePointer = pointers.components.getPage(siteData.MASTER_PAGE_ID, viewMode);
        const headerPointer = pointers.components.getComponent('SITE_HEADER', masterPagePointer);
        const fixedPositionPointer = pointers.getInnerPointer(headerPointer, ['layout', 'fixedPosition']);
        return DALFactory.getInstance(siteData).get(fixedPositionPointer);
    }

    // SVG Setters
    function setSvgShapeStore(siteData, svgId, storeData) {
        const displayedJsonDal = DALFactory.getInstance(siteData);
        displayedJsonDal.setByPath(['svgShapes', svgId], storeData);
    }

    // SVG Getters
    function getSvgString(siteData, svgId) {
        const displayedJsonDal = DALFactory.getInstance(siteData);
        return displayedJsonDal.getByPath(['svgShapes', svgId, 'content']);
    }

    function getSvgInfo(siteData, svgId) {
        const displayedJsonDal = DALFactory.getInstance(siteData);
        return displayedJsonDal.getByPath(['svgShapes', svgId, 'info']);
    }

    function getMediaStore(siteData, playerId) {
        const displayedJsonDal = DALFactory.getInstance(siteData);
        return displayedJsonDal.getByPath(['mediaStore', playerId]);
    }

    function isMobileStructureExists(siteData) {
        const displayedJsonDal = DALFactory.getInstance(siteData);
        const pointers = DALFactory.getPointersInstance(siteData);
        displayedJsonDal.isPathExist(['resolvedDataMaps', 'masterPage']); // necessary for mobx to observe
        const masterPagePointer = pointers.page.getPagePointer('masterPage');
        return !masterPagePointer || displayedJsonDal.isExist(pointers.getInnerPointer(masterPagePointer, ['structure', 'MOBILE']));
    }

    function initPackageCounters(displayedJsonDal, packageName) {
        if (!displayedJsonDal.isPathExist(['wixappsRenderCounters'])) {
            const defaultValue = _.set({}, packageName, {});
            displayedJsonDal.setByPath(['wixappsRenderCounters'], defaultValue);
            return;
        }

        if (!displayedJsonDal.isPathExist(['wixappsRenderCounters', packageName])) {
            displayedJsonDal.setByPath(['wixappsRenderCounters', packageName], {});
        }
    }

    function incrementWixappsRenderCounter(siteData, packageName, type) {
        type = type || 'general';
        const displayedJsonDal = DALFactory.getInstance(siteData);

        const path = ['wixappsRenderCounters', packageName, type];
        if (!displayedJsonDal.isPathExist(path)) {
            initPackageCounters(displayedJsonDal, packageName);
            displayedJsonDal.setByPath(path, 0);
        }
        const currRenderCounterVal = displayedJsonDal.getByPath(path);
        displayedJsonDal.setByPath(path, currRenderCounterVal + 1);
    }

    function getMapFromPageUriSeoToPageId(siteData) {
        const displayedJsonDal = DALFactory.getInstance(siteData);
        return displayedJsonDal.getByPath(['mapFromPageUriSeoToPageId']);
    }

    function getRequestModel(siteData, prop, noClone) {
        const displayedJsonDal = DALFactory.getInstance(siteData);
        if (prop) {
            return displayedJsonDal.getByPath(['requestModel', prop], false, noClone);
        }
        return displayedJsonDal.getByPath(['requestModel'], false, noClone);
    }

    function setCustomUrlMapping(siteData, permalink, data) {
        const displayedJsonDal = DALFactory.getInstance(siteData);
        displayedJsonDal.setByPath(['customUrlMapping', permalink], data);
    }

    function updateScreenSize(siteData, screenSize) {
        const displayedJsonDal = DALFactory.getInstance(siteData);
        const currentScreenSize = siteData.getScreenSize();
        if (!_.isEqual(currentScreenSize, screenSize)) {
            displayedJsonDal.mergeByPath(['screenSize'], screenSize);
        }
    }

    function getRendererModel(siteData, prop, noClone) {
        const displayedJsonDal = DALFactory.getInstance(siteData);
        if (prop) {
            return displayedJsonDal.getByPath(['rendererModel', prop], false, noClone);
        }
        return displayedJsonDal.getByPath(['rendererModel'], false, noClone);
    }

    function getAllPageIds(siteData) {
        const displayedJsonDal = DALFactory.getInstance(siteData);
        const masterPageDataPath = ['pagesData', 'masterPage', 'data', 'document_data'];
        const masterPageDataKeys = displayedJsonDal.getKeysByPath(masterPageDataPath);

        return _.filter(masterPageDataKeys, function (potentialPageId) {
            // TODO: can this be replaced with a dal pointer instead of getByPath ?
            const itemType = displayedJsonDal.getByPath(masterPageDataPath.concat([potentialPageId, 'type']));
            return itemType === 'Page' || itemType === 'AppPage';
        });
    }

    function isPageLoaded(siteData, pageId) {
        return _.invoke(siteData.resolvedDataMaps, 'has', pageId);
    }


    return {
        incrementWixappsRenderCounter,
        setRootNavigationInfo,
        isMobileStructureExists,
        getBrowserFlag,
        getSvgString,
        getSvgInfo,
        setSvgShapeStore,
        getMediaStore,
        isEditingMode,
        isWixAdsAllowed,
        isHeaderFixedPosition,
        getMapFromPageUriSeoToPageId,
        getRequestModel,
        getRendererModel,
        setCustomUrlMapping,
        getAllPageIds,
        updateScreenSize,
        isPageLoaded
    };
});
