define(['lodash', 'warmupUtils/pointers/pointerGeneratorsRegistry'],
    function (_, pointerGeneratorsRegistry) {
        'use strict';

        const ASPECTS_DATA_TYPE = 'siteAspects';
        const ASPECTS_DATA_ROOT_PATH = ['siteAspectsData'];

        pointerGeneratorsRegistry.registerPointerType(ASPECTS_DATA_TYPE, _.constant(null), _.constant(true));

        const getterFunctions = {
            getAspectGlobalData(getItemAt, cache, aspectName) {
                return cache.getPointer(`${aspectName}_globalData`, ASPECTS_DATA_TYPE, ASPECTS_DATA_ROOT_PATH.concat([aspectName, 'globalData']));
            },
            getAspectAllComponentsData(getItemAt, cache, aspectName) {
                return cache.getPointer(`${aspectName}_allCompsData`, ASPECTS_DATA_TYPE, ASPECTS_DATA_ROOT_PATH.concat([aspectName, 'dataByCompId']));
            },
            getAspectComponentData(getItemAt, cache, aspectName, compId) {
                return cache.getPointer(`${aspectName}_compData_${compId}`, ASPECTS_DATA_TYPE, ASPECTS_DATA_ROOT_PATH.concat([aspectName, 'dataByCompId', compId]));
            }
        };

        pointerGeneratorsRegistry.registerDataAccessPointersGenerator(ASPECTS_DATA_TYPE, getterFunctions);
    });
