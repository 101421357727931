define(['santa-components'], function (santaComponents) {
    'use strict';

    return {
        propTypes: {
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired
        },
        getOrigComponentId() {
            return this.props.compData.origCompId;
        }
    };
});

