define(['lodash',
    'coreUtils',
    'tpaComponents/components/tpaWidgetNative/nativeCompRequireService',
    'tpaComponents/services/clientSpecMapService'],
function (_, coreUtils, nativeCompRequireService, clientSpecMapService) {
    'use strict';

    function reportError(siteData, error, widgetId, applicationId) {
        const errorDesc = {
            tags: {
                platformViewer: true,
                'tpa-native': true
            },
            extra: {
                applicationId,
                widgetId
            }
        };

        if (siteData.isInSSR()) {
            _.invoke(siteData, 'ssrContext.Raven.captureException', error, errorDesc);
        } else {
            coreUtils.integrations.ravenUtils.captureError(error, errorDesc);
        }
    }

    /**
         * Returns an array of request descriptors to be completed before the render phase starts.
         * The custom download requires the related react component and registers it in compFactory.
         * The requestDescriptor is executed in store.loadBatch() santa-core-utils/src/coreUtils/core/store2_new.js
         * The done function of requestDescriptor is assigned to the requestDescriptor in
         * santa-core-utils/src/coreUtils/core/store2_new.js
         */
    function requireAndRegisterComponent(siteData, compInfo) {
        const {nativeComponentRegistrar} = siteData;
        const {widgetId, applicationId} = compInfo.data;
        if (nativeComponentRegistrar.isRegistered(widgetId)) {
            return [];
        }
        const componentUrl = clientSpecMapService.getNativeComponentUrl(siteData, applicationId, widgetId);
        if (!componentUrl) {
            reportError(siteData, new Error(`component url for application ${applicationId} widgetId ${widgetId} is missing`), widgetId, applicationId);
            return [];
        }
        const requestDescriptor = {
            customDownload: () => {
                const done = requestDescriptor.done;
                nativeCompRequireService.requireComponentFromUrl(componentUrl)
                    .then(urlData => {
                        const component = _.get(urlData, 'default.component', false);
                        if (component) {
                            nativeComponentRegistrar.register({widgetId, component});
                        } else {
                            reportError(siteData, new Error(`component url for application ${applicationId} widgetId ${widgetId} is invalid`), widgetId, applicationId);
                            nativeComponentRegistrar.register({widgetId, error: true});
                        }
                        done();
                    }).catch(error => {
                        reportError(siteData, error, widgetId, applicationId);
                        nativeComponentRegistrar.register({widgetId, error: true});
                        done();
                    });
            }
        };
        return [requestDescriptor];
    }

    return {
        requireAndRegisterComponent
    };
});
