define([
    'lodash',
    'coreUtils'
], function (
    _,
    coreUtils
) {
    'use strict';

    function getTemplateIdsToClearPointersMap(structure) {
        return [structure.id].concat(_.flatMap(structure.components, getTemplateIdsToClearPointersMap));
    }

    return (repeaterCompStructure, getStructureInfo, pointersMapApi = {clear: _.noop, update: _.noop}) => {
        const structureInfo = getStructureInfo(repeaterCompStructure);

        const template = repeaterCompStructure.components[0];
        if (!template || !structureInfo.dataItem) {
            return repeaterCompStructure;
        }

        if (_.isEmpty(structureInfo.dataItem.items)) {
            return _.defaults({components: [], layout: _.defaults({height: template.layout.height}, repeaterCompStructure.layout)}, repeaterCompStructure);
        }

        const templateIdsToRemoveFromPointersMap = getTemplateIdsToClearPointersMap(template);
        _.forEach(templateIdsToRemoveFromPointersMap, pointersMapApi.clear);

        const newChildren = _.reduce(structureInfo.dataItem.items, (res, itemId) => res.concat(coreUtils.displayedOnlyStructureUtil.getUniqueStructure(template, itemId, pointersMapApi.update)), []);
        return _.defaults({components: newChildren}, repeaterCompStructure);
    };
});
