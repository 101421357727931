import _ from 'lodash'
import {urlUtils} from 'santa-core-utils'

export const mergeBatchableListsRequestsIfAny = (appPartDrcAPI, requests) => {
    if (requests.length <= 1) { // no need to batch
        return requests
    }
    const [batchableRequests, nonBatchableRequests] = _.partition(requests, requestIsBatchable)
    const batchedRequest = mergeBatchableRequests(appPartDrcAPI, batchableRequests)
    return [...nonBatchableRequests, batchedRequest]
}

function requestIsBatchable(request) {
    return destinationIsInWixapps(request.destination) && urlIsBatchableOperationUrl(request.url)
}

function destinationIsInWixapps(destination) {
    return _.startsWith(destination, getWixappsDestination())
}

function urlIsBatchableOperationUrl(url) {
    const pathname = getPathnameFromUrl(url)
    const pathnameRegExp = getRegExpForMatchingBatchableOperationNameInPathname()
    return pathnameRegExp.test(pathname)
}

function mergeBatchableRequests({getExternalBaseUrl}, batchableRequests) {
    return {
        destination: getWixappsDestination(),
        name: 'wixapps batch',
        url: `${urlUtils.baseUrl(getExternalBaseUrl())}/apps/lists/1/Batch?consistentRead=false`,
        data: {operations: getOperationsForBatchableRequests(batchableRequests)},
        transformFunc: _.partial(delegateTransformationToBatchableRequests, batchableRequests),
        error: _.partial(delegateErrorHandlerToBatchableRequests, batchableRequests, 'error'),
        type: 'Batch'
    }
}

function getOperationsForBatchableRequests(batchableRequests) {
    return _.map(batchableRequests, batchableRequest => ({
        name: getBatchableOperationNameFromUrl(batchableRequest.url),
        params: batchableRequest.data
    }))
}

function getBatchableOperationNameFromUrl(url) {
    const pathname = getPathnameFromUrl(url)
    const operationNameInPathnameRegExp = getRegExpForMatchingBatchableOperationNameInPathname()
    const matches = pathname.match(operationNameInPathnameRegExp)
    return _.get(matches, 1)
}

function getPathnameFromUrl(url) {
    return urlUtils.parseUrl(url).path
}

function getRegExpForMatchingBatchableOperationNameInPathname() {
    return /^\/apps\/lists\/1\/(GroupByAndCount|Query|ReadItem)$/
}

async function delegateTransformationToBatchableRequests(batchableRequests, batchResponseData, wixappsInSiteData) {
    const transformPromises = []
    _.forEach(batchableRequests, (batchableRequest, index) => {
        const responseData = batchResponseData.payload.results[index]
        const transformValue = _.partial(batchableRequest.transformFunc, responseData)
        const destinationInWixapps = _.difference(batchableRequest.destination, getWixappsDestination())
        transformPromises.push(transformObjectAtPath(wixappsInSiteData, destinationInWixapps, transformValue))
    })

    await Promise.all(transformPromises)

    return wixappsInSiteData
}

function delegateErrorHandlerToBatchableRequests(batchableRequests, handleName) {
    _.forEach(batchableRequests, batchableRequest => {
        _.invoke(batchableRequest, handleName)
    })
}

function getWixappsDestination() {
    return ['wixapps']
}

async function transformObjectAtPath(object, path, transformValue) {
    const currentValue = _.get(object, path)
    const transformedValue = await transformValue(currentValue)
    _.set(object, path, transformedValue)
}
