define(['lodash',
    'layout/util/dataPreparationsForAnchors'
], function
(_,
 dataPreparationsForAnchors) {
    'use strict';
    const ANCHOR_TOP_TOP = 'TOP_TOP';
    const ANCHOR_BOTTOM_TOP = 'BOTTOM_TOP';
    const ANCHOR_BOTTOM_BOTTOM = 'BOTTOM_BOTTOM';
    const ANCHOR_BOTTOM_PARENT = 'BOTTOM_PARENT';
    const DEFAULT_MARGIN = 10;

    const PAGE_TYPES = ['Page', 'Document'];

    const HARD_WIRED_COMPS = {
        MOBILE: {
            'mobile.core.components.MasterPage': true,
            'wysiwyg.viewer.components.HeaderContainer': true,
            'wysiwyg.viewer.components.SiteRegionContainer': true,
            'wysiwyg.viewer.components.PagesContainer': true,
            'wysiwyg.viewer.components.PageGroup': true,
            'wysiwyg.viewer.components.FooterContainer': true,
            'wysiwyg.viewer.components.SiteBackground': true
        },
        DESKTOP: {
            'mobile.core.components.MasterPage': true,
            'wysiwyg.viewer.components.HeaderContainer': true,
            'wysiwyg.viewer.components.PagesContainer': true,
            'wysiwyg.viewer.components.PageGroup': true,
            'wysiwyg.viewer.components.FooterContainer': true,
            'wysiwyg.viewer.components.SiteBackground': true
        }
    };

    function isHardWired(comp, isMobileView) {
        const hardWiredCompForViewMode = isMobileView ? HARD_WIRED_COMPS.MOBILE : HARD_WIRED_COMPS.DESKTOP;

        return hardWiredCompForViewMode[comp.componentType];
    }

    const anchorPushers = {};

    function getIsChangedAndMarkDirty(pushedId, currentValue, newValue, dataMap) {
        if (currentValue !== newValue) {
            dataMap.dirty[pushedId] = true;
            dataMap.changedCompsMap[pushedId] = dataMap.flat[pushedId];
        }

        return dataMap.dirty[pushedId];
    }

    /**
     *
     * @param newValue
     * @param pusherId
     * @param maxValuesMap
     * @param  anchor
     * @returns {number}
     */
    function getMaxValueByAnchors(newValue, pusherId, maxValuesMap, anchor) {
        const pushedMap = maxValuesMap[anchor.targetComponent];
        const pusherMap = maxValuesMap[pusherId];
        //in 2 way anchors, if the value is enforced by a other anchor then it should be enforced on
        if (anchor.notEnforcingMinValue && !_.isEmpty(pusherMap)) {
            anchor.notEnforcingMinValue = false;
        }
        pushedMap[pusherId] = newValue;
        const maxValue = Math.max.apply(null, _.values(pushedMap));
        if (anchor.notEnforcingMinValue) {
            delete pushedMap[pusherId];
        }
        return maxValue;
    }

    function updateValueForFirstLockedAnchor(anchor, dataMap, maxValuesMap, pusherId, newValue) {
        const pushedId = anchor.targetComponent;
        const valueForFirstLockedAnchor = dataMap.valueForFirstLockedAnchor[pushedId];
        if (!valueForFirstLockedAnchor) {
            return;
        }

        //we can delete the whole map because all the stored values come from non locked anchors
        if (anchor.locked || newValue === null) { // newValue is null in case of top top anchors which are always treated as locked,
            if (valueForFirstLockedAnchor.pusherId) {
                maxValuesMap[pushedId] = {};
                //we set here only one pusher because we don't need all the rest anymore, only the max between them
                maxValuesMap[pushedId][valueForFirstLockedAnchor.pusherId] = valueForFirstLockedAnchor.value;
            }
            delete dataMap.valueForFirstLockedAnchor[pushedId];
        } else {
            valueForFirstLockedAnchor.pusherId = pusherId;
            valueForFirstLockedAnchor.value = Math.max(newValue, valueForFirstLockedAnchor.value);
        }
    }

    function getDecimalPart(number) {
        return number % 1;
    }

    /*
     * Makes sure not to push or pull by half a pixel. We couldn't find a better name for this function.
     * If currentPushedTop is 100 and newPushedTop is 94.5, we want to pull the top to 95 (closer to current)
     * If currentPushedTop is 100 and newPushedTop is 105.5, we want to keep the top at 105 (closer to current)
     */
    function makeSureNotPushingWithHalf(currentPushedTop, newPushedTop) {
        const pushedTopDiff = newPushedTop - currentPushedTop;
        newPushedTop -= getDecimalPart(pushedTopDiff);

        return newPushedTop;
    }

    function isCompExistsInDataMap(dataMap, compId) {
        return _.has(dataMap.currentHeight, compId);
    }

    function shouldCollapseMargin(dataMap, anchor) {
        return _.get(dataMap.collapsed, anchor.fromComp, false) &&
            _.includes([ANCHOR_BOTTOM_TOP, ANCHOR_BOTTOM_PARENT], anchor.type);
    }

    function getLockedDistance(dataMap, anchor) {
        return shouldCollapseMargin(dataMap, anchor) ? 0 : anchor.distance;
    }

    anchorPushers[ANCHOR_TOP_TOP] = function (pusherId, anchor, dataMap) {
        const pushedId = anchor.targetComponent;
        if (!isCompExistsInDataMap(dataMap, pushedId) || !isCompExistsInDataMap(dataMap, pusherId)) {
            return false;
        }
        if (_.has(dataMap.locked, pushedId)) {
            return false;
        }
        const currentPushedTop = dataMap.currentY[pushedId];
        let newPushedTop = dataMap.currentY[pusherId] + getLockedDistance(dataMap, anchor);
        //TODO: this should move to the data fixer when it seems safe - top top are always locked
        updateValueForFirstLockedAnchor(anchor, dataMap, dataMap.toTopAnchorsY, pusherId, null);
        newPushedTop = getMaxValueByAnchors(newPushedTop, pusherId, dataMap.toTopAnchorsY, anchor);
        newPushedTop = makeSureNotPushingWithHalf(currentPushedTop, newPushedTop);
        dataMap.currentY[pushedId] = newPushedTop;

        return getIsChangedAndMarkDirty(pushedId, currentPushedTop, newPushedTop, dataMap);
    };

    anchorPushers[ANCHOR_BOTTOM_TOP] = function (pusherId, anchor, dataMap) {
        const pushedId = anchor.targetComponent;
        if (!isCompExistsInDataMap(dataMap, pushedId) || !isCompExistsInDataMap(dataMap, pusherId)) {
            return false;
        }
        if (_.has(dataMap.locked, pushedId)) {
            return false;
        }

        const currentPushedTop = dataMap.currentY[pushedId];
        let newPushedTop = dataMap.currentHeight[pusherId] + dataMap.currentY[pusherId];

        let pushedTopWhenHaveLockedAnchor = null;

        if (anchor.locked) {
            newPushedTop += getLockedDistance(dataMap, anchor);
        } else {
            pushedTopWhenHaveLockedAnchor = newPushedTop + DEFAULT_MARGIN;
            newPushedTop = dataMap.valueForFirstLockedAnchor[pushedId] ?
                Math.max(newPushedTop + DEFAULT_MARGIN, anchor.originalValue) :
                pushedTopWhenHaveLockedAnchor;
        }
        newPushedTop = Math.max(newPushedTop, dataMap.currentY[pusherId] + dataMap.currentHeight[pusherId] / 2); // eslint-disable-line no-mixed-operators
        //TODO: this should move to the data fixer when it seems safe
        updateValueForFirstLockedAnchor(anchor, dataMap, dataMap.toTopAnchorsY, pusherId, pushedTopWhenHaveLockedAnchor);
        newPushedTop = getMaxValueByAnchors(newPushedTop, pusherId, dataMap.toTopAnchorsY, anchor);
        newPushedTop = makeSureNotPushingWithHalf(currentPushedTop, newPushedTop);
        dataMap.currentY[pushedId] = newPushedTop;

        return getIsChangedAndMarkDirty(pushedId, currentPushedTop, newPushedTop, dataMap);
    };

    function addAnchorDistanceToHeight(anchor, heightByPusher, pushedId, dataMap) {
        let newPushedHeight;

        if (dataMap.shrinkableContainer[pushedId]) {
            newPushedHeight = heightByPusher;
        } else if (anchor.locked) {
            newPushedHeight = heightByPusher + getLockedDistance(dataMap, anchor);
        } else {
            newPushedHeight = heightByPusher + DEFAULT_MARGIN;

            if (!dataMap.ignoreOriginalValue[pushedId]) {
                newPushedHeight = Math.max(newPushedHeight, anchor.originalValue);
            }
        }

        return newPushedHeight;
    }

    function enforceMinHeight(newHeight, pushedId, anchor, dataMap) {
        let enforcedHeight = newHeight;
        if (_.isNumber(dataMap.minHeight[pushedId]) && newHeight < dataMap.minHeight[pushedId]) {
            enforcedHeight = dataMap.minHeight[pushedId];
            //relevant only for 2 way anchors, if comp A shrinked and trying to shrink comp B but can't because of the minHeight
            // comp A should grow back (this will happen by enforcing the back anchor). and this value should be enforced for comp A as well
            if (anchor.notEnforcingMinValue) {
                anchor.notEnforcingMinValue = false;
                dataMap.dirty[pushedId] = true;
                dataMap.changedCompsMap[pushedId] = dataMap.flat[pushedId];
            }
        }
        return enforcedHeight;
    }


    anchorPushers[ANCHOR_BOTTOM_PARENT] = function (pusherId, anchor, dataMap) {
        const pushedId = anchor.targetComponent;
        if (_.get(dataMap.collapsed, pushedId)) {
            return false;
        }

        if (!isCompExistsInDataMap(dataMap, pushedId) || !isCompExistsInDataMap(dataMap, pusherId)) {
            return false;
        }
        if (_.has(dataMap.locked, pushedId)) {
            return false;
        }

        if (dataMap.flat[pushedId].layout && dataMap.flat[pushedId].layout.rotationInDegrees) {
            return false;
        }
        const currentPushedHeight = dataMap.currentHeight[pushedId];
        const currentPusherBottom = dataMap.currentHeight[pusherId] + dataMap.currentY[pusherId];
        const margin = dataMap.containerHeightMargin[pushedId] || 0;

        let newPushedHeight = addAnchorDistanceToHeight(anchor, currentPusherBottom + margin, pushedId, dataMap);

        newPushedHeight = enforceMinHeight(newPushedHeight, pushedId, anchor, dataMap);
        newPushedHeight = getMaxValueByAnchors(newPushedHeight, pusherId, dataMap.toBottomAnchorsHeight, anchor);

        dataMap.currentHeight[pushedId] = newPushedHeight;

        return getIsChangedAndMarkDirty(pushedId, currentPushedHeight, newPushedHeight, dataMap);
    };

    anchorPushers[ANCHOR_BOTTOM_BOTTOM] = function (pusherId, anchor, dataMap) {
        if (dataMap.ignoreBottomBottom) {
            return false;
        }
        const pushedId = anchor.targetComponent;
        if (!isCompExistsInDataMap(dataMap, pushedId) || !isCompExistsInDataMap(dataMap, pusherId)) {
            return false;
        }
        if (_.has(dataMap.locked, pushedId)) {
            return false;
        }

        if (dataMap.flat[pushedId].layout && dataMap.flat[pushedId].layout.rotationInDegrees) {
            return false;
        }
        const currentPushedHeight = dataMap.currentHeight[pushedId];
        const currentPusherBottom = dataMap.currentHeight[pusherId] + dataMap.currentY[pusherId];
        const currentPushedTop = dataMap.currentY[pushedId];

        let newPushedHeight = addAnchorDistanceToHeight(anchor, currentPusherBottom - currentPushedTop, pushedId, dataMap);

        newPushedHeight = enforceMinHeight(newPushedHeight, pushedId, anchor, dataMap);
        newPushedHeight = getMaxValueByAnchors(newPushedHeight, pusherId, dataMap.toBottomAnchorsHeight, anchor);

        dataMap.currentHeight[pushedId] = newPushedHeight;

        return getIsChangedAndMarkDirty(pushedId, currentPushedHeight, newPushedHeight, dataMap);
    };

    function getCompAnchors(compId, rootAnchorsMap, dataMap) {
        if (compId === 'masterPage') {
            return [];
        }

        return dataMap.injectedAnchors[compId] || rootAnchorsMap[compId] || [];
    }

    function enforceAnchorsOfMarkedDirty(ySortedIds, compIndexes, dataMap, rootAnchorsMap, skipEnforceAnchors, structureId, isMobileView) {
        let hasAnchorsToStructure = false;

        function enforceAnchorOfPusher(pusherId) {
            const anchors = getCompAnchors(pusherId, rootAnchorsMap, dataMap);
            let lowestChanged = Number.MAX_VALUE;
            if (dataMap.dirty[pusherId] && (!skipEnforceAnchors || isHardWired(dataMap.flat[pusherId], isMobileView))) { // BOTTOM pushing
                _.forEach(anchors, function (anchor) {
                    if (anchorPushers[anchor.type] && dataMap.flat[anchor.targetComponent] && (!skipEnforceAnchors || isHardWired(dataMap.flat[anchor.targetComponent], isMobileView))) {
                        if (anchor.targetComponent === structureId) {
                            hasAnchorsToStructure = true;
                        }
                        const anchorChanged = anchorPushers[anchor.type](pusherId, anchor, dataMap) ? compIndexes[anchor.targetComponent] : Number.MAX_VALUE;
                        lowestChanged = Math.min(anchorChanged, lowestChanged);
                    }
                });
            }
            dataMap.dirty[pusherId] = false;
            return lowestChanged;
        }

        const numOfComps = ySortedIds.length;
        let index = 0;
        while (index < numOfComps) {
            const lowestChangedIndex = enforceAnchorOfPusher(ySortedIds[index]);
            index = Math.min(index + 1, lowestChangedIndex);
        }
        return hasAnchorsToStructure;
    }

    /**
     *  @typedef {layout.structureDataMap} layout.anchorsDataMap
     *  @property {Object.<string, boolean>} dirty
     *  @property {Object.<string, number>} toTopAnchorsY
     *  @property {Object.<string, number>} toBottomAnchorsHeight
     *
     */

    /**
     * this method changes the measureMap height and top according to anchors
     * @param {data.compStructure} structure
     * @param {layout.measureMap} measureMap
     * @param rootAnchorsMap
     * @param isMobileView
     * @param skipEnforceAnchors
     * @param lockedCompsMap
     * @param compsToEnforce
     * @param {layout.measureMap} ignoreBottomBottom - boolean, if true do not enforce anchors with type BOTTOM_BOTTOM
     * @returns {Object.<string, Object>} the structure flat map

     */
    function enforceAnchors(structure, measureMap, rootAnchorsMap = {}, isMobileView, skipEnforceAnchors, lockedCompsMap, compsToEnforce, ignoreBottomBottom) {
        const structureDataAndSort = dataPreparationsForAnchors.getDataForAnchorsAndSort(structure, measureMap, isMobileView);
        /** @type layout.anchorsDataMap */
        const dataMap = structureDataAndSort.structureData;
        const ySortedIds = structureDataAndSort.sortedIds;
        const compIndexes = _.invert(ySortedIds);

        dataMap.dirty = {};
        dataMap.toTopAnchorsY = {};
        dataMap.toBottomAnchorsHeight = {};
        dataMap.locked = lockedCompsMap;
        dataMap.changedCompsMap = dataMap.flat;
        dataMap.ignoreBottomBottom = !!ignoreBottomBottom;

        _.forEach(dataMap.flat, function (compStructure, id) {
            dataMap.dirty[id] = true;
            dataMap.toTopAnchorsY[id] = {};
            dataMap.toBottomAnchorsHeight[id] = {};
        });

        if (compsToEnforce) {
            dataMap.changedCompsMap = _.pick(dataMap.flat, _.keys(compsToEnforce));
        }

        const hasAnchorsToStructure = enforceAnchorsOfMarkedDirty(ySortedIds, compIndexes, dataMap, rootAnchorsMap, skipEnforceAnchors, structure.id, isMobileView);

        dataPreparationsForAnchors.fixMeasureMap(measureMap, dataMap);

        if (!hasAnchorsToStructure && _.includes(PAGE_TYPES, structure.type)) {
            const originalHeight = measureMap.height[structure.id];
            measureMap.height[structure.id] = dataPreparationsForAnchors.maxMeasureMapHeight(measureMap, 0, isMobileView, structure);
            if (measureMap.height[structure.id] !== originalHeight) {
                dataMap.changedCompsMap[structure.id] = dataMap.flat[structure.id];
            }
        }

        return dataMap.changedCompsMap;
    }


    return {
        enforceAnchors,
        HARD_WIRED_COMPS_TO_RELAYOUT: {
            'SITE_FOOTER': true,
            'SITE_HEADER': true,
            'SITE_PAGES': true,
            'PAGES_CONTAINER': true,
            'masterPage': true,
            'SITE_BACKGROUND': true
        }
    };
});
