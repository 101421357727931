'use strict'

const React = require('react')
const PropTypes = require('prop-types')
const _ = require('lodash')

const rendererHOC = Component => {
    class RendererHOC extends React.Component {
        constructor(props) {
            super(props)
            this.state = {}
        }

        shouldComponentUpdate(nextProps) {
            return nextProps.shouldComponentUpdate
        }

        componentDidMount() {
            this.props.onComponentUpdate()
        }

        componentDidUpdate() {
            this.props.onComponentUpdate()
        }

        render() {
            const compProps = _.omit(this.props, ['shouldComponentUpdate', 'onComponentUpdate'])
            const {forwardedRef, ...rest} = compProps
            return <Component ref={forwardedRef} {...rest} />
        }
    }

    RendererHOC.propTypes = {
        shouldComponentUpdate: PropTypes.bool.isRequired,
        onComponentUpdate: PropTypes.func.isRequired
    }

    function forwardRefFunc(props, ref) {
        return <RendererHOC {...props} forwardedRef={ref} />
    }

    const name = Component.displayName || Component.name
    forwardRefFunc.displayName = `RendererHOC(${name})`

    // eslint-disable-next-line react/display-name
    return React.forwardRef(forwardRefFunc)
}

module.exports = rendererHOC





