import {Argument, FunctionLibrary, Graph} from 'carmi'
import {AspectInterfaces, AspectImplementations} from './aspectInterfaces'
import {Validator} from 'prop-types'
import {Structure, DataMap, Component} from './structureTypes'
import {RuntimeBehavior} from './runtimeTypes'
import {ActionBehavior} from './dataTypes'

interface StructureAndData<F extends FunctionLibrary> {
    nonTranslatedData: Graph<DataMap, F>
    data: Graph<DataMap, F>
    structure: Graph<Structure, F>
}

interface BaseModel<F extends FunctionLibrary> extends StructureAndData<F> {
    originalStructure: Graph<Structure, F>
    full: Graph<StructureAndData<F>, F>
    runtimeActionBehaviorsForComponent: Graph<{[compId: string]: RuntimeBehavior[]}, F>
    combinedActionBehaviorsForComponent: Graph<{[compId: string]: ActionBehavior[]}, F>
    compClasses: Graph<{[componentType: string]: any}, F>
}

type FixPlainObject<T> =
    object extends T ? {[key: string]: any} : T

interface ExtensionAPI<F extends FunctionLibrary> {
    getAspectAPI: <N extends keyof AspectInterfaces<F>>(name: N) => AspectInterfaces<F>[N]
    fetchSantaType: <N>(type: Validator<N>, props?: {id: string}) => Graph<FixPlainObject<N>, F>
    model: BaseModel<F>
}

type ComponentOverrides<F extends FunctionLibrary> = {
    structure: Graph<Partial<Component>, F>
    props: {[prop: string]: any}
}

interface ExtensionImplementation<F extends FunctionLibrary, Name extends keyof AspectInterfaces<F>> {
    fetchers: {[id: string]: any}
    publicAPI: AspectImplementations<F>[Name]
    privates: {[id: string]: any}
    initMethodData: {[id: string]: any}
    compClasses: {[componentType: string]: any}
    compsInfoToRender: Argument<{[id: string]: Partial<ComponentOverrides<F>>}>
}

export function defineExtension<F extends FunctionLibrary, Name extends keyof AspectInterfaces<F>>(
    name: Name,
    getExtension: (e: ExtensionAPI<F>) => Partial<ExtensionImplementation<F, Name>>,
    functionLibrary?: F
) {
    return {
        name, getExtension
    }
}
