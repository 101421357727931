/* Autogenerated file. Do not modify */
'use strict'
const skins = {}
 skins['wysiwyg.viewer.skins.mediaContainer.DefaultMediaContainer'] = {
  "react": [
    [
      "div",
      "container",
      [],
      {},
      [
        "div",
        "background",
        [],
        {}
      ],
      [
        "div",
        "inlineContentParent",
        [],
        {},
        [
          "div",
          "inlineContent",
          [],
          {}
        ]
      ]
    ]
  ],
  "css": {
    "%:not([data-mobile-responsive]) %inlineContent,%:not([data-mobile-responsive]) %container": "position:absolute;top:0;right:0;bottom:0;left:0;"
  }
}
 skins['wysiwyg.viewer.skins.screenwidthcontainer.GradientBottomScreen'] = {
  "react": [
    [
      "div",
      "screenWidthBackground",
      [],
      {}
    ],
    [
      "div",
      "centeredContent",
      [],
      {},
      [
        "div",
        "bg",
        [],
        {}
      ],
      [
        "div",
        "inlineContent",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "tdr": "URL",
    "bgc": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY",
    "bgc": "color_11"
  },
  "css": {
    "%screenWidthBackground": "position:absolute;top:0;right:0;bottom:0;left:0;background-image:url([tdr]gradient_bottom_white.png);background-repeat:repeat-x;background-position:0 100%;background-color:[bgc];",
    "%[data-state~=\"fixedPosition\"]": "position:fixed !important;left:auto !important;z-index:50;",
    "%[data-state~=\"fixedPosition\"]%_footer": "top:auto;bottom:0;",
    "%bg": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%centeredContent": "position:absolute;top:0;right:0;bottom:0;left:0;"
  }
}
 skins['wysiwyg.viewer.skins.screenwidthcontainer.GradientTopScreen'] = {
  "react": [
    [
      "div",
      "screenWidthBackground",
      [],
      {}
    ],
    [
      "div",
      "centeredContent",
      [],
      {},
      [
        "div",
        "bg",
        [],
        {}
      ],
      [
        "div",
        "inlineContent",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "tdr": "URL",
    "bgc": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY",
    "bgc": "color_11"
  },
  "css": {
    "%screenWidthBackground": "position:absolute;top:0;right:0;bottom:0;left:0;background-image:url([tdr]gradient_top_white.png);background-repeat:repeat-x;background-color:[bgc];",
    "%[data-state~=\"fixedPosition\"]": "position:fixed !important;left:auto !important;z-index:50;",
    "%[data-state~=\"fixedPosition\"]%_footer": "top:auto;bottom:0;",
    "%bg": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%centeredContent": "position:absolute;top:0;right:0;bottom:0;left:0;"
  }
}
 skins['wysiwyg.viewer.skins.screenwidthcontainer.GrassScreen'] = {
  "react": [
    [
      "div",
      "screenWidthBackground",
      [
        "_grass"
      ],
      {}
    ],
    [
      "div",
      "centeredContent",
      [],
      {},
      [
        "div",
        "bg",
        [],
        {}
      ],
      [
        "div",
        "inlineContent",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "bgc": "BG_COLOR_ALPHA",
    "$BorderRadius": "BORDER_RADIUS",
    "$boxShadow": "BOX_SHADOW",
    "baseThemeDir": "URL"
  },
  "paramsDefaults": {
    "bgc": "color_11",
    "$BorderRadius": "10px",
    "$boxShadow": "0 1px 3px rgba(0, 0, 0, 0.8)",
    "baseThemeDir": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%screenWidthBackground": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%[data-state~=\"fixedPosition\"]": "position:fixed !important;left:auto !important;z-index:50;",
    "%[data-state~=\"fixedPosition\"]%_footer": "top:auto;bottom:0;",
    "%centeredContent": "position:absolute;left:0;height:100%;",
    "%bg": "position:absolute;top:10px;right:0;bottom:10px;left:0;background-color:[bgc];[$BorderRadius]  [$boxShadow]",
    "%inlineContent": "position:absolute;top:50px;right:0;bottom:50px;left:0;",
    "%_grass": "background-image:url([baseThemeDir]bg_grass.jpg);"
  }
}
 skins['wysiwyg.viewer.skins.screenwidthcontainer.PopupOverlayContainer'] = {
  "react": [
    [
      "div",
      "background",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "css": {
    "%inlineContent,%background": "position:absolute;top:0;right:0;bottom:0;left:0;"
  }
}
 skins['wysiwyg.viewer.skins.screenwidthcontainer.SimpleScreen'] = {
  "react": [
    [
      "div",
      "screenWidthBackground",
      [],
      {}
    ],
    [
      "div",
      "centeredContent",
      [],
      {},
      [
        "div",
        "bg",
        [],
        {}
      ],
      [
        "div",
        "inlineContent",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "bgc1": "BG_COLOR_ALPHA",
    "bgc2": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW"
  },
  "paramsDefaults": {
    "bgc1": "color_11",
    "bgc2": "color_11",
    "rd": "5px",
    "shd": "0 1px 3px rgba(0, 0, 0, 0.5)"
  },
  "css": {
    "%screenWidthBackground": "position:absolute;top:0;right:0;bottom:0;left:0;background-color:[bgc1];",
    "%[data-state~=\"fixedPosition\"]": "position:fixed !important;left:auto !important;z-index:50;",
    "%[data-state~=\"fixedPosition\"]%_footer": "top:auto;bottom:0;",
    "%centeredContent": "position:absolute;left:0;height:100%;",
    "%bg": "position:absolute;top:10px;right:0;bottom:10px;left:0;background-color:[bgc2];[rd]  [shd]",
    "%inlineContent": "position:absolute;top:20px;right:20px;bottom:20px;left:20px;"
  }
}
 skins['wysiwyg.viewer.skins.stripContainer.DefaultStripContainer'] = {
  "react": [
    [
      "div",
      "background",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "css": {
    "%:not([data-mobile-responsive]) > %inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%[data-mobile-responsive] > %inlineContent": "position:relative;",
    "%[data-responsive]": "display:-ms-grid;display:grid;justify-content:center;grid-template-columns:100%;grid-template-rows:1fr;-ms-grid-columns:100%;-ms-grid-rows:1fr;",
    "%[data-responsive] > %inlineContent": "display:flex;",
    "%[data-responsive] > *": "position:relative;grid-row-start:1;grid-column-start:1;grid-row-end:2;grid-column-end:2;-ms-grid-row-span:1;-ms-grid-column-span:1;margin:0 auto;"
  }
}

module.exports = skins