define(['lodash'], function (_) {
    'use strict';

    const blockOuterScroll = function (e) {
        const contentNode = e.currentTarget;
        const totalHeight = e.currentTarget.scrollHeight;
        const maxScroll = totalHeight - e.currentTarget.offsetHeight;
        let delta;
        if (_.isUndefined(e.deltaY)) {
            delta = 0;
        } else {
            delta = e.deltaY % 3 ? e.deltaY : e.deltaY * 10;
        }
        if (contentNode.scrollTop + delta <= 0) {
            contentNode.scrollTop = 0;
            e.preventDefault();
        } else if (contentNode.scrollTop + delta >= maxScroll) {
            contentNode.scrollTop = maxScroll;
            e.preventDefault();
        }
        e.stopPropagation();
    };

    return {
        blockOuterScroll
    };
});
