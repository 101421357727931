define(['lodash'], function (_) {
    'use strict';

    const COMPARE_STRUCTURAL_PARAM_ERROR = 'is structural parameter must be a boolean';
    const GLOBAL_ID_PARAM_ERROR = 'global santa type must define id';

    const applyFetch = (propType, fetchFunc, config) => {
        config = config || {};

        const newPropType = propType.bind(null);
        newPropType.isRequired = propType.isRequired.bind(null);

        newPropType.fetch = fetchFunc;
        newPropType.isRequired.fetch = fetchFunc;

        if (config.globalId) {
            newPropType.globalId = config.globalId;
            newPropType.isRequired.globalId = config.globalId;
        }

        newPropType.isCompareStructuralSantaType = config.isStructural || false;

        return newPropType;
    };

    const validateIsStructural = isStructural => {
        if (!_.isBoolean(isStructural) && !_.isUndefined(isStructural)) {
            throw new Error(COMPARE_STRUCTURAL_PARAM_ERROR);
        }
    };

    const validateGlobalId = globalId => {
        if (!globalId || !_.isString(globalId)) {
            throw new Error(GLOBAL_ID_PARAM_ERROR);
        }
    };

    const createComponentSantaType = (propType, fetchFunc, isStructural) => {
        validateIsStructural(isStructural);

        return applyFetch(propType, fetchFunc, {isStructural});
    };

    const createComponentSantaTypeFetcher = (fetchFunc, isCompareStructural) => {
        validateIsStructural(isCompareStructural);

        fetchFunc.isCompareStructuralSantaType = isCompareStructural; // eslint-disable-line santa/no-side-effects
        return fetchFunc;
    };

    const createGlobalSantaTypesFetcher = (fetchFunc, globalId) => {
        validateGlobalId(globalId);

        fetchFunc.globalId = globalId; // eslint-disable-line santa/no-side-effects
        return fetchFunc;
    };

    const createGlobalSantaType = (propType, fetchFunc, globalId) => {
        validateGlobalId(globalId);

        return applyFetch(propType, fetchFunc, {globalId});
    };

    const isComponentSantaType = santaType => !santaType.globalId;

    const isGlobalSantaType = santaType => !!santaType.globalId;

    const isCompareStructuralSantaType = santaTypeFetcher => santaTypeFetcher.isCompareStructuralSantaType || false;

    const getGlobalId = santaTypeFetcher => santaTypeFetcher.globalId || null;

    const fetch = (santaType, state, props) => santaType.fetch(state, props);

    return {
        createComponentSantaType,
        createGlobalSantaType,
        createComponentSantaTypeFetcher,
        createGlobalSantaTypesFetcher,
        isComponentSantaType,
        isGlobalSantaType,
        isCompareStructuralSantaType,
        getGlobalId,
        fetch
    };
});
