define([
    'lodash',
    'coreUtils/core/behaviorHandlersFactory'
], function (
    _,
    behaviorHandlersFactory
) {
    'use strict';

    const behaviorIdentifierFields = [
        'targetId',
        'type',
        'name',
        'part',
        'viewMode'
    ];

    function filterByViewMode(behaviors, viewMode) {
        return _.filter(behaviors, function (behavior) {
            const allViewModes = !behavior.viewMode;
            const currentViewMode = behavior.viewMode === viewMode;
            return allViewModes || currentViewMode;
        });
    }

    function handleBehaviors(siteAPI, behaviors, event, type) {
        event = event || {};
        const viewMode = siteAPI.getSiteData().getViewMode();
        behaviors = filterByViewMode(behaviors, viewMode);
        behaviorHandlersFactory.getHandler(type).handle(behaviors, siteAPI, event);
    }

    function behaviorUniqueIdentifier(behavior) {
        const handler = behaviorHandlersFactory.getHandler(behavior.type);
        if (handler.getUniqueIdentifier) {
            return handler.getUniqueIdentifier(behavior);
        }
        return _(behavior)
            .at(behaviorIdentifierFields)
            .map(function (field) {
                return _.isPlainObject(field) ? _.values(field) : field;
            })
            .join();
    }

    return {
        handleBehaviors,
        behaviorUniqueIdentifier
    };
});
