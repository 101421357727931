export const name = 'HtmlComponentAspect'
import {withActions} from 'carmi-host-extensions/src/aspects/withActions'
const _ = require('lodash')

export const defaultModel = {
    windows: {},
    incomingMessages: {},
    outgoingMessages: {}
}

export function init(aspectActions, {eventsManager, initialData}) {
    const compIdToWindow = initialData.windows
    eventsManager.on('windowMessage', event => {
        _.forEach(compIdToWindow, (compWindow, compId) => {
            if (event.source === compWindow) {
                aspectActions.addPendingMessageFromComp(_.uniqueId('htmlComp'), {compId, event})
            }
        })
    })
}

export const functionLibrary = {
    postMessageToHtmlComponent: withActions((aspectActions, compWindow, compId, msg) => {
        if (compWindow) {
            compWindow.postMessage(msg, '*')
        } else {
            aspectActions.addPendingMessageToComp(_.uniqueId('htmlComp'), {compId, msg})
        }
    }),
    flushIncomingMessageAndClear: withActions((aspectActions, compWindow, msg, msgId) => {
        if (compWindow) {
            compWindow.postMessage(msg, '*')
            aspectActions.addPendingMessageToComp(msgId, undefined)
        }
    }),
    flushOutgoingMessageAndClear: withActions((aspectActions, msgId, event, msgAction, handleActionRef) => {
        if (msgAction) {
            handleActionRef(msgAction, event)
            aspectActions.addPendingMessageFromComp(msgId, undefined)
        }
    })

}
