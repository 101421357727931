define(['lodash', 'warmupUtilsLib'], function (_, warmupUtilsLib) {
    'use strict';

    const IS_BROWSER = typeof window !== 'undefined';
    const IS_PREVIEW = IS_BROWSER && window.queryUtil && window.queryUtil.isParameterTrue('isEdited');
    const IS_QA_DEBUG = IS_BROWSER && window.queryUtil && window.queryUtil.isParameterTrue('isqa');
    const MAX_BI_CALLS_KEPT = 1000;

    if (IS_QA_DEBUG && IS_PREVIEW) {
        window._biCalls = [];
    }

    const defaultOptions = {
        biUrl: '//frog.wixpress.com',
        adapter: '',
        params: {}
    };

    function sendBI(siteData, host, adapter, queryString) {
        let url = warmupUtilsLib.urlUtils.joinURL(host, adapter);
        if (queryString) {
            url += `?${queryString}`;
        }
        if (siteData.wixBiSession.sendBeacon) {
            siteData.wixBiSession.sendBeacon(url);
        }
        if (IS_QA_DEBUG && IS_PREVIEW) {
            logBICallToWindow(url);
        }
    }

    function logBICallToWindow(url) {
        if (IS_BROWSER) {
            const biCalls = window._biCalls;
            if (biCalls) {
                const length = biCalls.push(warmupUtilsLib.urlUtils.parseUrl(url));
                if (length > MAX_BI_CALLS_KEPT) {
                    biCalls.splice(0, length >> 2); // Delete a fourth
                }
            }
        }
    }

    function getCurrentTimeStamp(siteData, options) {
        // TODO: get mainLoaded value through DS and not through parent
        let start = IS_PREVIEW && options.adapter === 'editor' ? window.parent.mainLoaded : 0;
        start = start || siteData.wixBiSession.initialTimestamp || siteData.wixBiSession.mainLoaded;
        return _.now() - start;
    }

    function report(siteData, options) {
        const biUrl = _.get(siteData, ['serviceTopology', 'biServerUrl']);
        if (biUrl) {
            defaultOptions.biUrl = biUrl;
        }
        _.defaults(options, defaultOptions);
        const queryString = _.isString(options.queryString) ?
            options.queryString :
            warmupUtilsLib.urlUtils.toQueryString(_.defaults(options.params, {
                ts: getCurrentTimeStamp(siteData, options),
                rid: siteData.wixBiSession.requestId
            }));
        sendBI(siteData, options.biUrl, options.adapter, queryString);
    }

    return {
        report
    };
});
