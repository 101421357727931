const _ = require('lodash')
const {parseRoutePageDataResponse, errorPagesIds} = require('./parseRoutePageDataResponse')

const ERROR_ROUTE_DATA = {
    pageId: errorPagesIds.INTERNAL_ERROR
}

const handleDynamicPageResponse = (setNavigationInfos, currentNavInfo, startNavigationAgain, pageList, reportPageNavigationInteractionStarted, routePageDataResponse) => {
    const {pageId, tpaInnerRoute, pageData: routerData, pageHeadData, publicData} = routePageDataResponse
    const pageData = _.find(pageList, {pageId})
    const isProtectedPage = pageData && !_.get(pageData, 'pageJsonFileName')
    const nextNavigationInfo = {
        ...currentNavInfo,
        pageId,
        title: _.get(pageHeadData, 'title', ''),
        tpaInnerRoute,
        routerData,
        pageHeadData,
        routerPublicData: publicData
    }
    if (isProtectedPage && _.isFunction(startNavigationAgain)) {
        startNavigationAgain(nextNavigationInfo)
        return
    }

    reportPageNavigationInteractionStarted()
    setNavigationInfos({
        primaryPage: nextNavigationInfo
    })
}

const handleRelativeUrlRedirect = (relativeUrl, externalBaseUrl, warmupUtils, resolvedSiteData, setNavigationInfos, currentNavInfo, relativeRedirectCounter) => {
    // router returned an internal redirect path
    const newUrl = warmupUtils.urlUtils.joinURL(externalBaseUrl, relativeUrl)
    const pageInfo = warmupUtils.wixUrlParser.parseUrl(resolvedSiteData, newUrl)
    const primaryPage = pageInfo.routerDefinition ?
        {...currentNavInfo, ...pageInfo, relativeRedirectCounter: relativeRedirectCounter + 1, replaceHistory: true} :
        pageInfo

    setNavigationInfos({primaryPage})
}

const handleDynamicRedirectResponse = (setNavigationInfos, currentNavInfo, routeResponse, warmupUtils, externalBaseUrl, resolvedSiteData) => {
    const {redirectUrl} = routeResponse
    const relativeRedirectCounter = currentNavInfo.relativeRedirectCounter || 0
    if (warmupUtils.urlUtils.isExternalUrl(redirectUrl)) {
        window.location.assign(redirectUrl)
    } else if (warmupUtils.urlUtils.isRelativeUrl(redirectUrl) && relativeRedirectCounter < 4) {
        handleRelativeUrlRedirect(redirectUrl, externalBaseUrl, warmupUtils, resolvedSiteData, setNavigationInfos, currentNavInfo, relativeRedirectCounter)
    } else {
        // unexpected redirectUrl, display error page
        // callback(INTERNAL_ERROR_PAGE_INFO)
    }
}

const getAbsoluteUrl = (url, warmupUtils, externalBaseUrl, queryParams) => {
    if (!warmupUtils.urlUtils.isRelativeUrl(url)) {
        return url
    }

    const absoluteUrl = warmupUtils.urlUtils.joinURL(externalBaseUrl, url)

    return warmupUtils.urlUtils.setUrlParams(absoluteUrl, queryParams)
}
const handleRoutePageDataResponse = (responseHandlerParams, routeResponse) => {
    const {
        setNavigationInfos,
        reportPageNavigationInteractionStarted,
        currentNavInfo,
        isInSSR,
        warmupUtils,
        externalBaseUrl,
        resolvedSiteData,
        handleSsrRedirect,
        queryParams,
        startNavigationAgain,
        pageList
    } = responseHandlerParams
    const routePageDataResponse = parseRoutePageDataResponse(routeResponse)

    const {redirectUrl, status, message} = routePageDataResponse
    if (redirectUrl) {
        if (isInSSR) {
            const absoluteUrl = getAbsoluteUrl(redirectUrl, warmupUtils, externalBaseUrl, queryParams)
            handleSsrRedirect({redirectUrl: absoluteUrl, status, message})
            return
        }

        handleDynamicRedirectResponse(setNavigationInfos, currentNavInfo, routePageDataResponse, warmupUtils, externalBaseUrl, resolvedSiteData)
    } else {
        handleDynamicPageResponse(setNavigationInfos, currentNavInfo, startNavigationAgain, pageList, reportPageNavigationInteractionStarted, routePageDataResponse)
    }
}

const handleRouteFetchError = ({setNavigationInfos, currentNavInfo, pageList, reportPageNavigationInteractionStarted}) => {
    handleDynamicPageResponse(setNavigationInfos, currentNavInfo, null, pageList, reportPageNavigationInteractionStarted, ERROR_ROUTE_DATA)
}

const cleanPrefix = (url, prefix) => {
    const reg = new RegExp(`.*?\/${prefix}`)
    const urlWithoutPrefix = url.replace(reg, '')
    if (urlWithoutPrefix.charAt(0) === '/' && urlWithoutPrefix.length > 1) {
        return urlWithoutPrefix.substring(1)
    }

    return urlWithoutPrefix
}

const cleanPrefixesFromSiteMap = (siteMapEntries, prefix) =>
    _.map(siteMapEntries, entry => {
        if (!entry || !entry.url) {
            return entry
        }

        return {
            ...entry,
            url: cleanPrefix(entry.url, prefix)
        }
    })

const onSiteMapResponse = (resolve, reject, routerPrefix, routerResponse) => {
    if (routerResponse.exception) {
        reject(routerResponse.result)
        return
    }

    resolve({
        ...routerResponse,
        result: cleanPrefixesFromSiteMap(routerResponse.result, routerPrefix)
    })
}

const onErrorPageDownloaded = (setRawPage, {masterPageData: pageDataItem, themeData}, pageJson) => {
    const pageJsonToSet = _.defaultsDeep({
        data: {
            document_data: {
                [pageJson.structure.id]: pageDataItem
            },
            theme_data: themeData
        }
    }, pageJson)

    setRawPage(pageJsonToSet)
}

module.exports = {
    onSiteMapResponse,
    onErrorPageDownloaded,
    handleRouteFetchError,
    handleRoutePageDataResponse
}
