/**
 * Created by eitanr on 6/25/14.
 */
define(['layout/specificComponents/svgShape/svgBasicScaler'], function (basic) {
    'use strict';
    return {
        scale(ellipseElement, scaleX, scaleY) {
            const cxAttribute = ellipseElement.getAttribute('cx'),
                cyAttribute = ellipseElement.getAttribute('cy'),
                rxAttribute = ellipseElement.getAttribute('rx'),
                ryAttribute = ellipseElement.getAttribute('ry');
            const ellipseScaled = {};
            if (cxAttribute && rxAttribute) {
                ellipseScaled.cx = basic.scaleSingleValue(cxAttribute, scaleX);
                ellipseScaled.rx = basic.scaleSingleValue(rxAttribute, scaleX);
            }

            if (cyAttribute && ryAttribute) {
                ellipseScaled.cy = basic.scaleSingleValue(cyAttribute, scaleY);
                ellipseScaled.ry = basic.scaleSingleValue(ryAttribute, scaleY);
            }

            return ellipseScaled;
        }
    };
});
