define(['layout/util/layout'], function (layout) {
    'use strict';

    function patchQuickActionBarItem(id, patchers) {
        patchers.css(id, {
            top: '',
            left: '',
            width: '',
            height: '',
            position: '',
            'z-index': ''
        });
    }

    layout.registerPatcher('wysiwyg.viewer.components.QuickActionBarItem', patchQuickActionBarItem);
});
