define([
    'lodash',
    'layout/util/layout',
    'layout/util/elementQueries'
], function (_, /** layout.layout */ layout, elementQueries) {
    'use strict';

    const controlsElementQueries = {
        width: [
            {min: 0, max: 350, name: 'minimal'},
            {min: 0, max: 450, name: 'small'},
            {min: 450, max: 1200, name: 'normal'},
            {min: 1200, max: Infinity, name: 'large'}
        ]
    };

    function patchMediaControls(id, patchers, measureMap) {
        patchers.attr(id, elementQueries(id, measureMap, controlsElementQueries));
    }

    layout.registerPatcher('wysiwyg.viewer.components.MediaControls', patchMediaControls);
});
