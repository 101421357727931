define([
    'prop-types',
    'lodash',
    'componentsCore',
    'coreUtils',
    'image-client-api',
    'santa-components'
], function (PropTypes, _, componentsCore, coreUtils, imageClientLib, santaComponents) {
    'use strict';

    const linkRenderer = coreUtils.linkRenderer;

    const translatedLanguageKeys = { // TODO: add i18n bundle and move to another model if it's the same for both models
        goToLinkText: 'Go to link'
    };

    function getZoomChild(compData, zoomDimensions, isMobileOrTablet, quality) {
        const imageData = _.assign({}, compData, {
            alt: compData.title,
            itemProp: 'contentUrl'
        });

        const imageProps = {
            id: `${this.props.id}image`,
            ref: 'image',
            key: compData.id,
            imageData,
            quality,
            containerWidth: zoomDimensions.imageContainerWidth,
            containerHeight: zoomDimensions.imageContainerHeight,
            displayMode: imageClientLib.fittingTypes.LEGACY_FULL,
            onClick: this.props.goToNextItem,
            filterEffect: this.props.compProp.filterEffect,
            labelledById: `${this.props.id}title`,
            describedById: `${this.props.id}description`
        };


        if (isMobileOrTablet) {
            imageProps.onClick = this.props.toggleButtons;
            imageProps.onTap = this.props.toggleButtons;
        }

        return this.createChildComponent(
            imageData,
            'core.components.Image',
            'image',
            imageProps
        );
    }

    /**
     * @class components.ImageZoomDisplayer
     * @extends {core.skinBasedComp}
     */
    const imageZoomDisplayer = {
        displayName: 'ImageZoomDisplayer',
        mixins: [componentsCore.mixins.skinBasedComp, componentsCore.mixins.createChildComponentMixin],

        propTypes: _.assign({
            id: santaComponents.santaTypesDefinitions.Component.id,
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp,
            isMobileDevice: santaComponents.santaTypesDefinitions.Device.isMobileDevice,
            isTabletDevice: santaComponents.santaTypesDefinitions.Device.isTabletDevice,
            rootNavigationInfo: santaComponents.santaTypesDefinitions.Component.rootNavigationInfo.isRequired,
            linkRenderInfo: santaComponents.santaTypesDefinitions.Link.renderInfo.isRequired,
            goToNextItem: PropTypes.func,
            toggleButtons: PropTypes.func,
            zoomDimensions: PropTypes.object,
            quality: PropTypes.object
        }, santaComponents.utils.santaTypesUtils.getSantaTypesFromPropTypes(santaComponents.components.Image.propTypes)),

        getInitialState() {
            let device = 'desktop';
            if (this.props.isMobileDevice) {
                device = 'mobile';
            } else if (this.props.isTabletDevice()) {
                device = 'tablet';
            }
            return {$device: device};
        },

        getSkinProperties() {
            const compData = this.props.compData;
            const compProps = this.props.compProp;
            const zoomDimensions = this.props.zoomDimensions;
            const imgQuality = this.props.quality;
            const refs = {
                '': {
                    itemScope: true,
                    itemType: 'http://schema.org/ImageObject'
                },
                title: {
                    children: compData.title,
                    itemProp: 'name',
                    'aria-hidden': 'true'
                },
                description: {
                    children: compData.description,
                    itemProp: 'description'
                },
                image: getZoomChild.call(this, compData, zoomDimensions, this.props.isMobileDevice || this.props.isTabletDevice(), imgQuality)
            };

            if (compData.link) {
                refs.link = linkRenderer.renderLink(compData.link, this.props.linkRenderInfo, this.props.rootNavigationInfo);
                refs.link.children = compProps && compProps.goToLinkText ? compProps.goToLinkText : translatedLanguageKeys.goToLinkText;
            }

            return refs;
        }
    };

    return imageZoomDisplayer;
});
