/* Autogenerated file. Do not modify */
define([], function() {
    'use strict';
    var skins = {};
     skins['skins.viewer.displayers.SlideIronDisplayer'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "imageWrapper",
        [],
        {},
        [
          "div",
          null,
          [
            "_imgBorder"
          ],
          {},
          [
            "div",
            "zoom",
            [],
            {},
            [
              "div",
              "image",
              [],
              {}
            ]
          ],
          [
            "div",
            "panel",
            [
              "_pnl"
            ],
            {},
            [
              "h3",
              "title",
              [],
              {}
            ],
            [
              "p",
              "description",
              [],
              {}
            ]
          ]
        ],
        [
          "div",
          null,
          [
            "_screw-tl",
            "_screw"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_screw-tr",
            "_screw"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_screw-bl",
            "_screw"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_screw-br",
            "_screw"
          ],
          {}
        ]
      ]
    ]
  ],
  "exports": {
    "imageWrapperBottom": 12,
    "imageWrapperTop": 12,
    "imageWrapperRight": 12,
    "imageWrapperLeft": 12,
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "rd": "BORDER_RADIUS",
    "ishd": "BOX_SHADOW",
    "tdr": "URL",
    "imageWrapperTop": "TOP_SIZE",
    "imageWrapperRight": "RIGHT_SIZE",
    "imageWrapperBottom": "BOTTOM_SIZE",
    "imageWrapperLeft": "LEFT_SIZE",
    "bg": "BG_COLOR_ALPHA",
    "fntt": "FONT",
    "txt": "TEXT_COLOR",
    "fntds": "FONT"
  },
  "paramsDefaults": {
    "rd": "2px",
    "ishd": "rgba(255, 255, 255, 0.4) 0px 0px 6px 0px inset, rgba(255, 255, 255, 0.701961) 0px 1px 0px 0px inset, rgba(255, 255, 255, 0.2) 0px 0px 5px 0px inset",
    "tdr": "BASE_THEME_DIRECTORY",
    "imageWrapperTop": "12px",
    "imageWrapperRight": "12px",
    "imageWrapperBottom": "140px",
    "imageWrapperLeft": "12px",
    "bg": "color_15",
    "fntt": "font_6",
    "txt": "color_11",
    "fntds": "font_8",
    "s": [
      "brw"
    ]
  },
  "css": {
    "%title,%description": "overflow:hidden;text-overflow:ellipsis;white-space:pre-line;",
    "%imageWrapper": "position:absolute;",
    "%_imgBorder": "[rd]  [ishd]  background-image:url([tdr]ironpatern.png);border-bottom:0 solid transparent;height:100%;width:100%;",
    "%zoom": "position:absolute;top:[imageWrapperTop] !important;right:[imageWrapperRight] !important;bottom:[imageWrapperBottom] !important;left:[imageWrapperLeft] !important;",
    "%panel": "background-color:[bg];border-bottom:1px solid rgba(255, 255, 255, 0.1);border-radius:5px;border-top:1px solid #191919;bottom:12px;height:92px;left:12px;min-height:18px;overflow:hidden;padding:11px 15px 10px;position:absolute;right:12px;",
    "%_screw": "display:inline-block;background:url([tdr]skrew.png) no-repeat;width:15px;height:15px;",
    "%_screw-tl,%_screw-tr,%_screw-bl,%_screw-br": "position:absolute;",
    "%_screw-tl": "top:5px;left:5px;",
    "%_screw-tr": "top:5px;right:5px;",
    "%_screw-bl": "bottom:135px;left:5px;",
    "%_screw-br": "bottom:135px;right:5px;",
    "%title": "[fntt]  color:[txt];",
    "%description": "[fntds]  color:[txt];max-height:50px;",
    "%link": "[fntds]  color:[txt];margin-top:5px;",
    "%[data-state~=\"link\"] %link": "text-decoration:underline;cursor:pointer;[fntds]  color:[txt];"
  }
}
 skins['skins.viewer.displayers.SlideScotchTapeDisplayer'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "imageWrapper",
        [],
        {},
        [
          "div",
          "zoom",
          [],
          {},
          [
            "div",
            "image",
            [],
            {}
          ]
        ],
        [
          "div",
          "panel",
          [
            "_pnl"
          ],
          {},
          [
            "h3",
            "title",
            [],
            {}
          ],
          [
            "p",
            "description",
            [],
            {}
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "brw": "MARGIN_SIZE",
    "bg": "BG_COLOR_ALPHA",
    "trns": "TRANSITION",
    "fntt": "FONT",
    "txt": "TEXT_COLOR",
    "fntds": "FONT",
    "s": "SIZE",
    "imageWrapperBottom": "MARGIN_SIZE",
    "imageWrapperTop": "MARGIN_SIZE",
    "imageWrapperRight": "MARGIN_SIZE",
    "imageWrapperLeft": "MARGIN_SIZE"
  },
  "paramsDefaults": {
    "brw": "0",
    "bg": "color_15",
    "trns": "opacity 0.5s ease 0s",
    "fntt": "font_6",
    "txt": "color_11",
    "fntds": "font_8",
    "s": [
      "brw"
    ],
    "imageWrapperBottom": [
      "brw"
    ],
    "imageWrapperTop": [
      "brw"
    ],
    "imageWrapperRight": [
      "brw"
    ],
    "imageWrapperLeft": [
      "brw"
    ]
  },
  "paramsMutators": {
    "s": {
      "type": "decrease",
      "value": 1,
      "param": "brw"
    }
  },
  "css": {
    "%title,%description": "overflow:hidden;text-overflow:ellipsis;white-space:pre-line;",
    "%imageWrapper": "position:relative;",
    "%zoom": "position:absolute;top:[brw];right:[brw];bottom:[brw];left:[brw];",
    "%panel": "background-color:[bg];[trns]  opacity:0;position:absolute;right:[brw];left:[brw];bottom:[s];padding:10px 100px 10px 10px;min-height:18px;",
    "%title": "[fntt]  color:[txt];",
    "%description": "[fntds]  color:[txt];",
    "%link": "[fntds]  color:[txt];",
    "%[data-state~=\"link\"] %link": "text-decoration:underline;cursor:pointer;[fntds]  color:[txt];",
    "%[data-state~=\"showPanel\"] %panel": "opacity:1;",
    "%[data-state~=\"notShowPanel\"] %panel": "opacity:0;",
    "%[data-state~=\"transOut\"] %panel": "opacity:0;",
    "%[data-state~=\"transIn\"] %panel": "opacity:0;"
  }
}
 skins['skins.viewer.gallerymatrix.MatrixDisplayerIronSkin'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "imageWrapper",
        [],
        {},
        [
          "div",
          null,
          [
            "_boxShadow"
          ],
          {}
        ],
        [
          "div",
          "image",
          [],
          {}
        ],
        [
          "div",
          "zoom",
          [],
          {},
          [
            "div",
            "title",
            [],
            {}
          ],
          [
            "div",
            "description",
            [],
            {}
          ]
        ],
        [
          "div",
          null,
          [
            "_screw-tl",
            "_screw"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_screw-tr",
            "_screw"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_screw-bl",
            "_screw"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_screw-br",
            "_screw"
          ],
          {}
        ]
      ]
    ]
  ],
  "exports": {
    "imageWrapperBottom": -12,
    "imageWrapperTop": -12,
    "imageWrapperRight": -12,
    "imageWrapperLeft": -12,
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "shd": "BOX_SHADOW",
    "rd": "BORDER_RADIUS",
    "brw": "BORDER_SIZE",
    "tdr": "URL",
    "ishd": "BOX_SHADOW",
    "bgh": "BG_COLOR_ALPHA",
    "fntt": "FONT",
    "ttl": "TEXT_COLOR",
    "txt": "TEXT_COLOR",
    "fntds": "FONT",
    "contentPaddingLeft": "BORDER_SIZE",
    "contentPaddingRight": "BORDER_SIZE",
    "contentPaddingBottom": "BORDER_SIZE",
    "contentPaddingTop": "BORDER_SIZE"
  },
  "paramsDefaults": {
    "shd": "0 2px 5px rgba(0, 0, 0, 0.53)",
    "rd": "2px",
    "brw": "12px",
    "tdr": "BASE_THEME_DIRECTORY",
    "ishd": "rgba(255, 255, 255, 0.59) 0px 0px 6px 0px inset, rgba(255, 255, 255, 0.92) 0px 1px 0px 0px inset, rgba(255, 255, 255, 0.2) 0px 0px 5px 0px inset",
    "bgh": "color_15",
    "fntt": "font_6",
    "ttl": "color_18",
    "txt": "color_18",
    "fntds": "font_8",
    "contentPaddingLeft": [
      "brw"
    ],
    "contentPaddingRight": [
      "brw"
    ],
    "contentPaddingBottom": [
      "brw"
    ],
    "contentPaddingTop": [
      "brw"
    ]
  },
  "css": {
    "%imageWrapper": "position:absolute;top:0;right:0;bottom:0;left:0;[shd]  [rd]  border:[brw] solid transparent;background-image:url([tdr]ironpatern.png);box-sizing:border-box;",
    "%_screw": "display:inline-block;background:url([tdr]skrew.png) no-repeat;width:15px;height:15px;",
    "%_screw-bl,%_screw-br,%_screw-tl,%_screw-tr": "position:absolute;",
    "%_screw-tl": "top:-7px;left:-7px;",
    "%_screw-tr": "top:-7px;right:-7px;",
    "%_screw-bl": "bottom:-7px;left:-7px;",
    "%_screw-br": "bottom:-7px;right:-7px;",
    "%_boxShadow": "[ishd]  [rd]  position:absolute;top:-12px;right:-12px;bottom:-12px;left:-12px;",
    "%image": "[rd]",
    "%zoom": "position:absolute;top:0;right:0;bottom:0;left:0;background:[bgh];[rd]  padding:0 10%;opacity:0;overflow:hidden;",
    "%title": "[fntt]  color:[ttl];white-space:nowrap;padding-top:10%;",
    "%description": "color:[txt];[fntds]  white-space:normal;",
    "%link": "color:[txt];[fntds]  position:absolute;bottom:10px;text-decoration:underline !important;white-space:nowrap;",
    "%title,%description": "overflow:hidden;text-overflow:ellipsis;",
    "%:hover %zoom": "opacity:1;"
  }
}
 skins['skins.viewer.gallerymatrix.MatrixDisplayerScotchTapeSkin'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "imageWrapper",
        [],
        {},
        [
          "div",
          null,
          [
            "_imgBorder"
          ],
          {},
          [
            "div",
            "image",
            [],
            {}
          ],
          [
            "div",
            "zoom",
            [],
            {},
            [
              "div",
              "title",
              [],
              {}
            ],
            [
              "div",
              "description",
              [],
              {}
            ]
          ]
        ],
        [
          "div",
          null,
          [
            "_Scotch"
          ],
          {}
        ]
      ]
    ]
  ],
  "exports": {
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "shd": "BOX_SHADOW",
    "rd": "BORDER_RADIUS",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "tdr": "URL",
    "bgh": "BG_COLOR_ALPHA",
    "trans": "TRANSITION",
    "fntt": "FONT",
    "ttl": "TEXT_COLOR",
    "txt": "TEXT_COLOR",
    "fntds": "FONT",
    "imageWrapperBottom": "BORDER_SIZE",
    "imageWrapperTop": "BORDER_SIZE",
    "imageWrapperRight": "BORDER_SIZE",
    "imageWrapperLeft": "BORDER_SIZE"
  },
  "paramsDefaults": {
    "shd": "0 1px 3px rgba(0, 0, 0, 0.15)",
    "rd": "0",
    "brw": "8px",
    "brd": "color_15",
    "tdr": "BASE_THEME_DIRECTORY",
    "bgh": "color_17",
    "trans": "opacity 0.4s ease 0s",
    "fntt": "font_6",
    "ttl": "color_15",
    "txt": "color_15",
    "fntds": "font_8",
    "imageWrapperBottom": [
      "brw"
    ],
    "imageWrapperTop": [
      "brw"
    ],
    "imageWrapperRight": [
      "brw"
    ],
    "imageWrapperLeft": [
      "brw"
    ]
  },
  "css": {
    "%imageWrapper": "position:absolute;top:0;right:0;bottom:0;left:0;[shd]  [rd]",
    "%_imgBorder": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  border:solid [brw] [brd];background:[brd];",
    "%_Scotch": "position:absolute;display:inline-block;background:url([tdr]scotcht.png) no-repeat;width:75px;height:38px;top:-20px;left:50%;margin-left:-37.5px;",
    "%image": "[rd]",
    "%zoom": "position:absolute;top:0;right:0;bottom:0;left:0;background:[bgh];[rd]  padding:15px;opacity:0;[trans]  overflow:hidden;",
    "%title": "[fntt]  color:[ttl];white-space:nowrap;",
    "%description": "color:[txt];[fntds]",
    "%link": "color:[txt];[fntds]  position:absolute;bottom:10px;text-decoration:underline !important;white-space:nowrap;",
    "%title,%description": "overflow:hidden;text-overflow:ellipsis;",
    "%:hover %zoom": "opacity:1;[trans]"
  }
}
 skins['wysiwyg.common.components.matrixgallery.viewer.skins.MatrixDisplayerSeparateTextBoxSkin'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "imageWrapper",
        [],
        {},
        [
          "div",
          null,
          [
            "_imgBorder"
          ],
          {},
          [
            "div",
            "image",
            [],
            {}
          ]
        ],
        [
          "div",
          "zoom",
          [],
          {}
        ]
      ]
    ],
    [
      "div",
      "panel",
      [
        "_panel"
      ],
      {},
      [
        "div",
        null,
        [
          "_panelWrap"
        ],
        {},
        [
          "h6",
          "title",
          [],
          {}
        ],
        [
          "span",
          "description",
          [],
          {}
        ]
      ]
    ]
  ],
  "exports": {
    "heightDiff": 40,
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "rd": "BORDER_RADIUS",
    "brd": "BORDER_COLOR_ALPHA",
    "brw": "SIZE",
    "bghClr": "BG_COLOR_ALPHA",
    "trans": "TRANSITION",
    "fntt": "FONT",
    "ttl2": "TEXT_COLOR",
    "txt2": "TEXT_COLOR",
    "fntds": "FONT",
    "lnkClr": "TEXT_COLOR",
    "boxRd": "BORDER_RADIUS",
    "imgHeightDiff": "HEIGHT_SIZE",
    "boxbg": "BG_COLOR_ALPHA",
    "paddingSize": "MARGIN_SIZE",
    "topPadding": "SIZE",
    "imageWrapperBottom": "SIZE",
    "imageWrapperTop": "SIZE",
    "imageWrapperRight": "SIZE",
    "imageWrapperLeft": "SIZE"
  },
  "editableParams": [
    "topPadding"
  ],
  "paramsDefaults": {
    "rd": "0",
    "brd": "color_15",
    "brw": "0",
    "bghClr": "color_15",
    "trans": "opacity 0.4s ease 0s",
    "fntt": "font_7",
    "ttl2": "color_15",
    "txt2": "color_15",
    "fntds": "font_9",
    "lnkClr": "color_15",
    "boxRd": "0",
    "imgHeightDiff": "80px",
    "boxbg": "color_12",
    "paddingSize": "10px",
    "topPadding": "13px",
    "imageWrapperBottom": [
      "brw"
    ],
    "imageWrapperTop": [
      "brw"
    ],
    "imageWrapperRight": [
      "brw"
    ],
    "imageWrapperLeft": [
      "brw"
    ]
  },
  "css": {
    "%": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%imageWrapper": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  background-color:[brd];",
    "%_imgBorder": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  border:[brd] solid [brw];",
    "%image": "[rd]",
    "%zoom": "position:absolute;top:[brw];right:[brw];bottom:[brw];left:[brw];background:[bghClr];[rd]  opacity:0;[trans]",
    "%title": "[fntt]  color:[ttl2];white-space:nowrap;display:block;",
    "%description": "color:[txt2];[fntds]  display:block;margin-top:.05em;",
    "%link": "[fntds]  display:block;color:[lnkClr];position:static !important;",
    "%_panel": "[boxRd]  height:[imgHeightDiff];position:absolute;bottom:0;left:0;right:0;overflow:hidden;box-sizing:border-box;background:[boxbg];",
    "%_panelWrap": "position:absolute;left:[paddingSize];right:[paddingSize];top:[paddingSize];bottom:[paddingSize];overflow:hidden;",
    "% a": "position:absolute;left:[paddingSize];right:[paddingSize];bottom:[paddingSize];overflow:hidden;text-decoration:underline !important;",
    "%title,%description": "overflow:hidden;text-overflow:ellipsis;",
    "%[data-state~=\"noLink\"] %_panelWrap": "bottom:[paddingSize];",
    "%:hover %zoom": "opacity:1;[trans]"
  }
}
 skins['wysiwyg.common.components.matrixgallery.viewer.skins.MatrixDisplayerTextOnCenterSkin'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "imageWrapper",
        [],
        {},
        [
          "div",
          null,
          [
            "_imgBorder"
          ],
          {},
          [
            "div",
            "image",
            [],
            {}
          ],
          [
            "div",
            "zoom",
            [],
            {},
            [
              "div",
              null,
              [
                "_zoomPadding"
              ],
              {},
              [
                "div",
                null,
                [
                  "_table"
                ],
                {},
                [
                  "div",
                  null,
                  [
                    "_inner"
                  ],
                  {},
                  [
                    "div",
                    "title",
                    [],
                    {}
                  ],
                  [
                    "div",
                    "description",
                    [],
                    {}
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "shd": "BOX_SHADOW",
    "rd": "BORDER_RADIUS",
    "brw": "BORDER_SIZE",
    "borderColor": "BORDER_COLOR_ALPHA",
    "bgHover": "BG_COLOR_ALPHA",
    "trans": "TRANSITION",
    "fntt": "FONT",
    "titleColor": "TEXT_COLOR",
    "descColor": "TEXT_COLOR",
    "fntds": "FONT",
    "linkColor": "BORDER_COLOR",
    "webThemeDir": "URL",
    "linkHoverColor": "BG_COLOR_ALPHA",
    "imageWrapperBottom": "BORDER_SIZE",
    "imageWrapperTop": "BORDER_SIZE",
    "imageWrapperRight": "BORDER_SIZE",
    "imageWrapperLeft": "BORDER_SIZE"
  },
  "paramsDefaults": {
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "rd": "0",
    "brw": "0",
    "borderColor": "color_15",
    "bgHover": "color_15",
    "trans": "opacity 0.4s ease 0s",
    "fntt": "font_7",
    "titleColor": "color_18",
    "descColor": "color_11",
    "fntds": "font_9",
    "linkColor": "color_11",
    "webThemeDir": "WEB_THEME_DIRECTORY",
    "linkHoverColor": "color_12",
    "imageWrapperBottom": [
      "brw"
    ],
    "imageWrapperTop": [
      "brw"
    ],
    "imageWrapperRight": [
      "brw"
    ],
    "imageWrapperLeft": [
      "brw"
    ]
  },
  "css": {
    "%title,%description": "overflow:hidden;text-overflow:ellipsis;white-space:pre-line;",
    "%imageWrapper": "position:absolute;top:0;right:0;bottom:0;left:0;[shd]  [rd]",
    "%_imgBorder": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  border:solid [brw] [borderColor];background:[borderColor];",
    "%image": "[rd]",
    "%zoom": "position:absolute;top:0;right:0;bottom:0;left:0;background:[bgHover];[rd]  padding:0;opacity:0;[trans]  overflow:hidden;",
    "%_zoomPadding": "position:absolute;top:30px;right:30px;bottom:30px;left:30px;",
    "%title": "[fntt]  color:[titleColor];line-height:1.3em;max-height:3.9em;",
    "%description": "color:[descColor];[fntds]  line-height:1.3em;max-height:3.9em;",
    "%link": "display:block;[fntds]  position:relative;width:30px;",
    "%_linkIcon": "background-color:[linkColor];background-image:url([webThemeDir]gallery/link_icon.png);background-repeat:no-repeat !important;background-position:center center !important;width:30px;height:30px;",
    "%_linkIcon:hover": "background-color:[linkHoverColor];",
    "%title:not(:empty) + %description:not(:empty)": "margin-top:5px;",
    "%[data-state~=\"link\"] %title:not(:empty) ~ %link": "margin-top:13px;",
    "%[data-state~=\"link\"] %description:not(:empty) + %link": "margin-top:13px;",
    "%[data-state~=\"alignLeft\"] %link": "margin:0 auto 0 0;",
    "%[data-state~=\"alignCenter\"] %link": "margin:auto;",
    "%[data-state~=\"alignRight\"] %link": "margin:0 0 0 auto;",
    "%[data-state~=\"alignLeft\"] %zoom": "text-align:left;",
    "%[data-state~=\"alignCenter\"] %zoom": "text-align:center;",
    "%[data-state~=\"alignRight\"] %zoom": "text-align:right;",
    "%:hover %zoom": "opacity:1;[trans]",
    "%_table": "display:table;height:100%;table-layout:fixed;width:100%;",
    "%_inner": "vertical-align:middle;display:table-cell;"
  }
}
 skins['wysiwyg.viewer.skins.displayers.SlideShowDisplayerLiftedShadowSkin'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "imageWrapper",
        [],
        {},
        [
          "div",
          "zoom",
          [],
          {},
          [
            "div",
            "image",
            [],
            {}
          ]
        ]
      ],
      [
        "div",
        "panel",
        [
          "_pnl"
        ],
        {},
        [
          "h3",
          "title",
          [],
          {}
        ],
        [
          "p",
          "description",
          [],
          {}
        ]
      ]
    ]
  ],
  "exports": {
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "trns": "TRANSITION",
    "brw": "MARGIN_SIZE",
    "fntt": "FONT",
    "txt": "TEXT_COLOR",
    "fntds": "FONT",
    "s": "MARGIN_SIZE",
    "imageWrapperBottom": "MARGIN_SIZE",
    "imageWrapperTop": "MARGIN_SIZE",
    "imageWrapperRight": "MARGIN_SIZE",
    "imageWrapperLeft": "MARGIN_SIZE"
  },
  "paramsDefaults": {
    "rd": "0",
    "bg": "color_15",
    "trns": "opacity 0.5s ease 0s",
    "brw": "0",
    "fntt": "font_6",
    "txt": "color_11",
    "fntds": "font_8",
    "s": [
      "brw"
    ],
    "imageWrapperBottom": [
      "brw"
    ],
    "imageWrapperTop": [
      "brw"
    ],
    "imageWrapperRight": [
      "brw"
    ],
    "imageWrapperLeft": [
      "brw"
    ]
  },
  "css": {
    "%title,%description": "overflow:hidden;text-overflow:ellipsis;white-space:pre-line;",
    "%imageWrapper": "position:absolute;",
    "%panel": "[rd]  background-color:[bg];[trns]  opacity:0;position:absolute;right:[brw];left:[brw];bottom:[brw];padding:10px 100px 10px 10px;min-height:18px;border-top-left-radius:0 !important;border-top-right-radius:0 !important;",
    "%zoom": "position:absolute;top:[brw];right:[brw];bottom:[brw];left:[brw];",
    "%image": "[rd]",
    "%title": "[fntt]  color:[txt];",
    "%description": "[fntds]  color:[txt];",
    "%link": "[fntds]  color:[txt];",
    "%[data-state~=\"link\"] %link": "text-decoration:underline;cursor:pointer;[fntds]  color:[txt];",
    "%[data-state~=\"showPanel\"] %panel": "opacity:1;",
    "%[data-state~=\"notShowPanel\"] %panel": "opacity:0;",
    "%[data-state~=\"transOut\"] %panel": "opacity:0;",
    "%[data-state~=\"transIn\"] %panel": "opacity:0;"
  }
}
 skins['wysiwyg.viewer.skins.displayers.SlideShowPolaroidDisplayer'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "imageWrapper",
        [],
        {},
        [
          "div",
          "zoom",
          [],
          {},
          [
            "div",
            "image",
            [],
            {}
          ]
        ]
      ],
      [
        "div",
        "panel",
        [],
        {},
        [
          "h3",
          "title",
          [],
          {}
        ],
        [
          "p",
          "description",
          [],
          {}
        ]
      ]
    ]
  ],
  "exports": {
    "heightDiff": 70,
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "brw": "SIZE",
    "rd": "BORDER_RADIUS",
    "trns": "TRANSITION",
    "txt": "TEXT_COLOR",
    "fntds": "FONT",
    "fntt": "FONT",
    "imageWrapperRight": "SIZE",
    "imageWrapperLeft": "SIZE"
  },
  "paramsDefaults": {
    "brw": "5px",
    "rd": "5px",
    "trns": "opacity 0.3s ease 0s",
    "txt": "color_15",
    "fntds": "font_8",
    "fntt": "font_6",
    "imageWrapperRight": [
      "brw"
    ],
    "imageWrapperLeft": [
      "brw"
    ]
  },
  "css": {
    "%title,%description": "overflow:hidden;text-overflow:ellipsis;white-space:pre-line;",
    "%imageWrapper": "position:absolute;",
    "%zoom": "height:100%;position:absolute;top:[brw];right:[brw];bottom:70px;left:[brw];[rd]",
    "%image": "[rd]",
    "%panel": "[trns]  position:absolute;right:90px;bottom:10px;left:10px;height:50px;",
    "%title,%description,%link": "color:[txt];",
    "%description,%link": "[fntds]",
    "%description": "white-space:nowrap;",
    "%title,%link": "white-space:nowrap;",
    "%title": "[fntt]",
    "%link": "text-decoration:underline;cursor:pointer;position:absolute;right:-80px;top:28px;",
    "%[data-state~=\"defaultPanelState\"] %panel": "opacity:0;",
    "%[data-state~=\"showPanel\"] %panel": "opacity:1;",
    "%[data-state~=\"transOut\"] %panel,%[data-state~=\"transIn\"] %panel": "opacity:0;"
  }
}
 skins['wysiwyg.viewer.skins.displayers.SlideShowSloopyDisplayer'] = {
  "react": [
    [
      "div",
      "imageWrapper",
      [],
      {},
      [
        "a",
        "link",
        [],
        {},
        [
          "div",
          "zoom",
          [],
          {},
          [
            "div",
            "image",
            [],
            {}
          ]
        ]
      ]
    ],
    [
      "div",
      "panel",
      [
        "_pnl"
      ],
      {},
      [
        "h3",
        "title",
        [],
        {}
      ],
      [
        "p",
        "description",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "bg": "BG_COLOR_ALPHA",
    "trns": "TRANSITION",
    "fntt": "FONT",
    "txt": "TEXT_COLOR",
    "fntds": "FONT"
  },
  "paramsDefaults": {
    "bg": "color_15",
    "trns": "opacity 0.5s ease 0s",
    "fntt": "font_6",
    "txt": "color_11",
    "fntds": "font_8"
  },
  "css": {
    "%title,%description": "overflow:hidden;text-overflow:ellipsis;white-space:pre-line;",
    "%imageWrapper": "position:absolute;",
    "%zoom": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%panel": "background-color:[bg];[trns]  opacity:0;position:absolute;right:0;bottom:0;left:0;",
    "%title": "[fntt]  color:[txt];white-space:nowrap;padding:10px 10px 0 10px;",
    "%description": "[fntds]  color:[txt];white-space:nowrap;padding:10px;",
    "%link": "[fntds]  color:[txt];white-space:nowrap;padding:0 10px 10px 10px;display:block;text-decoration:underline;cursor:pointer;",
    "%[data-state~=\"link\"] %link": "text-decoration:underline;cursor:pointer;",
    "%[data-state~=\"showPanel\"] %panel": "opacity:1;",
    "%[data-state~=\"notShowPanel\"] %panel": "opacity:0;",
    "%[data-state~=\"transOut\"] %panel": "opacity:0;",
    "%[data-state~=\"transIn\"] %panel": "opacity:0;"
  }
}
 skins['wysiwyg.viewer.skins.displayers.SlideShowTextBottomDisplayer'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "imageWrapper",
        [],
        {},
        [
          "div",
          "zoom",
          [],
          {},
          [
            "div",
            "image",
            [],
            {}
          ]
        ]
      ],
      [
        "div",
        "panel",
        [],
        {},
        [
          "h3",
          "title",
          [],
          {}
        ],
        [
          "p",
          "description",
          [],
          {}
        ]
      ]
    ]
  ],
  "exports": {
    "heightDiff": 70,
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "bg": "BG_COLOR_ALPHA",
    "shd": "BOX_SHADOW",
    "rd": "BORDER_RADIUS",
    "brw": "SIZE",
    "trns": "TRANSITION",
    "fntt": "FONT",
    "txt": "TEXT_COLOR",
    "fntds": "FONT",
    "s": "SIZE",
    "imageWrapperBottom": "SIZE",
    "imageWrapperTop": "SIZE",
    "imageWrapperRight": "SIZE",
    "imageWrapperLeft": "SIZE"
  },
  "paramsDefaults": {
    "bg": "color_11",
    "shd": "0 1px 3px rgba(0, 0, 0, 0.5)",
    "rd": "0",
    "brw": "0",
    "trns": "opacity 0.3s ease 0s",
    "fntt": "font_6",
    "txt": "color_15",
    "fntds": "font_8",
    "s": [
      "brw"
    ],
    "imageWrapperBottom": [
      "brw"
    ],
    "imageWrapperTop": [
      "brw"
    ],
    "imageWrapperRight": [
      "brw"
    ],
    "imageWrapperLeft": [
      "brw"
    ]
  },
  "css": {
    "%imageWrapper": "background-color:[bg];[shd]  [rd]  position:absolute;",
    "%zoom": "position:absolute;top:[brw];right:[brw];bottom:[brw];left:[brw];",
    "%image": "[rd]",
    "%panel": "[trns]  height:50px;position:absolute;bottom:20px;left:0;right:130px;",
    "%title": "[fntt]  color:[txt];white-space:nowrap;overflow:hidden;text-overflow:ellipsis;margin-top:10px;",
    "%description": "[fntds]  color:[txt];white-space:nowrap;overflow:hidden;text-overflow:ellipsis;",
    "%link": "[fntds]  color:[txt];white-space:nowrap;text-decoration:underline;cursor:pointer;position:absolute;right:-112px;top:39px;",
    "%[data-state~=\"link\"] %link": "text-decoration:underline;cursor:pointer;",
    "%[data-state~=\"defaultPanelState\"] %panel": "opacity:0;",
    "%[data-state~=\"showPanel\"] %panel": "opacity:1;",
    "%[data-state~=\"transOut\"] %panel": "opacity:0;",
    "%[data-state~=\"transIn\"] %panel": "opacity:0;"
  }
}
 skins['wysiwyg.viewer.skins.displayers.SlideTextFloatingDisplayer'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "imageWrapper",
        [],
        {},
        [
          "div",
          "zoom",
          [],
          {},
          [
            "div",
            "image",
            [],
            {}
          ]
        ]
      ],
      [
        "div",
        "panel",
        [
          "_pnl"
        ],
        {},
        [
          "h3",
          "title",
          [],
          {}
        ],
        [
          "p",
          "description",
          [],
          {}
        ]
      ]
    ]
  ],
  "exports": {
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "brw": "SIZE",
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "trns": "TRANSITION",
    "txt": "TEXT_COLOR",
    "fntt": "FONT",
    "fntds": "FONT",
    "s": "SIZE",
    "s2": "SIZE",
    "imageWrapperBottom": "SIZE",
    "imageWrapperTop": "SIZE",
    "imageWrapperRight": "SIZE",
    "imageWrapperLeft": "SIZE"
  },
  "paramsDefaults": {
    "brw": "0",
    "rd": "0",
    "bg": "color_15",
    "trns": "opacity 0.5s ease 0s",
    "txt": "color_11",
    "fntt": "font_6",
    "fntds": "font_8",
    "s": [
      "brw"
    ],
    "s2": [
      "brw"
    ],
    "imageWrapperBottom": [
      "brw"
    ],
    "imageWrapperTop": [
      "brw"
    ],
    "imageWrapperRight": [
      "brw"
    ],
    "imageWrapperLeft": [
      "brw"
    ]
  },
  "paramsMutators": {
    "s": {
      "type": "increase",
      "value": 20,
      "param": "brw"
    },
    "s2": {
      "type": "increase",
      "value": 100,
      "param": "brw"
    }
  },
  "css": {
    "%title,%description": "overflow:hidden;text-overflow:ellipsis;white-space:pre-line;",
    "%imageWrapper": "position:absolute;",
    "%zoom": "position:absolute;top:[brw];right:[brw];bottom:[brw];left:[brw];",
    "%image": "[rd]",
    "%panel": "background-color:[bg];[trns]  opacity:0;position:absolute;left:[s];bottom:[s];margin-right:[s2];border-top-left-radius:0 !important;border-top-right-radius:0 !important;",
    "% h3,% p,% a": "color:[txt];",
    "%title": "[fntt]",
    "%link": "[fntds]",
    "%description": "[fntds]",
    "%[data-state~=\"link\"] %link": "text-decoration:underline;cursor:pointer;[fntds]  color:[txt];",
    "%[data-state~=\"showPanel\"] %panel": "opacity:1;",
    "%[data-state~=\"notShowPanel\"]%panel": "opacity:0;",
    "%[data-state~=\"transOut\"] %panel,%[data-state~=\"transIn\"] %panel": "opacity:0;"
  }
}
 skins['wysiwyg.viewer.skins.displayers.SlideTextOverlayDisplayer'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "imageWrapper",
        [],
        {},
        [
          "div",
          "zoom",
          [],
          {},
          [
            "div",
            "image",
            [],
            {}
          ]
        ]
      ],
      [
        "div",
        "panel",
        [
          "_pnl"
        ],
        {},
        [
          "h3",
          "title",
          [],
          {}
        ],
        [
          "p",
          "description",
          [],
          {}
        ]
      ]
    ]
  ],
  "exports": {
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "rd": "BORDER_RADIUS",
    "bg": "BG_COLOR_ALPHA",
    "trns": "TRANSITION",
    "brw": "SIZE",
    "fntt": "FONT",
    "txt": "TEXT_COLOR",
    "fntds": "FONT",
    "s": "SIZE",
    "imageWrapperBottom": "SIZE",
    "imageWrapperTop": "SIZE",
    "imageWrapperRight": "SIZE",
    "imageWrapperLeft": "SIZE"
  },
  "paramsDefaults": {
    "rd": "0",
    "bg": "color_15",
    "trns": "opacity 0.5s ease 0s",
    "brw": "0",
    "fntt": "font_6",
    "txt": "color_11",
    "fntds": "font_8",
    "s": [
      "brw"
    ],
    "imageWrapperBottom": [
      "brw"
    ],
    "imageWrapperTop": [
      "brw"
    ],
    "imageWrapperRight": [
      "brw"
    ],
    "imageWrapperLeft": [
      "brw"
    ]
  },
  "css": {
    "%title,%description": "overflow:hidden;text-overflow:ellipsis;white-space:pre-line;",
    "%imageWrapper": "position:absolute;",
    "%panel": "[rd]  background-color:[bg];[trns]  opacity:0;position:absolute;right:[brw];left:[brw];bottom:[brw];padding:10px 100px 10px 10px;min-height:18px;border-top-left-radius:0 !important;border-top-right-radius:0 !important;",
    "%zoom": "position:absolute;top:[brw];right:[brw];bottom:[brw];left:[brw];",
    "%image": "[rd]",
    "%title": "[fntt]  color:[txt];",
    "%description": "[fntds]  color:[txt];",
    "%link": "[fntds]  color:[txt];",
    "%[data-state~=\"link\"] %link": "text-decoration:underline;cursor:pointer;[fntds]  color:[txt];",
    "%[data-state~=\"showPanel\"] %panel": "opacity:1;",
    "%[data-state~=\"notShowPanel\"] %panel": "opacity:0;",
    "%[data-state~=\"transOut\"] %panel": "opacity:0;",
    "%[data-state~=\"transIn\"] %panel": "opacity:0;"
  }
}
 skins['wysiwyg.viewer.skins.displayers.SlideTextRightDisplayer'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "imageWrapper",
        [],
        {},
        [
          "div",
          "zoom",
          [],
          {},
          [
            "div",
            "image",
            [],
            {}
          ]
        ]
      ],
      [
        "div",
        "panel",
        [],
        {},
        [
          "h3",
          "title",
          [],
          {}
        ],
        [
          "p",
          "description",
          [],
          {}
        ]
      ]
    ]
  ],
  "exports": {
    "widthDiff": 240,
    "m_widthDiff": 0,
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "brw": "MARGIN_SIZE",
    "rd": "BORDER_RADIUS",
    "bgc": "BG_COLOR_ALPHA",
    "fntt": "FONT",
    "txt": "TEXT_COLOR",
    "fntds": "FONT",
    "s": "MARGIN_SIZE",
    "imageWrapperBottom": "MARGIN_SIZE",
    "imageWrapperTop": "MARGIN_SIZE",
    "imageWrapperRight": "MARGIN_SIZE",
    "imageWrapperLeft": "MARGIN_SIZE"
  },
  "paramsDefaults": {
    "brw": "0",
    "rd": "0",
    "bgc": "color_11",
    "fntt": "font_6",
    "txt": "color_15",
    "fntds": "font_8",
    "s": [
      "brw"
    ],
    "imageWrapperBottom": [
      "brw"
    ],
    "imageWrapperTop": [
      "brw"
    ],
    "imageWrapperRight": [
      "brw"
    ],
    "imageWrapperLeft": [
      "brw"
    ]
  },
  "css": {
    "%title,%description": "overflow:hidden;text-overflow:ellipsis;white-space:pre-line;",
    "%imageWrapper": "position:absolute;",
    "%zoom": "position:absolute;top:[brw];right:[brw];bottom:[brw];left:[brw];",
    "%[data-state~=\"mobileView\"] %zoom": "right:[brw];",
    "%image": "[rd]  border-top-right-radius:0 !important;border-bottom-right-radius:0 !important;",
    "%panel": "position:absolute;right:[brw];bottom:[brw];top:[brw];background-color:[bgc];width:220px;padding:10px;[rd]  border-top-left-radius:0 !important;border-bottom-left-radius:0 !important;",
    "%[data-state~=\"mobileView\"] %panel": "display:none;",
    "%title": "[fntt]  color:[txt];",
    "%description": "[fntds]  color:[txt];",
    "%link": "[fntds]  color:[txt];text-decoration:underline;cursor:pointer;",
    "%[data-state~=\"link\"] %link": "text-decoration:underline;cursor:pointer;"
  }
}
 skins['wysiwyg.viewer.skins.displayers.TextBelowDisplayerSkin'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "zoom",
        [],
        {},
        [
          "div",
          "imageWrapper",
          [],
          {},
          [
            "div",
            "image",
            [],
            {}
          ]
        ]
      ]
    ],
    [
      "div",
      "panel",
      [],
      {},
      [
        "div",
        "title",
        [],
        {}
      ],
      [
        "div",
        "description",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "heightDiff": 50,
    "widthDiff": 0,
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "brw": "BORDER_SIZE",
    "fnt": "FONT",
    "ttl": "TEXT_COLOR",
    "fntds": "FONT",
    "txt": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "brw": "12px",
    "fnt": "font_8",
    "ttl": "color_15",
    "fntds": "font_9",
    "txt": "color_15"
  },
  "css": {
    "%image": "border:solid [brw];",
    "%panel": "position:absolute;height:50px;bottom:0;left:0;right:0;",
    "%title": "[fnt]  color:[ttl];",
    "%description": "[fntds]  color:[txt];"
  }
}
 skins['wysiwyg.viewer.skins.displayers.TextOnRollDisplayerSkin'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "imageWrapper",
        [],
        {},
        [
          "div",
          "image",
          [],
          {}
        ]
      ],
      [
        "div",
        null,
        [
          "_panelWrapper"
        ],
        {},
        [
          "div",
          "panel",
          [],
          {},
          [
            "div",
            "title",
            [],
            {}
          ],
          [
            "div",
            "description",
            [],
            {}
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "heightDiff": 20,
    "widthDiff": 20,
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "bg": "BG_COLOR_ALPHA",
    "bgh": "BG_COLOR_ALPHA",
    "fnt": "FONT",
    "txth": "TEXT_COLOR",
    "fnt1": "FONT"
  },
  "paramsDefaults": {
    "bg": "color_11",
    "bgh": "color_6",
    "fnt": "font_8",
    "txth": "color_15",
    "fnt1": "font_8"
  },
  "css": {
    "%": "background-color:[bg];cursor:pointer;",
    "%link": "background-color:[bg];",
    "%imageWrapper": "margin:10px;",
    "%_panelWrapper": "height:100%;width:100%;position:relative;top:-100%;display:table;position:absolute;top:0;right:0;bottom:0;left:0;padding:10px;visibility:hidden;",
    "%panel": "display:table-cell;vertical-align:middle;padding:5px;background-color:[bgh];[fnt]  text-align:center;color:[txth];",
    "%title": "[fnt]",
    "%description": "[fnt1]",
    "%:hover %panel": "visibility:visible;"
  }
}
 skins['wysiwyg.viewer.skins.gallerymatrix.BlogMatrixDisplayer'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "imageWrapper",
        [],
        {},
        [
          "div",
          null,
          [
            "_imgBorder"
          ],
          {},
          [
            "div",
            "image",
            [],
            {}
          ],
          [
            "div",
            "zoom",
            [],
            {},
            [
              "div",
              "title",
              [],
              {}
            ],
            [
              "div",
              "description",
              [],
              {}
            ]
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "rd": "BORDER_RADIUS",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "bgh": "BG_COLOR_ALPHA",
    "trans": "TRANSITION",
    "txt": "TEXT_COLOR",
    "fntds": "FONT",
    "imageWrapperBottom": "BORDER_SIZE",
    "imageWrapperTop": "BORDER_SIZE",
    "imageWrapperRight": "BORDER_SIZE",
    "imageWrapperLeft": "BORDER_SIZE"
  },
  "paramsDefaults": {
    "rd": "0",
    "brw": "0",
    "brd": "color_15",
    "bgh": "#ffffff",
    "trans": "opacity 0.4s ease 0s",
    "txt": "color_15",
    "fntds": "font_8",
    "imageWrapperBottom": [
      "brw"
    ],
    "imageWrapperTop": [
      "brw"
    ],
    "imageWrapperRight": [
      "brw"
    ],
    "imageWrapperLeft": [
      "brw"
    ]
  },
  "css": {
    "%title,%description": "overflow:hidden;text-overflow:ellipsis;white-space:pre-line;overflow:hidden;text-overflow:ellipsis;display:none;",
    "%image,%_imgBorder,%imageWrapper,%zoom": "[rd]",
    "%imageWrapper,%_imgBorder,%zoom": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%_imgBorder": "border:solid [brw] [brd];background:transparent;",
    "%zoom": "background:[bgh];padding:10px;opacity:0;[trans]  overflow:hidden;",
    "%link": "color:[txt];[fntds]  position:absolute;left:10px;right:10px;bottom:10px;text-decoration:underline !important;white-space:nowrap;",
    "%:hover %zoom": "opacity:0.15;[trans]"
  }
}
 skins['wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerCircleSkin'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "imageWrapper",
        [],
        {},
        [
          "div",
          null,
          [
            "_imgBorder"
          ],
          {},
          [
            "div",
            "image",
            [],
            {}
          ]
        ]
      ],
      [
        "div",
        "zoom",
        [],
        {},
        [
          "div",
          "title",
          [],
          {}
        ],
        [
          "div",
          "description",
          [],
          {}
        ]
      ]
    ]
  ],
  "exports": {
    "addMarginToContainer": true,
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "brd": "BG_COLOR_ALPHA",
    "shd": "BOX_SHADOW",
    "fntt": "FONT",
    "ttl": "TEXT_COLOR",
    "brw": "SIZE",
    "trns": "TRANSITION",
    "bgh": "BG_COLOR_ALPHA",
    "txt": "TEXT_COLOR",
    "fntds": "FONT",
    "imageWrapperBottom": "SIZE",
    "imageWrapperTop": "SIZE",
    "imageWrapperRight": "SIZE",
    "imageWrapperLeft": "SIZE"
  },
  "paramsDefaults": {
    "brd": "color_15",
    "shd": "0 1px 3px rgba(0, 0, 0, 0.5)",
    "fntt": "font_6",
    "ttl": "color_15",
    "brw": "0",
    "trns": "opacity 0.5s ease 0s",
    "bgh": "color_17",
    "txt": "color_15",
    "fntds": "font_8",
    "imageWrapperBottom": [
      "brw"
    ],
    "imageWrapperTop": [
      "brw"
    ],
    "imageWrapperRight": [
      "brw"
    ],
    "imageWrapperLeft": [
      "brw"
    ]
  },
  "css": {
    "%title,%description": "overflow:hidden;text-overflow:ellipsis;white-space:pre-line;overflow:hidden;text-overflow:ellipsis;",
    "%": "border-radius:50%;background-color:[brd];[shd]",
    "%:hover %zoom": "opacity:1;",
    "%:hover %zoom %title": "[fntt]      color:[ttl];",
    "%_imgBorder": "position:absolute;top:[brw];right:[brw];bottom:[brw];left:[brw];overflow:hidden;",
    "%zoom": "opacity:0;[trns]  border-radius:50%;background-color:[bgh];text-align:center;padding:15%;position:absolute;top:[brw];right:[brw];bottom:[brw];left:[brw];overflow:hidden;",
    "%title": "color:[ttl];[fntt]  white-space:nowrap;",
    "%description": "color:[txt];[fntds]",
    "%link": "color:[txt];[fntds]  white-space:nowrap;text-decoration:underline !important;",
    "%image": "overflow:hidden;border-radius:50%;"
  }
}
 skins['wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerDefaultSkin'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "imageWrapper",
        [],
        {},
        [
          "div",
          null,
          [
            "_imgBorder"
          ],
          {},
          [
            "div",
            "image",
            [],
            {}
          ],
          [
            "div",
            "zoom",
            [],
            {},
            [
              "div",
              "title",
              [],
              {}
            ],
            [
              "div",
              "description",
              [],
              {}
            ]
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "bgh": "BG_COLOR_ALPHA",
    "trans": "TRANSITION",
    "fntt": "FONT",
    "ttl": "TEXT_COLOR",
    "txt": "TEXT_COLOR",
    "fntds": "FONT",
    "imageWrapperBottom": "BORDER_SIZE",
    "imageWrapperTop": "BORDER_SIZE",
    "imageWrapperRight": "BORDER_SIZE",
    "imageWrapperLeft": "BORDER_SIZE"
  },
  "paramsDefaults": {
    "rd": "0",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "brw": "0",
    "brd": "color_15",
    "bgh": "color_17",
    "trans": "opacity 0.4s ease 0s",
    "fntt": "font_6",
    "ttl": "color_15",
    "txt": "color_15",
    "fntds": "font_8",
    "imageWrapperBottom": [
      "brw"
    ],
    "imageWrapperTop": [
      "brw"
    ],
    "imageWrapperRight": [
      "brw"
    ],
    "imageWrapperLeft": [
      "brw"
    ]
  },
  "css": {
    "%title,%description": "overflow:hidden;text-overflow:ellipsis;white-space:pre-line;overflow:hidden;text-overflow:ellipsis;",
    "%image,%_imgBorder,%imageWrapper,%zoom": "[rd]",
    "%imageWrapper,%_imgBorder,%zoom": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%imageWrapper": "[shd]",
    "%_imgBorder": "border:solid [brw] [brd];background:[brd];",
    "%zoom": "background:[bgh];padding:10px;opacity:0;[trans]  overflow:hidden;",
    "%title": "[fntt]  color:[ttl];white-space:nowrap;",
    "%description": "color:[txt];[fntds]",
    "%link": "color:[txt];[fntds]  position:absolute;left:10px;right:10px;bottom:10px;text-decoration:underline !important;white-space:nowrap;",
    "%:hover %zoom": "opacity:1;[trans]"
  }
}
 skins['wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerLiftedShadow'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "imageWrapper",
        [],
        {},
        [
          "div",
          null,
          [
            "_imgBorder"
          ],
          {},
          [
            "div",
            null,
            [
              "_left",
              "_shd"
            ],
            {}
          ],
          [
            "div",
            null,
            [
              "_right",
              "_shd"
            ],
            {}
          ],
          [
            "div",
            "image",
            [],
            {}
          ],
          [
            "div",
            "zoom",
            [],
            {},
            [
              "div",
              "title",
              [],
              {}
            ],
            [
              "div",
              "description",
              [],
              {}
            ]
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "rd": "BORDER_RADIUS",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "tdr": "URL",
    "bgh": "BG_COLOR_ALPHA",
    "trans": "TRANSITION",
    "fntt": "FONT",
    "ttl": "TEXT_COLOR",
    "txt": "TEXT_COLOR",
    "fntds": "FONT",
    "s": "SIZE",
    "imageWrapperBottom": "BORDER_SIZE",
    "imageWrapperTop": "BORDER_SIZE",
    "imageWrapperRight": "BORDER_SIZE",
    "imageWrapperLeft": "BORDER_SIZE"
  },
  "paramsDefaults": {
    "rd": "0",
    "brw": "0",
    "brd": "color_15",
    "tdr": "BASE_THEME_DIRECTORY",
    "bgh": "color_11",
    "trans": "opacity 0.4s ease 0s",
    "fntt": "font_6",
    "ttl": "color_15",
    "txt": "color_15",
    "fntds": "font_8",
    "s": [
      "brw"
    ],
    "imageWrapperBottom": [
      "brw"
    ],
    "imageWrapperTop": [
      "brw"
    ],
    "imageWrapperRight": [
      "brw"
    ],
    "imageWrapperLeft": [
      "brw"
    ]
  },
  "paramsMutators": {
    "s": {
      "type": "increase",
      "value": 26,
      "param": "brw"
    }
  },
  "css": {
    "%title,%description": "overflow:hidden;text-overflow:ellipsis;white-space:pre-line;overflow:hidden;text-overflow:ellipsis;",
    "%imageWrapper,%_imgBorder": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%_imgBorder": "[rd]  border:solid [brw] [brd];background:[brd];",
    "%_imgBorder:before,%_imgBorder:after": "content:\"\";position:absolute;width:165px;height:26px;bottom:calc(-1 * [s]);background:url([tdr]liftedshadow_medium.png) no-repeat;",
    "%_imgBorder:before": "left:-15px;background-position:0 0;",
    "%_imgBorder:after": "right:-15px;background-position:100% 0;",
    "%image": "[rd]",
    "%zoom": "position:absolute;top:0;right:0;bottom:0;left:0;background:[bgh];[rd]  padding:10px;opacity:0;[trans]  overflow:hidden;",
    "%title": "[fntt]  color:[ttl];white-space:nowrap;",
    "%description": "color:[txt];[fntds]",
    "%link": "color:[txt];[fntds]  position:absolute;left:10px;right:10px;bottom:10px;text-decoration:underline !important;white-space:nowrap;",
    "%:hover %zoom": "opacity:1;[trans]"
  }
}
 skins['wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerPolaroidSkin'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "imageWrapper",
        [],
        {},
        [
          "div",
          null,
          [
            "_imgBorder"
          ],
          {},
          [
            "div",
            "image",
            [],
            {}
          ]
        ],
        [
          "div",
          "zoom",
          [],
          {}
        ]
      ],
      [
        "ul",
        null,
        [
          "_panel"
        ],
        {},
        [
          "li",
          null,
          [
            "_ttl"
          ],
          {},
          [
            "h6",
            "title",
            [],
            {}
          ]
        ],
        [
          "li",
          null,
          [
            "_desc"
          ],
          {},
          [
            "p",
            "description",
            [],
            {}
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "heightDiff": 80,
    "m_heightDiff": 40,
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "bg": "BG_COLOR_ALPHA",
    "brw": "PADDING_SIZE",
    "bgh": "BG_COLOR_ALPHA",
    "trans": "TRANSITION",
    "fntt": "FONT",
    "ttl": "TEXT_COLOR",
    "txt": "TEXT_COLOR",
    "fntds": "FONT",
    "imageWrapperTop": "PADDING_SIZE",
    "imageWrapperRight": "PADDING_SIZE",
    "imageWrapperLeft": "PADDING_SIZE"
  },
  "paramsDefaults": {
    "rd": "0",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "bg": "color_11",
    "brw": "5px",
    "bgh": "color_17",
    "trans": "opacity 0.4s ease 0s",
    "fntt": "font_6",
    "ttl": "color_15",
    "txt": "color_15",
    "fntds": "font_8",
    "imageWrapperTop": [
      "brw"
    ],
    "imageWrapperRight": [
      "brw"
    ],
    "imageWrapperLeft": [
      "brw"
    ]
  },
  "css": {
    "%title,%description": "overflow:hidden;text-overflow:ellipsis;white-space:pre-line;",
    "%": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  [shd]  background:[bg];",
    "%imageWrapper": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]",
    "%_imgBorder": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  padding:[brw] [brw] 0 [brw];",
    "%image": "[rd]",
    "%zoom": "position:absolute;top:[brw];bottom:0;left:[brw];right:[brw];background:[bgh];[rd]  opacity:0;[trans]",
    "%_panel": "height:60px;position:absolute;bottom:0;left:0;right:0;padding:0 10px;",
    "%title": "[fntt]  color:[ttl];white-space:nowrap;overflow:hidden;position:absolute;bottom:44px;left:10px;right:10px;height:auto;",
    "%[data-state~=\"mobileView\"] %title": "bottom:10px;",
    "%description": "color:[txt];[fntds]  overflow:hidden;position:absolute;bottom:28px;left:10px;right:10px;height:1.2em;",
    "%[data-state~=\"mobileView\"] %description": "display:none;",
    "%link": "color:[txt];[fntds]  position:absolute;left:10px;right:10px;bottom:10px;text-decoration:underline !important;white-space:nowrap;overflow:hidden;height:1.2em;",
    "%[data-state~=\"alignLeft\"] %title": "text-align:left;",
    "%[data-state~=\"alignCenter\"] %title": "text-align:center;",
    "%[data-state~=\"alignRight\"] %title": "text-align:right;",
    "%[data-state~=\"alignLeft\"] %description": "text-align:left;",
    "%[data-state~=\"alignCenter\"] %description": "text-align:center;",
    "%[data-state~=\"alignRight\"] %description": "text-align:right;",
    "%[data-state~=\"alignLeft\"] %link": "text-align:left;",
    "%[data-state~=\"alignCenter\"] %link": "text-align:center;",
    "%[data-state~=\"alignRight\"] %link": "text-align:right;",
    "%[data-state~=\"rollover\"]:hover %zoom": "opacity:1;[trans]"
  }
}
 skins['wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerSloopy'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "imageWrapper",
        [],
        {},
        [
          "div",
          "image",
          [],
          {}
        ]
      ],
      [
        "div",
        "zoom",
        [],
        {},
        [
          "div",
          "title",
          [],
          {}
        ],
        [
          "div",
          "description",
          [],
          {}
        ]
      ]
    ]
  ],
  "exports": {
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "bg": "BG_COLOR_ALPHA",
    "tdr": "URL",
    "bgh": "BG_COLOR_ALPHA",
    "trans": "TRANSITION",
    "fntt": "FONT",
    "ttl": "TEXT_COLOR",
    "txt": "TEXT_COLOR",
    "fntds": "FONT"
  },
  "paramsDefaults": {
    "bg": "color_11",
    "tdr": "BASE_THEME_DIRECTORY",
    "bgh": "color_17",
    "trans": "opacity 0.4s ease 0s",
    "fntt": "font_6",
    "ttl": "color_15",
    "txt": "color_15",
    "fntds": "font_8"
  },
  "css": {
    "%title,%description": "overflow:hidden;text-overflow:ellipsis;white-space:pre-line;",
    "%": "background:[bg];",
    "%:before": "content:\"\";background-repeat:no-repeat;position:absolute;z-index:1;top:-5px;left:-5px;bottom:0;right:0;background:url([tdr]sloppyframe.png);background-position:0 0;",
    "%:after": "content:\"\";background-repeat:no-repeat;position:absolute;z-index:1;top:0;left:0;bottom:-5px;right:-5px;background:url([tdr]sloppyframe.png);background-position:100% 100%;",
    "%zoom": "position:absolute;top:4px;bottom:4px;left:4px;right:4px;background:[bgh];padding:10px;opacity:0;[trans]  overflow:hidden;z-index:2;",
    "%title": "[fntt]  color:[ttl];white-space:nowrap;",
    "%description": "color:[txt];[fntds]",
    "%link": "color:[txt];[fntds]  position:absolute;left:10px;right:10px;bottom:10px;text-decoration:underline !important;white-space:nowrap;z-index:3;",
    "%[data-state~=\"alignLeft\"] %title": "text-align:left;",
    "%[data-state~=\"alignCenter\"] %title": "text-align:center;",
    "%[data-state~=\"alignRight\"] %title": "text-align:right;",
    "%[data-state~=\"alignLeft\"] %description": "text-align:left;",
    "%[data-state~=\"alignCenter\"] %description": "text-align:center;",
    "%[data-state~=\"alignRight\"] %description": "text-align:right;",
    "%[data-state~=\"alignLeft\"] %link": "text-align:left;",
    "%[data-state~=\"alignCenter\"] %link": "text-align:center;",
    "%[data-state~=\"alignRight\"] %link": "text-align:right;",
    "%:hover %zoom": "opacity:1;[trans]"
  }
}
 skins['wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerTextBelowSkin'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "imageWrapper",
        [],
        {},
        [
          "div",
          null,
          [
            "_imgBorder"
          ],
          {},
          [
            "div",
            "image",
            [],
            {}
          ]
        ],
        [
          "div",
          "zoom",
          [],
          {}
        ]
      ],
      [
        "ul",
        null,
        [
          "_panel"
        ],
        {},
        [
          "li",
          null,
          [
            "_ttl"
          ],
          {},
          [
            "h6",
            "title",
            [],
            {}
          ]
        ],
        [
          "li",
          null,
          [
            "_desc"
          ],
          {},
          [
            "p",
            "description",
            [],
            {}
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "heightDiff": 80,
    "m_heightDiff": 40,
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "bgh": "BG_COLOR_ALPHA",
    "trans": "TRANSITION",
    "fntt": "FONT",
    "ttl": "TEXT_COLOR",
    "txt": "TEXT_COLOR",
    "fntds": "FONT"
  },
  "paramsDefaults": {
    "rd": "0",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "brw": "0",
    "brd": "color_15",
    "bgh": "color_17",
    "trans": "opacity 0.4s ease 0s",
    "fntt": "font_6",
    "ttl": "color_15",
    "txt": "color_15",
    "fntds": "font_8"
  },
  "css": {
    "%title,%description": "overflow:hidden;text-overflow:ellipsis;white-space:pre-line;",
    "%imageWrapper": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  [shd]",
    "%_imgBorder": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  border:solid [brw] [brd];background:[brd];",
    "%image": "[rd]",
    "%zoom": "position:absolute;top:0;right:0;bottom:0;left:0;background:[bgh];[rd]  padding:10px;opacity:0;[trans]",
    "%_panel": "height:75px;position:absolute;overflow:visible;bottom:0;left:0;right:0;",
    "%_ttl": "position:absolute;bottom:40px;left:0;right:0;",
    "%title": "[fntt]  color:[ttl];white-space:nowrap;overflow:hidden;height:auto;",
    "%[data-state~=\"mobileView\"] %_ttl": "bottom:10px;",
    "%_desc": "position:absolute;bottom:20px;left:0;right:0;",
    "%description": "color:[txt];[fntds]  overflow:hidden;height:1.2em;",
    "%[data-state~=\"mobileView\"] %description": "display:none;",
    "%_lnk": "position:absolute;bottom:0;left:0;right:0;overflow:hidden;[fntds]  text-overflow:ellipsis !important;",
    "%link": "color:[txt];text-decoration:underline !important;white-space:nowrap;overflow:hidden;height:1.2em;",
    "%[data-state~=\"alignLeft\"] %title": "text-align:left;",
    "%[data-state~=\"alignCenter\"] %title": "text-align:center;",
    "%[data-state~=\"alignRight\"] %title": "text-align:right;",
    "%[data-state~=\"alignLeft\"] %description": "text-align:left;",
    "%[data-state~=\"alignCenter\"] %description": "text-align:center;",
    "%[data-state~=\"alignRight\"] %description": "text-align:right;",
    "%[data-state~=\"alignLeft\"] %link": "text-align:left;",
    "%[data-state~=\"alignCenter\"] %link": "text-align:center;",
    "%[data-state~=\"alignRight\"] %link": "text-align:right;",
    "%[data-state~=\"rollover\"]:hover %zoom": "opacity:1;[trans]"
  }
}
 skins['wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerTextSlideUpSkin'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "imageWrapper",
        [],
        {},
        [
          "div",
          null,
          [
            "_imgBorder"
          ],
          {},
          [
            "div",
            "image",
            [],
            {}
          ],
          [
            "div",
            "zoom",
            [],
            {},
            [
              "div",
              "zoomContentWrapper",
              [],
              {},
              [
                "div",
                "title",
                [],
                {}
              ],
              [
                "div",
                "description",
                [],
                {}
              ]
            ]
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "bgh": "BG_COLOR_ALPHA",
    "trans": "TRANSITION",
    "fntt": "FONT",
    "ttl": "TEXT_COLOR",
    "paddingSize": "MARGIN_SIZE",
    "txt": "TEXT_COLOR",
    "fntds": "FONT",
    "paddingTop": "SIZE",
    "paddingLeft": "SIZE",
    "imageWrapperBottom": "BORDER_SIZE",
    "imageWrapperTop": "BORDER_SIZE",
    "imageWrapperRight": "BORDER_SIZE",
    "imageWrapperLeft": "BORDER_SIZE"
  },
  "paramsDefaults": {
    "rd": "0",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "brw": "0",
    "brd": "color_15",
    "bgh": "color_11",
    "trans": "bottom 0.3s ease-in-out",
    "fntt": "font_8",
    "ttl": "color_15",
    "paddingSize": "9px",
    "txt": "color_15",
    "fntds": "font_9",
    "paddingTop": [
      "paddingSize"
    ],
    "paddingLeft": [
      "paddingSize"
    ],
    "imageWrapperBottom": [
      "brw"
    ],
    "imageWrapperTop": [
      "brw"
    ],
    "imageWrapperRight": [
      "brw"
    ],
    "imageWrapperLeft": [
      "brw"
    ]
  },
  "paramsMutators": {
    "paddingTop": {
      "type": "decrease",
      "value": 1,
      "param": "paddingSize"
    },
    "paddingLeft": {
      "type": "increase",
      "value": 2,
      "param": "paddingSize"
    }
  },
  "css": {
    "%title,%description": "overflow:hidden;text-overflow:ellipsis;white-space:pre-line;overflow:hidden;text-overflow:ellipsis;",
    "%imageWrapper,%_imgBorder,%image,%zoom,% zoomContentWrapper": "[rd]",
    "%imageWrapper,%_imgBorder,%zoom": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%imageWrapper": "[shd]",
    "%_imgBorder": "border:solid [brw] [brd];background:[brd];",
    "%zoom": "overflow:hidden;border-top-left-radius:0;border-top-right-radius:0;",
    "%zoomContentWrapper": "position:absolute;top:auto;right:0;bottom:-100%;left:0;padding:0;background:[bgh];[trans]  overflow:hidden;height:auto;max-height:100%;border-top-left-radius:0;border-top-right-radius:0;",
    "%:hover %zoom": "opacity:1;[trans]",
    "%:hover %zoomContentWrapper": "height:auto;bottom:0;",
    "%title": "[fntt]  color:[ttl];white-space:nowrap;margin-top:[paddingTop];margin-left:[paddingLeft];margin-right:[paddingSize];",
    "%description,%link": "color:[txt];[fntds]",
    "%link": "white-space:nowrap;text-decoration:underline !important;",
    "%title:empty": "margin-top:0;",
    "%title:empty + %description:empty + %link:not(:empty)": "margin-top:[paddingSize];",
    "%title:empty + %description:not(:empty)": "margin-top:[paddingSize];",
    "%description:empty": "margin-bottom:0;",
    "%title:not(:empty) + %description:empty": "margin-bottom:[paddingSize];"
  }
}
 skins['wysiwyg.viewer.skins.gallerymatrix.MatrixDisplayerTransparentSkin'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "imageWrapper",
        [],
        {},
        [
          "div",
          null,
          [
            "_imgBorder"
          ],
          {},
          [
            "div",
            "image",
            [],
            {}
          ],
          [
            "div",
            "zoom",
            [],
            {},
            [
              "div",
              null,
              [
                "_table"
              ],
              {},
              [
                "div",
                null,
                [
                  "_inner"
                ],
                {},
                [
                  "div",
                  "title",
                  [],
                  {}
                ],
                [
                  "div",
                  "description",
                  [],
                  {}
                ]
              ]
            ]
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "shd": "BOX_SHADOW",
    "rd": "BORDER_RADIUS",
    "brw": "BORDER_SIZE",
    "brdTrans": "BORDER_COLOR_ALPHA",
    "bgtrans": "TRANSITION",
    "optrans": "TRANSITION",
    "fntt": "FONT",
    "ttlTrans": "TEXT_COLOR",
    "coltrans": "TRANSITION",
    "fntdsTrans": "FONT",
    "txtTrans": "TEXT_COLOR",
    "ttlhTrans": "TEXT_COLOR",
    "txthTrans": "TEXT_COLOR",
    "imageWrapperBottom": "BORDER_SIZE",
    "imageWrapperTop": "BORDER_SIZE",
    "imageWrapperRight": "BORDER_SIZE",
    "imageWrapperLeft": "BORDER_SIZE"
  },
  "paramsDefaults": {
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "rd": "0",
    "brw": "10px",
    "brdTrans": "color_15",
    "bgtrans": "background-color 0.4s ease 0s",
    "optrans": "opacity 0.4s ease 0s",
    "fntt": "font_7",
    "ttlTrans": "color_18",
    "coltrans": "color 0.4s ease 0s",
    "fntdsTrans": "font_9",
    "txtTrans": "color_15",
    "ttlhTrans": "color_18",
    "txthTrans": "color_15",
    "imageWrapperBottom": [
      "brw"
    ],
    "imageWrapperTop": [
      "brw"
    ],
    "imageWrapperRight": [
      "brw"
    ],
    "imageWrapperLeft": [
      "brw"
    ]
  },
  "css": {
    "%title,%description": "overflow:hidden;text-overflow:ellipsis;white-space:pre-line;overflow:hidden;text-overflow:ellipsis;",
    "%imageWrapper,%_imgBorder,%zoom": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%imageWrapper": "[shd]  [rd]",
    "%_imgBorder": "[rd]  border:solid [brw] [brdTrans];[bgtrans]  overflow:hidden;",
    "%image": "[rd]  [optrans]",
    "%image,%image img": "opacity:0;",
    "%zoom": "[rd]  padding:10px;background-image:url(\"data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7\");",
    "%title": "[fntt]  color:[ttlTrans];[coltrans]  white-space:nowrap;",
    "%description,%link": "[fntdsTrans]  color:[txtTrans];[coltrans]",
    "%link": "display:block;text-decoration:underline !important;white-space:nowrap;line-height:1.8em;",
    "%:hover %image,%:hover %image img": "opacity:1;",
    "%:hover %image": "[optrans]",
    "%:hover %_imgBorder": "background-color:[brdTrans];[bgtrans]",
    "%:hover %title": "color:[ttlhTrans];[coltrans]",
    "%:hover %description,%:hover %link": "color:[txthTrans];[coltrans]",
    "%_table": "display:table;height:100%;table-layout:fixed;width:100%;",
    "%_inner": "vertical-align:middle;display:table-cell;"
  }
}
 skins['wysiwyg.viewer.skins.gallerymatrix.PolaroidDisplayerCustomHeightSkin'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "imageWrapper",
        [],
        {},
        [
          "div",
          null,
          [
            "_imgBorder"
          ],
          {},
          [
            "div",
            "image",
            [],
            {}
          ]
        ],
        [
          "div",
          "zoom",
          [],
          {}
        ]
      ]
    ],
    [
      "div",
      "panel",
      [
        "_panel"
      ],
      {},
      [
        "div",
        null,
        [
          "_panelWrap"
        ],
        {},
        [
          "h6",
          "title",
          [],
          {}
        ],
        [
          "span",
          "description",
          [],
          {}
        ]
      ]
    ]
  ],
  "exports": {
    "customDiff": "panel",
    "heightDiff": 80,
    "m_heightDiff": 40,
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "bg": "BG_COLOR_ALPHA",
    "imagePadding": "SIZE",
    "bgh": "BG_COLOR_ALPHA",
    "trans": "TRANSITION",
    "imgHeightDiff": "HEIGHT_SIZE",
    "paddingSize": "MARGIN_SIZE",
    "lnk": "TEXT_COLOR",
    "fntds": "FONT",
    "fntt": "FONT",
    "ttl": "TEXT_COLOR",
    "txt": "TEXT_COLOR",
    "paddingTop": "SIZE",
    "paddingBottom": "SIZE",
    "imageWrapperTop": "SIZE",
    "imageWrapperRight": "SIZE",
    "imageWrapperLeft": "SIZE"
  },
  "paramsDefaults": {
    "rd": "0",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "bg": "color_11",
    "imagePadding": "0",
    "bgh": "color_11",
    "trans": "opacity 0.4s ease 0s",
    "imgHeightDiff": "80px",
    "paddingSize": "10px",
    "lnk": "color_15",
    "fntds": "font_8",
    "fntt": "font_6",
    "ttl": "color_15",
    "txt": "color_15",
    "paddingTop": [
      "paddingSize"
    ],
    "paddingBottom": [
      "paddingSize"
    ],
    "imageWrapperTop": [
      "imagePadding"
    ],
    "imageWrapperRight": [
      "imagePadding"
    ],
    "imageWrapperLeft": [
      "imagePadding"
    ]
  },
  "paramsMutators": {
    "paddingTop": {
      "type": "decrease",
      "value": 5,
      "param": "paddingSize"
    },
    "paddingBottom": {
      "type": "increase",
      "value": 20,
      "param": "paddingSize"
    }
  },
  "css": {
    "%": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  [shd]  background:[bg];",
    "%imageWrapper": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]",
    "%image,%zoom,%_imgBorder": "[rd]  border-bottom-left-radius:0 !important;border-bottom-right-radius:0 !important;",
    "%_imgBorder": "position:absolute;top:0;right:0;bottom:0;left:0;padding:[imagePadding] [imagePadding] 0;",
    "%zoom": "position:absolute;top:[imagePadding];right:[imagePadding];bottom:[imagePadding];left:[imagePadding];background:[bgh];opacity:0;[trans]",
    "%_panel": "height:[imgHeightDiff];position:absolute;bottom:0;left:0;right:0;overflow:hidden;box-sizing:border-box;",
    "%_panelWrap": "position:absolute;left:[paddingSize];right:[paddingSize];top:[paddingTop];bottom:[paddingBottom];overflow:hidden;",
    "% a": "position:absolute;left:[paddingSize];right:[paddingSize];bottom:[paddingSize];overflow:hidden;text-decoration:underline !important;color:[lnk];[fntds]  background:[bg];display:block;",
    "% h6": "[fntt]  color:[ttl];display:block;white-space:nowrap;",
    "% span": "color:[txt];[fntds]  display:inline-block;width:100%;",
    "%title,%description": "overflow:hidden;text-overflow:ellipsis;",
    "%[data-state~=\"noLink\"] %_panelWrap": "bottom:[paddingSize];",
    "%:hover %zoom": "opacity:1;[trans]"
  }
}
 skins['wysiwyg.viewer.skins.gallerymatrix.TextBottomDisplayerCustomHeightSkin'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "imageWrapper",
        [],
        {},
        [
          "div",
          null,
          [
            "_imgBorder"
          ],
          {},
          [
            "div",
            "image",
            [],
            {}
          ]
        ],
        [
          "div",
          "zoom",
          [],
          {}
        ]
      ]
    ],
    [
      "div",
      "panel",
      [
        "_panel"
      ],
      {},
      [
        "div",
        null,
        [
          "_panelWrap"
        ],
        {},
        [
          "h6",
          "title",
          [],
          {}
        ],
        [
          "span",
          "description",
          [],
          {}
        ]
      ]
    ]
  ],
  "exports": {
    "customDiff": "panel",
    "heightDiff": 80,
    "m_heightDiff": 40,
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "rd": "BORDER_RADIUS",
    "brd": "BORDER_COLOR_ALPHA",
    "brw": "SIZE",
    "bgh": "BG_COLOR_ALPHA",
    "trans": "TRANSITION",
    "fntt": "FONT",
    "ttl": "TEXT_COLOR",
    "txt": "TEXT_COLOR",
    "fntds": "FONT",
    "lnk": "TEXT_COLOR",
    "imgHeightDiff": "HEIGHT_SIZE",
    "paddingSize": "MARGIN_SIZE",
    "paddingTop": "SIZE",
    "paddingBottom": "SIZE",
    "imageWrapperBottom": "SIZE",
    "imageWrapperTop": "SIZE",
    "imageWrapperRight": "SIZE",
    "imageWrapperLeft": "SIZE"
  },
  "paramsDefaults": {
    "rd": "0",
    "brd": "color_15",
    "brw": "0",
    "bgh": "color_17",
    "trans": "opacity 0.4s ease 0s",
    "fntt": "font_6",
    "ttl": "color_15",
    "txt": "color_15",
    "fntds": "font_8",
    "lnk": "color_15",
    "imgHeightDiff": "80px",
    "paddingSize": "0",
    "paddingTop": [
      "paddingSize"
    ],
    "paddingBottom": [
      "paddingSize"
    ],
    "imageWrapperBottom": [
      "brw"
    ],
    "imageWrapperTop": [
      "brw"
    ],
    "imageWrapperRight": [
      "brw"
    ],
    "imageWrapperLeft": [
      "brw"
    ]
  },
  "paramsMutators": {
    "paddingTop": {
      "type": "increase",
      "value": 3,
      "param": "paddingSize"
    },
    "paddingBottom": {
      "type": "increase",
      "value": 20,
      "param": "paddingSize"
    }
  },
  "css": {
    "%": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]",
    "%imageWrapper": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]",
    "%_imgBorder": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  border:[brd] solid [brw];",
    "%image": "[rd]",
    "%zoom": "position:absolute;top:[brw];right:[brw];bottom:[brw];left:[brw];background:[bgh];[rd]  opacity:0;[trans]",
    "%title": "[fntt]  color:[ttl];white-space:nowrap;display:block;line-height:normal;",
    "%description": "color:[txt];[fntds]  display:block;line-height:normal;",
    "%link": "[fntds]  display:block;color:[lnk];",
    "%_panel": "height:[imgHeightDiff];position:absolute;bottom:0;left:0;right:0;overflow:hidden;box-sizing:border-box;",
    "%_panelWrap": "position:absolute;left:[paddingSize];right:[paddingSize];top:[paddingTop];bottom:[paddingBottom];",
    "% a": "position:absolute;left:[paddingSize];right:[paddingSize];bottom:[paddingSize];overflow:hidden;text-decoration:underline !important;",
    "%title,%description": "overflow:hidden;text-overflow:ellipsis;",
    "%[data-state~=\"noLink\"] %_panelWrap": "bottom:[paddingSize];",
    "%:hover %zoom": "opacity:1;[trans]"
  }
}
 skins['wysiwyg.viewer.skins.galleryslider.SliderDisplayerCircleSkin'] = {
  "react": [
    [
      "div",
      "imageWrapper",
      [],
      {},
      [
        "div",
        null,
        [
          "_imgBorder"
        ],
        {},
        [
          "div",
          "image",
          [],
          {}
        ]
      ],
      [
        "a",
        "link",
        [],
        {},
        [
          "div",
          "zoom",
          [],
          {},
          [
            "div",
            "title",
            [],
            {}
          ],
          [
            "div",
            "description",
            [],
            {}
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "brd": "BG_COLOR_ALPHA",
    "brw": "SIZE",
    "rd": "BORDER_RADIUS",
    "trans": "TRANSITION",
    "bg": "BG_COLOR_ALPHA",
    "fntds": "FONT",
    "txt": "TEXT_COLOR",
    "imageWrapperBottom": "SIZE",
    "imageWrapperTop": "SIZE",
    "imageWrapperRight": "SIZE",
    "imageWrapperLeft": "SIZE"
  },
  "paramsDefaults": {
    "brd": "color_15",
    "brw": "0",
    "rd": "50%",
    "trans": "opacity 0.4s ease 0s",
    "bg": "color_11",
    "fntds": "font_8",
    "txt": "color_15",
    "imageWrapperBottom": [
      "brw"
    ],
    "imageWrapperTop": [
      "brw"
    ],
    "imageWrapperRight": [
      "brw"
    ],
    "imageWrapperLeft": [
      "brw"
    ]
  },
  "css": {
    "%imageWrapper": "position:relative;background:[brd];overflow:hidden;",
    "%_imgBorder": "position:absolute;top:[brw];right:[brw];bottom:[brw];left:[brw];overflow:hidden;",
    "%image": "overflow:hidden;",
    "%image,%imageWrapper,%zoom,%zoom:hover": "[rd]",
    "%zoom": "opacity:0;[trans]  background:[bg];text-align:center;padding:10px;position:absolute;top:0;right:0;bottom:0;left:0;",
    "%zoom:hover": "opacity:1;",
    "%title,%description": "display:none;",
    "%link": "[fntds]  color:[txt];position:absolute;top:50%;left:0;right:0;text-align:center;cursor:pointer !important;"
  }
}
 skins['wysiwyg.viewer.skins.galleryslider.SliderDisplayerDefaultSkin'] = {
  "react": [
    [
      "div",
      "imageWrapper",
      [],
      {},
      [
        "div",
        null,
        [
          "_imgBorder"
        ],
        {},
        [
          "div",
          "image",
          [],
          {}
        ]
      ],
      [
        "a",
        "link",
        [],
        {},
        [
          "div",
          "zoom",
          [],
          {},
          [
            "div",
            "title",
            [],
            {}
          ],
          [
            "div",
            "description",
            [],
            {}
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "rd": "BORDER_RADIUS",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "bg": "BG_COLOR_ALPHA",
    "trans": "TRANSITION",
    "fntds": "FONT",
    "txt": "TEXT_COLOR",
    "imageWrapperBottom": "BORDER_SIZE",
    "imageWrapperTop": "BORDER_SIZE",
    "imageWrapperRight": "BORDER_SIZE",
    "imageWrapperLeft": "BORDER_SIZE"
  },
  "paramsDefaults": {
    "rd": "5px",
    "brw": "0",
    "brd": "color_15",
    "bg": "color_11",
    "trans": "opacity 0.4s ease 0s",
    "fntds": "font_8",
    "txt": "color_15",
    "imageWrapperBottom": [
      "brw"
    ],
    "imageWrapperTop": [
      "brw"
    ],
    "imageWrapperRight": [
      "brw"
    ],
    "imageWrapperLeft": [
      "brw"
    ]
  },
  "css": {
    "%imageWrapper": "position:relative;[rd]",
    "%_imgBorder": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  border:solid [brw] [brd];background:[brd];",
    "%image": "[rd]  overflow:hidden;",
    "%zoom": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  padding:10px;background:[bg];opacity:0;[trans]",
    "%zoom:hover": "opacity:1;",
    "%title,%description": "display:none;",
    "%link": "[fntds]  color:[txt];position:absolute;bottom:10px;left:10px;cursor:pointer !important;"
  }
}
 skins['wysiwyg.viewer.skins.galleryslider.SliderDisplayerIronSkin'] = {
  "react": [
    [
      "div",
      "imageWrapper",
      [],
      {},
      [
        "div",
        null,
        [
          "_img-glow"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_imgBorder"
        ],
        {},
        [
          "div",
          "image",
          [],
          {}
        ],
        [
          "a",
          "link",
          [],
          {},
          [
            "div",
            "zoom",
            [],
            {},
            [
              "div",
              "title",
              [],
              {}
            ],
            [
              "div",
              "description",
              [],
              {}
            ]
          ]
        ]
      ],
      [
        "div",
        null,
        [
          "_screw-tl",
          "_screw"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_screw-tr",
          "_screw"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_screw-bl",
          "_screw"
        ],
        {}
      ],
      [
        "div",
        null,
        [
          "_screw-br",
          "_screw"
        ],
        {}
      ]
    ]
  ],
  "exports": {
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "shd": "BOX_SHADOW",
    "rd": "BORDER_RADIUS",
    "tdr": "URL",
    "brd": "BG_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "bg": "BG_COLOR_ALPHA",
    "trans": "TRANSITION",
    "ishd": "BOX_SHADOW",
    "fntds": "FONT",
    "txt": "TEXT_COLOR",
    "imageWrapperBottom": "BORDER_SIZE",
    "imageWrapperTop": "BORDER_SIZE",
    "imageWrapperRight": "BORDER_SIZE",
    "imageWrapperLeft": "BORDER_SIZE",
    "contentPaddingLeft": "BORDER_SIZE",
    "contentPaddingRight": "BORDER_SIZE",
    "contentPaddingBottom": "BORDER_SIZE",
    "contentPaddingTop": "BORDER_SIZE"
  },
  "paramsDefaults": {
    "shd": "0 1px 3px rgba(0, 0, 0, 0.5)",
    "rd": "2px",
    "tdr": "BASE_THEME_DIRECTORY",
    "brd": "color_15",
    "brw": "12px",
    "bg": "color_11",
    "trans": "opacity 0.4s ease 0s",
    "ishd": "rgba(255, 255, 255, 0.59) 0px 0px 6px 0px inset, rgba(255, 255, 255, 0.92) 0px 1px 0px 0px inset, rgba(255, 255, 255, 0.2) 0px 0px 5px 0px inset",
    "fntds": "font_8",
    "txt": "color_15",
    "imageWrapperBottom": [
      "brw"
    ],
    "imageWrapperTop": [
      "brw"
    ],
    "imageWrapperRight": [
      "brw"
    ],
    "imageWrapperLeft": [
      "brw"
    ],
    "contentPaddingLeft": [
      "brw"
    ],
    "contentPaddingRight": [
      "brw"
    ],
    "contentPaddingBottom": [
      "brw"
    ],
    "contentPaddingTop": [
      "brw"
    ]
  },
  "css": {
    "%imageWrapper": "[shd]  top:0;right:0;bottom:0;left:0;[shd]  [rd]  position:relative;[rd]  background-image:url([tdr]ironpatern.png);",
    "%_imgBorder": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  background:[brd];border:[brw] solid transparent;box-sizing:border-box;",
    "%image": "[rd]  overflow:hidden;",
    "%zoom": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  padding:10px;background:[bg];opacity:0;[trans]",
    "%title": "display:none;",
    "%description": "display:none;",
    "%_screw": "display:inline-block;background:url([tdr]skrew.png) no-repeat;width:15px;height:15px;",
    "%_screw-tl,%_screw-tr,%_screw-bl,%_screw-br": "position:absolute;",
    "%_screw-tl": "top:5px;left:5px;",
    "%_screw-tr": "top:5px;right:5px;",
    "%_screw-bl": "bottom:5px;left:5px;",
    "%_screw-br": "bottom:5px;right:5px;",
    "%_img-glow": "[ishd]  [rd]  position:absolute;top:0;right:0;bottom:2px;left:0;",
    "%link": "[fntds]  color:[txt];position:absolute;bottom:10px;left:10px;cursor:pointer !important;",
    "%zoom:hover": "opacity:1;",
    "%[data-state~=\"rollover\"] %zoom": "opacity:1;"
  }
}
 skins['wysiwyg.viewer.skins.galleryslider.SliderDisplayerNoArrows'] = {
  "react": [
    [
      "div",
      "imageWrapper",
      [],
      {},
      [
        "div",
        null,
        [
          "_imgBorder"
        ],
        {},
        [
          "div",
          "image",
          [],
          {}
        ]
      ],
      [
        "a",
        "link",
        [],
        {},
        [
          "div",
          "zoom",
          [],
          {},
          [
            "div",
            "title",
            [],
            {}
          ],
          [
            "div",
            "description",
            [],
            {}
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "rd": "BORDER_RADIUS",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "bg": "BG_COLOR_ALPHA",
    "trans": "TRANSITION",
    "fntds": "FONT",
    "txt": "TEXT_COLOR",
    "imageWrapperBottom": "BORDER_SIZE",
    "imageWrapperTop": "BORDER_SIZE",
    "imageWrapperRight": "BORDER_SIZE",
    "imageWrapperLeft": "BORDER_SIZE"
  },
  "paramsDefaults": {
    "rd": "5px",
    "brw": "0",
    "brd": "color_15",
    "bg": "color_11",
    "trans": "opacity 0.4s ease 0s",
    "fntds": "font_8",
    "txt": "color_15",
    "imageWrapperBottom": [
      "brw"
    ],
    "imageWrapperTop": [
      "brw"
    ],
    "imageWrapperRight": [
      "brw"
    ],
    "imageWrapperLeft": [
      "brw"
    ]
  },
  "css": {
    "%imageWrapper": "position:relative;[rd]",
    "%_imgBorder": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  border:solid [brw] [brd];background:[brd];",
    "%image": "[rd]  overflow:hidden;",
    "%zoom": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  padding:10px;background:[bg];opacity:0;[trans]",
    "%zoom:hover": "opacity:1;",
    "%title,%description": "display:none;",
    "%link": "[fntds]  color:[txt];position:absolute;bottom:10px;left:10px;cursor:pointer !important;"
  }
}
 skins['wysiwyg.viewer.skins.galleryslider.SliderDisplayerScotchTapeSkin'] = {
  "react": [
    [
      "div",
      "imageWrapper",
      [],
      {},
      [
        "div",
        null,
        [
          "_imgBorder"
        ],
        {},
        [
          "div",
          "image",
          [],
          {}
        ]
      ],
      [
        "a",
        "link",
        [],
        {},
        [
          "div",
          "zoom",
          [],
          {},
          [
            "div",
            "title",
            [],
            {}
          ],
          [
            "div",
            "description",
            [],
            {}
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "params": {
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "bg": "BG_COLOR_ALPHA",
    "trans": "TRANSITION",
    "fntds": "FONT",
    "txt": "TEXT_COLOR",
    "imageWrapperBottom": "BORDER_SIZE",
    "imageWrapperTop": "BORDER_SIZE",
    "imageWrapperRight": "BORDER_SIZE",
    "imageWrapperLeft": "BORDER_SIZE"
  },
  "paramsDefaults": {
    "rd": "0",
    "shd": "0 1px 3px rgba(0, 0, 0, 0.5)",
    "brw": "0",
    "brd": "color_15",
    "bg": "color_11",
    "trans": "opacity 0.4s ease 0s",
    "fntds": "font_8",
    "txt": "color_15",
    "imageWrapperBottom": [
      "brw"
    ],
    "imageWrapperTop": [
      "brw"
    ],
    "imageWrapperRight": [
      "brw"
    ],
    "imageWrapperLeft": [
      "brw"
    ]
  },
  "css": {
    "%imageWrapper": "position:relative;[rd]  [shd]",
    "%_imgBorder": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  border:solid [brw] [brd];background:[brd];",
    "%image": "[rd]  overflow:hidden;",
    "%zoom": "position:absolute;top:0;right:0;bottom:0;left:0;[rd]  padding:10px;background:[bg];opacity:0;[trans]",
    "%title,%description": "display:none;",
    "%link": "[fntds]  color:[txt];position:absolute;bottom:10px;left:10px;cursor:pointer !important;",
    "%zoom:hover": "opacity:1;"
  }
}

    return skins;
});
