/* eslint-disable */
define([
    'tpaComponents/components/tpaWidget',
    'tpaComponents/components/tpaWidgetNative/tpaWidgetNative',
    'tpaComponents/components/tpaWidgetNative/tpaWidgetNativeDeadcomp',
    'tpaComponents/components/tpaSection',
    'tpaComponents/components/tpaMultiSection',
    'tpaComponents/components/tpaWorker',
    'tpaComponents/components/tpaGluedWidget',
    'tpaComponents/mixins/tpaUrlBuilderMixin',
    'tpaComponents/mixins/tpaCompApiMixin',
    'tpaComponents/components/tpaPreloaderOverlay',
    'tpaComponents/components/tpaUnavailableMessageOverlay',
    'tpaComponents/components/tpaModal',
    'tpaComponents/components/tpaPopup',
    'tpaComponents/mixins/tpaCompBaseMixin',
    'tpaComponents/common/TPAUrlBuilder',
    'tpaComponents/common/TPABaseUrlBuilder',
    'tpaComponents/utils/tpaUtils',
    'tpaComponents/services/tpaPreviewEditorCommunicationService',
    'tpaComponents/utils/sitePages',
    'tpaComponents/common/tpaMetaData',
    'skins',
    'tpaComponents/skins/skins.json',
    'tpaComponents/components/tpaWidgetNative/nativeCompRequireService',
    'tpaComponents/services/pageService',
    'tpaComponents/services/clientSpecMapService',
    'tpaComponents/bi/errors',
    'tpaComponents/bi/events',
    'tpaComponents/common/supportedSiteEvents',
    'tpaComponents/layout/tpaLayout',
    'tpaComponents/layout/gluedWidgetPatcher',
    'tpaComponents/layout/tpaSectionPatcher',
    'tpaComponents/utils/gluedWidgetMeasuringUtils'
], function (tpaWidget,
             tpaWidgetNative,
             tpaWidgetNativeDeadComp,
             tpaSection,
             tpaMultiSection,
             tpaWorker,
             tpaGluedWidget,
             tpaUrlBuilder,
             tpaCompApi,
             tpaPreloaderOverlay,
             tpaUnavailableMessageOverlay,
             tpaModal,
             tpaPopup,
             tpaCompBase,
             TPAUrlBuilder,
             TPABaseUrlBuilder,
             tpaUtils,
             tpaPreviewEditorCommunicationService,
             sitePages,
             tpaMetaData,
             skinsPackage,
             skinsJson,
             nativeCompRequireService,
             pageService,
             clientSpecMapService,
             biErrors,
             events,
             supportedSiteEvents,
             tpaLayout,
             gluedWidgetPatcher,
             tpaSectionPatcher,
             gluedWidgetMeasuringUtils) {
    'use strict';
    skinsPackage.skinsMap.addBatch(skinsJson);

    return {
        tpaWidgetNative,
        tpaWidgetNativeDeadComp,
        nativeCompRequireService,
        widget: tpaWidget,
        section: tpaSection,
        multiSection: tpaMultiSection,
        worker: tpaWorker,
        gluedWidget: tpaGluedWidget,
        tpaMixins: {
            tpaUrlBuilder,
            tpaCompApi,
            tpaCompBase
        },
        gluedWidgetMeasuringUtils,
        GluedWidgetPatcher: gluedWidgetPatcher,
        tpaSectionPatcher,
        common: {
            metaData: tpaMetaData,
            TPAUrlBuilder,
            TPABaseUrlBuilder,
            utils: tpaUtils,
            supportedSiteEvents
        },
        services: {
            tpaPreviewEditorCommunicationService,
            pageService,
            clientSpecMapService
        },
        sitePages,
        bi: {
            errors: biErrors,
            events
        }
    };
});
