/**
 * Created by eitanr on 6/25/14.
 */
define(['layout/specificComponents/svgShape/svgBasicScaler'], function (basic) {
    'use strict';
    return {
        scale(lineElement, scaleX, scaleY) {
            const x1Attribute = lineElement.getAttribute('x1'),
                y1Attribute = lineElement.getAttribute('y1'),
                x2Attribute = lineElement.getAttribute('x2'),
                y2Attribute = lineElement.getAttribute('y2');

            return x1Attribute && y1Attribute && x2Attribute && y2Attribute ? {
                x1: basic.scaleSingleValue(x1Attribute, scaleX),
                x2: basic.scaleSingleValue(x2Attribute, scaleX),

                y1: basic.scaleSingleValue(y1Attribute, scaleY),
                y2: basic.scaleSingleValue(y2Attribute, scaleY)
            } : {};
        }
    };
});
