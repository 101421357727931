import _ from 'lodash'
import {withActions} from 'carmi-host-extensions'
import {mergeBatchableListsRequestsIfAny} from './dataRequirementChecker/batchableListsRequestsMerger'
import {getDescriptorRequest, getRequestsForAppPart} from './dataRequirementChecker/appPartDataRequirementsChecker'
import {reportFirstTimeRenderFinish, reportFirstTimeRenderStart} from '../../utils/wixappsLogger'

export const name = 'AppPartAspect'

export const defaultModel = {
    metadata: {
        descriptor: {},
        items: {}
    }
}

const SEO_SOURCE = 'TPA'

const cloneWixappsStore = wixapps =>
    _.reduce(wixapps, (acc, {descriptor, ...packageStore}, packageName) =>
        ({...acc, [packageName]: {descriptor, ..._.cloneDeep(packageStore)}}), {})

export const functionLibrary = {
    getItemsRequests: (getAppPartDrcAPI, compInfos, urlData) => {
        const appPartDrcAPI = getAppPartDrcAPI()
        const requests = _.reduce(compInfos, (compsRequests, compInfo) => {
            const compRequests = getRequestsForAppPart(appPartDrcAPI, compInfo, urlData)
            return compsRequests.concat(compRequests)
        }, [])

        return mergeBatchableListsRequestsIfAny(appPartDrcAPI, requests)
    },
    getDescriptorRequest,
    updatePageTitleAndMetaTags: (
        {setMetaTags, setCompData, setRuntimeSchema, setRunTimePageTitle, setRunTimePageDescription, getCurrentPageId},
        pageTitle,
        metaDescription,
        pageTitleSEO,
        metaKeywords,
        ogTags = [],
        jsonld,
        twitterMetaTags = []
    ) => {
        setCompData(getCurrentPageId(), {
            metaKeywordsSEO: metaKeywords,
            title: pageTitle,
            descriptionSEO: metaDescription,
            pageTitleSEO
        })
        setMetaTags({value: [...ogTags, ...twitterMetaTags], source: SEO_SOURCE})
        setRuntimeSchema({value: jsonld, source: SEO_SOURCE})
        setRunTimePageTitle({value: pageTitle, source: SEO_SOURCE})
        setRunTimePageDescription({value: metaDescription, source: SEO_SOURCE})
    },
    wixappsReportFirstTimeRenderStart: reportFirstTimeRenderStart,
    wixappsReportFirstTimeRenderFinish: reportFirstTimeRenderFinish,
    onDescriptorFetchSuccess: withActions(({$runInBatch, setDescriptorMetadata}, setDescriptor, requestDescriptor, descriptor) => {
        $runInBatch(() => {
            const value = requestDescriptor.transformFunc(descriptor)
            setDescriptor(requestDescriptor.name, value)
            setDescriptorMetadata(requestDescriptor.name, 'loaded')
        })
    }),
    onItemsFetchSuccess: withActions(async ({$runInBatch}, {requestDescriptor, getWixAppsStore, setWixApps, onLoaded}, response) => {
        const wixappsStore = {wixapps: cloneWixappsStore(getWixAppsStore())}
        const currentValue = _.get(wixappsStore, requestDescriptor.destination)
        const value = await requestDescriptor.transformFunc(response, currentValue)
        $runInBatch(() => {
            _.set(wixappsStore, requestDescriptor.destination, value)
            setWixApps(wixappsStore.wixapps)
            onLoaded()
        })
    })
}
