import _ from 'lodash'

const createBatches = messages => {
    const counters = {}
    const batches = []

    const appendMessageToBatch = (m, batchNumber) => {
        batches[batchNumber] = batches[batchNumber] || []
        batches[batchNumber].push(m)
    }

    for (let i = 0; i < messages.length; i++) {
        const message = messages[i]
        const messageData = message.message

        const isWixCodeCommand = _.isMatch(messageData, {intent: 'WIX_CODE', type: 'wix_code_iframe_command'})
        const isExecuteBatch = _.isMatch(messageData, {command: 'executeBatch'})
        if (isWixCodeCommand && !isExecuteBatch) {
            const commandCounter = _.get(counters, [messageData.compId, messageData.command], 0)
            appendMessageToBatch(message, commandCounter)
            _.set(counters, [messageData.compId, messageData.command], commandCounter + 1)
        } else {
            const lastBatchIndex = Math.max(0, batches.length - 1)
            appendMessageToBatch(message, lastBatchIndex)
            return batches.concat(createBatches(messages.slice(i + 1)))
        }
    }

    return batches
}

const splitToCarmiBatches = batch => {
    const batches = createBatches(batch)

    return {
        batches
    }
}


export {splitToCarmiBatches}
