import coreUtils from 'santa-core-utils'
import Color from 'color'


const isNumber = num => typeof num === 'number' && num === num // eslint-disable-line no-self-compare
function getFromColorMap(colorsMap, color) {
    if (/color_/.test(color)) {
        color = color.replace(/[\[\]{}]/g, '')
        color = colorsMap[color.split('_')[1]]
        color = coreUtils.cssUtils.normalizeColorStr(color)
    }
    return color
}

/**
 * Get color value, use color from theme if value is a pointer, normalize color to use rgba() syntax
 * @param {object} colorsMap
 * @param {string} color
 * @param {number} [alphaOverride]
 * @returns {string}
 */
const colorParser = {
    getColor(color, colorsMap, alphaOverride) {
        // Get color from theme if the color value is represented as {color_X}
        color = getFromColorMap(colorsMap, color)

        if (color === 'none') {
            return 'transparent'
        }

        const colorObj = new Color(color)

        if (isNumber(alphaOverride)) {
            colorObj.setValues('alpha', alphaOverride)
        }

        if (colorObj.alpha() === 0) {
            return 'transparent'
        }

        return colorObj.rgbaString()
    },

    getHexColor(color, colorsMap) {
        const colorObj = new Color(getFromColorMap(colorsMap, color))
        return colorObj.hexString()
    }
}


export {
    colorParser
}
