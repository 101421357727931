define([
    'lodash',
    'warmupUtils/core/pathValidationUtil',
    'warmupUtils/dal/DALFactory'
], function (_, pathValidationUtil, DALFactory) {
    'use strict';

    return {
        setMobxDataHandlers(siteData, mobxDataHandlers) {
            DALFactory.setMobxDataHandlers(siteData, mobxDataHandlers);
            pathValidationUtil.setMobxDataHandlers(siteData, _.pick(mobxDataHandlers, 'getObservableByPath'));
        }
    };
});
