/**
 * Created by eitanr on 6/24/14.
 */
define(['lodash',
    'layout/specificComponents/svgShape/svgBasicScaler',
    'layout/specificComponents/svgShape/svgPolygonParser'], function (_, basic, parser) {
    'use strict';
    return {
        scale(polygonElement, scaleX, scaleY) {
            const parsedPolygonPoints = parser.getParsedPoints(polygonElement.getAttribute('points'));

            _.forEach(parsedPolygonPoints, function (polygonPoint) {
                polygonPoint[0] = basic.scaleSingleValue(polygonPoint[0], scaleX); // eslint-disable-line santa/no-side-effects
                polygonPoint[1] = basic.scaleSingleValue(polygonPoint[1], scaleY); // eslint-disable-line santa/no-side-effects
            });

            return !_.isEmpty(parsedPolygonPoints) ? {
                points: parser.stringifyPoints(parsedPolygonPoints)
            } : {};

            //polygonElement.setAttribute('points', parser.stringifyPoints(parsedPolygonPoints));
        }
    };
});
