define(['lodash', 'warmupUtils'], function (_, warmupUtils) {
    'use strict';


    const MODES_GROUP_TYPES = {
        HOVER: 'HOVER',
        SCROLL: 'SCROLL',
        WIDTH: 'WIDTH',
        APPLICATIVE: 'APPLICATIVE'
    };

    const MODES_GROUP_OF_MODE = {};
    MODES_GROUP_OF_MODE[warmupUtils.siteConstants.COMP_MODES_TYPES.DEFAULT] = MODES_GROUP_TYPES.HOVER;
    MODES_GROUP_OF_MODE[warmupUtils.siteConstants.COMP_MODES_TYPES.HOVER] = MODES_GROUP_TYPES.HOVER;
    MODES_GROUP_OF_MODE[warmupUtils.siteConstants.COMP_MODES_TYPES.SCROLL] = MODES_GROUP_TYPES.SCROLL;
    MODES_GROUP_OF_MODE[warmupUtils.siteConstants.COMP_MODES_TYPES.WIDTH] = MODES_GROUP_TYPES.WIDTH;
    MODES_GROUP_OF_MODE[warmupUtils.siteConstants.COMP_MODES_TYPES.APPLICATIVE] = MODES_GROUP_TYPES.APPLICATIVE;

    function sortScroll(modeDef) {
        return modeDef.settings.scrollPos;
    }

    function sortWidth(modeDef) {
        return modeDef.settings.width;
    }

    function sortHover(modeDef) {
        return modeDef.type === warmupUtils.siteConstants.COMP_MODES_TYPES.DEFAULT ? 1 : 2;
    }

    function getActiveOrFirst(modesFromSameGroup, compActiveModes, sortFunc) {
        const activeMode = _.find(modesFromSameGroup, function (modeDef) {
            return compActiveModes[modeDef.modeId];
        });

        if (activeMode) {
            return activeMode;
        }

        return _(modesFromSameGroup).sortBy(sortFunc).head();
    }

    function getHoverActiveModeForMobile(hoverModes, compActiveModes, comp, compPropertiesInPage) {
        const defaultModeDef = _.find(hoverModes, {type: warmupUtils.siteConstants.COMP_MODES_TYPES.DEFAULT});
        const hoverModeDef = _.find(hoverModes, {type: warmupUtils.siteConstants.COMP_MODES_TYPES.HOVER});

        if (!compActiveModes[defaultModeDef.modeId]) {
            const propertiesId = comp.propertyQuery;
            const compProps = propertiesId && compPropertiesInPage && compPropertiesInPage[propertiesId];

            if (compProps && compProps.mobileDisplayedModeId) {
                return _.find(hoverModes, {modeId: compProps.mobileDisplayedModeId});
            }

            return hoverModeDef;
        }

        return getActiveOrFirst(hoverModes, compActiveModes, sortHover);
    }

    function getHoverActiveMode(hoverModes, compActiveModes, comp, isMobileView, compPropertiesInPage) {
        if (isMobileView) {
            return getHoverActiveModeForMobile(hoverModes, compActiveModes, comp, compPropertiesInPage);
        }

        return getActiveOrFirst(hoverModes, compActiveModes, sortHover);
    }

    function getScrollActiveMode(scrollModes, compActiveModes) {
        return getActiveOrFirst(scrollModes, compActiveModes, sortScroll);
    }

    function getWidthActiveMode(widthModes, compActiveModes) {
        return getActiveOrFirst(widthModes, compActiveModes, sortWidth);
    }

    function generalGetActiveMode(modesFromSameGroup, compActiveModes) {
        return _.filter(modesFromSameGroup, function (modeDef) {
            return compActiveModes[modeDef.modeId];
        });
    }

    const ACTIVE_MODES_GETTERS = {};

    ACTIVE_MODES_GETTERS[MODES_GROUP_TYPES.HOVER] = getHoverActiveMode;
    ACTIVE_MODES_GETTERS[MODES_GROUP_TYPES.SCROLL] = getScrollActiveMode;
    ACTIVE_MODES_GETTERS[MODES_GROUP_TYPES.WIDTH] = getWidthActiveMode;

    function getActiveModesByModeGroupType(groupType, groupModes, currentCompActiveModes, comp, isMobileView, compPropertiesInPage) {
        const activeModesGetter = ACTIVE_MODES_GETTERS[groupType] || generalGetActiveMode;
        const activeModes = activeModesGetter(groupModes, currentCompActiveModes, comp, isMobileView, compPropertiesInPage);

        return _.isArray(activeModes) ? activeModes : [activeModes];
    }

    function groupModeDefinitionsByType(modeDefinitions) {
        return _.groupBy(modeDefinitions, function (modeDef) {
            return MODES_GROUP_OF_MODE[modeDef.type];
        });
    }

    function getActiveModeData(modeDefinition) {
        return {
            modeType: modeDefinition.type
        };
    }

    function resolveCompActiveModes(comp, currentRootActiveModes, isMobileView, compPropertiesInPage) {
        const compModeDefinitions = _.get(comp, 'modes.definitions');
        if (_.isEmpty(compModeDefinitions)) {
            return {};
        }

        const componentModeDefinitionIds = _.map(compModeDefinitions, 'modeId');
        const currentCompActiveModes = _.pick(currentRootActiveModes, componentModeDefinitionIds);
        const modeGroupedByType = groupModeDefinitionsByType(compModeDefinitions);

        return _.reduce(modeGroupedByType, function (result, groupModes, groupType) {
            const activeModes = getActiveModesByModeGroupType(groupType, groupModes, currentCompActiveModes, comp, isMobileView, compPropertiesInPage);

            _.forEach(activeModes, function (modeDef) {
                result[modeDef.modeId] = getActiveModeData(modeDef);
            });

            return result;
        }, {});
    }

    function resolveCompActiveModesRecursive(fullCompStructure, currentRootActiveModes, isMobileView, compPropertiesInPage) {
        const activeModes = resolveCompActiveModes(fullCompStructure, currentRootActiveModes, isMobileView, compPropertiesInPage);
        const children = warmupUtils.dataUtils.getChildrenData(fullCompStructure, false);
        _.reduce(children, function (result, child) {
            const childActiveModes = resolveCompActiveModesRecursive(child, currentRootActiveModes, isMobileView, compPropertiesInPage);
            return _.assign(result, childActiveModes);
        }, activeModes);
        return activeModes || {};
    }

    function isAlwaysHiddenInModes(modeDefinitions, compModes) { // eslint-disable-line complexity
        const hoverAndDefaultModes = [];
        const scrollModesIds = [];
        const widthModesIds = [];
        const otherModeIds = [];

        _.forEach(modeDefinitions, function (modeDef) {
            switch (modeDef.type) {
                case warmupUtils.siteConstants.COMP_MODES_TYPES.DEFAULT:
                case warmupUtils.siteConstants.COMP_MODES_TYPES.HOVER:
                    hoverAndDefaultModes.push(modeDef.modeId);
                    break;
                case warmupUtils.siteConstants.COMP_MODES_TYPES.SCROLL:
                    scrollModesIds.push(modeDef.modeId);
                    break;
                case warmupUtils.siteConstants.COMP_MODES_TYPES.WIDTH:
                    widthModesIds.push(modeDef.modeId);
                    break;
                default:
                    otherModeIds.push(modeDef.modeId);
            }
        });

        const isHiddenPredicate = _.partial(isHiddenInMode, compModes.overrides);
        const isExplicitlyDisplayedPredicate = _.partial(isExplicitlyDisplayedInMode, compModes.overrides);
        if (compModes.isHiddenByModes) {
            return hoverAndDefaultModes.length && !_.some(hoverAndDefaultModes, isExplicitlyDisplayedPredicate) || // eslint-disable-line no-mixed-operators
                scrollModesIds.length && !_.some(scrollModesIds, isExplicitlyDisplayedPredicate) || // eslint-disable-line no-mixed-operators
                widthModesIds.length && !_.some(widthModesIds, isExplicitlyDisplayedPredicate) || // eslint-disable-line no-mixed-operators
                otherModeIds.length && !_.some(otherModeIds, isExplicitlyDisplayedPredicate); // eslint-disable-line no-mixed-operators
        }

        return hoverAndDefaultModes.length && _.every(hoverAndDefaultModes, isHiddenPredicate) || // eslint-disable-line no-mixed-operators
            scrollModesIds.length && _.every(scrollModesIds, isHiddenPredicate) || // eslint-disable-line no-mixed-operators
            widthModesIds.length && _.every(widthModesIds, isHiddenPredicate) || // eslint-disable-line no-mixed-operators
            otherModeIds.length && _.every(otherModeIds, isHiddenPredicate); // eslint-disable-line no-mixed-operators
    }

    function isHiddenInMode(compOverrides, modeId) {
        const override = _.find(compOverrides, function (compOverride) {
            return _.isEqual([modeId], compOverride.modeIds);
        });
        return override && override.isHiddenByModes;
    }

    function isExplicitlyDisplayedInMode(compOverrides, modeId) {
        const override = _.find(compOverrides, function (compOverride) {
            return _.isEqual([modeId], compOverride.modeIds);
        });
        return override && override.isHiddenByModes === false;
    }

    function getActiveModesForActivationChange(modesDefinitions, currentActiveModes, modeToActivate, modeToDeactivate) { // eslint-disable-line complexity
        const activeModes = _.clone(currentActiveModes);
        const scrollModes = _.filter(modesDefinitions, {type: warmupUtils.siteConstants.COMP_MODES_TYPES.SCROLL});
        const widthModes = _.filter(modesDefinitions, {type: warmupUtils.siteConstants.COMP_MODES_TYPES.WIDTH});
        const defaultMode = _.find(modesDefinitions, {type: warmupUtils.siteConstants.COMP_MODES_TYPES.DEFAULT});
        const hoverMode = _.find(modesDefinitions, {type: warmupUtils.siteConstants.COMP_MODES_TYPES.HOVER});

        if (modeToActivate) {
            activeModes[modeToActivate.modeId] = getActiveModeData(modeToActivate);
            switch (modeToActivate.type) {
                case warmupUtils.siteConstants.COMP_MODES_TYPES.SCROLL:
                    _.forEach(scrollModes, function (modeDef) {
                        if (modeDef.modeId !== modeToActivate.modeId) {
                            delete activeModes[modeDef.modeId];
                        }
                    });
                    break;
                case warmupUtils.siteConstants.COMP_MODES_TYPES.WIDTH:
                    _.forEach(widthModes, function (modeDef) {
                        if (modeDef.modeId !== modeToActivate.modeId) {
                            delete activeModes[modeDef.modeId];
                        }
                    });
                    break;
                case warmupUtils.siteConstants.COMP_MODES_TYPES.DEFAULT:
                    delete activeModes[hoverMode.modeId];
                    break;
                case warmupUtils.siteConstants.COMP_MODES_TYPES.HOVER:
                    if (defaultMode) {
                        delete activeModes[defaultMode.modeId];
                    }
                    break;
            }
        }
        if (modeToDeactivate) {
            let fallbackMode;
            delete activeModes[modeToDeactivate.modeId];

            switch (modeToDeactivate.type) {
                case warmupUtils.siteConstants.COMP_MODES_TYPES.SCROLL:
                    if (!modeToActivate || modeToActivate.type !== warmupUtils.siteConstants.COMP_MODES_TYPES.SCROLL) {
                        fallbackMode = _.head(scrollModes);
                    }
                    break;
                case warmupUtils.siteConstants.COMP_MODES_TYPES.WIDTH:
                    if (!modeToActivate || modeToActivate.type !== warmupUtils.siteConstants.COMP_MODES_TYPES.WIDTH) {
                        fallbackMode = _.head(widthModes);
                    }
                    break;
                case warmupUtils.siteConstants.COMP_MODES_TYPES.DEFAULT:
                    fallbackMode = hoverMode;
                    break;
                case warmupUtils.siteConstants.COMP_MODES_TYPES.HOVER:
                    if (defaultMode) {
                        fallbackMode = defaultMode;
                    }
                    break;
            }

            if (fallbackMode) {
                activeModes[fallbackMode.modeId] = getActiveModeData(fallbackMode);
            }
        }

        return activeModes;
    }

    /**
     * @class modesUtils
     */
    return {
        getActiveModeData,

        getActiveComponentModeIds(activeModes, compModeDefinitions) {
            return _(activeModes)
                .mapValues(function (modeData, modeId) {
                    return _.some(compModeDefinitions, {modeId});
                })
                .pickBy()
                .value();
        },

        getModeChanges(prevRootActiveModes, nextRootActiveModes) {
            prevRootActiveModes = prevRootActiveModes || {};
            nextRootActiveModes = nextRootActiveModes || {};
            const allRootActiveModes = _({})
                .assign(prevRootActiveModes, nextRootActiveModes)
                .keys()
                .value();

            return _.transform(allRootActiveModes, function (accu, modeId) {
                const wasActive = prevRootActiveModes[modeId];
                const isActive = nextRootActiveModes[modeId];

                if (wasActive && !isActive) {
                    accu[modeId] = false;
                } else if (!wasActive && isActive) {
                    accu[modeId] = true;
                }
            }, {});
        },

        /**
         *
         * @param {ps.pointers} pointers
         * @param {ps.dal.full} fullJsonDal
         * @param {Pointer} compPointer
         * @param {Pointer} ancestorWithActiveMode
         * @param {string} modeIdToRemoveFrom
         * @returns {boolean}
         */
        isCompVisibleAfterRemovalFromMode(pointers, fullJsonDal, compPointer, ancestorWithActiveMode, modeIdToRemoveFrom) {
            const compModesPointer = pointers.componentStructure.getModes(compPointer);
            const compModes = fullJsonDal.isExist(compModesPointer) && fullJsonDal.get(compModesPointer);
            if (!compModes) {
                return true;
            }

            const ancestorModeDefinitionsPointer = pointers.componentStructure.getModesDefinitions(ancestorWithActiveMode);
            const ancestorModeDefinitions = fullJsonDal.get(ancestorModeDefinitionsPointer);

            const compModesAfterDeletion = warmupUtils.objectUtils.cloneDeep(compModes);
            const relevantOverrideIndex = _.findIndex(compModes.overrides, function (override) {
                return _.isEqual(override.modeIds, [modeIdToRemoveFrom]);
            });
            compModesAfterDeletion.overrides = compModesAfterDeletion.overrides || [];
            if (relevantOverrideIndex >= 0) {
                compModesAfterDeletion.overrides[relevantOverrideIndex].isHiddenByModes = true;
            } else {
                compModesAfterDeletion.overrides.push({
                    modeIds: [modeIdToRemoveFrom],
                    isHiddenByModes: true
                });
            }

            return !isAlwaysHiddenInModes(ancestorModeDefinitions, compModesAfterDeletion);
        },

        getActiveModesForActivationChange,

        resolveCompActiveModesRecursive
    };
});
