import _ from 'lodash'
import * as constants from 'santa-renderer/src/utils/constants'

export const name = 'WindowObjectAspect'

export const functionLibrary = {
    setWindowLocationHref: (windowObject, target) => {
        windowObject.location.href = target
    },
    reload: windowObject => {
        windowObject.location.reload()
    },
    windowOpen(windowObject, URL, windowName, specs, replace) {
        windowObject.open(URL, windowName, specs, replace)
    },
    flushPostMessages(windowObj, emitEvent) {
        if (windowObj.removeEventListener) {
            windowObj.removeEventListener('message', windowObj.messageHandler, false)
        }
        _.forEach(windowObj.messageBuffer, event => {
            emitEvent('windowMessage', event)
        })
        windowObj.messageBuffer = []
    },
    getParentWindow(windowObj) {
        return windowObj && (windowObj.parent === windowObj ? null : windowObj.parent)
    },
    postWindowMessage(windowObj, data, origin) {
        _.invoke(windowObj, 'postMessage', data, origin)
    },

    getCurrentPosition: windowObject =>
        new Promise((resolve, reject) => {
            const navigator = _.get(windowObject, 'navigator')
            if (navigator) {
                navigator.geolocation.getCurrentPosition(resolve, reject)
            }
        })
}

export const defaultModel = {
    orientation: 'portrait',
    readyToFlush: false
}

export function init({updateOrientation}, {eventsManager, initialData: {windowObject}}) {
    if (!windowObject) {
        return
    }

    windowObject.addEventListener('scroll', () => eventsManager.emit('windowScroll', windowObject))
    windowObject.addEventListener('blur', () => eventsManager.emit('windowBlur'))
    windowObject.addEventListener('focus', () => eventsManager.emit('windowFocus'))
    windowObject.addEventListener('message', messageEvent => eventsManager.emit('windowMessage', messageEvent))
    windowObject.addEventListener('resize', () => eventsManager.emit(constants.EVENTS.WINDOW_RESIZE))
    const query = windowObject.matchMedia('(orientation:portrait)')

    const currentOrientation = query.matches ? 'portrait' : 'landscape'
    if (currentOrientation !== defaultModel.orientation) {
        updateOrientation(currentOrientation)
    }

    query.addListener(() => updateOrientation(query.matches ? 'portrait' : 'landscape'))

    windowObject.document.addEventListener('visibilitychange', event => eventsManager.emit('visibilityChange', event))

    windowObject.addEventListener('touchstart', e => eventsManager.emit('windowTouchStart', e))
    windowObject.addEventListener('touchmove', e => eventsManager.emit('windowTouchMove', e))
    windowObject.addEventListener('touchend', e => eventsManager.emit('windowTouchEnd', e))
    windowObject.addEventListener('touchcancel', e => eventsManager.emit('windowTouchCancel', e))
    windowObject.addEventListener('keydown', keyDownEvent => eventsManager.emit('windowKeyDown', keyDownEvent))
    windowObject.addEventListener('keyup', keyUpEvent => eventsManager.emit('windowKeyUp', keyUpEvent))

    windowObject.document.addEventListener('keyup', keyUpEvent => eventsManager.emit('documentKeyUp', keyUpEvent))
    windowObject.document.addEventListener('blur', keyUpEvent => eventsManager.emit('documentBlur', keyUpEvent))
    windowObject.document.addEventListener('focus', keyUpEvent => eventsManager.emit('documentFocus', keyUpEvent))
    windowObject.document.addEventListener('click', documentClickEvent => eventsManager.emit('documentClick', documentClickEvent))
}
