define([
    'coreUtils',
    'componentsCore'
], function (
    coreUtils,
    componentsCore
) {
    'use strict';

    /**
     * @class components.BgImageStrip
     * @extends {core.skinBasedComp}
     */

    const bgImageStrip = {
        displayName: 'BgImageStrip',
        mixins: [componentsCore.mixins.skinBasedComp],
        getSkinProperties() {
            return {
                'bg': {
                    style: {},
                    'data-align-type': this.props.compProp.alignType,
                    'data-fitting-type': this.props.compProp.fittingType,
                    'data-type': coreUtils.mediaConsts.balataConsts.BG_IMAGE
                }
            };
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.BgImageStrip', bgImageStrip);

    return bgImageStrip;
});
