import {withActions} from 'carmi-host-extensions/src/aspects/withActions'

export const name = 'designDataChangeAspect'
export const STORE_NS = 'cssScaleTransition'

export const functionLibrary = {
    triggerAnimationOnChange: withActions(({updateCssScaleTransition}, compId, transition) => {
        if (transition) {
            updateCssScaleTransition(compId, transition)
        }
    })
}

export const defaultModel = {
    [STORE_NS]: {}
}
