define([
    'lodash',
    'warmupUtils/pointers/pointerGeneratorsRegistry'
], function (_, pointerGeneratorsRegistry) {
    'use strict';

    const POINTER_TYPE = 'displayedOnlyComponents';

    pointerGeneratorsRegistry.registerPointerType(POINTER_TYPE, _.constant(null), _.constant(true));

    const getterFunctions = {
        getTemplateIdMap(getItemAt, cache) {
            return cache.getPointer(POINTER_TYPE, POINTER_TYPE, [POINTER_TYPE, 'componentTemplateId']);
        },
        getComponentTemplateId(getItemAt, cache, compId) {
            return cache.getPointer(`${POINTER_TYPE}_${compId}`, POINTER_TYPE, [POINTER_TYPE, 'componentTemplateId', compId]);
        }
    };

    pointerGeneratorsRegistry.registerDataAccessPointersGenerator(POINTER_TYPE, getterFunctions);
});
