define([
    'lodash',
    'coreUtils/core/anchorsGenerator',
    'coreUtils/core/skinAnchorsMetaData'
], function (_,
             anchorsGenerator,
             skinAnchorsMetaData) {
    'use strict';

    /**
     * @class layoutAnchorsUtils
     */
    return {
        createPageAnchors: anchorsGenerator.createPageAnchors,
        createMobileTightMasterPageAnchors: anchorsGenerator.createMobileTightMasterPageAnchors,
        createMobileTightSectionsAnchors: anchorsGenerator.createMobileTightSectionsAnchors,
        createChildrenAnchors: anchorsGenerator.createChildrenAnchors,
        getNonAnchorableHeightForSkin: skinAnchorsMetaData.getNonAnchorableHeightForSkin
    };
});
