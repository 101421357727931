define([
    'coreUtils'
], function (
    coreUtils
) {
    'use strict';

    const checkerMap = {};
    const allCompsOfTypeCheckerMap = {};

    coreUtils.sessionFreezer.freeze(allCompsOfTypeCheckerMap);
    coreUtils.sessionFreezer.freeze(checkerMap);

    const registerCheckerForCompType = (type, requestsGetter) => {
        checkerMap[type] = requestsGetter;
    };

    /**
     *
     * @param {string} type
     * @param {function} requestGetter
     */
    const registerCheckerForAllCompsOfType = (type, requestGetter) => {
        allCompsOfTypeCheckerMap[type] = requestGetter;
    };

    const getCheckerForCompType = type => checkerMap[type];

    const getAllCompsOfTypeChecker = type => allCompsOfTypeCheckerMap[type];

    return {
        registerCheckerForCompType,
        registerCheckerForAllCompsOfType,
        getAllCompsOfTypeChecker,
        getCheckerForCompType
    };
});
