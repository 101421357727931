'use strict'
const name = 'SpinIn'
const properties = {
    hideOnStart: true,
    viewportThreshold: 0.15
}

const paramsMap = {
    cw: {direction: '-1'},
    ccw: {direction: '1'}
}
const scaleMap = {
    soft: 0.8,
    medium: 0.5,
    hard: 0
}

function register({factory}) {
    /**
     * SpinIn animation object
     * @param {Array<HTMLElement>|HTMLElement} elements DOM element to animate
     * @param {Number} [duration]
     * @param {Number} [delay]
     * @param {Object} [params]
     * @param {'cw'|'ccw'} [direction=cw] 'cw' for clock wise or 'ccw' for counter clock wise
     * @param {Number} [cycles=5]
     * @param {'soft'|'medium'|'hard'} [power='hard']
     * @returns {TimelineMax}
     */
    function animation(elements, duration, delay, {direction = 'cw', cycles = 5, power = 'hard', ...params} = {}) {
        const scale = scaleMap[power]
        const fromParams = paramsMap[direction]
        const transformRotate = (fromParams.direction > 0 ? '+=' : '-=') + 360 * cycles

        const sequence = factory.sequence(params)

        sequence.add([
            factory.animate('BaseFade', elements, duration, delay, {from: {opacity: 0}, to: {opacity: 1}, ease: 'Sine.easeIn'}),
            factory.animate('BaseScale', elements, duration, delay, {from: {scale}, ease: 'Sine.easeOut'}),
            factory.animate('BaseRotate', elements, duration, delay, {from: {rotation: transformRotate}, ease: 'Sine.easeIn'})
        ])

        return sequence.get()
    }

    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}
