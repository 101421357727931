import {withActions} from 'carmi-host-extensions'
export const name = 'QuickActionBarAspect'
export const defaultModel = {
    dynamicActions: []
}
export const functionLibrary = {
    throwIfUndefined: (existingAction, appId) => {
        if (!existingAction) {
            throw new Error(`failed to add unsupported dynamic actions with app ids: "${appId}"`)
        }
        return true
    },
    spliceDynamicActions: withActions((aspectActions, startIndex, numberOfActionsToRemove, action) => aspectActions.spliceDynamicActions(startIndex, numberOfActionsToRemove, action))
}
