/* eslint-disable */
define([
    'lodash',
    'santa-core-utils',
    'componentsCore',
    'tpaComponents/mixins/tpaCompBaseMixin',
    'tpaComponents/mixins/tpaUrlBuilderMixin',
    'tpaComponents/mixins/tpaCompApiMixin',
    'tpaComponents/mixins/tpaWidgetMixin',
    'tpaComponents/mixins/tpaResizeWindowMixin'

], function (_, coreUtilsLib, componentsCore, tpaCompBaseMixin, tpaUrlBuilderMixin, tpaCompApiMixin, tpaWidgetMixin, tpaResizeWindowMixin) {
    'use strict';
    /* eslint-enable */

    const compRegistrar = componentsCore.compRegistrar;

    /**
     * @class components.TPAPreloader
     * @extends {componentsCore.skinBasedComp}
     * @extends {tpa.mixins.tpaCompBase}
     */
    const tpaGluedWidget = {
        displayName: 'TPAGluedWidget',
        statics: {
            behaviors: {
                notifyWidget: {methodName: 'notifyWidgetInternal', params: ['action']}
            }
        },
        mixins: [componentsCore.mixins.skinBasedComp, coreUtilsLib.timersMixins.timeoutsMixin, tpaCompBaseMixin, tpaUrlBuilderMixin, tpaCompApiMixin, tpaWidgetMixin, tpaResizeWindowMixin],
        isZeroHeightAllowed() {
            return true;
        },
        mutateIframeUrlQueryParam(queryParamsObj) {
            queryParamsObj.width = this.state.initialWidth; // eslint-disable-line santa/no-side-effects

            return queryParamsObj;
        },
        mutateSkinProperties(skinProps) {
            return _.merge(skinProps, {'': {
                'data-has-ads': this.props.shouldShowWixAds,
                'data-horizontal-margin': this.props.compProp.horizontalMargin,
                'data-vertical-margin': this.props.compProp.verticalMargin,
                'data-placement': this.props.compProp.placement
            }});
        },
        notifyWidgetInternal(actionName) {
            this.sendPostMessage({
                intent: 'addEventListener',
                eventType: 'QUICK_ACTION_TRIGGERED',
                params: {
                    quickAction: actionName
                }
            });
        }
    };

    compRegistrar.register('wysiwyg.viewer.components.tpapps.TPAGluedWidget', tpaGluedWidget);
    return tpaGluedWidget;
});
