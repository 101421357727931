define([
    'warmupUtilsLib', 'lodash', 'experiment', 'warmupUtils/wixUrlParser/utils/urlParserUtils'
], function (warmupUtilsLib, _, experiment, urlParserUtils) {
    'use strict';

    const BLOG_SINGLE_POST_APP_ID = '7326bfbb-4b10-4a8e-84c1-73f776051e10';

    function getSimplyParsedUrl(url) {
        let urlObject;
        if (typeof url === 'string') {
            urlObject = warmupUtilsLib.urlUtils.parseUrl(url);
        } else if (url.full) {
            urlObject = url;
        } else {
            warmupUtilsLib.log.error('url to parse has to be either a string or an object');
        }
        return urlObject;
    }

    function isPage(siteData, pageId) {
        return _.includes(siteData.allPageIds, pageId);
    }

    function getPageInfoFromHash(hashString, siteData) { // eslint-disable-line complexity
        const pageInfo = {};
        const imageZoomMatch = hashString.match(/#!(.*?)\/zoom[\/\|](.+?)\/([^\/]+)$/i);

        if (imageZoomMatch) {
            if (imageZoomMatch[1]) {
                pageInfo.title = imageZoomMatch[1];
            }
            pageInfo.pageId = imageZoomMatch[2];
            pageInfo.pageItemId = imageZoomMatch[3];
            pageInfo.imageZoom = true;
        } else {
            const match = hashString.match(/#!(.*?)[\/\|]([^\/]+)\/?(.*$)/i);

            if (match) {
                const title = match[1];
                const itemId = match[2];
                const extraData = match[3];

                if (title) {
                    pageInfo.title = title;
                }

                if (isPage(siteData, itemId)) {
                    pageInfo.pageId = itemId;
                } else { //wixapps zoom
                    pageInfo.pageId = siteData.primaryPageId || siteData.mainPageId; // if old eCom gallery is placed inside a popup, the popup will close
                    pageInfo.pageItemId = itemId;
                }

                if (extraData) {
                    pageInfo.tpaInnerRoute = extraData;
                    pageInfo.pageAdditionalData = extraData;
                }

                return pageInfo;
            }

            return {pageId: siteData.mainPageId};
        }

        return pageInfo;
    }

    function getCurrentQueryParams(siteData, removeQueryParams) {
        const whitelist = ['EditorSource', 'debug', 'debugViewer', 'experiments', 'experimentsoff', 'isqa', 'ReactSource', 'petri_ovr', 'suppressbi', 'lang', 'inBizMgr'];

        if (!removeQueryParams || !_(siteData.currentUrl.query).keys().intersection(whitelist).isEmpty()) {
            return siteData.currentUrl.query;
        }
        return [];
    }

    function concatUrl(siteData, navigationInfo, cleanQuery, baseUrl, dismissQueryParamList) { // eslint-disable-line complexity
        const currentUrl = siteData.currentUrl;
        let url = baseUrl || siteData.externalBaseUrl;
        dismissQueryParamList = dismissQueryParamList || [];
        url = url.replace(`.${warmupUtilsLib.siteConstants.FREE_DOMAIN.WIXSITE}/`, `.${warmupUtilsLib.siteConstants.FREE_DOMAIN.WIX}/`);

        const removeQueryParams = experiment.isOpen('removeQueryParams', siteData);
        const navigationQuery = navigationInfo.queryParams || navigationInfo.query || {};

        let queryParams = _.assign({}, getCurrentQueryParams(siteData, removeQueryParams), navigationQuery);

        if (!removeQueryParams) {
            queryParams = _.omit(queryParams, dismissQueryParamList);
        }

        if ((removeQueryParams || !cleanQuery) && !_.isEmpty(queryParams)) {
            url += `?${warmupUtilsLib.urlUtils.toQueryString(queryParams)}`;
        }

        currentUrl.url = url;

        if (navigationInfo.id) {
            url += `#!${navigationInfo.title}/${navigationInfo.id}`;
        }

        if (navigationInfo.additionalData) {
            url += `/${navigationInfo.additionalData}`;
        }

        return url;
    }


    /**
     *
     * @param {core.SiteData} siteData
     * @param {string|object} url
     * @returns {{
     *      pageTitle: string,
     *      pageId: string,
     *      pageItemId: ?string,
     *      pageAdditionalData: ?string
     * }}
     */
    function parseUrl(siteData, url) { // eslint-disable-line complexity
        if (!url) {
            return null;
        }

        let urlObject = url === '#' ? getSimplyParsedUrl(siteData.currentUrl) : getSimplyParsedUrl(url);
        const isLocalDevMode = urlObject.hostname === 'localhost';

        if (isExternalUrl(siteData, urlObject) && !isLocalDevMode) {
            if (siteData.currentUrl.full === urlObject.full && !_.includes(urlObject.full, '#')) { //support for !Wix! landing pages with aliases
                return {
                    pageId: siteData.mainPageId
                };
            }
            return null; // external url
        }

        if (_.isString(url) && url.indexOf('#!') === 0) {
            urlObject = _.clone(siteData.currentUrl);
            urlObject.hash = url;
        }

        return getPageInfoFromHash(urlObject.hash, siteData);
    }

    function isExternalUrl(siteData, urlObject) {
        return !(warmupUtilsLib.urlUtils.isHostnameYandexWebvisor(urlObject.hostname) || isUrlOnSameDomain(siteData, urlObject));
    }

    function isBlogSinglePostPage(siteData, pageId) {
        const pageDataItem = siteData.pagesDataItemsMap[pageId];
        return pageDataItem && pageDataItem.type === 'AppPage' &&
            pageDataItem.appPageType === 'AppPage' &&
            pageDataItem.appPageId === BLOG_SINGLE_POST_APP_ID;
    }

    function withoutProtocol(url) {
        return url && url.replace(/^https?:\/\//, '');
    }

    /**
     * Checks if provided url belongs to the same Wix site
     * @param {core.SiteData} siteData
     * @param {object} candidateUrlObj Parsed url Object
     * @returns {Boolean}
     */

    function isUrlOnSameDomain(siteData, candidateUrlObj) {
        const candidateSiteUrlBase = candidateUrlObj.hostname + candidateUrlObj.path;
        return warmupUtilsLib.urlUtils.isSame(candidateSiteUrlBase, withoutProtocol(siteData.externalBaseUrl)) ||
            warmupUtilsLib.urlUtils.isSame(candidateSiteUrlBase, withoutProtocol(siteData.unicodeExternalBaseUrl));
    }

    /**
     *
     * @param {core.SiteData} siteData
     * @param {{
     *      pageId: string,
     *      title: string,
     *      pageItemId: ?string,
     *      pageAdditionalData: ?string
     * }} pageInfo there can be pageId and pageItemId only if image zoom
     * @param {bool} forceAddHash - force add to the url the string after the #
     * @returns {string}
     */
    function getUrl(siteData, pageInfo, forceAddHash, cleanQuery, baseUrl, urlMapping, dismissQueryParamList) { // eslint-disable-line complexity
        const navigationInfo = {
            title: pageInfo.title || warmupUtilsLib.siteConstants.DEFAULT_PAGE_URI_SEO,
            queryParams: pageInfo.queryParams
        };

        if (pageInfo.pageItemId && !pageInfo.pageAdditionalData && pageInfo.imageZoom) {
            navigationInfo.id = 'zoom';
            navigationInfo.additionalData = `${pageInfo.pageId}/${pageInfo.pageItemId}`;
        } else if (pageInfo.pageId !== siteData.mainPageId || pageInfo.pageAdditionalData || pageInfo.tpaInnerRoute || forceAddHash) {
            navigationInfo.id = pageInfo.pageItemId || pageInfo.pageId;
            navigationInfo.additionalData = pageInfo.tpaInnerRoute || pageInfo.pageAdditionalData;
        }

        if (isBlogSinglePostPage(siteData, pageInfo.pageId) && urlMapping) {
            const pageAdditionalData = pageInfo.pageAdditionalData || '';
            const map = urlMapping[pageAdditionalData] || urlMapping[decodeURIComponent(pageAdditionalData)] || {};
            navigationInfo.id = pageInfo.pageId;
            navigationInfo.title = map.title;
            navigationInfo.additionalData = map.id;
        }

        return concatUrl(siteData, navigationInfo, cleanQuery, baseUrl, dismissQueryParamList);
    }


    function parseUrlWithResolvedSiteData(siteData, url) {
        const resolvedSiteData = urlParserUtils.getResolvedSiteData(siteData);

        return parseUrl(resolvedSiteData, url);
    }

    function getUrlWithResolvedSiteData(siteData, pageInfo, forceAddHash, cleanQuery, baseUrl, urlMapping, _rawSiteData, dismissQueryParamList) {
        const resolvedSiteData = urlParserUtils.getResolvedSiteData(siteData);

        return getUrl(resolvedSiteData, pageInfo, forceAddHash, cleanQuery, baseUrl, urlMapping, dismissQueryParamList);
    }

    function isUrlToCurrentSite(siteData, url) {
        if (url === '#') {
            return true;
        }
        const urlObject = warmupUtilsLib.urlUtils.parseUrl(url);
        const resolvedSiteData = urlParserUtils.getResolvedSiteData(siteData);
        return isUrlOnSameDomain(resolvedSiteData, urlObject);
    }

    /** @class core.wixUrlParser */
    return {
        parseUrl: parseUrlWithResolvedSiteData,
        getUrl: getUrlWithResolvedSiteData,
        isUrlToCurrentSite
    };
});
