/* Autogenerated file. Do not modify */
'use strict'
const skins = {}
 skins['wysiwyg.viewer.skins.WRichTextClickableSkin'] = {
  "react": [
    [
      "div",
      "richTextContainer",
      [
        "_richTextContainer"
      ],
      {}
    ]
  ],
  "css": {
    "%richTextContainer": "position:relative;width:100%;height:100%;word-wrap:break-word;",
    "%richTextContainer%_override-left *": "text-align:left !important;",
    "%richTextContainer%_override-right *": "text-align:right !important;",
    "%richTextContainer%_override-center *": "text-align:center !important;",
    "%richTextContainer%_override-justify *": "text-align:justify !important;",
    "%richTextContainer ol,%richTextContainer ul": "padding-left:1.3em;margin-left:.5em;line-height:normal;letter-spacing:normal;",
    "%richTextContainer ol[dir=\"rtl\"],%richTextContainer ul[dir=\"rtl\"]": "padding-right:1.3em;margin-right:.5em;",
    "%richTextContainer ul": "list-style-type:disc;",
    "%richTextContainer ol": "list-style-type:decimal;",
    "%richTextContainer ul[dir=\"rtl\"]": "padding-right:1.3em;margin-right:.5em;",
    "%richTextContainer ol[dir=\"rtl\"]": "padding-right:1.3em;margin-right:.5em;",
    "%richTextContainer ul ul": "list-style-type:circle;line-height:normal;",
    "%richTextContainer ol ul": "list-style-type:circle;line-height:normal;",
    "%richTextContainer ol ol ul": "list-style-type:square;line-height:normal;",
    "%richTextContainer ol ul ul": "list-style-type:square;line-height:normal;",
    "%richTextContainer ul ol ul": "list-style-type:square;line-height:normal;",
    "%richTextContainer ul ul ul": "list-style-type:square;line-height:normal;",
    "%richTextContainer li": "font-style:inherit;font-weight:inherit;line-height:inherit;letter-spacing:normal;",
    "%richTextContainer p": "margin:0;line-height:normal;letter-spacing:normal;",
    "%richTextContainer h1": "margin:0;line-height:normal;letter-spacing:normal;",
    "%richTextContainer h2": "margin:0;line-height:normal;letter-spacing:normal;",
    "%richTextContainer h3": "margin:0;line-height:normal;letter-spacing:normal;",
    "%richTextContainer h4": "margin:0;line-height:normal;letter-spacing:normal;",
    "%richTextContainer h5": "margin:0;line-height:normal;letter-spacing:normal;",
    "%richTextContainer h6": "margin:0;line-height:normal;letter-spacing:normal;",
    "%richTextContainer a": "color:inherit;"
  }
}
 skins['wysiwyg.viewer.skins.WRichTextNewSkin'] = {
  "react": [],
  "css": {
    "%": "word-wrap:break-word;text-align:start;",
    "%_override-left *": "text-align:left !important;",
    "%_override-right *": "text-align:right !important;",
    "%_override-center *": "text-align:center !important;",
    "%_override-justify *": "text-align:justify !important;",
    "% > *": "pointer-events:auto;",
    "% li": "font-style:inherit;font-weight:inherit;line-height:inherit;letter-spacing:normal;",
    "% ol,% ul": "padding-left:1.3em;padding-right:0;margin-left:0.5em;margin-right:0;line-height:normal;letter-spacing:normal;",
    "% ul": "list-style-type:disc;",
    "% ol": "list-style-type:decimal;",
    "% ul ul,% ol ul": "list-style-type:circle;",
    "% ul ul ul,% ol ul ul": "list-style-type:square;",
    "% ul ol ul,% ol ol ul": "list-style-type:square;",
    "% ul[dir=\"rtl\"],% ol[dir=\"rtl\"]": "padding-left:0;padding-right:1.3em;margin-left:0;margin-right:0.5em;",
    "% ul[dir=\"rtl\"] ul,% ul[dir=\"rtl\"] ol,% ol[dir=\"rtl\"] ul,% ol[dir=\"rtl\"] ol": "padding-left:0;padding-right:1.3em;margin-left:0;margin-right:0.5em;",
    "% p": "margin:0;line-height:normal;letter-spacing:normal;",
    "% h1": "margin:0;line-height:normal;letter-spacing:normal;",
    "% h2": "margin:0;line-height:normal;letter-spacing:normal;",
    "% h3": "margin:0;line-height:normal;letter-spacing:normal;",
    "% h4": "margin:0;line-height:normal;letter-spacing:normal;",
    "% h5": "margin:0;line-height:normal;letter-spacing:normal;",
    "% h6": "margin:0;line-height:normal;letter-spacing:normal;",
    "% a": "color:inherit;"
  }
}
 skins['wysiwyg.viewer.skins.WRichTextSkin'] = {
  "react": [
    [
      "div",
      "richTextContainer",
      [
        "_richTextContainer"
      ],
      {}
    ]
  ],
  "css": {
    "%richTextContainer": "position:relative;width:100%;height:100%;word-wrap:break-word;",
    "%richTextContainer%_override-left *": "text-align:left !important;",
    "%richTextContainer%_override-right *": "text-align:right !important;",
    "%richTextContainer%_override-center *": "text-align:center !important;",
    "%richTextContainer%_override-justify *": "text-align:justify !important;",
    "%richTextContainer ul": "list-style:disc inside;",
    "%richTextContainer li": "margin-bottom:12px;"
  }
}

module.exports = skins