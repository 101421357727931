define(['santa-components', 'lodash', 'componentsCore/registrars/compRegistrar'], function (santaComponents, _, compRegistrar) {
    'use strict';

    function createRRChildProps(itemData, className, compClass, skinPartName, extraProperties) { // eslint-disable-line complexity
        extraProperties = extraProperties || {};
        const skinExports = this.getSkinExports();
        const styleData = typeof skinPartName === 'string' ? {
            skin: skinExports[skinPartName].skin,
            styleId: this.props.styleId + skinPartName
        } : skinPartName;
        const props = {
            compProp: _.omit(this.props.compProp, ['isHidden', 'isCollapsed']),
            compData: itemData,
            skin: styleData.skin,
            styleId: styleData.styleId
        };

        if (!itemData && !extraProperties.id) {
            throw new Error('Unable to set child comp id - no data item\\custom id were passed');
        }
        props.id = extraProperties.id || this.props.id + itemData.id;

        if (!itemData && !extraProperties.ref) {
            throw new Error('Unable to set child comp ref - no data item\\custom ref were passed');
        }
        props.ref = extraProperties.ref || itemData.id;

        // partial structure to be used by QA renderer plugin
        props.structure = {
            componentType: className,
            styleId: this.props.structure ? this.props.structure.styleId : '',
            skinPart: extraProperties.skinPart || props.ref
        };
        const childCompSantaTypes = santaComponents.utils.santaTypesUtils.getSantaTypesForChildComponentClass(compClass, santaComponents.santaTypesDefinitions);
        const childSantaTypesProps = _.pick(this.props, _.keys(childCompSantaTypes));

        return _.assign(childSantaTypesProps, props, _.omit(extraProperties, 'structure'));
    }

    return {
        propTypes: {
            compFactoryRuntimeState: santaComponents.santaTypesDefinitions.compFactoryRuntimeState
        },

        /**
         *
         * @param {Object} itemData
         * @param {string} className
         * @param {(string|{skin: string, styleId: string})} skinPartName the skinPart name that is used in skin exports.
         * pass and object if you need to pass custom styleId and skin name
         * @param {Object} extraProperties
         * @returns {ReactCompositeComponent}
         */
        createChildComponent: function createChildComponent(itemData, className, skinPartName, extraProperties) {
            const compClass = compRegistrar.getCompReactClass(className, false, this.props.compFactoryRuntimeState);
            if (!compClass) {
                return null;
            }

            const compClassFactory = compRegistrar.getCompClass(className, true, this.props.compFactoryRuntimeState);
            return compClassFactory(createRRChildProps.call(this, itemData, className, compClass, skinPartName, extraProperties));
        }
    };
});
