const updateHistory = (navInfo, url, protocol) => {
    const primaryPage = navInfo.primaryPage
    const {skipHistory = false, replaceHistory = false} = primaryPage
    if (replaceHistory) {
        window.history.replaceState(null, primaryPage.title, url.replace(/https?:/, protocol))
    } else if (!skipHistory) {
        window.history.pushState(null, primaryPage.title, url.replace(/https?:/, protocol))
    }
}

module.exports = {
    initiateNavigation(setNavigationInfos, setBoltInstanceNavigateCallback, setBoltInstanceRetryNavigateCallback, callback, retryCallback, navInfos) {
        setNavigationInfos(navInfos)
        setBoltInstanceNavigateCallback(callback)
        setBoltInstanceRetryNavigateCallback(retryCallback)
    },
    listenToHistory: (warmupUtils, getResolvedSiteData, setNavigationInfos, isInSSR) => {
        if (!isInSSR) {
            window.onpopstate = () => {
                const url = window.location.href
                const navigationInfo = warmupUtils.wixUrlParser.parseUrl(getResolvedSiteData(), url)
                navigationInfo.skipHistory = true
                setNavigationInfos({primaryPage: navigationInfo})
            }
        }
    },
    updateHistory,
    donePreparingNavigation: (
        boltInstance,
        navInfos,
        url,
        urlAsObj,
        boltInstanceNavigationCallback,
        setParsedUrl,
        stopWorkersFunction,
        platformModel
    ) => {
        if (!boltInstanceNavigationCallback) {
            return
        }
        //TODO: we need this becasue the dataRequirementState is changing to false and then back to true which triggers navigation again..
        const _ = require('lodash')
        const prevNavInfos = boltInstance.$model.navigationInfos
        if (_.isEqual(navInfos, prevNavInfos)) {
            return
        }


        stopWorkersFunction()
        setParsedUrl(urlAsObj)

        //we assume here that we cannot navigate to both popup and page and the same time
        if (!navInfos.popupPage) {
            updateHistory(navInfos, url, window.location.protocol)
        }

        boltInstance.setCurrentUrl(_.cloneDeep(urlAsObj))
        //we set each platform part separately cause it seems there is a bug in carmi
        const api = platformModel.wixCodeAppApi
        _.forOwn(api, (contextApi, contextId) => boltInstance.setAppApi(contextId, {...{}, ...contextApi}))

        boltInstance.setCurrentContexts(_.cloneDeep(platformModel.currentContexts))
        boltInstance.setContextsMap(_.cloneDeep(platformModel.contextsMap))
        boltInstance.setHasPlatform(_.cloneDeep(platformModel.hasPlatform))
        // boltInstance.setPlatformModel(clonePlatormModel(platformModel))
        boltInstanceNavigationCallback(_.cloneDeep(navInfos))
    }
}
