define(['lodash', 'zepto', 'layout/util/layout', 'layout/specificComponents/imageLayout', 'image-client-api', 'warmupUtilsLib'], function (_, $, /** layout.layout*/ layout, imageLayout, imageClientLib, warmupUtilsLib) {
    'use strict';

    const mediaZoomCalculations = warmupUtilsLib.mediaZoomCalculations;


    function getImageCompDataFromStructure(structureInfo) {
        return structureInfo.dataItem || structureInfo.structure.compData;
    }

    function getImageCompData(siteData, imageId) {
        return siteData.getNonPageItemZoomData() || siteData.getDataByQuery(imageId);
    }

    function getImageId(siteData) {
        const zoomedImageData = siteData.getNonPageItemZoomData();
        return siteData.getExistingRootNavigationInfo(siteData.getFocusedRootId()).pageItemId || zoomedImageData && zoomedImageData.id; // eslint-disable-line no-mixed-operators
    }

    /*The update of Dialog box Layout on client side render is on componentDidLayout of mediaZoom.js*/
    function patchMediaZoomImage(id, patchers, measureMap, structureInfo, siteData) {
        const imageId = getImageId(siteData);
        const isNonOptimizedView = !siteData.isMobileView() && siteData.isMobileDevice() || siteData.isTabletDevice(); // eslint-disable-line no-mixed-operators
        const compData = getImageCompData(siteData, imageId);

        const imageData = _.defaults({displayMode: imageClientLib.fittingTypes.LEGACY_FULL}, compData);
        imageData.quality = _.defaults({quality: 90}, imageData.quality || {});
        const zoomDimensions = measureMap.custom[id];
        const containerSize = {width: zoomDimensions.imageContainerWidth, height: zoomDimensions.imageContainerHeight};

        imageLayout.patchNodeImage(`${id + imageId}image`, patchers, measureMap, siteData, imageData, containerSize);
        // patch panel.
        if (zoomDimensions.hasPanel) {
            const panelWidth = isNonOptimizedView ? zoomDimensions.dialogBoxWidth : containerSize.width;
            patchers.css(`${id + compData.id}panel`, {width: panelWidth});
        }
        // patch dialog box
        const dialogBoxId = `${id}dialogBox`;
        patchers.css(dialogBoxId, {visibility: 'inherit'});
        if (siteData.isFirstRenderAfterSSR()) {
            patchers.css(dialogBoxId, {
                width: zoomDimensions.dialogBoxWidth,
                height: zoomDimensions.dialogBoxHeight,
                'margin-top': zoomDimensions.marginTop,
                'margin-left': zoomDimensions.marginLeft,
                padding: zoomDimensions.padding
            });
        }
    }

    function patchMobileViewMediaZoom(mediaZoomId, patchers, measureMap, structureInfo, siteData) {
        const zoomMeasure = measureMap.custom[mediaZoomId];

        const dialogBoxId = `${mediaZoomId}dialogBox`;
        patchers.css(dialogBoxId, {visibility: 'inherit'});
        patchers.css(dialogBoxId, {
            width: zoomMeasure.imageContainerWidth,
            minHeight: zoomMeasure.dialogBoxHeight,
            paddingTop: zoomMeasure.paddingTop
        });

        patchMediaZoomImage(mediaZoomId, patchers, measureMap, structureInfo, siteData);

        const imageId = getImageId(siteData);

        // handle the ellipsis ( we show an ellipsis when the text is bigger than 2 or 3 lines )
        if (zoomMeasure.showDescription && zoomMeasure.descriptionHeight > zoomMeasure.descriptionHeightLimit) {
            const descriptionId = `${mediaZoomId + imageId}description`;
            patchers.css(descriptionId, {height: zoomMeasure.descriptionHeightLimit});
            patchers.data(descriptionId, {expandable: 'true'});
            patchers.css(descriptionId, {height: zoomMeasure.descriptionHeightLimit});
            patchers.css(`${mediaZoomId + imageId}ellipsis`, {display: ''}); //equivalent to $(node).show()
        }
    }

    function measureMediaZoom(id, measureMap, nodesMap, isMobileView, isMobileDevice, isTabletDevice, siteWidth, getZoomedImageId, getZoomedImageCompData) {
        const imageId = getZoomedImageId();
        const compData = getZoomedImageCompData(imageId);
        const isNonOptimizedView = !isMobileView && isMobileDevice || isTabletDevice; // eslint-disable-line no-mixed-operators
        const getDimensionsFunc = isNonOptimizedView ?
            mediaZoomCalculations.getNonOptimizedViewDimensions : mediaZoomCalculations.getDesktopViewDimensions;

        const $mediaZoomContainer = $(nodesMap[id]);
        const $dialogBoxContainer = $(nodesMap[`${id}dialogBox`]);
        const dialogBoxPadding = getNodePadding($dialogBoxContainer);

        const panelId = `${id + imageId}panel`;
        const spacers = {
            width: parseInt($mediaZoomContainer.data('width-spacer'), 10),
            height: parseInt($mediaZoomContainer.data('height-spacer'), 10)
        };
        // TODO: refactor when implementing santaTypes in this file!
        const siteDataInformation = {
            isMobileDevice,
            isTabletDevice,
            siteWidth
        };
        // TODO: refactor when implementing santaTypes in this file!
        const screenMeasures = {
            width: measureMap.width.screen,
            height: measureMap.height.screen,
            innerHeight: measureMap.innerHeight.screen
        };
        measureMap.custom[id] = getDimensionsFunc(compData, siteDataInformation, screenMeasures, spacers, measureMap.height[panelId], dialogBoxPadding);
        measureMap.custom[id].hasPanel = Boolean(nodesMap[panelId]);
    }

    function getNodePadding(dialogBoxContainer) {
        const dialogBoxPaddingVertical = getNumericCSSPropValue(dialogBoxContainer, 'padding-bottom') +
            getNumericCSSPropValue(dialogBoxContainer, 'padding-top');
        const dialogBoxPaddingHorizontal = getNumericCSSPropValue(dialogBoxContainer, 'padding-right') +
            getNumericCSSPropValue(dialogBoxContainer, 'padding-left');
        return {horizontal: dialogBoxPaddingHorizontal, vertical: dialogBoxPaddingVertical};
    }

    function getNumericCSSPropValue($node, cssProperty) {
        if ($node && cssProperty) {
            return parseInt($node.css(cssProperty), 10) || 0;
        }
        return 0;
    }

    function addMobileZoomDescriptionToMeasure(customMeasure, descriptionNode) {
        const $description = $(descriptionNode);
        if ($description.css('display') !== 'none') {
            const lineHeight = parseInt($description.css('line-height'), 10);
            customMeasure.showDescription = true;
            customMeasure.descriptionHeight = $description.height();
            customMeasure.descriptionHeightLimit = Math.floor(lineHeight * 3);
        }
    }

    function measureMobileViewMediaZoom(mediaZoomId, measureMap, nodesMap, siteWidth, getZoomedImageId, getZoomedImageCompData) {
        const imageId = getZoomedImageId();
        const compData = getZoomedImageCompData(imageId);

        const customMeasure = mediaZoomCalculations.getMobileViewDimensions(compData, siteWidth, measureMap);
        const descriptionNode = nodesMap[`${mediaZoomId + imageId}description`];
        addMobileZoomDescriptionToMeasure(customMeasure, descriptionNode);
        measureMap.custom[mediaZoomId] = customMeasure;
    }

    const IMAGE_ZOOM_COMP_CLASSNAME = 'wysiwyg.components.imageZoom';

    layout.registerCustomMeasure(IMAGE_ZOOM_COMP_CLASSNAME, function (id, measureMap, nodesMap, structureInfo,
                                                                      {
                                                                          isMobileView,
                                                                          isMobileDevice,
                                                                          isTabletDevice,
                                                                          siteWidth,
                                                                          getZoomedImageId,
                                                                          getZoomedImageCompData
                                                                      }) {
        const mediaZoomId = getImageCompDataFromStructure(structureInfo).id;

        if (isMobileView()) {
            measureMobileViewMediaZoom(id + mediaZoomId, measureMap, nodesMap, siteWidth, getZoomedImageId, getZoomedImageCompData);
        } else {
            measureMediaZoom(id + mediaZoomId, measureMap, nodesMap, isMobileView(), isMobileDevice(), isTabletDevice(), siteWidth, getZoomedImageId, getZoomedImageCompData);
        }
    });

    layout.registerPatcher(IMAGE_ZOOM_COMP_CLASSNAME, function (id, patchers, measureMap, structureInfo, siteData) {
        const mediaZoomId = getImageCompDataFromStructure(structureInfo).id;
        const patchFunction = siteData.isMobileView() ? patchMobileViewMediaZoom : patchMediaZoomImage;
        patchFunction(id + mediaZoomId, patchers, measureMap, structureInfo, siteData);
    });

    layout.registerRequestToMeasureChildren(IMAGE_ZOOM_COMP_CLASSNAME, function (id, nodesMap, structureInfo, siteData) {
        const mediaZoomId = getImageCompDataFromStructure(structureInfo).id;
        const imageId = getImageId(siteData);
        const childImageComponent = {pathArray: [mediaZoomId, imageId, 'image'], type: 'core.components.Image'};
        if (siteData.isMobileView()) {
            return [[mediaZoomId], childImageComponent, [mediaZoomId, 'dialogBox'], [mediaZoomId, imageId, 'description'], [mediaZoomId, imageId, 'ellipsis']];
        }
        return [[mediaZoomId], childImageComponent, [mediaZoomId, 'dialogBox'], [mediaZoomId, 'buttonPrev'], [mediaZoomId, 'buttonNext'], [mediaZoomId, imageId, 'panel']];
    });
});
