import {noop} from 'lodash'
import {withActions} from 'carmi-host-extensions'

export const name = 'CompScrollHandlerAspect'

export const defaultModel = {}

export const functionLibrary = {
    setCompToScrollTo: withActions(({setCompIdToScrollTo, setScrollToCompCallback}, compId, callback) => {
        setCompIdToScrollTo(compId)
        if (callback) {
            setScrollToCompCallback(callback)
        }
    }),
    scrollToComp: withActions(({setScrollToCompCallback, setCompIdToScrollTo}, scrollSiteTo, getCompYPosition, getScrollCallback = noop) => {
        scrollSiteTo(0, getCompYPosition(), getScrollCallback())
        setScrollToCompCallback(undefined)
        setCompIdToScrollTo(undefined)
    }),
    updateAnchorIdToScrollTo: withActions(({setAnchorDataIdToScrollTo}, {anchorData: anchorDataId}) => {
        if (anchorDataId) {
            setAnchorDataIdToScrollTo(anchorDataId)
        }
    }),
    scrollToAnchorNotDuringTransition: withActions(({setAnchorDataIdToScrollTo}, checkIsDuringPageTransition, scrollToAnchor) => {
        if (!checkIsDuringPageTransition()) {
            scrollToAnchor()
        }
        setAnchorDataIdToScrollTo(undefined)
    })
}
