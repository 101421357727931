define(['tpaComponents/bi/events.json', 'lodash', 'coreUtils'], function (events, _, coreUtils) {
    'use strict';

    const logger = coreUtils.loggingUtils.logger;

    /**
     * Please ctrl/cmd + click on biEvents to see the schema :)
     * @type {Object.<String, biEvent>}
     */

    logger.register('tpa', 'event', events);

    return events;
});
