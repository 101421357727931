import {withActions} from 'carmi-host-extensions'
import _ from 'lodash'

const name = 'PopupPageAspect'

const defaultModel = {
    registeredCloseHandlers: {},
    popupIdToRender: null
}

const functionLibrary = {
    openPopupPage: (behavior, getRootNavInfo, navigateToPageRef) => {
        const delay = _.get(behavior, ['params', 'delay'], 0) * 1000
        setTimeout(() => {
            const rootNavInfo = getRootNavInfo() || {}
            if (behavior.compId === 'masterPage' || behavior.compId === rootNavInfo.pageId) {
                navigateToPageRef()
            }
        }, delay)
    },
    registerCloseHandler: withActions((aspectActions, popupId, closeHandler) => {
        aspectActions.setCloseHandler(popupId, closeHandler)
    }),
    closePopupPageIfOpen: withActions((aspectActions, currentPopupId, navigateToPageRef, closeHandler, extraData) => {
        if (!currentPopupId) {
            return
        }
        navigateToPageRef()
        if (closeHandler) {
            closeHandler(extraData)
            aspectActions.setCloseHandler(currentPopupId, undefined)
        }
    }),

    updatePopupToRender: withActions((actions, popupId, shouldSetPopupToRender) => {
        if (popupId && shouldSetPopupToRender) {
            actions.setPopupIdToRender(popupId)
        }
        if (!popupId) {
            actions.setPopupIdToRender(null)
        }
    })
}

export {
    name,
    defaultModel,
    functionLibrary
}
