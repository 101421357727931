define([
    'lodash',
    'coreUtils'
], function (
    _,
    coreUtils
) {
    'use strict';

    function addStyle(structureInfo, getStyleData, siteData, loadedStyles /*, pageId*/) {
        const styleId = _.get(structureInfo, 'styleItem.id') || _.get(structureInfo, 'structure.styleId');
        if (styleId) {
            loadedStyles[styleId] = {
                skinName: coreUtils.santaTypes.shortenStyleId(structureInfo.structure.skin),
                componentType: structureInfo.structure.componentType
            };
        }
        const structureOverrides = _.get(structureInfo.structure, ['modes', 'overrides']);
        _.forEach(structureOverrides, function (overrideObj) {
            const overrideStyleId = overrideObj.styleId;
            if (overrideStyleId && getStyleData(overrideStyleId)) {
                loadedStyles[overrideStyleId] = {
                    skinName: coreUtils.santaTypes.shortenStyleId(structureInfo.structure.skin),
                    componentType: structureInfo.structure.componentType
                };
            }
        });
    }

    coreUtils.styleCollector.registerClassBasedStyleCollector('wysiwyg.viewer.components.svgshape.SvgShape', addStyle);
    coreUtils.styleCollector.registerClassBasedStyleCollector('wysiwyg.viewer.components.PopupCloseIconButton', addStyle);
});
