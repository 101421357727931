define([
    'lodash',
    'layout/util/layout'
], function (
    _,
    layout
) {
    'use strict';

    function measureInlinePopupToggle(id, measureMap, nodesMap, structureInfo, siteData) {
        if (structureInfo.layout.fixedPosition && siteData.isMobileView()) {
            measureMap.top[id] = structureInfo.layout.y;
        }
    }

    function patchInlinePopupToggle(id, patchers, measureMap, structureInfo, siteData) {
        const wixAdsHeight = _.get(measureMap, ['height', 'WIX_ADStop'], 0);

        if (structureInfo.layout.fixedPosition && siteData.isMobileView()) {
            patchers.css(id, {
                marginTop: `${wixAdsHeight}px`
            });
        }
    }

    const registeredComponentTypes = [
        'wysiwyg.viewer.components.InlinePopupToggle',
        'wysiwyg.viewer.components.MenuToggle'
    ];

    _.forEach(registeredComponentTypes, function (compType) {
        layout.registerCustomMeasure(compType, measureInlinePopupToggle);
        layout.registerPatcher(compType, patchInlinePopupToggle);
    });
});
