const {compTypeToPackages} = require('../compsToPackages')

const PACKAGES_CALLBACKS_MAP = {
    facebookShare: (facebookShareComp, registerComponent) => registerComponent('wysiwyg.viewer.components.FacebookShare', facebookShareComp, true),
    svgShape: (svgShapeComps, registerComponent) => {
        const {svgShape, vectorImage, popupCloseIconButton} = svgShapeComps
        registerComponent('wysiwyg.viewer.components.svgshape.SvgShape', svgShape)
        registerComponent('wysiwyg.viewer.components.VectorImage', vectorImage)
        registerComponent('wysiwyg.viewer.components.PopupCloseIconButton', popupCloseIconButton)
    },
    imageZoom: (imageZoomComps, registerComponent) => {
        const {imageZoom, imageZoomDisplayer, mediaZoom, mobileMediaZoom, touchMediaZoom, touchMediaZoomItem} = imageZoomComps
        registerComponent('wysiwyg.components.imageZoom', imageZoom)
        registerComponent('wysiwyg.components.ImageZoomDisplayer', imageZoomDisplayer)
        registerComponent('wysiwyg.viewer.components.MediaZoom', mediaZoom)
        registerComponent('wysiwyg.viewer.components.MobileMediaZoom', mobileMediaZoom)
        registerComponent('wysiwyg.viewer.components.TouchMediaZoom', touchMediaZoom)
        registerComponent('wysiwyg.viewer.components.TouchMediaZoomItem', touchMediaZoomItem)
    },
    slideShowGallery: (slideShowGalleryComp, registerComponent) => registerComponent('wysiwyg.viewer.components.SlideShowGallery', slideShowGalleryComp, true),
    'santa-components/popover': (popoverAssets, registerComponent, registerAspect) => {
        const {Component, Aspect} = popoverAssets
        registerComponent('wysiwyg.viewer.components.Popover', Component)
        registerAspect(Aspect.aspectName, Aspect)
    },
    inlinePopup: (inlinePopupAssets, registerComponent) => {
        const {InlinePopup, MenuContainer, InlinePopupToggle, MenuToggle} = inlinePopupAssets
        registerComponent('wysiwyg.viewer.components.InlinePopup', InlinePopup)
        registerComponent('wysiwyg.viewer.components.InlinePopupToggle', InlinePopupToggle)
        registerComponent('wysiwyg.viewer.components.MenuContainer', MenuContainer)
        registerComponent('wysiwyg.viewer.components.MenuToggle', MenuToggle)
    },
    'bolt-components': (components, registerComponent) => Object.values(components).forEach(comp => registerComponent(comp.compType, comp))
}

const registerNewComponents = (compRegistrar, loadedPackages, boltInstance) => {
    const newlyRegisteredCompTypes = compRegistrar
        .keys()
        .filter(compType => !boltInstance.compClasses[compType])
        .filter(compType => !compTypeToPackages[compType] || compTypeToPackages[compType].every(dep => loadedPackages[dep]))

    if (newlyRegisteredCompTypes.length) {
        newlyRegisteredCompTypes.forEach(componentType => {
            try {
                const compReactClass = compRegistrar.getCompReactClass(componentType, true)
                boltInstance.updateCompClass(componentType, compReactClass)
            } catch (ex) {
                console.error('could not get component', componentType)
                boltInstance.updateCompClass(componentType, boltInstance.compClasses['mobile.core.components.Container'])
            }
        })
    }
}

const onPackageLoaded = (packageName, exports, compRegistrar, loadedPackages, boltInstance) => {
    const onCurrentPackageLoaded = PACKAGES_CALLBACKS_MAP[packageName]
    if (onCurrentPackageLoaded) { // TODO @santa-ssr invoke once per server warmup
        onCurrentPackageLoaded(exports, compRegistrar.register)
    }

    registerNewComponents(compRegistrar, loadedPackages, boltInstance)
}

const registerExternalComponent = ({santaComponent, componentType, component}, registerComponent) => {
    const componentToRegister = santaComponent || component
    if (componentToRegister) {
        registerComponent(componentType, componentToRegister)
    }
    /*if (componentToRegister && componentToRegister.getComponentSkins) {
        skinRegistrar.addBatch(componentToRegister.getComponentSkins());
    }*/
}

const loadExternalComponent = (externalComponentsLoader, wixUiBase, compType, setExternalComponentPackage, compRegistrar, loadedPackages, boltInstance) => {
    externalComponentsLoader.load(compType, wixUiBase)
        .then(viewerBundles => {
            viewerBundles.forEach(viewerBundle =>
                registerExternalComponent(viewerBundle.default, compRegistrar.register)
            )
            setExternalComponentPackage(viewerBundles)
            registerNewComponents(compRegistrar, loadedPackages, boltInstance)
        })
    return compType
}

module.exports = {
    loadExternalComponent,
    onPackageLoaded
}
