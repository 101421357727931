define([
    'lodash',
    'warmupUtilsLib',
    'experiment'
], function (
    _,
    warmupUtilsLib,
    experiment
) {
    'use strict';

    const createExperimentFn = siteData => name => experiment.isOpen(name, siteData);

    return _.assign({}, warmupUtilsLib.layoutUtils, {
        getLayoutMechanism(siteData) {
            return warmupUtilsLib.layoutUtils.getLayoutMechanism(siteData, createExperimentFn(siteData));
        }
    });
});
