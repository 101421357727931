import _ from 'lodash'
import {withActions} from '../withActions'

export const defaultModel = {
    registeredCompIds: {
        specificKeysKeyDown: {
            Escape: [],
            ArrowRight: [],
            ArrowLeft: []
        },
        KeyDown: [],
        KeyUp: []
    }
}

const supportedKeys = {
    39: 'ArrowRight',
    37: 'ArrowLeft',
    27: 'Escape',
    13: 'Enter',
    32: 'Space'
}

export const name = 'WindowKeyboardEventAspect'

const isKeySupported = event => Boolean(supportedKeys[event.keyCode || event.which])

const propagateKeyboardEvent = ({registeredCompIds}, eventType, event) => {
    if (!isKeySupported(event)) {
        return
    }

    _.forEach(registeredCompIds[eventType], item => {
        if (!item) {
            // remove listener if component was unmounted
        } else if (item && item.listener) {
            const {listener} = item
            event.preventDefault()
            listener(event)
        }
    })
}

const registerToKeyDown = ({setKeyDownListener, componentCountMap, comp}) => {
    const {props: {id}, onKeyDown} = comp
    const keyDownLength = componentCountMap.KeyDown
    setKeyDownListener(keyDownLength, 0, {id, listener: onKeyDown})
}

const unRegisterKeyDown = ({setKeyDownListener, comp, componentIndexMap}) => {
    const {props: {id}} = comp
    setKeyDownListener(componentIndexMap[id], 1)
}

const unRegisterKeyUp = ({setKeyUpListener, comp, componentIndexMap}) => {
    const {props: {id}} = comp
    setKeyUpListener(componentIndexMap[id], 1)
}

const unRegisterKeys = ({componentIndexMap, comp, setSpecificKeysKeyDownListener}) => {
    const {props: {id}} = comp
    _.forEach(componentIndexMap.specificKeysKeyDown, (value, key) => {
        const compIndex = componentIndexMap.specificKeysKeyDown[key][id]
        if (!_.isUndefined(compIndex)) {
            setSpecificKeysKeyDownListener(key, compIndex, 1)
        }
    })
}

export const functionLibrary = {
    registerToKeyDown: withActions(({setKeyDownListener}, {componentCountMap}, comp) => {
        registerToKeyDown({
            setKeyDownListener,
            componentCountMap,
            comp
        })
    }),
    registerToKeyUp: withActions(({setKeyUpListener}, {componentCountMap}, comp) => {
        const {props: {id}, onKeyUp} = comp
        const keyUpLength = componentCountMap.KeyUp

        setKeyUpListener(keyUpLength, 0, {id, listener: onKeyUp})
    }),
    registerToEscapeKey: withActions(({setEscapeListener}, {componentCountMap}, comp) => {
        const {props: {id}, onEscapeKey} = comp
        const escapeLength = componentCountMap.Escape

        setEscapeListener(escapeLength, 0, {id, listener: onEscapeKey})
    }),
    registerToArrowLeftKey: withActions(({setArrowLeftListener}, {componentCountMap}, comp) => {
        const {props: {id}, onArrowLeftKey} = comp
        const arrowLeftLength = componentCountMap.ArrowLeft

        setArrowLeftListener(arrowLeftLength, 0, {id, listener: onArrowLeftKey})
    }),
    registerToArrowRightKey: withActions(({setArrowRightListener}, {componentCountMap}, comp) => {
        const {props: {id}, onArrowRightKey} = comp
        const arrowRightLength = componentCountMap.ArrowRight

        setArrowRightListener(arrowRightLength, 0, {id, listener: onArrowRightKey})
    }),
    registerToKeyDownWithFocus: withActions(({setKeyDownListener}, {componentIndexMap, componentCountMap}, comp, element) => {
        element.addEventListener('focus', () => {
            registerToKeyDown({setKeyDownListener, componentCountMap, comp})
        })

        element.addEventListener('blur', () => {
            unRegisterKeyDown({setKeyDownListener, componentIndexMap, comp})
        })
    }),
    unRegisterKeys: withActions(({setSpecificKeysKeyDownListener}, {componentIndexMap}, comp) => {
        unRegisterKeys({
            componentIndexMap,
            comp,
            setSpecificKeysKeyDownListener
        })
    }),
    unRegisterKeyDown: withActions(({setKeyDownListener}, {componentIndexMap}, comp) => {
        unRegisterKeyDown({
            setKeyDownListener,
            componentIndexMap,
            comp
        })
    }),
    unRegisterKeyUp: withActions(({setKeyUpListener}, {componentIndexMap}, comp) => {
        unRegisterKeyUp({
            setKeyUpListener,
            componentIndexMap,
            comp
        })
    }),
    unRegisterAll: withActions(({setKeyDownListener, setKeyUpListener, setSpecificKeysKeyDownListener}, {componentIndexMap}, comp) => {
        unRegisterKeys({
            setSpecificKeysKeyDownListener,
            componentIndexMap,
            comp
        })
        unRegisterKeyDown({
            setKeyDownListener,
            componentIndexMap,
            comp
        })
        unRegisterKeyUp({
            setKeyUpListener,
            componentIndexMap,
            comp
        })
    }),
    propagateKeyDownSpecificKeyEvent: ({registeredCompIds}, event) => {
        const key = supportedKeys[event.keyCode || event.which]

        propagateKeyboardEvent({registeredCompIds}, 'KeyDown', event)

        if (_.isEmpty(registeredCompIds.specificKeysKeyDown[key])) {
            return
        }

        const comp = _.last(registeredCompIds.specificKeysKeyDown[key])
        const {listener} = comp
        if (!listener) { // if component doesn't exist
            // _registeredCompIds.specificKeysKeyDown[key] = _.without(_registeredCompIds.specificKeysKeyDown[key], compId)
        } else {
            event.preventDefault()
            listener()
        }
    },
    propagateKeyboardEvent

}

export function init({}, {eventsManager, initialData: {initPropagateKeyDownSpecificKeyEvent, initPropagateKeyboardEvent}}) {
    eventsManager.on('windowKeyDown', event => {
        initPropagateKeyDownSpecificKeyEvent(event)
    })
    eventsManager.on('windowKeyUp', event => {
        initPropagateKeyboardEvent('KeyUp', event)
    })
}
