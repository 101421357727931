define(['santa-components', 'lodash', 'reactDOM'], function (santaComponents, _, ReactDOM) {
    'use strict';

    const THROTTLE_INTERVAL = 250;
    const THROTTLE_OPTIONS = {
        trailing: true
    };

    const getMeasurements = function (comp, scrollPos, siteScale) {
        let measuresRelativeToViewPort;

        if (isTPAFixedPosition(comp.props)) {
            measuresRelativeToViewPort = comp._cachedBoundingClientRect;
        } else {
            measuresRelativeToViewPort = comp._domNode.getBoundingClientRect();
        }

        const measurements = {
            x: measuresRelativeToViewPort.left + (scrollPos.x || 0),
            y: measuresRelativeToViewPort.top + (scrollPos.y || 0),
            width: measuresRelativeToViewPort.width,
            height: measuresRelativeToViewPort.height
        };
        measurements.scale = siteScale;

        if (comp.windowSize === null) {
            comp.windowSize = comp.props.screenSize;
        }

        const documentMeasures = {
            scrollTop: scrollPos.y,
            scrollLeft: scrollPos.x,
            documentHeight: comp.props.screenSize.height,
            documentWidth: comp.props.screenSize.width
        };

        return _.assign({}, measurements, measuresRelativeToViewPort, documentMeasures);
    };

    const throttledOnScroll = () => _.throttle(function (position) { // eslint-disable-line santa/no-module-state
        if (shouldHandleEvent(this.props)) {
            const siteScale = _.get(this, 'props.siteScale', 1);
            const scrollData = getMeasurements(this, position, siteScale);
            this.sendPostMessage({
                intent: 'addEventListener',
                eventType: 'SCROLL',
                params: scrollData
            });
        }
    }, THROTTLE_INTERVAL, THROTTLE_OPTIONS);

    function isTPAFixedPosition(props) {
        return _.get(props, 'structure.layout.fixedPosition') || false;
    }

    const shouldHandleEvent = function (props) {
        return !props.rootId || _.includes(props.getRootIdsWhichShouldBeRendered(), props.rootId);
    };

    return {
        propTypes: {
            getRootIdsWhichShouldBeRendered: santaComponents.santaTypesDefinitions.getRootIdsWhichShouldBeRendered,
            siteScale: santaComponents.santaTypesDefinitions.RenderFlags.siteScale,
            rootId: santaComponents.santaTypesDefinitions.Component.rootId,
            screenSize: santaComponents.santaTypesDefinitions.screenSize.isRequired
        },

        onScroll: _.noop,

        getInitialState() {
            this._domNode = null;
            this._cachedBoundingClientRect = null;

            return {};
        },

        componentDidMount() { // eslint-disable-line complexity
            this._domNode = ReactDOM.findDOMNode(this);
            if (isTPAFixedPosition(this.props)) {
                this._cachedBoundingClientRect = this._domNode.getBoundingClientRect();
            }
        },

        onResize() {
            if (isTPAFixedPosition(this.props)) {
                this._cachedBoundingClientRect = this._domNode.getBoundingClientRect();
            }
        },

        componentWillMount() {
            this._domNode = null;
            this.onScroll = throttledOnScroll();
        },


        componentWillUpdate(nextProps, nextState) {
            const willComponentResize = nextState.height !== this.state.height || nextState.width !== this.state.width;
            if (isTPAFixedPosition(nextProps) && willComponentResize) {
                this._cachedBoundingClientRect = null;
            }
        },

        componentDidUpdate() {
            if (this._cachedBoundingClientRect === null && isTPAFixedPosition(this.props)) {
                this._cachedBoundingClientRect = this._domNode.getBoundingClientRect();
            }
        }
    };
});
