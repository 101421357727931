define([], function () {
    'use strict';
    return [
        'de',
        'en',
        'es',
        'fr',
        'it',
        'ja',
        'ko',
        'pl',
        'ru',
        'nl',
        'tr',
        'sv',
        'pt',
        'no',
        'da',
        'hi',
        'zh',
        'cs',
        'th'
    ];
});
