'use strict'

const _ = require('lodash')
const santaTypesDefinitions = require('../definitions/santaTypesDefinitions')

const COMP_SANTA_TYPES_TO_PASS = {
    rootId: true,
    rootNavigationInfo: true,
    theme: true,
    compDesign: true
}

function isSantaTypeProp(propType) {
    return propType.id || _.isFunction(propType.fetch)
}

const getSantaTypesByClass = _.memoize(reactClass => _.pickBy(reactClass.propTypes, isSantaTypeProp))

function shouldPassSantaTypeToChild(propType) {
    return _.every(santaTypesDefinitions.RenderRealtimeConfig, function (realtimePropType) {
        return propType !== realtimePropType && propType !== realtimePropType.isRequired
    }) && _.every(santaTypesDefinitions.Component, function (componentPropType, propName) {
        return COMP_SANTA_TYPES_TO_PASS[propName] || propType !== componentPropType && propType !== componentPropType.isRequired
    })
}

const getSantaTypesForChildComponentClass = _.memoize(compClass => _.pickBy(getSantaTypesByClass(compClass), shouldPassSantaTypeToChild))

function getChildStyleDataFromSkinPart(skinPartName, skinExports, parentStyleId) {
    return {
        skin: skinExports[skinPartName].skin,
        styleId: parentStyleId + skinPartName
    }
}

function getChildSantaTypes(childClassName, parentProps) {
    const childCompSantaTypes = getSantaTypesForChildComponentClass(childClassName)
    return _.pick(parentProps, _.keys(childCompSantaTypes))
}

module.exports = {
    getChildStyleDataFromSkinPart,
    getChildSantaTypes
}