/* Autogenerated file. Do not modify */
'use strict'
const skins = {}
 skins['skins.core.ImageNewSkin'] = {
  "react": [],
  "css": {
    "%": "overflow:hidden;",
    "%image": "position:static;box-shadow:#000 0 0 0;"
  }
}
 skins['skins.core.ImageNewSkinZoomable'] = {
  "react": [],
  "css": {
    "%image": "position:static;box-shadow:#000 0 0 0;user-select:none;"
  }
}
 skins['skins.core.ImageSkin'] = {
  "react": [],
  "css": {
    "%image": "position:static;box-shadow:#000 0 0 0;user-select:none;"
  }
}
 skins['skins.image.default'] = {
  "react": []
}

module.exports = skins