import _ from 'lodash'
import {withActions} from '../withActions'

export const name = 'WindowTouchEvents'

export const Events = {
    TouchStart: 'TouchStart',
    TouchMove: 'TouchMove',
    TouchEnd: 'TouchEnd',
    TouchCancel: 'TouchCancel'
}

export const defaultModel = {
    listeners: {
        [Events.TouchStart]: {},
        [Events.TouchMove]: {},
        [Events.TouchEnd]: {},
        [Events.TouchCancel]: {}
    }
}

export const functionLibrary = {
    registerToWindowTouchEvent: withActions(({windowTouchEventsSetListener}, type, reactComp) => {
        const typePascalCase = _.upperFirst(type)
        windowTouchEventsSetListener(typePascalCase, reactComp.props.id, reactComp)
    }),

    unregisterFromWindowTouchEvent: withActions(({windowTouchEventsSetListener}, type, reactComp) => {
        const typePascalCase = _.upperFirst(type)
        windowTouchEventsSetListener(typePascalCase, reactComp.props.id, undefined)
    }),

    propagateTouchEvent: ({listeners}, eventType, event) => {
        const methodName = `onWindow${eventType}`
        _.forEach(listeners[eventType], comp => {
            if (comp && comp[methodName]) {
                comp[methodName](event)
            }
        })
    }
}

export function init({}, {eventsManager, initialData: {isInSSr, propagateTouchEvent}}) {
    if (isInSSr) {
        return
    }
    eventsManager.on(`window${Events.TouchStart}`, event => propagateTouchEvent(Events.TouchStart, event))
    eventsManager.on(`window${Events.TouchMove}`, event => propagateTouchEvent(Events.TouchMove, event))
    eventsManager.on(`window${Events.TouchEnd}`, event => propagateTouchEvent(Events.TouchEnd, event))
    eventsManager.on(`window${Events.TouchCancel}`, event => propagateTouchEvent(Events.TouchCancel, event))
}
