const _ = require('lodash')
const urlRegex = /((https?\:)\/\/)?([^\?\:\/#]+)(\:([0-9]+))?(\/[^\?\#]*)?(\?([^#]*))?(#.*)?/i

module.exports = {
    stringifyQuery: queryObj => Object.keys(queryObj).map(key => `${key}=${encodeURIComponent(queryObj[key])}`).join('&'),

    isHttps: url => {
        const match = url.match(urlRegex)
        const protocol = match[2] || 'http:'
        return protocol.startsWith('https')
    },
    getQueryParamValue: (url, key) => {
        const search = url.match(urlRegex)[8] // url search string
        return _(search)
            .split('&')
            .map(x => x.split('='))
            .fromPairs()
            .pickBy((value, currentKey) => key.toLowerCase() === currentKey.toLowerCase())
            .values()
            .head()
    },
    parseUrl: (rawUrl, warmupUtils) => warmupUtils.urlUtils.parseUrl(rawUrl),
    getUrl: (warmupUtils, linkRenderInfo, options) => {
        const {pageInfo, forceAddPageInfo, cleanQuery, baseUrl, urlMapping, hasAppSectionParams} = options
        const dismissQueryParams = hasAppSectionParams ? [] : ['appSectionParams']
        return warmupUtils.wixUrlParser.getUrl(linkRenderInfo, pageInfo, forceAddPageInfo, cleanQuery, baseUrl, urlMapping, dismissQueryParams)
    },
    setProtocol: (url, protocol) => url && url.replace(/https?:/, protocol),
    resolveNavigationInfo: (rawUrl, warmupUtils, resolvedSiteData) => {
        const resolvedSiteDataAPI = Object.assign({
            isResolvedSiteData: true
        }, resolvedSiteData)

        return {
            primaryPage: warmupUtils.wixUrlParser.parseUrl(resolvedSiteDataAPI, rawUrl)
        }
    }
}
