define([
    'lodash'
], function (_) {
    'use strict';

    return id => {
        const {document} = window;
        const node = document.querySelector(`#${id}`);
        if (!node) {
            return _.noop;
        }
        const placeholder = document.querySelector(`#${id}-placeholder`);
        const isFixed = (node.dataset.state || '').match(/\d*fixedPosition\d*/);
        const headerTop = _.toNumber(node.dataset.headerTop) || 0;
        const height = isFixed ? node.offsetHeight : node.offsetHeight + headerTop;
        return node && placeholder && isFixed ? () => {
            placeholder.style.height = `${height}px`;
        } : _.noop;
    };
});
