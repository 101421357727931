define([
    'lodash',
    'zepto',
    'layout/util/layout',
    'warmupUtilsLib',
    'image-client-api',
    'layout/util/optimizedCssImageLayout'
], function (_, $, layout, warmupUtilsLib, imageClientApi, optimizedCssImageLayout) {
    'use strict';

    const balataConsts = warmupUtilsLib.mediaConsts.balataConsts;

    function isEmptyOrExternalUri(uri) {
        return !uri || warmupUtilsLib.urlUtils.isExternalUrl(uri);
    }

    function getImageTransformData(fittingType, alignType, imageData, targetDimensions, siteData, htmlTag) {
        const pixelAspectRatio = siteData.mobile.getDevicePixelRatio();
        const target = {
            width: targetDimensions.width,
            height: targetDimensions.height,
            alignment: alignType,
            htmlTag: htmlTag || 'bg',
            pixelAspectRatio
        };
        const src = {id: imageData.uri, width: imageData.width, height: imageData.height};
        const imageQualityFilters = _.defaults({quality: 85}, imageData.quality || {});
        return imageClientApi.getData(fittingType, src, target, imageQualityFilters, siteData.browser);
    }

    function measureLegacyBgImageStrip(id, measureMap, nodesMap/*, siteData, structureInfo*/) {
        const legacyBgImageId = `${id}bg`;
        const node = nodesMap[legacyBgImageId];
        if (!node) {
            return;
        }
        measureMap.custom[id] = {
            fittingType: $(node).data('fittingType'),
            alignType: $(node).data('alignType')
        };
        optimizedCssImageLayout.cacheCssImageMeasureData(measureMap.custom[id], node);
    }

    function measureBgImageBalata(balataNodeId, measureMap, nodesMap) {
        const imageCompNodeId = balataNodeId + balataConsts.MEDIA + balataConsts.IMAGE;
        const innerImageId = `${imageCompNodeId}image`;
        nodesMap[innerImageId] = nodesMap[imageCompNodeId].querySelector(`#${innerImageId}`);
        const innerImageNode = nodesMap[innerImageId];
        measureMap.custom[imageCompNodeId] = {};
        optimizedCssImageLayout.cacheCssImageMeasureData(measureMap.custom[imageCompNodeId], innerImageNode);
    }

    function getImageProperties(balataData, nodeType, parentDimensions, innerImageHeight, siteData) {
        const width = parentDimensions.width;
        const height = innerImageHeight || parentDimensions.height;

        const imageData = getImageData(balataData);
        const fittingType = balataData.fittingType;
        const alignType = balataData.alignType;
        return getImageTransformData(fittingType, alignType, imageData, {
            width,
            height
        }, siteData, nodeType);
    }

    function patchBgImage(balataNodeId, imageCompNodeId, patchers, measureMap, structureInfo, siteData, innerImageHeight, parentDimensions) {
        const balataData = getBalataData(structureInfo);
        const innerImageId = `${imageCompNodeId}image`;
        const imageTransformData = getImageProperties(balataData, 'bg', parentDimensions, innerImageHeight, siteData);
        const cssValues = _.assign({height: innerImageHeight}, imageTransformData.css.container);
        optimizedCssImageLayout.patchCssImage(measureMap.custom[imageCompNodeId], innerImageId, patchers, cssValues, imageTransformData.uri, siteData);
    }


    function getBalataData(structureInfo) {
        if (_.isUndefined(structureInfo.designDataItem)) {
            return structureInfo.dataItem.background;
        }
        return structureInfo.designDataItem.background;
    }

    function getImageData(balataData) {
        let imageData = balataData.mediaRef;
        // If media is not image but video, go another level deeper to posterImage
        if (imageData && imageData.type === 'WixVideo') {
            imageData = imageData.posterImageRef;
        }
        return imageData;
    }

    function patchLegacyBgImageStrip(id, patchers, measureMap, structureInfo, siteData, parentDimensions) {
        const customMeasureData = measureMap.custom[id];
        const legacyBgImageId = `${id}bg`;
        if (customMeasureData) {
            const imageData = structureInfo.dataItem;
            if (!imageData || isEmptyOrExternalUri(imageData.uri)) {
                return;
            }
            const {fittingType, alignType} = customMeasureData;
            const imageTransformData = getImageTransformData(fittingType, alignType, imageData, parentDimensions, siteData);
            optimizedCssImageLayout.patchCssImage(customMeasureData, legacyBgImageId, patchers, imageTransformData.css.container, imageTransformData.uri, siteData);
        }
    }

    layout.registerRequestToMeasureChildren('wysiwyg.viewer.components.BgImageStrip', [['bg']]);

    return {
        measureLegacyBgImageStrip,
        measureBgImageBalata,
        patchBgImage,
        patchLegacyBgImageStrip
    };
})
;
