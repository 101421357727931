define([
    'lodash',
    'image-client-api',
    'coreUtils',
    'santa-components',
    'components/common/mediaCommon/mediaCommon',
    'backgroundCommon',
    'componentsCore'
], function (
    _,
    imageClientApi,
    coreUtils,
    santaComponents,
    mediaCommon,
    backgroundCommon,
    componentsCore
) {
    'use strict';

    const {fittingTypes} = imageClientApi;
    const DEFAULT_BG_WIDTH = '100%';
    const DEFAULT_BG_HEIGHT = '100%';
    const DEFAULT_BG_TOP = 0;
    const PARALLAX_BG_HEIGHT = '120%';
    const PARALLAX_BG_TOP = 0;//'-25%';

    const DEFAULT_BG_PX_WIDTH = 1920;
    const DEFAULT_MOBILE_BG_PX_WIDTH = 1000;

    const consts = coreUtils.mediaConsts;
    const bgUtils = coreUtils.containerBackgroundUtils;
    const fillMixin = mediaCommon.mediaLogicMixins.fill;

    function shouldChangeScrollType(props) {
        if (props.isMobileView) {
            return props.compData.mediaSizing !== 'viewport';
        }
        return !props.fixedSiteBackground;
    }

    function getBgType(bgData) {
        return _.get(bgData, ['mediaRef', 'type'], 'Color');
    }

    function getAllowedScrollType(bgData, props) {
        let scrollType = _.get(bgData, 'scrollType');
        const mediaType = _.get(bgData, ['mediaRef', 'type']);
        const hasMedia = mediaType === 'Image' || mediaType === 'WixVideo';
        if (!hasMedia || shouldChangeScrollType(props) || props.isAndroidOldBrowser) {
            scrollType = 'scroll';
        }
        return scrollType;
    }

    function resolvePosition(bgData, scrollType) {
        let position = 'absolute';
        const mediaType = _.get(bgData, ['mediaRef', 'type']);
        if (mediaType === 'WixVideo' || scrollType === 'fixed' || scrollType === 'parallax') {
            position = 'fixed';
        }
        return position;
        //return 'absolute';
    }

    function getDimensions(imageData, isMobileView) {
        const defaultWidth = isMobileView ? DEFAULT_MOBILE_BG_PX_WIDTH : DEFAULT_BG_PX_WIDTH;
        const width = Math.min(defaultWidth, imageData.width);
        const height = Math.min(defaultWidth, Math.round(width / (imageData.width / imageData.height)));
        return {width, height};
    }

    /**
     * Get standard css style object from background data item
     * @param {object} props
     * @param {object} data
     * @param {boolean} hide
     * @returns {{backgroundImage: string?, backgroundAttachment: string?, backgroundSize: string?, backgroundPosition: string?, backgroundRepeat: string?, backgroundColor: string, top: number, height: string, width: string, position: string?, display: string, willChange: string?}}
     */
    function getRootStyle(props, data, hide) {
        const scrollType = getAllowedScrollType(data, props);
        const resolvedPosition = resolvePosition(data, scrollType);
        const bgStyle = {
            top: 0,
            height: DEFAULT_BG_HEIGHT,
            //minHeight: '100vh',
            width: DEFAULT_BG_WIDTH,
            backgroundColor: coreUtils.colorParser.getColor(props.colorsMap, data.color),
            display: hide ? 'none' : '',
            position: resolvedPosition
        };
        if (resolvedPosition === 'fixed') {
            if (props.isMobileView) {
                bgStyle.willChange = 'transform';
            } else {
                bgStyle.top = `${props.style.top}px`;
            }
        }
        return bgStyle;
    }

    function getBgHeightByScrollType(scrollType) {
        let height = DEFAULT_BG_HEIGHT;

        if (scrollType === 'parallax') {
            height = PARALLAX_BG_HEIGHT;
        }

        return height;
    }

    function getBgTopByScrollType(scrollType) {
        let top = DEFAULT_BG_TOP;

        if (scrollType === 'parallax') {
            top = PARALLAX_BG_TOP;
        }

        return top;
    }

    /**
     * Get standard css style object from background data item
     * @param {SiteData} siteData
     * @param {object} data
     * @returns {{position: string, top: number, height: number|string, width: number|string, opacity: number}}
     */
    function getImageStyle(props, data) {
        const imageData = data.mediaRef;
        const scrollType = getAllowedScrollType(data, props);
        const bgStyle = {
            position: 'absolute',
            top: getBgTopByScrollType(scrollType),
            height: getBgHeightByScrollType(scrollType),
            width: DEFAULT_BG_WIDTH,
            opacity: imageData && imageData.opacity
        };

        // fix for mobile devices (browser action bar is shown in some cases and push the image up living the white space)
        if (props.isMobileView && scrollType === 'fixed') {
            bgStyle.willChange = 'transform';
        }
        if (props.shouldRenderSrc && getBgType(data) === 'Image') {
            const {url, css} = getBackgroundImage(props, data);
            bgStyle.backgroundImage = `url(${url})`;
            _.assign(bgStyle, css);
        }
        return bgStyle;
    }

    function getBackgroundImage(props, data) {
        const imageData = data.mediaRef;
        const targetDimensions = getDimensions(imageData, props.isMobileView);

        const imageUrlPreMeasureParams = bgUtils.getImageUrlPreMeasureParams(targetDimensions, imageData, data.fittingType, data.alignType, props.isMobileView, props.isInSeo);
        const target = {
            width: imageUrlPreMeasureParams.width,
            height: imageUrlPreMeasureParams.height,
            htmlTag: 'bg',
            alignment: data.alignType,
            devicePixelRatio: imageUrlPreMeasureParams.devicePixelRatio
        };
        const src = {
            width: imageData.width,
            height: imageData.height,
            id: imageData.uri
        };
        //build the uri from pre measure params
        const imageTransformForUri = imageClientApi.getData(
            imageUrlPreMeasureParams.fittingType,
            src,
            target,
            _.pick(imageUrlPreMeasureParams, 'filters'));
        //get the css pre as with regular dimensions
        const imageTransformForCss = imageClientApi.getData(data.fittingType, src, _.assign(target, targetDimensions));

        const imageUrl = coreUtils.urlUtils.isExternalUrl(imageTransformForUri.uri) ? imageTransformForUri.uri : coreUtils.urlUtils.joinURL(props.staticMediaUrl, imageTransformForUri.uri);
        const css = _.assign({}, imageTransformForCss.css.container, imageUrlPreMeasureParams.imageCss);
        //mobile media sizing
        if (shouldApplyMobileMediaSizing(props)) {
            //override alignment to top
            css.backgroundPosition = imageTransformForCss.css.container.backgroundPosition.replace(/(center|bottom)$/, 'top');
            if (data.fittingType === fittingTypes.SCALE_TO_FILL) {
                css.height = '100vh';
            }
        }
        return {
            url: imageUrl,
            css
        };
    }

    /**
     * check for mobile "media sizing" override
     * @param props
     * @returns {boolean}
     */
    function shouldApplyMobileMediaSizing(props) {
        return props.isMobileView && props.compData.mediaSizing === 'viewport';
    }

    function getParallaxBehavior() {
        return [
            {
                action: 'bgScrub',
                name: 'SiteBackgroundParallax',
                duration: 1,
                delay: 0
            }
        ];
    }

    /**
     * Deep compare two background data items
     * @param {SiteData} siteData
     * @param {object} sourceData
     * @param {object} targetData
     * @returns {boolean}
     */
    function isEqualBackgrounds(sourceData, targetData) { // eslint-disable-line complexity
        const sourceBG = sourceData.background;
        const targetBG = targetData.background;

        // Get Media Data
        const sourceMediaData = sourceBG.mediaRef || {};
        const targetMediaData = targetBG.mediaRef || {};
        // Check if only color
        const isOnlyColor = !sourceBG.mediaRef && !targetBG.mediaRef;
        // Check if media type & media sizing are equal
        const isMediaTypeEqual = isOnlyColor || sourceMediaData.type === targetMediaData.type;
        const isMediaSizingEqual = sourceData.mediaSizing === targetData.mediaSizing;
        // Ignore color if media type is video and media types are equal
        const isIgnoreColor = sourceMediaData.type === 'WixVideo' && isMediaTypeEqual;

        const refKeys = ['mediaRef', 'imageOverlay'];
        let includes = ['type', 'alignType', 'fittingType', 'scrollType', 'colorOverlay', 'colorOverlayOpacity', 'color', 'videoId', 'uri', 'opacity'];
        if (isIgnoreColor) {
            includes = _.without(includes, 'color');
        } else if (isOnlyColor) {
            includes = ['color'];
        }

        return isMediaTypeEqual && isMediaSizingEqual && compareDataDeep(sourceBG, targetBG, refKeys, includes);
    }

    /**
     * Deep Compare 2 Data objects by refs and include list
     * @param {object} sourceData
     * @param {object} targetData
     * @param {Array} refKeys
     * @param {Array} includes
     * @returns {boolean}
     */
    function compareDataDeep(sourceData, targetData, refKeys, includes) {
        let equal = _.every(includes, function (key) {
            return (sourceData && sourceData[key]) === (targetData && targetData[key]);
        });

        equal = equal && _.every(refKeys, function (ref) {
            return sourceData ? compareDataDeep(sourceData[ref], targetData[ref], refKeys, includes) : true;
        });

        return equal;
    }

    /**
     * gets background data and return overlay style
     * @param {SiteData} siteData
     * @param {object} bgData
     * @returns {{position: string, top: number, width: string, height: string, backgroundImage: url string, backgroundColor: rgb/rgba string}}
     */
    function getOverlayStyle(props, bgData) {
        const color = bgData.colorOverlay ? coreUtils.colorParser.getColor(props.colorsMap, bgData.colorOverlay, bgData.colorOverlayOpacity) : null;
        const imageUrl = getImageOverlayUrl(props, bgData.imageOverlay);
        return {
            position: 'absolute',
            top: 0,
            width: DEFAULT_BG_WIDTH,
            height: DEFAULT_BG_HEIGHT,
            backgroundImage: imageUrl,
            backgroundColor: color
        };
    }

    /**
     * Get the full url path of the image overlay and return a css background-image string
     * @param {SiteData} siteData
     * @param imageOverlayData
     * @returns {string} 'url(...)'
     */
    function getImageOverlayUrl(props, imageOverlayData) {
        if (!imageOverlayData) {
            return null;
        }
        return `url(${coreUtils.urlUtils.joinURL(props.staticMediaUrl, imageOverlayData.uri)})`;
    }

    /**
     * @class components.siteBackground
     * @extends {core.skinBasedComp}
     */
    const siteBackground = {
        displayName: 'SiteBackground',
        mixins: [fillMixin, componentsCore.mixins.skinBasedComp, santaComponents.mixins.animationsMixin, backgroundCommon.mixins.backgroundDetectionMixin, componentsCore.mixins.createChildComponentMixin],
        propTypes: _.defaults(
            {
                id: santaComponents.santaTypesDefinitions.Component.id,
                compData: santaComponents.santaTypesDefinitions.Media.SiteBackground.data, // Site background specific
                actionsAspect: santaComponents.santaTypesDefinitions.SiteAspects.actionsAspect.isRequired,
                currentUrlPageId: santaComponents.santaTypesDefinitions.Component.currentUrlPageId,
                isMobileView: santaComponents.santaTypesDefinitions.isMobileView.isRequired,
                isInSeo: santaComponents.santaTypesDefinitions.isInSeo,
                isTouchDevice: santaComponents.santaTypesDefinitions.Device.isTouchDevice,
                isAndroidOldBrowser: santaComponents.santaTypesDefinitions.mobile.isAndroidOldBrowser,
                colorsMap: santaComponents.santaTypesDefinitions.Theme.colorsMap,
                staticMediaUrl: santaComponents.santaTypesDefinitions.ServiceTopology.staticMediaUrl,
                componentViewMode: santaComponents.santaTypesDefinitions.RenderFlags.componentViewMode.isRequired,
                hideSiteBackground: santaComponents.santaTypesDefinitions.RenderFlags.hideSiteBackground.isRequired,
                fixedSiteBackground: santaComponents.santaTypesDefinitions.BrowserFlags.fixedSiteBackground,
                // Media Props Override
                mediaQuality: santaComponents.santaTypesDefinitions.Media.SiteBackground.mediaQuality, // Site background specific
                renderParts: santaComponents.santaTypesDefinitions.Media.SiteBackground.renderParts, // Site background specific
                playbackFormat: santaComponents.santaTypesDefinitions.Media.SiteBackground.playbackFormat,
                playbackConfig: santaComponents.santaTypesDefinitions.Media.SiteBackground.playbackConfig,
                playbackUrl: santaComponents.santaTypesDefinitions.Media.SiteBackground.playbackUrl,
                style: santaComponents.santaTypesDefinitions.Component.style.isRequire, // includes top position override - wixAds height
                isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen
            },
            santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(backgroundCommon.components.html5Video)
        ),
        statics: {
            behaviors: mediaCommon.mediaBehaviors.fill
        },

        previousScroll: null,

        getInitialState() {
            this.size = {};
            return {
                resetAttachment: false,
                hidePrevious: false,
                showMedia: false
            };
        },

        callForBackgroundChange(previousPageBackground) {
            const callbacks = {
                onComplete: function () {
                    if (this.isPageChanged()) {
                        _.result(this.refs, ['previousVideo', 'kill']);
                        this.setState({
                            previousPageBackground: null,
                            resetAttachment: false,
                            hidePrevious: true
                        });
                    }
                }.bind(this)
            };
            this.props.actionsAspect.registerNextBGPageTransition(this, 'previous', 'current', callbacks);

            this.setState({
                previousPageBackground,
                resetAttachment: true,
                hidePrevious: false,
                currentVisiblePageId: null
            });
        },

        componentWillReceiveProps(nextProps) {
            // If changing page
            if (this.props.currentUrlPageId !== nextProps.currentUrlPageId) {
                const boltPrevPageBackground = this.props.mediaAspect.getPrevPageBackground();
                const previousPageData = boltPrevPageBackground || this.props.compData;
                const nextPageData = nextProps.compData;

                if (!isEqualBackgrounds(previousPageData, nextPageData)) {
                    // if bg data is different between pages set current id as visual id, render both and do page transition
                    this.callForBackgroundChange(_.assign(previousPageData.background, {
                        mediaQuality: this.props.mediaQuality,
                        renderParts: _.cloneDeep(this.props.renderParts),
                        playbackConfig: _.cloneDeep(this.props.playbackConfig),
                        playbackUrl: this.props.playbackUrl,
                        playbackFormat: this.props.playbackFormat
                    }));
                } else {
                    _.result(this.refs, ['previousVideo', 'kill']);
                    this.setState({
                        previousPageBackground: null,
                        currentVisiblePageId: this.state.currentVisiblePageId || this.props.compData.background.id
                    });
                }
            }
        },

        handleParallaxBehavior(currentPageBgStyle) {
            const currentScroll = getAllowedScrollType(currentPageBgStyle, this.props);
            const isScrollTypeParallaxChanged = this.previousScroll === 'parallax' || currentScroll === 'parallax';

            if (isScrollTypeParallaxChanged) {
                this.previousScroll = currentScroll;

                if (currentScroll === 'parallax') {
                    this.props.actionsAspect.registerBehaviors('currentImage', 'siteBackground', getParallaxBehavior());
                } else {
                    this.props.actionsAspect.unRegisterBehaviors('currentImage', getParallaxBehavior());
                }
            }
        },

        getClasses() {
            return '';
        },

        isPageChanged() {
            return this.props.compData.background.id !== _.get(this.state, ['previousPageBackground', 'id']);
        },

        /**
         * gets background data and invoke create component callback if data is of type WixVideo
         * @param {object} bgData
         * @returns {ReactCompositeComponent|null}
         */
        createVideoComponent(bgData, part, mediaQuality, videoRenderParts, playbackUrl, playbackFormat, playbackConfig) {
            const VIDEO_PARAMS = {
                comp: 'wysiwyg.viewer.components.background.html5Video',
                skin: 'skins.viewer.bgVideo.html5VideoSkin',
                style: 'bgVideo'
            };
            const mediaData = bgData.mediaRef;
            const isCurrent = part === 'current';

            if (_.get(mediaData, 'type') !== 'WixVideo') {
                if (isCurrent) {
                    // clear media api for non video bg
                    //https://jira.wixpress.com/browse/SE-21018
                    this.setMediaAPI(null);
                }
                return null;
            }
            const posterDimensions = getDimensions(mediaData.posterImageRef, this.props.isMobileView);
            return this.createChildComponent(
                mediaData,
                VIDEO_PARAMS.comp,
                {
                    skin: VIDEO_PARAMS.skin,
                    styleId: VIDEO_PARAMS.style
                },
                {
                    id: `${this.props.id + part}Video`,
                    parentId: this.props.id,
                    key: `vid_${mediaData.videoId}`,
                    style: {width: '100%', height: '100%'},
                    notifyMediaState: this.onMediaChange,
                    setMediaAPI: isCurrent ? this.setMediaAPI : _.noop,
                    videoRenderParts,
                    mediaQuality,
                    playbackUrl,
                    format: playbackFormat,
                    config: playbackConfig,
                    className: `siteBackground${part}Video`,
                    showMedia: this.state.showMedia,
                    notifyVideoVisibility: isCurrent ? this.notifyVideoVisibility : _.noop,
                    posterWidth: posterDimensions.width,
                    posterHeight: posterDimensions.height,
                    shouldRenderSrc: this.props.shouldRenderSrc,
                    fittingType: bgData.fittingType,
                    alignType: bgData.alignType,
                    compProp: _.pick(mediaData, consts.playback.SUPPORTED_MEDIA_ATTRIBUTES)
                }
            );
        },

        notifyVideoVisibility(showMedia) {
            if (showMedia !== this.state.showMedia) {
                this.setState({showMedia});
            }
        },

        getSkinProperties() { // eslint-disable-line complexity
            const visibleBgPageId = this.state.currentVisiblePageId || this.props.compData.background.id;
            let previousVisibleBgPageId = _.get(this.state.previousPageBackground, 'id', 'noPrev');

            previousVisibleBgPageId = previousVisibleBgPageId === visibleBgPageId ? 'noPrev' : previousVisibleBgPageId;

            let previousPageBackground = null;
            const shouldPlayVideo = !this.props.isTouchDevice;
            let previousVideo, currentVideo, previousImage;
            const baseId = this.props.id;

            const currentPageBackground = this.props.compData.background;

            const currentImageStyle = getImageStyle(this.props, currentPageBackground);
            const currentImage = {
                id: `${baseId}_currentImage_${visibleBgPageId}`,
                style: currentImageStyle,
                'data-type': consts.balataConsts.BG_IMAGE,
                'data-height': currentImageStyle.height
            };

            if (shouldPlayVideo) {
                currentVideo = this.createVideoComponent(currentPageBackground,
                    'current',
                    this.props.mediaQuality,
                    this.props.renderParts.media.video,
                    this.props.playbackUrl,
                    this.props.playbackFormat,
                    this.props.playbackConfig);
                if (!currentVideo) {
                    // clear media api for non videos
                    this.setMediaAPI(null);
                }
            }

            if (this.state.previousPageBackground) {
                previousPageBackground = this.state.previousPageBackground;

                previousImage = {
                    id: `${baseId}_previousImage_${previousVisibleBgPageId}`,
                    style: previousPageBackground ? getImageStyle(this.props, previousPageBackground) : {}
                };

                if (shouldPlayVideo) {
                    previousVideo = this.createVideoComponent(previousPageBackground,
                        'previous',
                        previousPageBackground.mediaQuality,
                        previousPageBackground.renderParts.media.video,
                        previousPageBackground.playbackUrl,
                        previousPageBackground.playbackFormat,
                        previousPageBackground.playbackConfig);
                }
            }

            this.handleParallaxBehavior(currentPageBackground);
            const currentBgStyle = getRootStyle(this.props, currentPageBackground, false);
            const {minHeight, top} = this.props.style;
            const skinProps = {
                '': {
                    id: baseId,
                    style: {
                        height: '100%',
                        top,
                        minHeight
                    },
                    className: this.getClasses(),
                    'aria-hidden': this.state.isAriaHidden
                },
                'current': {
                    key: visibleBgPageId + getAllowedScrollType(currentPageBackground, this.props) + this.props.componentViewMode,
                    id: `${baseId}_current_${visibleBgPageId}`,
                    style: currentBgStyle,
                    'data-position': currentBgStyle.position,
                    'data-align': currentPageBackground.alignType,
                    'data-fitting': currentPageBackground.fittingType
                },
                currentImage,
                currentVideo,
                'currentOverlay': {
                    id: `${baseId}_currentOverlay_${visibleBgPageId}`,
                    style: getOverlayStyle(this.props, currentPageBackground)
                },

                'previous': {
                    key: previousVisibleBgPageId + getAllowedScrollType(previousPageBackground, this.props) + this.props.componentViewMode,
                    id: `${baseId}_previous_${previousVisibleBgPageId}`,
                    style: previousPageBackground ? getRootStyle(this.props, previousPageBackground, this.state.hidePrevious) : {},
                    'data-position': currentBgStyle.position,
                    'data-align': _.get(previousPageBackground, 'alignType', ''),
                    'data-fitting': _.get(previousPageBackground, 'fittingType', '')
                },
                previousImage,
                previousVideo,
                'previousOverlay': {
                    id: `${baseId}_previousOverlay_${previousVisibleBgPageId}`,
                    style: previousPageBackground ? getOverlayStyle(this.props, previousPageBackground) : {}
                }
            };

            if (this.props.hideSiteBackground) {
                skinProps['']['data-dead-comp'] = true;
            }

            return skinProps;
        },

        setAriaHiddenState(isAriaHidden) {
            this.setState({
                isAriaHidden
            });
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.SiteBackground', siteBackground);
    return siteBackground;
});
