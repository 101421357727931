define([], function () {
    'use strict';

    /**
     *  A wrapper module for requirejs to enable SSR code to override this module with its own require function.
     *  This module will be used in client-side only, SSR module will override this module and use the loadFromWeb.
     */
    return {
        requireComponentFromUrl: url => new Promise((resolve, reject) => requirejs([url], resolve, reject))
    };
});
