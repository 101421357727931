define(['lodash', 'coreUtils', 'tpaComponents/utils/tpaUtils'], function (_, coreUtils, tpaUtils) {
    'use strict';

    const getSitePagesInfoData = function (siteData, options) {
        options = options || {};
        let siteMenu = coreUtils.menuUtils.getSiteMenuWithoutRenderedLinks(siteData, true);
        if (options.filterHideFromMenuPages) {
            siteMenu = filterHiddenFromMenuPages(siteMenu, siteData.getClientSpecMap());
        }
        options.homePageId = siteData.getMainPageId(); // eslint-disable-line santa/no-side-effects
        let sitePages = _getSitePagesFromMenuItems(siteMenu, siteData, options);
        if (options.includeIsHomePage) {
            sitePages = addIsHomePageParameter(sitePages, options.homePageId);
        }
        if (options.includePagesUrl) {
            sitePages = addUrlParameter(siteData, sitePages, _.get(options, 'baseUrl'), options);
        }
        return sitePages;
    };

    const filterHiddenFromMenuPages = function (siteMenu, clientSpecMap) {
        return _.filter(siteMenu, function (item) {
            return !isPageMarkedAsHideFromMenu(clientSpecMap, item.link);
        });
    };

    function getPageUrl(siteData, pageId, pageUriSEO, baseUrl) {
        return coreUtils.wixUrlParser.getUrl(siteData, {
            pageId,
            title: pageUriSEO
        }, undefined, true, baseUrl);
    }

    const addUrlParameter = function (siteData, sitePages, baseUrl) {
        return _.map(sitePages, function (sitePage) {
            const pageData = siteData.getDataByQuery(sitePage.id, 'masterPage');
            if (pageData && pageData.pageUriSEO) {
                sitePage.url = getPageUrl(siteData, sitePage.id, pageData.pageUriSEO, baseUrl);
            }
            if (sitePage.subPages) {
                sitePage.subPages = addUrlParameter(siteData, sitePage.subPages, baseUrl);
            }
            return sitePage;
        });
    };

    const isPageMarkedAsHideFromMenu = function (clientSpecMap, linkObject) {
        if (_.get(linkObject, 'type') === 'PageLink') {
            const tpaApplicationId = _.get(linkObject, 'pageId.tpaApplicationId');
            const tpaPageId = _.get(linkObject, 'pageId.tpaPageId');
            const appData = _.get(clientSpecMap, tpaApplicationId);
            return tpaUtils.isPageMarkedAsHideFromMenu(appData, tpaPageId);
        }
        return false;
    };

    const addIsHomePageParameter = function (sitePages, homePageId) {
        return _.map(sitePages, function (sitePage) {
            sitePage.isHomepage = isHomePage(sitePage, homePageId); // eslint-disable-line santa/no-side-effects
            if (sitePage.subPages) {
                sitePage.subPages = addIsHomePageParameter(sitePage.subPages, homePageId);
            }
            return sitePage;
        });
    };

    const isHomePage = function (pageData, homePageId) {
        return !_.isUndefined(pageData) &&
            pageData.id === homePageId;
    };

    const getSitePagesData = function (siteData) {
        const pages = getSitePagesInfoData(siteData);
        return _getPagesData(pages, siteData);
    };

    const _getSitePagesFromMenuItems = function (menuItems, siteData, options) {
        const pages = [];
        const pageInfoFunc = options.enhancedInfo ? _getEnhancedPageInfo : _getPageInfo;
        _.forEach(menuItems, function (item) {
            const pageInfo = pageInfoFunc(item, siteData, options);
            const subItems = item.items;
            _.forEach(subItems, function (subItem) {
                const subPageInfo = pageInfoFunc(subItem, siteData, options);
                pageInfo.subPages = pageInfo.subPages || [];
                pageInfo.subPages.push(subPageInfo);
            });
            pages.push(pageInfo);
        });
        return pages;
    };

    const _getPageInfo = function (pageData) {
        if (pageData.link) {
            const pageId = _.get(pageData.link, 'pageId.id');
            return {
                title: pageData.label || '',
                id: coreUtils.stringUtils.startsWith(pageId, '#') ? pageId.substr(1) : pageId,
                hide: !pageData.isVisible || false
            };
        }
        return {};
    };

    const _getPageId = function (pageData) {
        const pageId = _.get(pageData, 'link.pageId.id');
        if (pageId) {
            return coreUtils.stringUtils.startsWith(pageId, '#') ? pageId.substr(1) : pageId;
        }
        return '';
    };

    const getExtraPageInfo = function (pageData, siteData, options) {
        const pageId = _getPageId(pageData);
        const pageUriSEO = _.get(pageData, 'link.pageId.pageUriSEO');
        const page = {
            isHomePage: pageId === options.homePageId
        };
        if (pageUriSEO) {
            page.url = getPageUrl(siteData, pageId, pageUriSEO, options.baseUrl);
        }
        return page;
    };

    const _getEnhancedPageInfo = function (menuItem, siteData, options) {
        let link = coreUtils.linkUtils.getRelevantLinkDataFromMenuItem(menuItem);
        link = _.merge(link, {
            type: link.type || 'MenuHeader',
            title: menuItem.label || '',
            hidden: !menuItem.isVisible || false
        });

        switch (link.type) {
            case 'PageLink':
                link = _.merge(link, getExtraPageInfo(menuItem, siteData, options));
                break;
            case 'AnchorLink':
                link = _.merge(link, getExtraPageInfo(menuItem, siteData, options));
                break;
            case 'DocumentLink':
                link.url = coreUtils.linkUtils.getDocumentLink(menuItem.link, siteData);
                break;
        }

        return link;
    };


    const _getPageData = function (id, siteData) {
        return siteData.getDataByQuery(id, 'masterPage');
    };

    const _getPagesData = function (pages, siteData) {
        const pagesFullData = [];
        _.forEach(pages, function (pageData) {
            _.forEach(pageData.subPages, function (subPageData) {
                pagesFullData.push(_getPageData(subPageData.id, siteData));
            });
            pagesFullData.push(_getPageData(pageData.id, siteData));
        });
        return pagesFullData;
    };

    return {
        getSitePagesInfoData,
        getSitePagesData
    };
});
