import coreUtils from 'santa-core-utils'

const {SVG_TYPES} = coreUtils.svgUtils

const attributesRegexes = {
    fill: /fill="(.*?)"/g,
    'data-color': /data-color="(.*?)"/g,
    'data-type': /data-type="(.*?)"/g,
    'data-bbox': /data-bbox="(.*?)"/g,
    width: /width="(.*?)"/g,
    height: /height="(.*?)"/g,
    viewBox: /viewBox="(.*?)"/g
}

const elementsRegexes = {
    svg: /(<svg(.*?)>)/g,
    path: /(<path(.*?)>)/g
}

const getAll = (str, regex) => {
    const match = regex.exec(str)
    if (match) {
        return [match[1], ...getAll(str, regex)]
    }
    return []
}

const getUGCViewBox = (svgType, svgNode) => {
    if (svgType === SVG_TYPES.UGC) {
        const [width] = getAll(svgNode, attributesRegexes.width)
        const [height] = getAll(svgNode, attributesRegexes.height)
        if (width && height) {
            return `0 0 ${width} ${height}`
        }
    }
    return ''
}

const getColors = nodes => nodes.reduce((colors, node) => {
    const [dataColor] = getAll(node, attributesRegexes['data-color'])
    const [fill] = getAll(node, attributesRegexes.fill)
    colors[`color${dataColor}`] = fill
    return colors
}, {})

module.exports = {
    parseSvgString: string => {
        const [svgNode] = getAll(string, elementsRegexes.svg)
        const pathNodes = getAll(string, elementsRegexes.path)

        const [svgType] = getAll(svgNode, attributesRegexes['data-type'])
        const [viewBox] = getAll(svgNode, attributesRegexes.viewBox).concat([getUGCViewBox(svgType, svgNode)])
        const [bbox] = getAll(svgNode, attributesRegexes['data-bbox']) || ''

        const svgInfo = {
            ...getColors(pathNodes),
            svgType,
            viewBox,
            bbox
        }

        return {
            content: string,
            info: svgInfo,
            boxBoundaries: {}
        }
    }
}
