define(['lodash', 'warmupUtils/core/SiteDataPrivates', 'warmupUtils/dal/DisplayedJsonDal', 'warmupUtils/pointers/pointersCache', 'warmupUtils/pointers/DataAccessPointers'], function (_, SiteDataPrivates, displayedJsonDalFactory, PointersCache, DataAccessPointers) {
    'use strict';

    const dalPrivates = new SiteDataPrivates(); //eslint-disable-line santa/no-module-state

    function getCacheInstance(siteData, fullJson) {
        const privates = dalPrivates.get(siteData);
        if (privates && privates.pointersCache) {
            return privates.pointersCache;
        }

        const privateFullJson = getFullPagesData(siteData, fullJson || _.pick(siteData, 'pagesData'));
        const pointersCache = new PointersCache(siteData, siteData, privateFullJson, _.get(privates, 'mobxDataHandlers'));

        dalPrivates.set(siteData, _.assign(dalPrivates.get(siteData), {pointersCache}));

        return pointersCache;
    }

    function getPointersInstance(siteData, fullJson) {
        const cacheInstance = getCacheInstance(siteData, fullJson);
        return new DataAccessPointers(cacheInstance);
    }

    function getInstance(siteData, fullJson) {
        const privates = dalPrivates.get(siteData);
        if (privates && privates.displayedJsonDal) {
            return privates.displayedJsonDal;
        }

        const cache = getCacheInstance(siteData, fullJson);
        const displayedJsonDal = displayedJsonDalFactory(siteData, cache.getBoundCacheInstance(false), _.get(privates, 'mobxDataHandlers'));

        dalPrivates.set(siteData, _.assign(dalPrivates.get(siteData), {displayedJsonDal}));

        return displayedJsonDal;
    }

    function getFullPagesData(siteData, fullJson) {
        const privates = dalPrivates.get(siteData);
        if (privates && privates.fullJson) {
            return privates.fullJson;
        }

        dalPrivates.set(siteData, _.assign(dalPrivates.get(siteData), {fullJson}));

        return fullJson;
    }

    function setMobxDataHandlers(siteData, dataHandlers) {
        const privates = dalPrivates.get(siteData);
        dalPrivates.set(siteData, _.assign(privates, {mobxDataHandlers: dataHandlers}));
    }

    return {
        setMobxDataHandlers,
        getInstance,
        getCacheInstance,
        getPointersInstance,
        getFullPagesData
    };
});
