define([
    'backgroundCommon/mixins/backgroundDetectionMixin',
    'backgroundCommon/components/bgImage',
    'backgroundCommon/components/bgMedia',
    'backgroundCommon/components/bgOverlay',
    'backgroundCommon/components/html5Video',
    'backgroundCommon/components/webglMedia',
    'backgroundCommon/components/iframeVideo',
    'skins',
    'backgroundCommon/skins/skins.json',
    'componentsCore'
], function (
    backgroundDetectionMixin,
    bgImage,
    bgMedia,
    bgOverlay,
    html5Video,
    webglMedia,
    iframeVideo,
    skinsPackage,
    skinsJson,
    componentsCore
) {
    'use strict';

    const backgroundCommon = {
        mixins: {
            backgroundDetectionMixin
        },
        components: {
            bgImage,
            bgMedia,
            bgOverlay,
            html5Video,
            webglMedia,
            iframeVideo
        }
    };

    componentsCore.compRegistrar
        .register('wysiwyg.viewer.components.background.bgMedia', backgroundCommon.components.bgMedia)
        .register('wysiwyg.viewer.components.background.bgImage', backgroundCommon.components.bgImage)
        .register('wysiwyg.viewer.components.background.html5Video', backgroundCommon.components.html5Video)
        .register('wysiwyg.viewer.components.background.webglMedia', backgroundCommon.components.webglMedia)
        .register('wysiwyg.viewer.components.background.iframeVideo', backgroundCommon.components.iframeVideo)
        .register('wysiwyg.viewer.components.background.bgOverlay', backgroundCommon.components.bgOverlay);

    skinsPackage.skinsMap.addBatch(skinsJson);

    return backgroundCommon;
});
