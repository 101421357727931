define(['lodash', 'warmupUtils/pointers/pointerGeneratorsRegistry'], function (_, pointerGeneratorsRegistry) {
    'use strict';

    const POINTER_TYPE = 'layoutAdjustment';

    pointerGeneratorsRegistry.registerPointerType(POINTER_TYPE, _.constant(null), _.constant(true));

    const getterFunctions = {
        getLayoutAdjustmentPointer(getItemAt, cache) {
            return cache.getPointer('layoutAdjustment', POINTER_TYPE, ['layoutAdjustment']);
        }
    };

    pointerGeneratorsRegistry.registerDataAccessPointersGenerator(POINTER_TYPE, getterFunctions);
});
