define([], function () {
    'use strict';

    const WIX_CHAT = '14517e1a-3ff0-af98-408e-2bd6953c36a2';
    const PERF_CATEGORY = {category: 'tpa'};

    return {
        PERF_CATEGORY,
        PERMITTED_GLUED_TPAS_IN_MOBILE: [WIX_CHAT],
        PERMITTED_FULL_SCREEN_TPAS_IN_MOBILE: [WIX_CHAT]
    };
});
