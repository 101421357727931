'use strict'

module.exports = {
    name: 'ModesAspect',
    functionLibrary: {
        activateModeById: (updateActiveModes, compId, rootId, modeId) => {
            updateActiveModes(modeId, true)
        },

        deactivateModeById: (updateActiveModes, compId, rootId, modeId) => {
            updateActiveModes(modeId, false)
        }
    }
}
