define([
    'lodash',
    'warmupUtilsLib'
], function (
    _,
    warmupUtilsLib
) {
    'use strict';

    const {urlUtils} = warmupUtilsLib;

    const getMLPointerValue = (selector, defaultVal) => ({dal, pointers}) => dal.get(pointers.multilingual[selector]()) || defaultVal;

    const Multilingual = {

        PointerOperation: {
            GET: 'get',
            SET: 'set'
        },

        EMPTY_TRANSLATION_DATA: {
            data: {
                document_data: {}
            }
        },

        setCurrentLanguage(state) {
            return languageCode => {
                const {siteDataAPI, siteAPI, dal, pointers} = state;
                const {siteData} = siteDataAPI;

                if (this.getCurrentLanguageCode({dal, pointers}) === languageCode) { return; }

                dal.set(pointers.multilingual.currentLanguageCode(), languageCode);

                if (!(siteData.isInSSR() || window.isMockWindow)) {
                    const htmlElement = window.document.querySelector('html');
                    htmlElement.setAttribute('lang', languageCode);
                }
                const url = siteData.currentUrl.full;
                const updatedUrl = urlUtils.setUrlParams(url, {lang: languageCode});

                urlUtils.updateUrl(updatedUrl);

                const pageInfo = _.omit(siteData.getRootNavigationInfo(), ['queryParams']);
                const navigationInfo = _.assign(
                    pageInfo,                    
                    {
                        isLanguageChange: true
                    },
                    urlUtils.parseUrl(updatedUrl)
                );

                siteAPI.updatePageNavInfo(navigationInfo);
            };
        },

        getCurrentLanguageCode: getMLPointerValue('currentLanguageCode', null),

        getOriginalLanguage: getMLPointerValue('originalLanguage', {}),

        getTranslationLanguages: getMLPointerValue('translationLanguages', []),

        getTranslationPath(currentLanguageCode, path, insertPosition) {
            return _.concat(
                _.take(path, insertPosition),
                'translations',
                currentLanguageCode,
                _.drop(path, insertPosition)
            );
        },

        generatePageTranslationsData({dal, pointers}) {
            return _.reduce(Multilingual.getTranslationLanguages({dal, pointers}), (acc, {languageCode}) => {
                acc[languageCode] = _.cloneDeep(Multilingual.EMPTY_TRANSLATION_DATA);
                return acc;
            }, {});
        }
    };

    return Multilingual;
});
