define([
    'lodash',
    'skins',
    'componentsCore/components/transitionGroup/wixTransitionGroup',
    'componentsCore/activities/activityTypes',
    'componentsCore/activities/activityService',
    'componentsCore/mixins/uniquePageIdMixin',
    'componentsCore/mixins/postMessageCompMixin',
    'componentsCore/mixins/blockOuterScrollMixin',
    'componentsCore/mixins/cyclicTabbingMixin',
    'componentsCore/mixins/galleryImageExpandedActionMixin',
    'componentsCore/mixins/createChildComponentMixin',
    'componentsCore/mixins/mediaZoomWrapperMixin',
    'componentsCore/utils/accessibility/accessibility',
    'componentsCore/utils/matrixAnimationManipulation',
    'componentsCore/utils/boxSlideShowCommon',
    'componentsCore/utils/galleryPagingCalculations',
    'componentsCore/utils/fullScreenOverlay',
    'componentsCore/registrars/dataRequirementsCheckerRegistrar',
    'componentsCore/registrars/siteAspectsRegistry',
    'componentsCore/registrars/santaTypesFetchersRegistry',
    'componentsCore/registrars/compRegistrar',
    'santa-components'
], function (
    _,
    skinsPackage,
    wixTransitionGroup,
    activityTypes,
    activityService,
    uniquePageIdMixin,
    postMessageCompMixin,
    blockOuterScrollMixin,
    cyclicTabbingMixin,
    galleryImageExpandedActionMixin,
    createChildComponentMixin,
    mediaZoomWrapperMixin,
    accessibility,
    matrixAnimationManipulation,
    boxSlideShowCommon,
    galleryPagingCalculations,
    fullScreenOverlay,
    dataRequirementsCheckerRegistrar,
    siteAspectsRegistry,
    santaTypesFetchersRegistry,
    compRegistrar,
    santaComponents
) {
    'use strict';

    return {
        dataRequirementsCheckerRegistrar,
        compRegistrar,
        siteAspectsRegistry,
        santaTypesFetchersRegistry,
        activityTypes,
        activityService,
        mixins: {
            skinBasedComp: santaComponents.mixins.skinBasedComp(skinsPackage.skinsMap),
            customSkinBasedComp: ({customCssFunc, customFontsFunc}) => {
                const mixin = santaComponents.mixins.skinBasedComp(skinsPackage.skinsMap);

                if (customCssFunc) {
                    const baseGetCompCss = mixin.statics.getCompCss;
                    mixin.statics.getCompCss = (styleId, props) => {
                        const defaultCss = baseGetCompCss(styleId, props);
                        const getSkin = skinId => skinsPackage.skinsMap.get(skinId);
                        const customCss = customCssFunc(getSkin, props);

                        return _.assign(defaultCss, customCss);
                    };
                    mixin.statics.getCompCss.cssTypes = _.assign(customCssFunc.cssTypes, mixin.statics.getCompCss.cssTypes);
                }

                if (customFontsFunc) {
                    const baseGetCompFonts = mixin.statics.getCompFonts;
                    mixin.statics.getCompFonts = (styleIds, props) => {
                        const defaultFonts = baseGetCompFonts(styleIds, props);
                        const customFonts = customFontsFunc(props);

                        return _.union(defaultFonts, customFonts);
                    };
                    mixin.statics.getCompFonts.fontsTypes = _.assign(customFontsFunc.fontsTypes, mixin.statics.getCompFonts.fontsTypes);
                }

                return mixin;
            },
            skinInfo: santaComponents.mixins.skinInfoMixin(skinsPackage.skinsMap),
            cyclicTabbingMixin,
            blockOuterScrollMixin,
            uniquePageIdMixin,
            galleryImageExpandedActionMixin,
            createChildComponentMixin,
            mediaZoomWrapperMixin,
            postMessageCompMixin
        },
        utils: {
            accessibility,
            matrixAnimationManipulation,
            boxSlideShowCommon,
            galleryPagingCalculations,
            fullScreenOverlay
        },
        wixTransitionGroup
    };
});
