define([
    'lodash',
    'warmupUtils/core/pathValidationUtil',
    'warmupUtilsLib',
    'warmupUtils/pointers/pointerGeneratorsRegistry',
    'warmupUtils/core/multilingual',
    'experiment'
], function (
    _,
    pathValidationUtil,
    warmupUtilsLib,
    pointerGeneratorsRegistry,
    Multilingual,
    experiment
) {
    'use strict';

    const {constants} = warmupUtilsLib;
    const NOT_FOUND = 0;
    const PARENT_FOUND = 1;
    const FOUND_AND_CORRECT = 2;

    function getItemInPath(json, path) {
        let object = json;
        _.forEach(path, function (pathPart) {
            if (_.isUndefined(object)) {
                return false;
            }
            object = object[pathPart];
        });
        return object;
    }

    function isEqualPath(a, b) {
        if (!a) {
            return !b;
        }
        if (!b || b.length !== a.length) {
            return false;
        }
        for (let i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) {
                return false;
            }
        }
        return true;
    }

    /**
     *
     * @constructor DS.PointersCache
     */
    function PointersCache(siteData, json, fullJson, mobxDataHandlers) {
        this.initMyState();
        this.json = json;
        this.fullJson = fullJson;
        this.siteData = siteData;

        const getItemInPathFunc = _.get(mobxDataHandlers, 'getItemInPath', getItemInPath);
        this.getItemInPath = getItemInPathFunc.bind(null, json);
        this.fullJsonGetItemInPath = getItemInPathFunc.bind(null, fullJson);
        this.validated = {};
        this.pointersMap = {};

        // uses the types registered in the dataPointers module.
        const types = pointerGeneratorsRegistry.getAllTypes();

        _.forOwn(types, (typeDescription, typeName) => {
            this.cache[typeName] = {};
            this.itemFinders[typeName] = typeDescription.findItemFunction;
            this.identityCheckers[typeName] = typeDescription.identityCheckFunction;
            this.identityCheckersCache[typeName] = {};
            if (typeDescription.isUsingDifferentNameSpaceForFull) {
                this.fullJsonCache[typeName] = {};
            }
            if (typeDescription.isExistInFullJson) {
                this.typesExistingInFullJson[typeName] = true;
            }
            this.supportDisplayedOnlySplit[typeName] = typeDescription.supportDisplayedOnlySplit;
        });
    }

    function checkPointerPathValidity(validatedKey, pointer, path, ignoreLastPartInPointerPath, json) { // eslint-disable-line complexity
        let valid = this.validated[validatedKey] || NOT_FOUND;

        if (ignoreLastPartInPointerPath) {
            return valid || (pathValidationUtil.validatePathExist(json, path, false) ? PARENT_FOUND : NOT_FOUND);
        }

        if (valid !== FOUND_AND_CORRECT) {
            const id = pointer.id;
            const identityCheckers = this.identityCheckersCache[pointer.type];
            let identityChecker = identityCheckers[id];
            if (!identityChecker) {
                identityChecker = this.identityCheckers[pointer.type].bind(this, id);
                identityCheckers[id] = identityChecker;
            }
            valid = pathValidationUtil.validatePathExistsAndCorrect(json, path, identityChecker) ? FOUND_AND_CORRECT : NOT_FOUND;
        }

        return valid;
    }

    function findAndCachePathForPointer(pointer, typeCache, oldPath, getItemInPathFunction) {
        let foundPath = null;
        const itemFinder = this.itemFinders[pointer.type];
        if (itemFinder) {
            foundPath = itemFinder(this.siteData.getAllPossiblyRenderedRoots(), getItemInPathFunction, pointer, experiment.isOpen('sv_componentPointersFindComponentInOtherPages', this.siteData));
            typeCache[pointer.id] = foundPath || oldPath;
        }
        return foundPath;
    }

    function getValidationIndex(pointer, isUsingFullJson) {
        const sep = isUsingFullJson ? '|' : ',';
        return pointer.id + sep + pointer.type;
    }

    function _getPath(isUsingFullJson, pointer, ignoreLastPartInValidation) { // eslint-disable-line complexity
        const isUsingFullCache = isUsingFullJson && this.fullJsonCache.hasOwnProperty(pointer.type);
        const cache = isUsingFullCache ? this.fullJsonCache : this.cache;
        //TODO (Alissa): find a better way.. :)
        const isLookingInFullJson = isUsingFullJson && this.typesExistingInFullJson.hasOwnProperty(pointer.type);
        const json = isLookingInFullJson ? this.fullJson : this.json;
        const getItemInPathFunction = isLookingInFullJson ? this.fullJsonGetItemInPath : this.getItemInPath;

        const typeCache = cache[pointer.type];
        if (!typeCache) {
            return null;
        }
        let path = typeCache[pointer.id];
        const ignoreLastPartInPointerPath = ignoreLastPartInValidation && _.isEmpty(pointer.innerPath);

        const index = getValidationIndex(pointer, isUsingFullCache);
        let valid = checkPointerPathValidity.call(this, index, pointer, path, ignoreLastPartInPointerPath, json, isUsingFullCache);

        if (valid === NOT_FOUND) {
            //no point in looking, probably won't find it otherwise why ignore last part..
            path = ignoreLastPartInPointerPath ? null : findAndCachePathForPointer.call(this, pointer, typeCache, path, getItemInPathFunction);
            //so that we won't override the PARENT_FOUND
            valid = path ? FOUND_AND_CORRECT : this.validated[index] || NOT_FOUND;
        }

        this.validated[index] = valid;

        if (path && pointer.innerPath) {
            path = path.concat(pointer.innerPath);
            if (!pathValidationUtil.validatePathExist(json, path, !ignoreLastPartInValidation)) {
                return null;
            }
        }

        if (pointer.hasOwnProperty('multilingual')) { // No need to check experiment because it's already checked in getPath
            const {multilingual} = this.siteData;
            const currentLanguageCode = (multilingual && multilingual.currentLanguageCode) || null;
            if (currentLanguageCode !== null && path && path[0] === 'pagesData') { // check if ML is enabled
                const {multilingualInfo} = this.siteData.rendererModel.sitePropertiesInfo;
                const originalLanguageCode = _.get(multilingualInfo, 'originalLanguage.languageCode', null);
                if (currentLanguageCode !== originalLanguageCode && pathValidationUtil.validatePathExist(json, path, true)) {
                    const translationPath = Multilingual.getTranslationPath(currentLanguageCode, path, _.indexOf(path, 'data'));
                    const validateLastPathPart = pointer.multilingual === Multilingual.PointerOperation.GET;
                    if (pathValidationUtil.validatePathExist(json, translationPath, validateLastPathPart)) {
                        return translationPath;
                    }
                }
            }
        }

        return path;
    }

    PointersCache.prototype = {
        initMyState() {
            this.cache = {general: {}};
            this.fullJsonCache = {};
            this.itemFinders = {
                general() {
                    return null;
                } //we can't look for these, and we assume they don't move
            };
            this.identityCheckers = {
                general() {
                    return true;
                } //we assume they don't move..
            };
            this.identityCheckersCache = {};
            //TODO: find a way to get rid of this, the jsons should look the same
            //now we have only the pagesData on the full json
            this.typesExistingInFullJson = {};
            this.supportDisplayedOnlySplit = {};
        },

        getBoundCacheInstance(isUsingFullJson) {
            return {
                getAllPointers: this.getAllPointers.bind(this),
                registerDisplayedOnlyComponent: this.registerDisplayedOnlyComponent.bind(this),
                clearDisplayedOnlyComponents: this.clearDisplayedOnlyComponents.bind(this),
                getPath: this.getPath.bind(this, isUsingFullJson),
                getPointer: this.getPointer.bind(this, isUsingFullJson),
                setPath: this.setPath.bind(this, isUsingFullJson),
                resolveId: this.resolveId.bind(this, isUsingFullJson),
                resetValidations: this.resetValidations.bind(this),
                isMoveStyleExperimentOpen: this.isMoveStyleExperimentOpen.bind(this)
            };
        },

        isMoveStyleExperimentOpen() {
            return _.get(this.siteData.getMasterPageStyleSettings(), 'stylesPerPage') === constants.STYLES_PER_PAGE_VER;
        },

        setPath(isUsingFullJson, pointer, path) {
            if (path) {
                if (isUsingFullJson) {
                    this.fullJsonCache[pointer.type][this.resolveId(isUsingFullJson, pointer.id, pointer.type)] = path;
                } else {
                    this.cache[pointer.type][this.resolveId(isUsingFullJson, pointer.id, pointer.type)] = path;
                }
            }
        },

        // To avoid deopt on getPath due to different pointer structures
        getPath(isUsingFullJson, pointer, ignoreLastPartInValidation) {
            if (!pointer) {
                return null;
            }
            const p = {
                type: pointer.type,
                id: this.resolveId(isUsingFullJson, pointer.id, pointer.type)
            };
            if (pointer.hasOwnProperty('innerPath')) {
                p.innerPath = pointer.innerPath;
            }

            if (pointer.hasOwnProperty('multilingual') && pointer.useOriginalLanguage !== true) {
                p.multilingual = pointer.multilingual;
            }
            return _getPath.call(this, isUsingFullJson, p, !!ignoreLastPartInValidation);
        },

        getPointer(isUsingFullJson, id, type, path) {
            const isUsingFullCache = isUsingFullJson && this.fullJsonCache[type];
            const typeCache = isUsingFullCache ? this.fullJsonCache[type] : this.cache[type];
            const pointer = {
                type,
                id
            };
            if (path) {
                if (!isEqualPath(typeCache[id], path)) {
                    this.validated[getValidationIndex(pointer, isUsingFullCache)] = NOT_FOUND;
                    typeCache[id] = path;
                }
            }
            return typeCache[id] ? pointer : null;
        },

        getAllPointers(pointer) {
            const resolvedId = this.resolveId(true, pointer.id, pointer.type);
            const compPointersMap = _.get(this.pointersMap, resolvedId);
            if (!compPointersMap) {
                return [pointer];
            }

            const allPointers = _(compPointersMap)
                .map(compId => ({
                    id: compId,
                    path: this.getPath(false, {type: pointer.type, id: compId}, false)
                }))
                .filter('path')
                .map(def => {
                    const displayedPointer = this.getPointer(false, def.id, pointer.type, def.path);
                    if (pointer.innerPath && pointer.innerPath.length) {
                        displayedPointer.innerPath = pointer.innerPath;
                    }
                    return displayedPointer;
                })
                .value();

            if (resolvedId === pointer.id && _.isEmpty(allPointers)) {
                delete this.pointersMap[resolvedId];
                return [pointer];
            }

            return allPointers;
        },

        registerDisplayedOnlyComponent(source, dest) {
            const currentMap = _.get(this.pointersMap, source, {});
            currentMap[dest] = dest;
            this.pointersMap[source] = currentMap;
        },

        clearDisplayedOnlyComponents(source) {
            delete this.pointersMap[source];
        },

        resolveId(isUsingFullJson, id, type) {
            return isUsingFullJson && this.supportDisplayedOnlySplit[type] && warmupUtilsLib.displayedOnlyStructureUtil.getOriginalId(id) || id; // eslint-disable-line no-mixed-operators
        },

        resetValidations() {
            this.validated = {};
        }
    };

    return PointersCache;

    /**
     * @typeDef {Object} jsonDataPointer
     * @property {String} type
     * @property {String} id
     */
});
