define([
    'coreUtils',
    'components/components/bootstrap/repeater/layouters/repeaterLayouter',
    'components/components/bootstrap/repeater/utils/repeaterStructurePlugin'
], function (
    coreUtils,
    repeaterLayouter,
    repeaterStructurePlugin
) {
    'use strict';

    const repeaterCompType = 'wysiwyg.viewer.components.Repeater';
    coreUtils.jsonUpdaterRegistrar.registerPlugin(repeaterCompType, repeaterStructurePlugin);
    coreUtils.jsonUpdaterRegistrar.registerLayouterComp(repeaterCompType, repeaterLayouter);
});
