define([
    'lodash',
    'coreUtils'
], function (
    _,
    coreUtils) {
    'use strict';

    function checkRequirements(siteData, compInfo) {
        const svgId = _.get(compInfo, ['properties', 'overrideCrop', 'svgId'], _.get(compInfo, ['data', 'crop', 'svgId']));
        if (svgId) {
            return _.compact([
                coreUtils.svg.createSvgFetchRequest(
                    siteData.serviceTopology.mediaRootUrl,
                    siteData,
                    svgId
                )
            ]);
        }
        return [];
    }


    return {
        checkRequirements
    };
});
