define(['santa-components', 'lodash', 'tpaComponents/common/supportedSiteEvents'], function (santaComponents, _, SUPPORTED_SITE_EVENTS) {
    'use strict';

    return {
        propTypes: {
            getExistingRootNavigationInfo: santaComponents.santaTypesDefinitions.getExistingRootNavigationInfo,
            rootId: santaComponents.santaTypesDefinitions.Component.rootId.isRequired,
            pageUrlWithHash: santaComponents.santaTypesDefinitions.pageUrlWithHash.isRequired,
            pageId: santaComponents.santaTypesDefinitions.Component.pageId,
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            isViewerMode: santaComponents.santaTypesDefinitions.isViewerMode,
            currentUrlPageId: santaComponents.santaTypesDefinitions.Component.currentUrlPageId,
            dynamicClientSpecMapAspect: santaComponents.santaTypesDefinitions.SiteAspects.dynamicClientSpecMapAspect.isRequired
        },
        isTPASection: true,

        getSiteUrlStateFromProps(props) {
            const info = props.getExistingRootNavigationInfo(props.rootId);
            return info && info.tpaInnerRoute || ''; // eslint-disable-line no-mixed-operators
        },

        getSiteUrlQueryParamsFromProps(props) {
            const info = props.getExistingRootNavigationInfo(props.rootId);

            const appSectionParams = _.get(info, 'queryParams.appSectionParams');
            let queryParams = {};

            if (appSectionParams) {
                try {
                    queryParams = JSON.parse(appSectionParams);
                } catch (ex) { /* bummer */
                }
            }

            return queryParams;
        },

        mutateSkinProperties(skinProps) {
            if (typeof skinProps.iframe === 'object') {
                if (skinProps.iframe.src) {
                    skinProps.iframe.src = this.buildUrl(this.getBaseUrl());
                } else {
                    skinProps.iframe['data-src'] = this.buildUrl(this.getBaseUrl());
                }
            }

            return skinProps;
        },

        mutateInitialState(initialState) {
            initialState.sectionUrlState = this.getSiteUrlStateFromProps(this.props); // eslint-disable-line santa/no-side-effects
            initialState.sectionUrlParams = this.getSiteUrlQueryParamsFromProps(this.props); // eslint-disable-line santa/no-side-effects
            initialState.sectionUrl = this.fixSectionUrl(); // eslint-disable-line santa/no-side-effects

            return initialState;
        },

        fixSectionUrl() {
            const sectionUrl = this.props.pageUrlWithHash;
            return this.endsWith(sectionUrl, '/') ? sectionUrl : `${sectionUrl}/`;
        },

        endsWith(str, suffix) {
            return _.endsWith(str, suffix);
        },

        isViewerMode() {
            return this.getViewMode() === 'site';
        },

        componentWillReceiveProps(nextProps) { // eslint-disable-line complexity
            const nextUrlState = this.getSiteUrlStateFromProps(nextProps);
            const nextUrlParams = this.getSiteUrlQueryParamsFromProps(nextProps);

            if (!_.isUndefined(this.state.pushState)) {
                this.urlState = this.state.pushState;
                this.setState({
                    pushState: undefined
                });
                this.reportStateChanged(this.urlState);
            } else if (nextProps.currentUrlPageId === this.props.pageId) {
                if (this.urlState !== nextUrlState || this.shouldUpdateState) {
                    this.urlState = nextUrlState;
                    this.shouldUpdateState = false;
                    this.setState({
                        sectionUrlState: nextUrlState
                    });
                    this.reportStateChanged(this.urlState);
                }
            }

            if (nextProps.currentUrlPageId === this.props.pageId && this.state.sectionUrlParams !== nextUrlParams) {
                this.setState({
                    sectionUrlParams: nextUrlParams
                });
            }
        },

        componentDidMount() {
            this.shouldUpdateState = false;
            this.props.dynamicClientSpecMapAspect.registerReloadSpecMapPlugin(this.props.pageId, function () {
                this.shouldUpdateState = true;
            }.bind(this));
        },

        reportStateChanged(nextUrlState) {
            if (this.isCompListensTo(SUPPORTED_SITE_EVENTS.STATE_CHANGED)) {
                this.sendPostMessage({
                    intent: 'addEventListener',
                    eventType: 'STATE_CHANGED',
                    params: {
                        newState: nextUrlState
                    }
                });
            }
        },

        isMobileReady() { // eslint-disable-line complexity
            const appData = this.getAppData();
            const isInDevMode = this.isInMobileDevMode && this.isInMobileDevMode();
            const compData = this.props.compData;
            const widgets = _.get(appData, 'widgets');
            if (widgets && compData.widgetId) {
                const widgetData = widgets[compData.widgetId];
                return widgetData.mobileUrl && (isInDevMode || widgetData.mobilePublished);
            }

            return appData.sectionMobileUrl && (isInDevMode || appData.sectionMobilePublished);
        },

        mutateIframeUrlQueryParam(queryParamsObj) {
            if (this.props.isViewerMode) {
                queryParamsObj['section-url'] = this.fixSectionUrl();
                queryParamsObj.target = '_top';
            } else {
                queryParamsObj['section-url'] = this.getBaseUrl();
                queryParamsObj.target = '_self';
            }

            queryParamsObj.width = this.state.initialWidth; // eslint-disable-line santa/no-side-effects

            return queryParamsObj;
        },

        mutateIframeSrc(urlObj) {
            urlObj = this.addStateToUrlObj(urlObj, this.state.sectionUrlState);

            return urlObj;
        },

        addStateToUrlObj(urlObj, state) {
            if (state) {
                const hashState = state.charAt(0) === '#';

                if (hashState) {
                    urlObj.hash = state;
                } else {
                    if (urlObj.path && urlObj.path.slice(-1) !== '/') {
                        urlObj.path += '/';
                    }

                    urlObj.path += state;
                }
            }

            return urlObj;
        }
    };
});
