import _ from 'lodash'
import {anchorTagsGenerator, xssUtils} from 'santa-core-utils'

export const name = 'SiteButtonAspect'

const getImpliedLink = (label, isInSSR, isMobileView) => {
    if (isInSSR) {
        return null
    }
    const includedPatterns = anchorTagsGenerator.getIncludedPatterns(null, isMobileView)
    return _.head(anchorTagsGenerator.findDataForAnchors(xssUtils.filterHtmlString(label), includedPatterns, {useEarlyLinkCheck: true}))
}

export const functionLibrary = {
    getImpliedLink
}
