'use strict'
const _ = require('lodash')

const addRootId = (rootId, content) =>
    _.map(content.trim().split('\n'), line => {
        const lineParts = line.split('{')
        const selectorParts = lineParts[0].split(',')
        lineParts[0] = _.map(selectorParts, part => `#${rootId} ${part}`)
        return lineParts.join('{')
    }).join('\n')

const generateStyleNodeProps = (styleId, content = '', rootId) => (
    {
        key: styleId,
        type: 'text/css',
        'data-styleid': styleId,
        dangerouslySetInnerHTML: {__html: rootId ? addRootId(rootId, content) : content}
    }
)

module.exports = {generateStyleNodeProps}