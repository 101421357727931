import {withActions} from '../withActions'
import {aspectNames} from 'common-types'

export const name = aspectNames.NonPageItemZoomAspect

export const defaultModel = {
    nonPageItemZoom: null
}

export const functionLibrary = {
    setNonPageItemZoom: withActions(({setNonPageItemZoom}, zoomedImageData, galleryData) => {
        if (!zoomedImageData || zoomedImageData.type !== 'Image' || !galleryData) {
            throw new Error('nonPageItemZoom should be used only with (imageData, galleryData)')
        }
        setNonPageItemZoom({
            image: zoomedImageData,
            galleryData
        })
    }),
    unsetNonPageItemZoom: withActions(({setNonPageItemZoom}) => {
        setNonPageItemZoom(null)
    })
}
