/**
 * Created by Talm on 14/07/2014.
 */
define(['lodash', 'warmupUtilsLib'], function (_, warmupUtilsLib) {
    'use strict';

    const matrixCalculations = warmupUtilsLib.matrixCalculations;
    /**
     *
     *
     * @class componentsCore.utils.galleryPagingCalculations
     */
    const galleryPagingCalculations = {
        getNumberOfDisplayedRows(fixedNumberOfRows, maxRows, numCols, totalItems) {
            if (fixedNumberOfRows || totalItems && totalItems < maxRows * numCols) { // eslint-disable-line no-mixed-operators
                return maxRows;
            }
            return matrixCalculations.getAvailableRowsNumber(maxRows, numCols, totalItems);
        },

        getItemsPerPage(numCols, maxRows, itemsLength) {
            return numCols * this.getNumberOfDisplayedRows(null, maxRows, numCols, itemsLength);
        },

        getTotalPageCount(numCols, maxRows, itemsLength) {
            const itemsPerPage = this.getItemsPerPage(numCols, maxRows, itemsLength);
            let totalPageCount = Math.floor(itemsLength / itemsPerPage);
            if (itemsLength % itemsPerPage > 0) {
                totalPageCount++;
            }
            return totalPageCount;
        },

        getNextPageItemIndex(pageIndex, numCols, maxRows, itemsLength) {
            let index = pageIndex + this.getItemsPerPage(numCols, maxRows, itemsLength);
            if (index >= itemsLength) {
                index = 0;
            }
            return index;
        },

        getPrevPageItemIndex(pageIndex, numCols, maxRows, itemsLength) {
            const itemsPerPage = this.getItemsPerPage(numCols, maxRows, itemsLength);
            let index = pageIndex - itemsPerPage;
            if (index < 0) {
                index = (this.getTotalPageCount(numCols, maxRows, itemsLength) - 1) * itemsPerPage;
            }
            return index;
        },

        getCounterText(currentItem, numCols, maxRows, itemsLength) {
            const currentPage = Math.floor(currentItem / this.getItemsPerPage(numCols, maxRows, itemsLength));
            let totalPageCount = this.getTotalPageCount(numCols, maxRows, itemsLength);
            if (!totalPageCount) {
                totalPageCount = 1;
            }
            return `${String(currentPage + 1)}/${String(totalPageCount)}`;
        },

        getPageItems(itemList, firstItemIndex, numCols, maxRows) {
            return this.getPageItemsByStartIndex(itemList, firstItemIndex, this.getItemsPerPage(numCols, maxRows, itemList.length));
        },

        getPageItemsByStartIndex(itemList, firstItemIndex, itemsPerPage) {
            const pageItems = [];

            if (itemsPerPage < itemList.length) {
                const finalItemIndex = this.getLastItemIndex(itemList, firstItemIndex, itemsPerPage);
                for (let i = firstItemIndex; i <= finalItemIndex; i++) {
                    pageItems.push(itemList[i]);
                }
                return pageItems;
            }

            return itemList;
        },

        getLastItemIndex(itemList, firstItemIndex, itemsPerPage) {
            return Math.min(itemList.length - 1, firstItemIndex + itemsPerPage - 1);
        }
    };
    return galleryPagingCalculations;
});
