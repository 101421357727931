'use strict'
const name = 'GlideIn'
const properties = {
    hideOnStart: true,
    viewportThreshold: 0.15
}

function register({factory}) {
    /**
     * GlideIn animation object
     * @param {Array<HTMLElement>|HTMLElement} elements DOM element to animate
     * @param {Number} [duration]
     * @param {Number} [delay]
     * @param {Object} [params]
     * @param {number} [angle=0] 0 - 360
     * @param {number} [distance=0] 0 - 300
     * @returns {TimelineMax}
     */
    function animation(elements, duration, delay, {angle = 0, distance = 0, ...params} = {}) {
        const angleInRad = angle * Math.PI / 180

        const transformX = Math.sin(angleInRad) * distance
        const transformY = Math.cos(angleInRad) * distance * -1
        const fadeDuration = 0

        const sequence = factory.sequence(params)
        sequence.add([
            factory.animate('BaseFade', elements, fadeDuration, 0, {from: {opacity: 0}, to: {opacity: 1}, ease: 'Sine.easeIn'}),
            factory.animate('BasePosition', elements, duration, delay, {
                from: {x: transformX, y: transformY},
                ease: 'Sine.easeInOut'
            })
        ], 0)

        return sequence.get()
    }

    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}
