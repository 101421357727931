'use strict'
const _ = require('lodash')
const balataConsts = require('./balataConsts')

const name = 'BackgroundParallax'
const properties = {
    hideOnStart: false,
    shouldDisableSmoothScrolling: true,
    getMaxTravel(elementMeasure, viewportHeight) {
        return viewportHeight + elementMeasure.height
    }
}

const speedFactorDefault = 0.2
const viewPortHeightDefault = 1
const componentHeightDefault = 1

function register({factory}/*, frame*/) {
    /**
     * Move balata media elements vertically (from y:0)
     * @param {Array<HTMLElement>|HTMLElement} elements DOM elements to animate
     * @param {Number} [duration]
     * @param {Number} [delay]
     * @param {Object} [params] Timeline optional parameters (Tween values cannot be changed here, use BaseFade).
     * @param {Number} [params.speedFactor] the speed of the animation relative to the scroll
     * @returns {TimelineMax}
     */
    function animation(elements, duration, delay, params) {
        params = params || {}
        const speedFactor = _.isUndefined(params.speedFactor) ? speedFactorDefault : params.speedFactor
        const viewPortHeight = params.viewPortHeight || viewPortHeightDefault
        const componentHeight = params.componentHeight || componentHeightDefault

        const sequence = factory.sequence(params)
        let childrenToAnimate

        if (_.get(params, ['browserFlags', 'animateParallaxScrubAction'])) {
            //fixed layers on Edge Browser, jitter while scrolling , we're animating the layers for steady reveal.
            _.forEach(elements, element => {
                childrenToAnimate = _.map(balataConsts.PARALLAX_SELECTORS, selector => element.querySelector(selector))
                sequence.add([
                    factory.animate('BasePosition', element, duration, delay, {
                        from: {y: viewPortHeight},
                        to: {y: -componentHeight},
                        force3D: true,
                        immediateRender: true
                    }),
                    factory.animate('BasePosition', childrenToAnimate, duration, delay, {
                        from: {y: viewPortHeight * (speedFactor - 1)},
                        to: {y: componentHeight * (1 - speedFactor)},
                        force3D: true,
                        immediateRender: true
                    })])
            })
        } else {
            // animate single layer
            let cssParams = {}
            if (_.get(params, ['browserFlags', 'preserve3DParallaxScrubAction'])) {
                cssParams = {transformStyle: 'preserve-3d'}
            }
            sequence.add(factory.animate('BaseNone', elements, 0, 0, cssParams))
            _.forEach(elements, element => {
                childrenToAnimate = _.map(balataConsts.PARALLAX_SELECTORS, selector => element.querySelector(selector))
                sequence.add(factory.animate('BasePosition', childrenToAnimate, duration, delay, {
                    from: {y: viewPortHeight * speedFactor},
                    to: {y: 0 - componentHeight * speedFactor}, // eslint-disable-line no-mixed-operators
                    ease: 'Linear.easeNone',
                    force3D: true,
                    immediateRender: true
                }))
            })
        }

        return sequence.get()
    }

    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}
