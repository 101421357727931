define([
    'lodash',
    'layout/util/layout',
    'layout/specificComponents/balataLayout'
], function (
    _,
    /** layout.layout */ layout,
    balataLayout
) {
    'use strict';

    function measureStripContainerSlideShow(id, measureMap, nodesMap) {
        measureMap.containerHeightMargin[id] = 0;

        const navigationArrows = nodesMap[`${id}navigationArrows`];
        measureMap.custom[id] = {
            offset: parseInt(navigationArrows.getAttribute('data-navigation-button-margin'), 10)
        };
    }

    function patchStripContainerSlideShow(id, patchers, measureMap) {
        const arrowSideOffset = measureMap.custom[id].offset;
        patchers.css(`${id}prevButton`, {
            left: arrowSideOffset
        });

        patchers.css(`${id}nextButton`, {
            right: arrowSideOffset
        });
    }

    function measureStripContainerSlideShowSlide(id, measureMap, nodesMap, structureInfo) {
        const slideNode = nodesMap[id];
        const slideParentId = slideNode.getAttribute('data-parent-id');
        measureMap.height[id] = measureMap.height[slideParentId];
        measureMap.minHeight[id] = slideNode.getAttribute('data-min-height');
        measureMap.width[id] = slideNode.offsetWidth;
        balataLayout.measure(id, measureMap, nodesMap, structureInfo);
    }

    function patchStripContainerSlideShowSlide(id, patchers, measureMap, structureInfo, siteData) {
        balataLayout.patch(id, patchers, measureMap, structureInfo, siteData);
    }

    const SLIDESHOW = 'wysiwyg.viewer.components.StripContainerSlideShow';
    const SLIDES = 'wysiwyg.viewer.components.StripContainerSlideShowSlide';

    layout.registerRequestToMeasureDom(SLIDESHOW);
    layout.registerRequestToMeasureChildren(SLIDESHOW, [['background'], ['inlineContentParent'], ['inlineContent'], ['shownOnAllSlides'], ['navigationArrows'], ['prevButton'], ['nextButton']]);
    layout.registerCustomMeasure(SLIDESHOW, measureStripContainerSlideShow);
    layout.registerPatcher(SLIDESHOW, patchStripContainerSlideShow);

    layout.registerRequestToMeasureDom(SLIDES);
    layout.registerRequestToMeasureChildren(SLIDES, [['inlineContentParent'], ['inlineContent'], ...balataLayout.BALATA_PATHS_TO_REQUEST_MEASURE]);
    layout.registerCustomMeasure(SLIDES, measureStripContainerSlideShowSlide);
    layout.registerPatcher(SLIDES, patchStripContainerSlideShowSlide);
});
