define([], function () {
    'use strict';

    return {
        start() {
            requirejs(['wixFullstoryLoader'], function (wixFullstoryLoader) {
                wixFullstoryLoader({
                    label: 'santa-viewer',
                    iframe: true,
                    spec: 'sv_fullstory'
                });
            });
        }
    };
});
