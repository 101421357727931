'use strict'
const _ = require('lodash')
const balataConsts = require('./balataConsts')

const name = 'BackgroundZoom'
const properties = {
    hideOnStart: false,
    shouldDisableSmoothScrolling: true,
    getMaxTravel(elementMeasure, viewportHeight) {
        return viewportHeight + elementMeasure.height
    }
}

function register({factory}/*, frame*/) {
    /**
     * Move balata media elements vertically and zoom(from y:0)
     * @param {Array<HTMLElement>|HTMLElement} elements DOM elements to animate
     * @param {Number} [duration]
     * @param {Number} [delay]
     * @param {Object} [params] Timeline optional parameters (Tween values cannot be changed here, use BaseFade).
     * @param {Number} [params.speedFactor] the speed of the animation relative to the scroll
     * @returns {TimelineMax}
     */
    function animation(elements, duration, delay, params) {
        params = params || {}
        const sequence = factory.sequence(params)

        _.forEach(elements, element => {
            const childrenToZoom = _.map(balataConsts.ZOOM_SELECTORS, selector => element.querySelector(selector))
            sequence.add([
                factory.animate('BasePosition', element, 0, delay, {
                    perspective: 100,
                    force3D: true,
                    immediateRender: true
                }),
                factory.animate('BasePosition', childrenToZoom, duration, delay, {
                    force3D: true,
                    from: {z: 0},
                    to: {z: 40},
                    ease: 'Sine.easeIn',
                    immediateRender: true
                })
            ])
        })

        return sequence.get()
    }

    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}
