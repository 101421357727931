define(['lodash', 'color', 'santa-core-utils'], function (_, Color, coreUtilsLib) {
    'use strict';

    function isThemeColor(value) {
        return /color_/.test(value);
    }

    function getFromColorMap(colorsMap, color) {
        if (isThemeColor(color)) {
            color = color.replace(/[\[\]{}]/g, '');
            color = getColorValueFromColorsMap(colorsMap, color);
            color = coreUtilsLib.cssUtils.normalizeColorStr(color);
        }
        return color;
    }

    /**
     * Get color value, use color from theme if value is a pointer, normalize color to use rgba() syntax
     * @param {object} colorsMap
     * @param {string} color
     * @param {number} [alphaOverride]
     * @returns {string}
     */
    function getParsedColor(colorsMap, color, alphaOverride) {
        let parsedColor, colorObj;

        // Get color from theme if the color value is represented as {color_X}
        color = getFromColorMap(colorsMap, color);

        if (color === 'none') {
            parsedColor = 'transparent';
        } else {
            colorObj = new Color(color);
            if (_.isNumber(alphaOverride)) {
                colorObj.setValues('alpha', alphaOverride);
            }
            if (colorObj.alpha() === 0) {
                parsedColor = 'transparent';
            } else {
                parsedColor = colorObj.rgbaString();
            }
        }

        return parsedColor;
    }

    function getColorValueFromColorsMap(colorsMap, colorClassName) {
        const colorNumber = colorClassName && colorClassName.split('_')[1];

        return colorsMap[colorNumber] || colorClassName;
    }

    function getColorValue(theme, colorClassName) {
        return getColorValueFromColorsMap(theme.color, colorClassName);
    }

    function getHexColor(colorsMap, color) {
        const resolved = new Color(getFromColorMap(colorsMap, color));
        return resolved.hexString();
    }

    return {
        getColor: getParsedColor,

        /**
         *  Get the color value by theme
         * @param {Object} theme - current theme
         * @param {string} colorClassName
         *  @returns {String} color rgb/rgba value
         */
        getColorValue,
        getHexColor,
        isThemeColor
    };
});
