'use strict'

function wixElementWrapper(services) {
    class WixElement extends HTMLElement {
        constructor() { // eslint-disable-line no-useless-constructor
            super()
        }

        reLayout() {
            //should be implemented inside child element
        }

        connectedCallback() {
            services.resizeService.observe(this)
            this.reLayout()
        }

        attributeChangedCallback(name, oldValue) {
            if (oldValue) {
                this.reLayout()
            }
        }

        disconnectedCallback() {
            services.resizeService.unobserve(this)
        }
    }

    return WixElement
}


module.exports = wixElementWrapper
