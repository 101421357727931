define([
    'lodash',
    'mobileLayoutUtils',
    'coreUtils/core/anchorsUtils'
], function (
    _,
    mobileLayoutUtils,
    anchorsUtils
) {
    'use strict';

    const ROW_MARGIN_THRESHOLD = 10;
    const VIRTUAL_GROUP_COMPONENT_TYPE = 'VirtualGroup';

    function groupRowsComponents(siblings) {
        const groupableRowComponents = findGroupableRowComponents(siblings);
        const groupedComponentsMap = _(groupableRowComponents)
            .map(createVirtualGroup)
            .transform(addVirtualGroupToGroupedComponentsMap, {virtualGroups: {}, childIdToVirtualGroupId: {}})
            .value();
        return replaceGroupedComponentsToVirtualGroups(siblings, groupedComponentsMap);
    }

    function findGroupableRowComponents(siblings) {
        const rows = mobileLayoutUtils.splitToFragments(siblings, 'y');
        return rows.length === 1 ? [] : _(rows)
            .filter(areFragmentComponentsGroupable)
            .map('comps')
            .value();
    }

    function translateComponents(components, xOffset, yOffset) {
        return _.map(components, function (component) {
            const componentPosition = {x: component.layout.x + xOffset, y: component.layout.y + yOffset};
            const componentLayout = _.defaults(componentPosition, component.layout);
            return _.defaults({layout: componentLayout}, component);
        });
    }

    function createVirtualGroup(rowComponents) {
        const virtualGroupLayout = _.assign({fixedPosition: false}, mobileLayoutUtils.getSnugLayout(rowComponents));
        const virtualGroupId = `virtualGroup_${rowComponents[0].id}`;
        return {
            id: virtualGroupId,
            layout: virtualGroupLayout,
            components: translateComponents(rowComponents, -virtualGroupLayout.x, -virtualGroupLayout.y),
            componentType: VIRTUAL_GROUP_COMPONENT_TYPE,
            type: 'Container',
            skin: 'undefined',
            styleId: 'undefined'
        };
    }

    function addVirtualGroupToGroupedComponentsMap(groupedComponentsMap, virtualGroupToAdd) {
        groupedComponentsMap.virtualGroups[virtualGroupToAdd.id] = virtualGroupToAdd;
        return _.transform(virtualGroupToAdd.components, function (childrenMap, comp) {
            childrenMap[comp.id] = virtualGroupToAdd.id;
        }, groupedComponentsMap.childIdToVirtualGroupId);
    }

    function replaceGroupedComponentsToVirtualGroups(siblings, groupedComponentsMap) {
        const newSiblingsMap = _.reduce(siblings, function (res, component) {
            const virtualGroupId = groupedComponentsMap.childIdToVirtualGroupId[component.id];
            if (!virtualGroupId) {
                return _.set(res, component.id, component);
            }
            return res[virtualGroupId] ? res : _.set(res, virtualGroupId, groupedComponentsMap.virtualGroups[virtualGroupId]);
        }, {});
        return _.values(newSiblingsMap);
    }

    function isGroupableComponent(component) {
        return !component.layout.rotationInDegrees &&
            anchorsUtils.componentCanBePushed(component.componentType) &&
            anchorsUtils.componentCanPush(component.componentType) &&
            anchorsUtils.canCreateAnchorsToComp(component);
    }

    function areFragmentComponentsGroupable(fragment) {
        return fragment.comps.length > 1 &&
            (fragment.distanceToPreviousFragment >= ROW_MARGIN_THRESHOLD || fragment.distanceToPreviousFragment < 0) &&
            _.every(fragment.comps, isGroupableComponent);
    }

    function isVirtualGroup(component) {
        return component.componentType === VIRTUAL_GROUP_COMPONENT_TYPE;
    }

    function convertGroupingAnchors(anchorsMap, containerComponents) {
        const containerComponentsMap = _.mapKeys(containerComponents, 'id');
        const virtualGroupsMap = _.pickBy(containerComponentsMap, isVirtualGroup);
        if (_.isEmpty(virtualGroupsMap)) {
            return anchorsMap;
        }
        const allComponentsMap = _(virtualGroupsMap)
            .flatMap('components')
            .mapKeys('id')
            .assign(containerComponentsMap)
            .value();
        const newAnchorsMap = replaceAnchorsFromVirtualGroups(anchorsMap, virtualGroupsMap);
        return _.mapValues(newAnchorsMap, _.partial(replaceAnchorsToVirtualGroup, allComponentsMap));
    }

    function replaceAnchorsFromVirtualGroups(anchorsMap, virtualGroupsMap) {
        return _.transform(anchorsMap, function (newAnchorsMap, componentAnchors, componentId) {
            if (!virtualGroupsMap[componentId]) {
                newAnchorsMap[componentId] = componentAnchors;
                return;
            }
            const newComponentAnchors = replaceAnchorsFromVirtualGroup(virtualGroupsMap[componentId], componentAnchors);
            mergeAnchorsMaps(newAnchorsMap, newComponentAnchors);
        }, {});
    }

    function replaceAnchorsFromVirtualGroup(virtualGroup, virtualGroupAnchors) {
        return _.transform(virtualGroup.components, function (childrenAnchorsMap, child) {
            const distanceToParentBottom = virtualGroup.layout.height - child.layout.y - child.layout.height;
            const childAnchors = _.map(virtualGroupAnchors, function (virtualGroupAnchor) {
                const distance = virtualGroupAnchor.distance + distanceToParentBottom;
                const locked = anchorsUtils.isAnchorLocked(virtualGroupAnchor.type, child.componentType, distance);
                return _.defaults({
                    fromComp: child.id,
                    distance: virtualGroupAnchor.distance + distanceToParentBottom,
                    locked,
                    originalValue: virtualGroupAnchor.originalValue
                }, virtualGroupAnchor);
            });
            childrenAnchorsMap[child.id] = childAnchors;
        }, {});
    }

    function replaceAnchorsToVirtualGroup(allComponentsMap, componentAnchors) {
        return _.reduce(componentAnchors, function (newComponentAnchors, componentAnchor) {
            const targetComponent = allComponentsMap[componentAnchor.targetComponent];
            if (!isVirtualGroup(targetComponent)) {
                return newComponentAnchors.concat([componentAnchor]);
            }
            const toVirtualGroupChildrenAnchors = _.map(targetComponent.components, function (child) {
                const distanceToParentTop = child.layout.y;
                const distance = componentAnchor.distance + distanceToParentTop;
                const locked = anchorsUtils.isAnchorLocked(componentAnchor.type, allComponentsMap[componentAnchor.fromComp], distance);
                return _.defaults({
                    targetComponent: child.id,
                    distance,
                    locked,
                    originalValue: componentAnchor.originalValue + distanceToParentTop
                }, componentAnchor);
            });

            return newComponentAnchors.concat(toVirtualGroupChildrenAnchors);
        }, []);
    }

    function mergeAnchorsMaps(destinationAnchorsMap, sourceAnchorsMap) {
        return _.transform(sourceAnchorsMap, function (mergedAnchorsMap, componentAnchors, componentId) {
            mergedAnchorsMap[componentId] = (mergedAnchorsMap[componentId] || []).concat(componentAnchors);
        }, destinationAnchorsMap);
    }

    return {
        testAPI: {
            groupRowsComponents,
            findGroupableRowComponents,
            createVirtualGroup,
            addVirtualGroupToGroupedComponentsMap,
            replaceGroupedComponentsToVirtualGroups,
            areFragmentComponentsGroupable,
            convertGroupingAnchors,
            mergeAnchorsMaps,
            replaceAnchorsFromVirtualGroup,
            replaceAnchorsToVirtualGroup,
            translateComponents
        },

        isVirtualGroup,

        groupRows(children, applyGroupingAnchors) {
            if (!applyGroupingAnchors || !children.length) {
                return children;
            }
            return groupRowsComponents(children);
        },

        resolve(parent, children, applyGroupingAnchors, groupedChildrenAnchors, anchorsMap) {
            if (!applyGroupingAnchors || !children.length || _.isEmpty(groupedChildrenAnchors)) {
                return anchorsMap;
            }
            const containerComponents = children.concat([parent]);
            return mergeAnchorsMaps(groupedChildrenAnchors, convertGroupingAnchors(anchorsMap, containerComponents));
        }
    };
});
