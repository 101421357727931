import {common, bi} from 'tpaComponents'
import * as warmupUtils from 'warmupUtils'
import _ from 'lodash'
import {HANDLER_PREFIX} from 'bolt-tpa/src/aspects/constants'

const shouldSendBIEvent = (msg, compData) => compData && msg.version && msg.type && msg.namespace

const getIsPublished = (appData, widgetId) => {
    const widgets = appData.widgets
    const widget = widgets[widgetId]
    const isPublished = widget ? widget.published || widget.santaEditorPublished : appData.sectionPublished
    return isPublished ? 1 : 0
}

const getAppParams = (widgetId, appData) => {
    if (!appData) {
        throw new Error('app definition could not be found by the given appId')
    }
    return {
        appId: appData.appDefinitionId,
        instanceId: appData.instanceId,
        isPublished: getIsPublished(appData, widgetId)
    }
}

const getEventDescriptor = applicationId => applicationId ? bi.events.JS_SDK_FUNCTION_CALL : bi.events.GALLERY_FUNCTION_CALL

const isPubSubMessage = msg => {
    if (_.has(msg, 'data') && !_.isNull(msg.data)) {
        return msg.data.eventKey && _.startsWith(msg.data.eventKey, HANDLER_PREFIX.TPA_PUB_SUB_PREFIX)
    }
    return false
}

const isEventSupported = msg => {
    if (msg.type === 'registerEventListener' && !isPubSubMessage(msg)) {
        return _.includes(common.supportedSiteEvents, msg.data.eventKey)
    }
    return true
}

const reportHandlerCalledBi = (aspectActions, msg, {compData, getAppBiEventsMapFn, getClientSpecMapEntryFn, reportBiFn}) => {
    if (shouldSendBIEvent(msg, compData) && isEventSupported(msg)) {
        const applicationId = _.get(compData, 'applicationId')
        let storedEvent = {
            count: 1,
            target: 1
        }

        const appBiEventsMap = getAppBiEventsMapFn()
        if (_.has(appBiEventsMap, msg.type)) {
            storedEvent = _.clone(appBiEventsMap[msg.type])
            ++storedEvent.count
        }

        if (storedEvent.count === storedEvent.target) {
            const eventParams = {
                visitorUuid: common.utils.getVisitorUuid(warmupUtils),
                sdkVersion: msg.version,
                origin: 'viewer', //todo: fix when ds
                fnName: msg.type,
                param: JSON.stringify(msg.data),
                namespace: msg.namespace,
                count: storedEvent.count
            }

            if (compData.applicationId) {
                const appData = getClientSpecMapEntryFn(applicationId)
                const appParams = getAppParams(compData.widgetId, appData)
                _.merge(eventParams, appParams)
            }

            const eventDescriptor = getEventDescriptor(compData.applicationId)
            reportBiFn(eventDescriptor, eventParams)

            storedEvent.target *= 10
        }

        if (!appBiEventsMap) {
            aspectActions.setAppBiEvents(applicationId, {[msg.type]: storedEvent})
        } else {
            aspectActions.setBiEvent(applicationId, msg.type, storedEvent)
        }
    }
}

export {
    reportHandlerCalledBi
}
