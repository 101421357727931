'use strict'

const _ = require('lodash')
const constants = require('./constants')

const runtimeIdDelimiter = '_runtime_'
const getIdPrefix = runtimeId => runtimeId.split(runtimeIdDelimiter)[0]
const getIdSuffix = runtimeId => runtimeId.split(runtimeIdDelimiter)[1]

const isRuntimeId = id => /(.*)_runtime_(.*)/.test(id)
const getRuntimeId = (compId, queryId) => isRuntimeId(queryId) ? queryId : `${compId}${runtimeIdDelimiter}${queryId}`
const getCompId = runtimeId => getIdPrefix(runtimeId)
const getQueryId = runtimeId => getIdSuffix(runtimeId)

const createEmptyDataRuntime = () =>
    _(constants.pointers.data.DATA_MAPS).keyBy().mapValues(() => ({})).value()

const createEmptyRuntime = () => ({
    data: createEmptyDataRuntime(),
    behaviors: [],
    state: {}
})

const createRuntime = (overrides = {}) => _.assign(createEmptyRuntime(), overrides)

module.exports = {
    createRuntime,
    createEmptyDataRuntime,
    createEmptyRuntime,
    isRuntimeId,
    getRuntimeId,
    getCompId,
    getQueryId
}
