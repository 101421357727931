export const name = 'SiteBackgroundAspect'


export const functionLibrary = {
    dangerouslyForceBackground: (windowObject, background) => {
        windowObject.document.body.style.background = background
    },
    dangerouslyDisableForcedBackground: windowObject => {
        windowObject.document.body.style.background = ''
    }
}
