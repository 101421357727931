define(['skins/util/skinsMap', 'santa-components', 'skins/util/params'], function (skinsMap, santaComponents, params) {
    'use strict';

    skinsMap.addBatch(santaComponents.skinsJson);

    return {
        skinsMap,
        skinsRenderer: santaComponents.utils.skinsRenderer,
        registerRenderPlugin: santaComponents.utils.skinsRenderer.registerRenderPlugin,
        params
    };
});

