'use strict'

const _ = require('lodash')
const React = require('react')
const PropTypes = require('prop-types')
const Renderer = require('./Renderer')

const COMPONENT_PROPS = _(Renderer.propTypes)
    .keys()
    .without('id')
    .value()

const IsolatedRenderer = props => {
    const wrapperProps = _.omit(props, COMPONENT_PROPS)
    return <div {...wrapperProps}>
        <Renderer {...props} />
    </div>
}

IsolatedRenderer.propTypes = _.defaults({
    id: PropTypes.string.isRequired
}, Renderer.propTypes)

module.exports = IsolatedRenderer