define(['lodash', 'image-client-api', 'warmupUtilsLib'], function (_, imageClientLib, warmupUtilsLib) {
    'use strict';

    const exports = {
        createStageData,
        getImageApiData,
        generateTransformString
    };

    const MOBILE_VIEW_DEFAULTS = {
        width: 320,
        height: 400
    };

    function createStageData(overlap) {
        return {
            leftPart_leftMargin: calcContentLeft(overlap, 0),
            leftPart_begin: calcContentCenter(overlap, 0),
            leftPart_rightMargin: calcContentRight(overlap, 0),

            centerPart_leftMargin: calcContentLeft(overlap, 1),
            centerPart_begin: calcContentCenter(overlap, 1),
            centerPart_rightMargin: calcContentRight(overlap, 1),

            rightPart_leftMargin: calcContentLeft(overlap, 2),
            rightPart_begin: calcContentCenter(overlap, 2),
            rightPart_rightMargin: calcContentRight(overlap, 2),

            fullWidth: calcContentCenter(overlap, 3)
        };
    }

    function calcContentLeft(overlap, index) {
        return index * (1 + overlap);
    }

    function calcContentCenter(overlap, index) {
        return index * (1 + overlap) + overlap; // eslint-disable-line no-mixed-operators
    }

    function calcContentRight(overlap, index) {
        return index * (1 + overlap) + 2 * overlap; // eslint-disable-line no-mixed-operators
    }

    function calcImageSize(imageDimensions, viewport) {
        const wScale = Math.min(viewport.width / imageDimensions.width, 1);
        const hScale = Math.min(viewport.height / imageDimensions.height, 1);
        const targetScale = Math.min(wScale, hScale);
        return {
            width: Math.round(imageDimensions.width * targetScale),
            height: Math.round(imageDimensions.height * targetScale)
        };
    }

    function isEmptyOrExternalUri(uri) {
        return !uri || warmupUtilsLib.urlUtils.isExternalUrl(uri);
    }


    function getImageApiData(imageData, clientData, scale) {
        const fittingType = imageClientLib.fittingTypes.SCALE_TO_FIT;
        if (!imageData.width) {
            imageData.width = MOBILE_VIEW_DEFAULTS.width;
        }
        if (!imageData.height) {
            imageData.height = MOBILE_VIEW_DEFAULTS.height;
        }
        const src = {id: imageData.uri, width: imageData.width, height: imageData.height};

        const imageSize = calcImageSize(
            _.pick(imageData, ['width', 'height']),
            clientData.screenSize
        );
        const scaledDimensions = {
            width: Math.round(imageSize.width * (scale || 1)),
            height: Math.round(imageSize.height * (scale || 1))
        };
        const target = {
            width: scaledDimensions.width,
            height: scaledDimensions.height,
            alignment: imageClientLib.alignTypes.CENTER,
            htmlTag: 'img',
            pixelAspectRatio: clientData.pixelAspectRatio
        };
        const imageQualityFilters = _.defaults({quality: 85}, imageData.quality || {});
        const returnedImageData = imageClientLib.getData(fittingType, src, target, imageQualityFilters, clientData.browser);
        _.assign(returnedImageData.css.img, imageSize, {objectFix: 'internal'});
        if (!isEmptyOrExternalUri(returnedImageData.uri)) {
            _.assign(returnedImageData, {uri: warmupUtilsLib.urlUtils.joinURL(clientData.staticMediaUrl, returnedImageData.uri)});
        }
        return returnedImageData;
    }

    function generateTransformString(swipeOffset, fullWidth) {
        return `translate3d(${-1 * swipeOffset * (100 / fullWidth)}%, 0, 0)`;
    }

    return exports;
});
