define(['lodash'], function (_) {
    'use strict';

    const visibilityPlugins = []; // eslint-disable-line santa/no-module-state

    function registerVisibilityPlugin(plugin) {
        visibilityPlugins.push(plugin);
    }

    function isComponentVisible(argsObj) {
        return _.every(visibilityPlugins, function (plugin) {
            return plugin(argsObj);
        });
    }

    return {
        isComponentVisible,
        registerPlugin: registerVisibilityPlugin
    };
});
