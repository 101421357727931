define([], function () {
    'use strict';

    const isTpaByAppType = function (appDataType) {
        return !(appDataType === 'wixapps' || appDataType === 'appbuilder');
    };

    return {
        isTpaByAppType
    };
});
