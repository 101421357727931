export const name = 'ActivityAspect'
const {urlUtils, guidUtils} = require('santa-core-utils')

const API_HUB_PATH = '/_api/app-integration-bus-web/v1/activities'

export const functionLibrary = {
    reportActivity: (externalBaseUrl, fetchFunc, activity) => new Promise((resolve, reject) => {
        const url = `${externalBaseUrl}${API_HUB_PATH}?${urlUtils.toQueryString(activity.params)}`

        return fetchFunc(
            url,
            {
                method: 'POST',
                body: JSON.stringify(activity.payload),
                contentType: 'application/json'
            },
            'json',
            resolve,
            reject
        )
    }),
    getUniqueActivityId: () => guidUtils.getUniqueId()
}
