//const {positionAndSize} = require('warmupUtils')
let positionAndSize // eslint-disable-line santa/no-module-state
export const functionLibrary = {
    calcDimensions: (layout, parentDimension, clientSize, siteWidth) => {
        if (!positionAndSize) {positionAndSize = require('warmupUtils').positionAndSize}

        if (positionAndSize.isVerbsLayout(layout)) {
            return positionAndSize.getVerbsPositionAndSize(layout, parentDimension ? parentDimension : {}, clientSize, siteWidth)
        }
        return positionAndSize.getAbsolutePositionAndSize(layout)
    }
}

export function init() {
}
