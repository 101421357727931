/**
 * Created by eitanr on 6/25/14.
 */
define(['lodash', 'layout/specificComponents/svgShape/svgBasicScaler'], function (_, basic) {
    'use strict';

    function scaleAttribute(attributeName, originalValue, scale) {
        return originalValue ? _.set({}, attributeName, basic.scaleSingleValue(originalValue, scale)) : null;
    }

    return {
        scale(rectElement, scaleX, scaleY) {
            const width = rectElement.getAttribute('width'),
                height = rectElement.getAttribute('height'),
                rx = rectElement.getAttribute('rx'),
                ry = rectElement.getAttribute('ry'),
                x = rectElement.getAttribute('x'),
                y = rectElement.getAttribute('y');

            return _.assign({},
                scaleAttribute('width', width, scaleX),
                scaleAttribute('height', height, scaleY),
                scaleAttribute('rx', rx, scaleX),
                scaleAttribute('ry', ry, scaleY),
                scaleAttribute('x', x, scaleX),
                scaleAttribute('y', y, scaleY));
        }
    };
});
