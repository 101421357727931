import {withActions} from '../withActions'
import {throttleHandler} from './resizeUtils'
import * as constants from 'santa-renderer/src/utils/constants'
export const name = 'ScreenDimensionsAspect'

export const defaultModel = {
    dimensions: {
        documentElementDimensions: {width: 0, height: 0},
        windowSize: {width: 0, height: 0},
        innerDimensions: {width: 0, height: 0}
    },
    compsToNotify: {
        orientation: {},
        resize: {}
    },
    resizeCounter: -1
}

export const functionLibrary = {
    updateScreenSize: withActions(({setScreenDimensions, setResizeCounter}, documentElement, windowObj, getResizeCounter) => {
        if (!documentElement || !windowObj) {
            return
        }

        setScreenDimensions({
            windowSize: {
                width: documentElement.clientWidth,
                height: documentElement.clientHeight
            },
            innerDimensions: {
                width: windowObj.innerWidth,
                height: windowObj.innerHeight
            }
        })

        setResizeCounter(getResizeCounter() + 1)
    }),
    getCurrentTime: () => new Date().getTime(),
    removeCompToNotifyOnEvent: removeFn => { removeFn() }
}

export function init({}, {eventsManager, initialData: {updateScreenSize}}) {
    eventsManager.on(constants.EVENTS.WINDOW_RESIZE, throttleHandler(updateScreenSize))

    updateScreenSize()
}
