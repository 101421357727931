define([
    'lodash',
    'prop-types',
    'reactDOM',
    'coreUtils',
    'santa-core-utils',
    'componentsCore',
    'skins',
    'santa-components',
    'galleriesCommon',
    'displayer'
],
function (
    _,
    PropTypes,
    ReactDOM,
    coreUtils,
    coreUtilsLib,
    componentsCore,
    skinsPackage,
    santaComponents,
    galleriesCommon,
    displayer
) {
    'use strict';
    const galleriesHelperFunctions = galleriesCommon.utils.galleriesHelperFunctions;
    const matrixScalingCalculations = coreUtils.matrixScalingCalculations;

    function setCurrentIndex(itemIndex, event, domID) {
        const oldSelectedIndex = this.state.currentIndex;
        if (oldSelectedIndex !== itemIndex) {
            if (this.props.onImageSelected) {
                event.type = 'imageSelected';
                event.payload = {
                    itemIndex,
                    imageData: this.props.compData.items[itemIndex]
                };
                this.props.onImageSelected(event, domID);
            }
            this.setState({currentIndex: itemIndex});
        }
    }

    function getDimensionWithLegacyFallbackForWixapps(props, dim) {
        return props.dimensions ? props.dimensions[dim] : parseFloat(props.style[dim]);
    }

    // This constant is used as a base line for the speed of the slide animation (sorry, we had to do it like the old wix)
    // the calculation is: (speed * constant) pixels per second
    const animationSpeedConstant = 60;

    function getPublicState(state, propsInfo) {
        const defaultIndex = propsInfo.props.selectedItemIndex || 0;
        const currentIndex = _.get(state, ['currentIndex'], defaultIndex);
        return {currentIndex};
    }

    /**
     * @class components.SliderGallery
     * @extends {core.skinBasedComp}
     */
    const sliderGallery = {
        displayName: 'SliderGallery',
        statics: {
            behaviors: {
                nextSlide: {methodName: 'next'},
                prevSlide: {methodName: 'prev'}
            }
        },
        mixins: [
            componentsCore.mixins.skinBasedComp,
            componentsCore.mixins.skinInfo,
            santaComponents.mixins.animationsMixin,
            coreUtilsLib.timersMixins.timeoutsMixin,
            santaComponents.mixins.compStateMixin(getPublicState),
            componentsCore.mixins.createChildComponentMixin
        ],

        propTypes: _.assign({
	        isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen,
	        id: santaComponents.santaTypesDefinitions.Component.id.isRequired,
            rootId: santaComponents.santaTypesDefinitions.Component.rootId.isRequired,
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            compProp: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            skin: santaComponents.santaTypesDefinitions.Component.skin.isRequired,
            style: santaComponents.santaTypesDefinitions.Component.style.isRequired,
            dimensions: santaComponents.santaTypesDefinitions.Component.dimensions, //not required, since sliderGalleryProxy doesn't have dimensions
            isMobileView: santaComponents.santaTypesDefinitions.isMobileView,
            isMobileDevice: santaComponents.santaTypesDefinitions.Device.isMobileDevice,
            isTabletDevice: santaComponents.santaTypesDefinitions.Device.isTabletDevice,
            windowTouchEventsAspect: santaComponents.santaTypesDefinitions.SiteAspects.windowTouchEvents.isRequired,
            getSliderGalleryMeasures: santaComponents.santaTypesDefinitions.__DangerousSantaTypes.getSliderGalleryMeasures,
            onImageSelected: PropTypes.func
        }, santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(displayer)),

        _currentOffset: null,
        _motion: false,
        _firstChild: null,

        getInitialState() {
            this.props.windowTouchEventsAspect.registerToWindowTouchEvent('touchStart', this);

            return _.assign(getPublicState(null, {props: this.props.compProp}), {
                $mobile: this.props.isMobileDevice || this.props.isTabletDevice() ? 'mobile' : 'notMobile',
                $displayDevice: this.props.isMobileView ? 'mobileView' : 'desktopView'
            });
        },
        componentWillUnmount() {
            this.props.windowTouchEventsAspect.unregisterFromWindowTouchEvent('touchStart', this);
        },
        getSkinProperties() {
            this.gap = _.isNumber(this.props.compProp.margin) ? this.props.compProp.margin : 20;
            this.contentOverflow = false;
            const skin = skinsPackage.skinsMap.get(this.props.skin, this.props.isExperimentOpen);
            const bottomGap = skin.exports && skin.exports.bottomGap || 0; // eslint-disable-line no-mixed-operators
            const additionalHeight = Math.abs(this.getFromExports('itemContainerAdditionalHeight'));
            let imagesArr = this.populate(bottomGap, additionalHeight);
            const itemContainerTotalWidthOffset = Math.abs(this.getFromExports('itemContainerTotalOffset'));
            const itemContainerWidth = getDimensionWithLegacyFallbackForWixapps(this.props, 'width') - itemContainerTotalWidthOffset;
            if (this.itemsHolderWidth > itemContainerWidth) {
                this.contentOverflow = true;
                imagesArr = this.populate(bottomGap, additionalHeight);
            }

            return {
                imageItem: {},
                images: {
                    children: imagesArr,
                    'data-gallery-id': this.props.id
                },
                swipeLeftHitArea: {
                    onMouseEnter: this.prev,
                    onMouseLeave: this._stopMovement,
                    onTouchStart: this.prev,
                    'data-gallery-id': this.props.id
                },
                swipeRightHitArea: {
                    onMouseEnter: this.next,
                    onMouseLeave: this._stopMovement,
                    onTouchStart: this.next,
                    'data-gallery-id': this.props.id
                },
                '': {
                    onSwipeLeft: this.next,
                    onSwipeRight: this.prev,
                    'data-height-diff': galleriesHelperFunctions.getSkinHeightDiff(this.props.skin),
                    'data-width-diff': galleriesHelperFunctions.getSkinWidthDiff(this.props.skin),
                    'data-image-mode': this.props.compProp.imageMode,
                    'data-aspect-ratio': this.props.compProp.aspectRatio,
                    'data-bottom-gap': bottomGap,
                    'data-additional-height': additionalHeight
                }
            };
        },
        getChildrenData() {
            const data = this.props.compData.items;
            return this.props.compProp.loop && this.contentOverflow ? data.concat(data) : data;
        },
        populate(bottomGap, additionalHeight) {
            const props = this.props.compProp;
            const compData = this.props.compData;
            this.itemsHolderWidth = 0;
            const data = this.getChildrenData();
            const children = data.map(function (childData, index) {
                const displayerData = childData;

                const displayerSkin = this.getSkinExports().imageItem.skin;
                const displayerSkinParams = this.getParams(['topPadding', 'imgHeightDiff'], displayerSkin);
                const skin = skinsPackage.skinsMap.get(displayerSkin, this.props.isExperimentOpen);
                const heightDiff = galleriesHelperFunctions.getDisplayerHeightDiff(skin, displayerSkinParams, this.state.$displayDevice);
                const widthDiff = galleriesHelperFunctions.getDisplayerWidthDiff(skin, this.state.$displayDevice);
                const totalItemContainerHeight = Math.floor(getDimensionWithLegacyFallbackForWixapps(this.props, 'height') + additionalHeight);
                const numberOfUniqueImages = this.props.compData.items.length;
                const sizeAfterScaling = matrixScalingCalculations.getSizeAfterScaling({
                    itemHeight: totalItemContainerHeight,
                    itemWidth: Math.floor(totalItemContainerHeight * (this.props.compProp.aspectRatio || 1.0)),
                    displayerData,
                    imageMode: this.props.compProp.imageMode,
                    heightDiff,
                    widthDiff,
                    bottomGap
                });

                this.itemsHolderWidth = this.itemsHolderWidth + sizeAfterScaling.imageWrapperSize.imageWrapperWidth + this.gap;
                return this.createChildComponent(
                    displayerData,
                    'wysiwyg.viewer.components.Displayer',
                    'imageItem',
                    {
                        currentUrlPageId: this.props.currentUrlPageId,
                        galleryDataId: compData.id,
                        imageWrapperSize: sizeAfterScaling.imageWrapperSize,
                        style: {
                            display: 'inline-block',
                            margin: `0 ${_.isNumber(props.margin) ? props.margin : 20}px 0 0`,
                            height: sizeAfterScaling.displayerSize.height,
                            width: sizeAfterScaling.displayerSize.width
                        },
                        isSelected: this.state.currentIndex === index,
                        onClick: setCurrentIndex.bind(this, index),
                        displayerDataQuery: childData,
                        galleryId: this.props.id,
                        heightDiff,
                        widthDiff,
                        bottomGap,
                        imageIndex: index % numberOfUniqueImages,
                        key: `${this.props.id + displayerData.id}_${index}`,
                        ref: `displayer${index}`,
                        id: `${this.props.id}displayer${index}`
                    }
                );
            }, this);

            return children;
        },

        prev() {
            this._move(true);
        },

        next() {
            this._move(false);
        },

        _move(isReverse) {
            const speed = this.props.compProp.maxSpeed || 0.05;
            this.slide(isReverse, speed, this.props.compProp.loop);
        },

        _stopMovement() {
            if (this._sequenceId) {
                this.easeStopSequence(this._sequenceId, 1);
                this._sequenceId = null;
            }
        },
        slide(isReverse, speed, isLoop) { // eslint-disable-line complexity
            if (!this.contentOverflow) {
                return;
            }
            const sliderGalleryMeasures = this.props.getSliderGalleryMeasures(this.props.id);
            const imagesWidth = sliderGalleryMeasures.imagesWidth - (this.props.compProp.margin || 0); //width of all the images
            const containerWidth = sliderGalleryMeasures.itemsContainerWidth; //container size of visible images
            const currentLeft = ReactDOM.findDOMNode(this.refs.images).offsetLeft;
            const maxLeft = isLoop ? -imagesWidth / 2 : containerWidth - imagesWidth;

            //Clear all existing animations and initiate a new timeline
            this._stopMovement();
            const sequence = this.sequence();
            const duration = Math.abs(maxLeft) / (speed * animationSpeedConstant);
            const relativeDuration = duration * (isReverse ? Math.abs(currentLeft / maxLeft) : 1 - Math.abs(currentLeft / maxLeft));

            //Create first tween to bring us back to the starting point
            sequence.add('images', 'BasePosition', relativeDuration, 0, {from: {left: currentLeft}, to: {left: isReverse ? 0 : maxLeft}, ease: 'Linear.easeNone'});
            if (isLoop) {
                sequence.add('images', 'BasePosition', duration, 0, {from: {left: isReverse ? maxLeft : 0}, to: {left: isReverse ? 0 : maxLeft}, repeat: isLoop ? -1 : 0, immediateRender: false, ease: 'Linear.easeNone'});
            }

            sequence.onCompleteAll(function () {
                const currentImageData = this.props.compData.items[this.state.currentIndex];
                this.handleAction(coreUtils.siteConstants.ACTION_TYPES.IMAGE_CHANGED, {item: currentImageData, imageIndex: this.state.currentIndex});
            }.bind(this));
            this._sequenceId = sequence.execute({paused: true});
            this.easeStartSequence(this._sequenceId, 1);

            //fix for slider stopping after a few frames - need to do it in order to support old viewer
            if (this.state.$mobile === 'mobile') {
                this.clearTimeoutNamed(this.props.id);
                this._nextStopTimeout = this.setTimeoutNamed(this.props.id, function () {
                    this._stopMovement();
                }.bind(this), 2000);
            }

            //A cool trick to ease in an entire timeline
            //timeScaleTween = TweenMax.from(sequence, 0.5, {timeScale:0})
        },
        onWindowTouchStart(event) {
            const galleryId = event.target.getAttribute('data-gallery-id') || event.target.parentNode.getAttribute('data-gallery-id');
            if (galleryId !== this.props.id) {
                this._stopMovement();
            }
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.SliderGallery', sliderGallery);

    return sliderGallery;
});
