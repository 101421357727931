'use strict'
const _ = require('lodash')
const coreUtils = require('coreUtils')

module.exports = {
    functionLibrary: {
        getStyleData: (stylesMap, skinName, styleId) => {
            const styleData = stylesMap[styleId]
            return _.defaults({}, styleData, {skin: skinName})
        },
        shortenStyleId: coreUtils.santaTypes.shortenStyleId
    }
}
