define([
    'textCommon/mixins/textScaleMixin',
    'textCommon/mixins/baseTextInput',
    'textCommon/utils/labelUtils'
], function (textScaleMixin, baseTextInput, labelUtils) {
    'use strict';

    return {
        textScaleMixin,
        baseTextInput,
        labelUtils
    };
});
