
export {name} from './platform.schema'
import _ from 'lodash'
import {withActions} from 'carmi-host-extensions'

export const defaultModel = {
    __runtime: null,
    inited: {},
    didHydrate: false,
    isHandlingMessage: false,
    currentContextsMap: null
}

export const functionLibrary = {
    initWixCodeAPI: (api, contextId, messageHandler, setInited, isInited) => {
        if (isInited()) {
            return
        }
        //we need the defer here cause otherwise we are running the buffered messages during a carmi mapValues which isn't nice
        _.defer(() => {
            api.listen((...args) => {
                messageHandler(contextId, ...args)
            })
        })

        setInited()
    },
    updateCurrentContexts: (currentContexts, setCurrentContextsMap, isFirstLoad) => {
        if (isFirstLoad) {
            setCurrentContextsMap(currentContexts)
        }
    },

    deleteInited: (cId, setInited) => {
        setInited(cId, undefined)
    },

    clearPendingSsrRuntime: withActions(({setPendingRuntime, setSsrRuntime}) => {
        setPendingRuntime('POST_SSR')
        setSsrRuntime()
    }),

    dealWithPendingRuntime(setRuntime, runtimeData) {
        setRuntime(runtimeData)
        return true
    }
}
