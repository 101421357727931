import _ from 'lodash'

// const siteDataUtils = coreUtils.siteDataUtils

const getPackageData = (wixapps, packageName) => {
    const data = wixapps[packageName]
    if (!data) {
        wixapps[packageName] = {}
    }

    return wixapps[packageName]
}

// const setPackageData = (siteData, packageName, dataStore) => {
//     if (!siteData) {
//         return null
//     }
//     siteData.wixapps = siteData.wixapps || {}
//     siteData.wixapps[packageName] = dataStore
//
//     return undefined
//     // siteDataUtils.incrementWixappsRenderCounter(siteData, packageName)
// }

const getBlogCategoriesFromPackageData = dataStore => _.get(dataStore, 'categories', null)

const getBlogCategories = wixapps => {
    const dataStore = getPackageData(wixapps, 'blog')
    return getBlogCategoriesFromPackageData(dataStore)
}

const getBlogCategoryByName = (wixapps, name) => {
    const categoryStore = getBlogCategories(wixapps)
    let category
    if (categoryStore) {
        category = _.find(categoryStore.categoryById, {name})
    }
    return category || null
}

function setCompFailedRequests(value, appPartDrcAPI, packageName, compId) {
    const dataStore = getPackageData(appPartDrcAPI.wixapps, packageName)
    dataStore.failedRequests = dataStore.failedRequests || {}
    dataStore.failedRequests[compId] = value
}

// const getDataByCompId = ({wixapps}, packageName, compId) => {
//     const dataStore = getPackageData(wixapps, packageName)
//     if (dataStore[compId]) {
//         return dataStore[compId]
//     }
//     return null
// }

// const setDataForCompId = (siteData, packageName, compId, path) => {
//     const dataStore = getPackageData(siteData, packageName)
//     dataStore[compId] = path
//     // siteDataUtils.incrementWixappsRenderCounter(siteData, packageName, compId)
// }

// const clearDataForCompId = (siteData, packageName, compId) => {
//     const dataStore = getPackageData(siteData, packageName)
//     delete dataStore[compId]
//     // siteDataUtils.incrementWixappsRenderCounter(siteData, packageName, compId)
// }

const getExtraDataByCompId = (dataStore = {}, compId) => dataStore[`${compId}_extraData`] || null

const getDataByPath = (dataStore = {}, path) => {
    if (!_.isArray(path) || path.length === 0) {
        return []
    }

    // if the path is an array of arrays resolve each inner array as an item.
    if (_.every(path, _.isArray)) {
        return _.map(path, itemPath => getDataByPath(dataStore, itemPath))
    }
    const pathParts = _.clone(path)
    if (!dataStore.items) {
        return null
    }

    let currentScope = dataStore.items
    while (pathParts.length) {
        const prop = pathParts.shift()
        currentScope = currentScope[prop]
    }
    return currentScope
}

const setDataByPath = (wixapps, setWixapps, packageName, path, value) => {
    const pathParts = _.clone(path)
    const dataStore = wixapps[packageName]
    let currentScope = dataStore.items
    while (pathParts.length > 1) {
        const prop = pathParts.shift()
        currentScope = currentScope[prop]
    }

    const propertyName = pathParts.shift()
    if (!_.isEqual(currentScope[propertyName], value)) {
        currentScope[propertyName] = value
        setWixapps(wixapps)
        return true
    }

    return false
}

const getSiteDataDestination = packageName => ['wixapps', packageName]

// const didCompHadFailedRequestsInSSR = (siteData, packageName, compId) => {
//     const dataStore = getPackageData(siteData, packageName)
//     return _.get(dataStore, `ssr.failedRequests.${compId}`, false)
// }

// const didPackageHadRequestsTimeoutInSSR = (siteData, packageName) => {
//     const dataStore = getPackageData(siteData, packageName)
//     return !!dataStore.packageHadRequestsTimeout
// }

function getCompMetadata(appPartDrcAPI, packageName, compId) {
    const dataStore = getPackageData(appPartDrcAPI.wixapps, packageName)
    dataStore.metadata = dataStore.metadata || {}
    dataStore.metadata[compId] = dataStore.metadata[compId] || {}
    return dataStore.metadata[compId]
}

function setCompMetadata(metadata, appPartDrcAPI, packageName, compId) {
    const currentMetadata = getCompMetadata(appPartDrcAPI, packageName, compId)
    _.merge(currentMetadata, metadata)
    //siteDataUtils.incrementWixappsRenderCounter(siteData, packageName, compId);
}

function clearCompMetadata(appPartDrcAPI, packageName, compId) {
    const dataStore = getPackageData(appPartDrcAPI.wixapps, packageName)
    if (dataStore.metadata && dataStore.metadata[compId]) {
        dataStore.metadata[compId] = {}
        //siteDataUtils.incrementWixappsRenderCounter(appPartDrcAPI, packageName, compId);
    }
}

function getDescriptor(appPartDrcAPI, packageName) {
    const dataStore = getPackageData(appPartDrcAPI.wixapps, packageName)
    if (dataStore.descriptor) {
        return dataStore.descriptor
    }
    return null
}

export {
    // getDataByCompId,
    // setDataForCompId,
    // clearDataForCompId,
    // getPackageData,
    // setPackageData,
    getExtraDataByCompId,
    getSiteDataDestination,
    getDataByPath,
    setDataByPath,
    // didCompHadFailedRequestsInSSR,
    // didPackageHadRequestsTimeoutInSSR,
    getBlogCategoriesFromPackageData,
    setCompFailedRequests,
    getCompMetadata,
    setCompMetadata,
    clearCompMetadata,
    getDescriptor,
    getBlogCategories,
    getBlogCategoryByName
}
