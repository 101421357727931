'use strict'
const name = 'ExpandIn'
const properties = {
    hideOnStart: true,
    viewportThreshold: 0.15
}

const scaleMap = {
    soft: 0.85,
    medium: 0.4,
    hard: 0
}

function register({factory}) {
    /**
     * Expand in from
     * @param {Array<HTMLElement>|HTMLElement} elements DOM element to animate
     * @param {Number} [duration]
     * @param {Number} [delay]
     * @param {Object} [params] Timeline optional parameters (Tween values cannot be changed here, use BaseFade).
     * @param {'soft'|'medium'|'hard'} [power='hard'] 'soft', 'medium', 'hard'
     * @returns {TimelineMax}
     */
    function animation(elements, duration, delay, {power = 'hard', ...params} = {}) {
        const sequence = factory.sequence(params)
        const scale = scaleMap[power]

        sequence.add([
            factory.animate('BaseFade', elements, duration, delay, {from: {opacity: 0}, to: {opacity: 1}, ease: 'Cubic.easeIn'}),
            factory.animate('BaseScale', elements, duration, delay, {from: {scale}, ease: 'Sine.easeIn'})
        ])
        return sequence.get()
    }

    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}
