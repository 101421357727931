define([], function () {
    'use strict';

    /*
     * Patches width of TPA iframes on mobile Safari. Solves known issue in Safari where
     * width: 100% is not calculated right.
     */
    function patchWidth(id, patchers) {
        patchers.css(`${id}iframe`, {
            width: 1,
            minWidth: '100%'
        });
    }

    function customLayout(id, nodesMap, measureMap, layoutAPI) {
        const isSafariOnIOS = layoutAPI.os.ios && layoutAPI.browser.safari;
        const hasIframe = nodesMap[id].dataset.hasIframe === 'true';

        if (isSafariOnIOS && hasIframe) {
            return patchers => {
                patchWidth(id, patchers);
                if (layoutAPI.isMesh) {
                    patchers.css(id, {
                        height: `${measureMap.height[id]}px`
                    });
                }
            };
        }
    }

    return customLayout;
});
