import {withActions} from 'carmi-host-extensions/src/aspects/withActions'
import {reportError, reportEvent} from '../../utils/wixappsLogger'
import {getDataByPath, setDataByPath, getExtraDataByCompId} from '../../utils/wixappsDataHandler'
import {todoFunctionLibrary} from '../todoAspect/todoAspect'
import {ColorsUtils} from 'carmi-host-extensions/src/index'
import {santaTypesDefinitions} from 'santa-components'
import _ from 'lodash'
import {getAppPageParams} from '../../utils/wixappsUrlParser'

export const name = 'WixappsCoreAspect'

export const defaultModel = {
    compsInfoToRender: {},
    wixapps: {}
}

export const functionLibrary = {
    getComponentProps: withActions(({setCompInfoToRender, compProps, compClasses, $runInBatch}, compStructure, rootId = 'stamNonExistingId', propsOverride) => {
        $runInBatch(() => setCompInfoToRender(compStructure.id, {structure: {...compStructure, parent: rootId}, props: propsOverride}))
        const componentProps = compProps[compStructure.id]
        const setCompDataKey = _.findKey(compClasses[compStructure.componentType].propTypes, {id: santaTypesDefinitions.DAL.setCompData.id})

        return setCompDataKey ? _.assign({}, componentProps, {[setCompDataKey]: _.noop}) : componentProps
    }),
    parseAppPage: (appPageId, urlExtraData) => getAppPageParams(appPageId, urlExtraData),
    getExtraDataByCompId: (wixappsStore, pkgName, compId) => getExtraDataByCompId(wixappsStore[pkgName], compId),
    getAppPageUrl: (renderInfo, requireFn, pageId, itemId, itemTitle) => {
        const warmupUtils = requireFn('warmupUtils')
        const pageInfo = {
            pageId,
            title: itemTitle,
            pageAdditionalData: itemId
        }

        return warmupUtils.wixUrlParser.getUrl(renderInfo, pageInfo)
    },
    getLogic: (appPartName, requireFn) => requireFn('wixappsCore').logicFactory.getLogicClass(appPartName),
    wixAppsReportEvent: reportEvent,
    wixAppsReportError: reportError,
    wixAppsGetDataByPath: (wixapps, packageName, path) => getDataByPath(wixapps[packageName], path),
    wixAppsSetDataByPath: (getWixapps, setWixapps, packageName, path, value) => setDataByPath(getWixapps(), setWixapps, packageName, path, value),
    getColorWixApps: (colorsMap, colorId) => ColorsUtils.getColor(colorsMap, colorId),
    getWixappsFunctionLibrary: (FunctionLibrary, isMobile, zoom, serviceTopology, getColor, experimentContext) => FunctionLibrary.bind(null, isMobile, zoom, serviceTopology, getColor, experimentContext),
    ...todoFunctionLibrary
}
