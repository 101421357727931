define(['lodash', 'warmupUtils', 'coreUtils/core/skinAnchorsMetaData'], function (_, warmupUtils, skinAnchorsMetaData) {
    'use strict';

    function createOriginalValuesMap(pageStructure, siteTheme, isMobileView) {
        const originalValuesMap = {};

        function addComponentToOriginalValuesMap(component) {
            const children = warmupUtils.dataUtils.getChildrenData(component, isMobileView);
            const originalData = {};

            if (component.layout) {
                originalData.top = warmupUtils.boundingLayout.getBoundingY(component.layout);
            }

            if (!_.isEmpty(children)) {
                if (component.layout) {
                    const componentStyleId = component.styleId && component.styleId.replace('#', '');
                    const componentStyle = siteTheme[componentStyleId];
                    const componentSkin = componentStyle && componentStyle.skin || component.skin; // eslint-disable-line no-mixed-operators

                    originalData.height = component.layout.height - skinAnchorsMetaData.getNonAnchorableHeightForSkin(componentSkin, componentStyle);
                }

                _.forEach(children, addComponentToOriginalValuesMap);
            }

            originalValuesMap[component.id] = originalData;
        }

        addComponentToOriginalValuesMap(pageStructure);
        return originalValuesMap;
    }

    return {
        createOriginalValuesMap
    };
});
