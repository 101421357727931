define(['componentsCore', 'wixFreemiumBanner/components/banner', 'wixFreemiumBanner/components/banner2'],
    function (componentsCore, banner, banner2) {
        'use strict';

        componentsCore.compRegistrar.register('wysiwyg.viewer.components.WixFreemiumBanner', banner);
        componentsCore.compRegistrar.register('wysiwyg.viewer.components.WixFreemiumBanner2', banner2);

        return {
            banner,
            banner2
        };
    });
