define([
    'lodash',
    'layout/util/layout',
    'layout/specificComponents/shapeLayout'
], function (_, /** layout.layout */ layout, shapeLayout) {
    'use strict';

    function layoutMediaOverlayControls(id, nodesMap) {
        const catcherId = `${id}catcher`;
        const playerId = nodesMap[id].getAttribute('data-player-id');
        nodesMap[catcherId] = nodesMap[id].querySelector(`#${catcherId}`);

        const top = -nodesMap[id].offsetTop;
        const left = -nodesMap[id].offsetLeft;
        const width = nodesMap[playerId].offsetWidth;
        const height = nodesMap[playerId].offsetHeight;

        const shapePatcher = shapeLayout.layoutShapeNodes(id, nodesMap);

        return patchers => {
            patchers.css(catcherId, {
                top,
                left,
                width,
                height
            });

            shapePatcher(patchers);
        };
    }

    layout.registerCustomLayoutFunction('wysiwyg.viewer.components.MediaOverlayControls', layoutMediaOverlayControls);
});
