define([
    'lodash',
    'warmupUtilsLib'
], function (_,
             warmupUtilsLib) {
    'use strict';
    const FALLBACK_BOX_BOUNDARIES = {
        width: 1,
        height: 1,
        x: 0,
        y: 0
    };

    const getViewBox = ({x, y, width, height}) => `${x} ${y} ${width} ${height}`;

    /**
     *
     * @param {{x: number, y: number, width: number, height: number}} boxBoundaries
     * @param {number} strokeSize
     * @param {{width: number, height: number}} targetSize, the target dom size
     * @param {boolean} maintainAspectRatio
     */
    function getSvgScaleProps(boxBoundaries = FALLBACK_BOX_BOUNDARIES, strokeSize, targetSize, maintainAspectRatio) {
        const preserveAspectRatio = maintainAspectRatio ? 'xMidYMid meet' : 'none';
        const isVectorEffect = warmupUtilsLib.svgFeatureDetection.flags().isVectorEffect; // browser support of css 'vector-effect: non-scaling-stroke'
        const viewBoxObj = strokeSize ? getScaledViewBox(boxBoundaries, strokeSize, targetSize, maintainAspectRatio) : boxBoundaries;
        const viewBox = getViewBox(viewBoxObj);
        const strokeWidth = strokeSize && !isVectorEffect ? getScaledStroke(boxBoundaries, strokeSize, targetSize) : strokeSize;
        const {width, height} = targetSize;

        return {
            viewBox,
            strokeWidth,
            width,
            height,
            preserveAspectRatio
        };
    }

    function getScaledStroke(boxBoundaries, strokeWidth, size) {
        const scale = Math.min((size.width - strokeWidth) / boxBoundaries.width, (size.height - strokeWidth) / boxBoundaries.height);
        return strokeWidth * (1 / scale);
    }

    /**
     *
     * @param {{x: number, y: number, width: number, height: number}} boxBoundaries
     * @param {number} strokeWidth
     * @param {{width: number, height: number}} size, the target dom size
     * @param {boolean} maintainAspectRatio
     */
    function getScaledViewBox(boxBoundaries, strokeWidth, size, maintainAspectRatio) {
        const wScale = (size.width - strokeWidth) / boxBoundaries.width;
        const hScale = (size.height - strokeWidth) / boxBoundaries.height;
        const aspectScale = Math.min(wScale, hScale);

        const width = size.width / (maintainAspectRatio ? aspectScale : wScale);
        const height = size.height / (maintainAspectRatio ? aspectScale : hScale);
        const x = boxBoundaries.x - (width - boxBoundaries.width) / 2; // eslint-disable-line no-mixed-operators
        const y = boxBoundaries.y - (height - boxBoundaries.height) / 2; // eslint-disable-line no-mixed-operators

        return {
            width,
            height,
            x,
            y
        };
    }

    return {
        getSvgScaleProps
    };
});
