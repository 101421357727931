define(['lodash', 'coreUtils'], function (_, coreUtils) {
    'use strict';
    const siteAspects = {};
    const hostLibsAspects = {};
    coreUtils.sessionFreezer.freeze(siteAspects);
    coreUtils.sessionFreezer.freeze(hostLibsAspects);

    /**
     * @class core.siteAspectsRegistry
     */
    return {
        /**
         *
         * @param {string} aspectName
         * @param {function(core.SiteAspectsSiteAPI)} aspectConstructor
         */
        registerSiteAspect(aspectName, aspectConstructor) {
            siteAspects[aspectName] = aspectConstructor;
        },

        registerHostLibsAspect(aspectName, aspectConstructor) {
            hostLibsAspects[aspectName] = aspectConstructor;
        },

        getAllAspectConstructors() {
            return siteAspects;
        },

        getHostLibsAspectConstructor(driverName) {
            return hostLibsAspects[driverName];
        },

        getSiteAspectConstructor(driverName) {
            return siteAspects[driverName];
        }
    };
});


/**
 * @name core.SiteAspectInterface
 * @interface
 * @property {function(object<string, string>): ReactComponent[]} getReactComponents gets the loaded styles map, should be used to build comp props
 * @property {function(): data.compStructure[]} getComponentStructures
 * @property {function(): {structure: data.compStructure, props: object}[]} getComponentsToRender
 */


