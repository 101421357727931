define(['lodash'], function (_) {
    'use strict';

    return {
        /**
         * This is here so that we can mock this module in tests without needing to mock requirejs for dynamic request based on account id
         * @param accountId
         * @param callback
         */
        load(accountId, callback) {
            requirejs([`//www.googletagmanager.com/gtm.js?id=${accountId}`], callback, _.noop);
        }
    };
});
