define(['lodash'], function (_) {
    'use strict';

    if (typeof WeakMap !== 'undefined') {
        return WeakMap; // eslint-disable-line no-undef
    }

    let counter = 0;

    function SiteDataPrivatesPolyfill() {
        this.name = `_privates_${counter}`;
        counter++;
    }

    SiteDataPrivatesPolyfill.prototype.set = function (key, value) {
        key[this.name] = value; // eslint-disable-line santa/no-side-effects
    };

    SiteDataPrivatesPolyfill.prototype.get = function (key) {
        return key[this.name];
    };

    SiteDataPrivatesPolyfill.prototype.delete = function (key) {
        delete key[this.name];
    };

    SiteDataPrivatesPolyfill.prototype.has = function (key) {
        return _.has(key, this.name);
    };

    return SiteDataPrivatesPolyfill;
});
