define([
    'lodash',
    'warmupUtils',
    'coreUtils/core/masterPageLayoutUtils',
    'coreUtils/core/skinAnchorsMetaData',
    'coreUtils/core/componentsAnchorsMetaData',
    'coreUtils/core/groupingAnchors',
    'coreUtils/core/anchorsUtils'
], function (
    _,
    warmupUtils,
    masterPageLayoutUtils,
    skinAnchorsMetaData,
    componentsAnchorsMetaData,
    groupingAnchors,
    anchorsUtils
) {
    'use strict';

    const types = anchorsUtils.anchorsTypes;

    const COMPS_WITH_RUNTIME_DYNAMIC_HEIGHT = ['PAGES_CONTAINER'];

    const parentPlaceholder = 'parentNode';

    function isLowerCompBottomBelowUpperComp(upperCompAnchorsInfo, lowerCompAnchorsInfo) {
        const lowerCompY = lowerCompAnchorsInfo.boundingY;
        const lowerCompBottom = lowerCompAnchorsInfo.boundingBottom;

        const upperCompY = upperCompAnchorsInfo.boundingY;
        const upperCompHeight = upperCompAnchorsInfo.boundingHeight;
        const upperCompBottom = upperCompAnchorsInfo.boundingBottom;

        const upperCompCenter = upperCompY + upperCompHeight / 2; // eslint-disable-line no-mixed-operators

        return lowerCompBottom > upperCompBottom && lowerCompY > upperCompCenter;
    }

    function createBottomTopAnchor(fromCompAnchorsInfo, toCompAnchorsInfo, zeroOriginalValue) {
        const distance = toCompAnchorsInfo.boundingY - fromCompAnchorsInfo.boundingY - fromCompAnchorsInfo.boundingHeight;
        const originalValue = zeroOriginalValue ? 0 : toCompAnchorsInfo.boundingY;

        return createAnchor(types.bottomTop, fromCompAnchorsInfo.componentType, fromCompAnchorsInfo.id, toCompAnchorsInfo.id, distance, originalValue);
    }

    function createTopTopAnchor(fromCompAnchorsInfo, toCompAnchorsInfo) {
        const distance = toCompAnchorsInfo.boundingY - fromCompAnchorsInfo.boundingY;

        return createAnchor(types.topTop, fromCompAnchorsInfo.componentType, fromCompAnchorsInfo.id, toCompAnchorsInfo.id, distance, toCompAnchorsInfo.boundingY);
    }

    function createBottomParentAnchor(fromCompAnchorsInfo, toCompAnchorsInfo, siteTheme) { // eslint-disable-line complexity
        if (toCompAnchorsInfo.id === 'masterPage' || toCompAnchorsInfo.id === warmupUtils.constants.COMP_IDS.PAGES_CONTAINER) {
            return createAnchor(types.bottomParent, fromCompAnchorsInfo.componentType, fromCompAnchorsInfo.id, toCompAnchorsInfo.id, 0, 0);
        }

        const toCompStyleId = toCompAnchorsInfo.styleId && toCompAnchorsInfo.styleId.replace('#', '');
        const toCompStyle = siteTheme[toCompStyleId];
        const toCompSkin = toCompStyle && toCompStyle.skin || toCompAnchorsInfo.skin; // eslint-disable-line no-mixed-operators
        const anchorableContainerHeight = toCompAnchorsInfo.height - skinAnchorsMetaData.getNonAnchorableHeightForSkin(toCompSkin, toCompStyle);
        const originalValue = anchorableContainerHeight;
        const compAnchorsMetaData = componentsAnchorsMetaData.get();
        const isZeroBottomParentDistance = _.get(compAnchorsMetaData, [toCompAnchorsInfo.componentType, 'to', 'zeroBottomParentDistance']);
        let distance = isZeroBottomParentDistance ? 0 : anchorableContainerHeight - (fromCompAnchorsInfo.boundingY + fromCompAnchorsInfo.boundingHeight);

        const isPositiveAnchorToDisplayedOnlyParent = _.get(compAnchorsMetaData, [fromCompAnchorsInfo.componentType, 'from', 'positiveAnchorToDisplayedOnlyParent']);
        const isDisplayedOnlyParent = warmupUtils.displayedOnlyStructureUtil.isDisplayedComponent(toCompAnchorsInfo.id);
        if (isPositiveAnchorToDisplayedOnlyParent && isDisplayedOnlyParent) {
            distance = Math.max(0, distance);
        }

        return createAnchor(types.bottomParent, fromCompAnchorsInfo.componentType, fromCompAnchorsInfo.id, toCompAnchorsInfo.id, distance, originalValue);
    }

    function getBasicAnchorStructure(type, fromCompId, toCompId, originalValue, distance) {
        return {
            distance: distance || 0,
            locked: true,
            originalValue,
            fromComp: fromCompId,
            targetComponent: toCompId,
            type
        };
    }

    function createAnchor(type, fromCompType, fromCompId, toCompId, distance, originalValue) {
        const anchor = getBasicAnchorStructure(type, fromCompId, toCompId, originalValue, distance);

        anchor.locked = anchorsUtils.isAnchorLocked(type, fromCompType, distance);
        return anchor;
    }

    function getTargetsOfAnchorsFromComps(compsIds, graph) {
        return _.flatMap(compsIds, function (compId) {
            return _.map(graph[compId], 'target');
        });
    }

    function getTargetsOfAnchorsToResetOriginalValue(graph) {
        let idsInIgnoreOriginalValueChain = getTargetsOfAnchorsFromComps(COMPS_WITH_RUNTIME_DYNAMIC_HEIGHT, graph);

        const targetsOfAnchorsToResetOriginalValue = {};
        while (idsInIgnoreOriginalValueChain.length) {
            const currentId = idsInIgnoreOriginalValueChain.shift();
            targetsOfAnchorsToResetOriginalValue[currentId] = true;
            const nextInChain = _(graph[currentId])
                .map('target')
                .reject(function (compId) {
                    return _.has(targetsOfAnchorsToResetOriginalValue, compId);
                })
                .value();
            idsInIgnoreOriginalValueChain = idsInIgnoreOriginalValueChain.concat(nextInChain);
        }

        return targetsOfAnchorsToResetOriginalValue;
    }

    function createAnchorsAccordingToGraph(graph, compsMap, parent, siteTheme) {
        const targetsOfAnchorsToResetOriginalValue = getTargetsOfAnchorsToResetOriginalValue(graph);

        return _.mapValues(graph, function (compAnchorsData, compId) {
            if (!compAnchorsData.length) {
                return [];
            }
            const pusher = compsMap[compId];

            if (pusher.layoutAnchors) {
                return pusher.layoutAnchors.slice();
            }
            return _(compAnchorsData)
                .map(function (anchorData) {
                    if (anchorData.target === parentPlaceholder) {
                        return createBottomParentAnchor(pusher, parent, siteTheme);
                    }
                    const pushed = compsMap[anchorData.target];
                    if (anchorData.type === types.bottomTop) {
                        return createBottomTopAnchor(pusher, pushed, targetsOfAnchorsToResetOriginalValue[pushed.id]);
                    }
                    return createTopTopAnchor(pusher, pushed);
                })
                .compact()
                .value();
        });
    }

    function createNewAnchorsForRowsChildren(children, siteTheme) {
        const groupedRows = _.filter(children, groupingAnchors.isVirtualGroup);
        return _.reduce(groupedRows, function (rowsAnchorsMap, row) {
            const flags = {forceMobileStructure: true, applyGroupingAnchors: false};
            const rowAnchorsMap = _.reduce(createNewAnchorsForChildren(row, flags, siteTheme), function (res, anchorsArray, componentId) {
                return componentId === row.id ? res : _.set(res, componentId, _.reject(anchorsArray, {targetComponent: row.id}));
            }, {});
            return _.assign(rowsAnchorsMap, rowAnchorsMap);
        }, {});
    }

    function createCopiedChildrenAnchorsMap(currentChildren, templateId, templateAnchorsMap) {
        const dispOnlyUtil = warmupUtils.displayedOnlyStructureUtil;
        const anchorsForChildren = {};
        const itemIds = _(currentChildren)
            .map('id')
            .map(warmupUtils.displayedOnlyStructureUtil.getItemId)
            .without(templateId)
            .value();

        _.forEach(templateAnchorsMap, function (templateAnchors, templateCompId) {
            _.forEach(itemIds, function (itemId) {
                const originalId = dispOnlyUtil.getOriginalId(templateCompId);
                const childToCopyTo = dispOnlyUtil.getUniqueDisplayedId(originalId, itemId);
                anchorsForChildren[childToCopyTo] = _.reduce(templateAnchors, function (res, anchor) {
                    const newFromComp = dispOnlyUtil.getUniqueDisplayedId(dispOnlyUtil.getOriginalId(anchor.fromComp), itemId);
                    const newTargetComp = dispOnlyUtil.getUniqueDisplayedId(dispOnlyUtil.getOriginalId(anchor.targetComponent), itemId);
                    const childInItemAnchor = _.defaults({fromComp: newFromComp, targetComponent: newTargetComp}, anchor);
                    res.push(childInItemAnchor);
                    return res;
                }, []);
            });
        });

        return anchorsForChildren;
    }

    function createTemplateAnchorsMap(children, templateId, flags, siteTheme) {
        const templateChild = _.find(children, function (child) {
            const childItemId = warmupUtils.displayedOnlyStructureUtil.getItemId(child.id);
            return childItemId === templateId;
        });

        return createChildrenAnchorsDeep(templateChild || _.head(children), siteTheme, flags);
    }

    function createNewAnchorsForSpecificComps(components, parent, flags, siteTheme, componentsTemplateId, anchorsInfoOverrides) {
        const {pushRows, applyGroupingAnchors} = flags;
        const compAnchorsMetaData = componentsAnchorsMetaData.get();
        const applyGrouping = applyGroupingAnchors && !_.get(compAnchorsMetaData, [parent.componentType, 'skipGroupingForChildren']);
        const groupedChildren = groupingAnchors.groupRows(components, applyGrouping);
        const rowsAnchorsMap = createNewAnchorsForRowsChildren(groupedChildren, siteTheme);
        const compsAnchorsInfo = createCompsAnchorsInfo(groupedChildren, anchorsInfoOverrides);
        const shouldPushRows = pushRows && !_.get(compAnchorsMetaData, [parent.componentType, 'disablePushRows']);
        const graph = createDependencyGraph(compsAnchorsInfo, shouldPushRows);
        const currentAnchorsMap = _(compsAnchorsInfo)
            .mapValues('anchors')
            .assign(createAnchorsAccordingToGraph(graph, compsAnchorsInfo, createCompAnchorsInfo(parent), siteTheme))
            .omit('parentNode')
            .thru(_.partial(groupingAnchors.resolve, parent, groupedChildren, applyGrouping, rowsAnchorsMap))
            .value();

        const parentTemplateId = _.get(componentsTemplateId, parent.id);
        if (parentTemplateId) {
            const templateAnchorsMap = createTemplateAnchorsMap(components, parentTemplateId, flags, siteTheme);
            _.assign(currentAnchorsMap, templateAnchorsMap, createCopiedChildrenAnchorsMap(components, parentTemplateId, templateAnchorsMap));
        }

        return currentAnchorsMap;
    }

    function createNewAnchorsForLandingPage(children, parent, flags, siteTheme, componentsTemplateId) {
        const masterLandingPageChildren = resolveLandingPageStructure(parent, children, flags.forceMobileStructure);

        return createNewAnchorsForSpecificComps(masterLandingPageChildren, parent, flags, siteTheme, componentsTemplateId);
    }

    function getGapsData(flags) {
        const gapsData = {};

        if (flags.headerPagesContainerLegacyGap) {
            gapsData.SITE_HEADER = {targetComponent: 'PAGES_CONTAINER', distance: flags.headerPagesContainerLegacyGap};
        }

        if (flags.pagesContainerFooterLegacyGap) {
            gapsData.PAGES_CONTAINER = {targetComponent: 'SITE_FOOTER', distance: flags.pagesContainerFooterLegacyGap};
        }

        return gapsData;
    }

    function createFloatingComponentsAnchorsMap(masterPageSections, nonSectionComps, parent, flags, siteTheme, componentsTemplateId) {
        const headerAndNonSectionComps = _.filter(masterPageSections, {id: 'SITE_HEADER'}).concat(nonSectionComps);
        const anchorsInfoOverrides = {
            'SITE_HEADER': {
                canBePushed: false
            }
        };

        return createNewAnchorsForSpecificComps(headerAndNonSectionComps, parent, flags, siteTheme, componentsTemplateId, anchorsInfoOverrides);
    }

    function createSectionsMasterPageAnchors(parent, flags, siteTheme, componentsTemplateId) {
        const segregatedChildren = masterPageLayoutUtils.getMasterPageChildrenSegregateByIsSection(parent, flags.forceMobileStructure);
        const masterPageSections = segregatedChildren[0];
        const nonSectionComps = segregatedChildren[1];

        const anchorsMap = createFloatingComponentsAnchorsMap(masterPageSections, nonSectionComps, parent, flags, siteTheme, componentsTemplateId);
        const gaps = getGapsData(flags);
        const siteSectionsAnchorsMap = _.mapValues(getMasterPageSectionsAnchors(masterPageSections, gaps), function (anchor) {
            return [anchor];
        });

        anchorsMap.SITE_HEADER = anchorsMap.SITE_HEADER.concat(siteSectionsAnchorsMap.SITE_HEADER);

        return _.defaults(anchorsMap, siteSectionsAnchorsMap);
    }

    function createNewAnchorsForMasterPageChildren(children, parent, flags, siteTheme, componentsTemplateId) {
        if (isLandingPageStructure(children)) {
            return createNewAnchorsForLandingPage(children, parent, flags, siteTheme, componentsTemplateId);
        }

        return !flags.forceMobileStructure && flags.useDesktopSectionsLayout ?
            createSectionsMasterPageAnchors(parent, flags, siteTheme, componentsTemplateId) :
            createNewAnchorsForSpecificComps(children, parent, flags, siteTheme, componentsTemplateId);
    }

    function createNewAnchorsForChildren(parent, flags, siteTheme, componentsTemplateId) {
        const children = warmupUtils.dataUtils.getChildrenData(parent, flags.forceMobileStructure);
        if (_.isEmpty(children)) {
            return {};
        }

        return parent.type === 'Document' ?
            createNewAnchorsForMasterPageChildren(children, parent, flags, siteTheme, componentsTemplateId) :
            createNewAnchorsForSpecificComps(children, parent, flags, siteTheme, componentsTemplateId);
    }

    function isLandingPageStructure(children) {
        const header = _.find(children, {id: 'SITE_HEADER'});
        if (!header) {
            return false;
        }
        const pagesContainer = _.find(children, {id: 'PAGES_CONTAINER'});
        return pagesContainer.layout.y < header.layout.y + header.layout.height;
    }

    function createCompsAnchorsInfo(comps, anchorsInfoOverrides) {
        const compsAnchorsInfo = _.reduce(comps, function (anchorsInfo, comp) {
            anchorsInfo[comp.id] = createCompAnchorsInfo(comp, anchorsInfoOverrides);
            return anchorsInfo;
        }, {});

        _.forEach(anchorsInfoOverrides, function (currInfoOverrides, compId) {
            compsAnchorsInfo[compId] = _.assign(compsAnchorsInfo[compId], currInfoOverrides);
        });

        return compsAnchorsInfo;
    }

    function createCompAnchorsInfo(comp) {
        const layout = comp.layout;
        const boundingY = warmupUtils.boundingLayout.getBoundingY(layout);
        const boundingHeight = warmupUtils.boundingLayout.getBoundingHeight(layout);
        const boundingX = layout.x ? warmupUtils.boundingLayout.getBoundingX(layout) : 0;
        return {
            id: comp.id,
            layoutAnchors: layout.anchors,
            anchors: [],
            y: layout.y,
            x: layout.x,
            height: layout.height,
            width: layout.width,
            canBePushed: anchorsUtils.componentCanBePushed(comp.componentType),
            canPush: anchorsUtils.componentCanPush(comp.componentType),
            boundingY,
            boundingX,
            boundingBottom: boundingY + boundingHeight,
            boundingHeight,
            boundingWidth: warmupUtils.boundingLayout.getBoundingWidth(layout),
            canCreateAnchorsToComp: anchorsUtils.canCreateAnchorsToComp(comp),
            styleId: comp.styleId,
            skin: comp.skin,
            componentType: comp.componentType
        };
    }

    function hasBottomTopToPages(compStructure) {
        return _.find(compStructure.layout.anchors, {
            targetComponent: 'PAGES_CONTAINER',
            type: types.bottomTop
        });
    }

    function resolveLandingPageStructure(masterPageComp, structureComps, isMobileView) {
        if (masterPageComp.layout.anchors) {
            return resolveLandingPageStructureFromMasterPageAnchors(masterPageComp, structureComps, isMobileView);
        }

        return resolveLandingPageStructureFromOldAnchors(structureComps);
    }

    /**
     * Master page of sites that went through remove anchors migration, contains an anchor with distance between the header and the pages container for desktop.
     * @param masterPageComp
     * @param structureComps
     * @returns {*}
     */
    function resolveLandingPageStructureFromMasterPageAnchors(masterPageComp, structureComps, isMobileView) {
        const res = _.clone(structureComps);
        const header = _.find(structureComps, {id: 'SITE_HEADER'});
        const footer = _.find(structureComps, {id: 'SITE_FOOTER'});
        const distanceFromHeaderToPagesContainer = getDistanceFromHeaderToPagesContainer(masterPageComp, isMobileView);
        const deducedPagesContainerTop = distanceFromHeaderToPagesContainer + warmupUtils.boundingLayout.getBoundingY(header.layout) + warmupUtils.boundingLayout.getBoundingHeight(header.layout);
        const pagesContainerIndex = _.findIndex(structureComps, {id: 'PAGES_CONTAINER'});
        const pagesContainer = structureComps[pagesContainerIndex];
        const updatedLayout = _.assign({}, pagesContainer.layout, {
            y: deducedPagesContainerTop,
            height: footer.layout.y - deducedPagesContainerTop
        });
        res[pagesContainerIndex] = _.assign({}, pagesContainer, {layout: updatedLayout});
        return res;
    }

    function resolveLandingPageStructureFromOldAnchors(structureComps) {
        const res = _.clone(structureComps);
        const footer = _.find(structureComps, {id: 'SITE_FOOTER'});
        const bottomMostCompAbovePagesContainer = _.maxBy(structureComps, function (comp) {
            if (hasBottomTopToPages(comp)) {
                return warmupUtils.boundingLayout.getBoundingY(comp.layout) + warmupUtils.boundingLayout.getBoundingHeight(comp.layout);
            }

            return -10000;
        });
        if (!bottomMostCompAbovePagesContainer || !hasBottomTopToPages(bottomMostCompAbovePagesContainer)) {
            return res;
        }
        const bottomMostAnchorToPagesContainer = _.find(bottomMostCompAbovePagesContainer.layout.anchors, {
            targetComponent: 'PAGES_CONTAINER',
            type: types.bottomTop
        });
        const bottomOfClosestCompAbovePagesContainer = warmupUtils.boundingLayout.getBoundingY(bottomMostCompAbovePagesContainer.layout) + warmupUtils.boundingLayout.getBoundingHeight(bottomMostCompAbovePagesContainer.layout);
        const deducedPagesContainerTop = bottomMostAnchorToPagesContainer.distance + bottomOfClosestCompAbovePagesContainer;
        const pagesContainerIndex = _.findIndex(structureComps, {id: 'PAGES_CONTAINER'});
        const pagesContainer = structureComps[pagesContainerIndex];
        const updatedLayout = _.assign({}, pagesContainer.layout, {
            y: deducedPagesContainerTop,
            height: footer.layout.y - deducedPagesContainerTop
        });
        res[pagesContainerIndex] = _.assign({}, pagesContainer, {layout: updatedLayout});
        return res;
    }

    function getDistanceFromHeaderToPagesContainer(masterPageComp, isMobileView) {
        return isMobileView ? 0 : _.get(masterPageComp, 'layout.anchors.0.distance');
    }

    function removeTransitiveBottomTopEdges(anchorsToConnect, reversedGraph) {
        // TODO GuyR 21/03/2016 23:00 - only add from the reversed graph comps that have bottom top to id
        _(anchorsToConnect)
            .keys()
            .flatMap(_.propertyOf(reversedGraph))
            .filter({type: types.bottomTop})
            .map('source')
            .forEach(function (bottomTopPushersId) {
                delete anchorsToConnect[bottomTopPushersId];
            });
    }

    function filterBottomParentWithTransitiveBottomTopChain(anchorsToConnect, graph) {
        return _.omitBy(anchorsToConnect, function (anchorType, id) {
            return _.some(graph[id], {type: types.bottomTop});
        });
    }

    const updateGraphs = (graph, reversedGraph, comp, anchorsToConnect) => {
        if (_.isEmpty(anchorsToConnect)) {
            return;
        }

        if (comp.id === parentPlaceholder) {
            anchorsToConnect = filterBottomParentWithTransitiveBottomTopChain(anchorsToConnect, graph);
        } else {
            removeTransitiveBottomTopEdges(anchorsToConnect, reversedGraph);
        }

        _.forEach(anchorsToConnect, function (anchorType, id) {
            graph[id].push({target: comp.id, type: anchorType});
            reversedGraph[comp.id].push({source: id, type: anchorType});
        });
    };

    const getParentPlaceholder = () => ({
        x: -100000,
        y: Infinity, //should be beneath other components
        height: 10,
        width: Infinity,
        id: parentPlaceholder,
        canBePushed: true,
        canPush: false,
        boundingY: Infinity,
        boundingBottom: Infinity,
        boundingHeight: 10
    });

    const getPushedCandidates = comps => _(comps)
        .filter('canCreateAnchorsToComp')
        .sortBy('boundingY')
        .concat(getParentPlaceholder())
        .value();

    const createAnchorsDependencyGraph = comps => {
        const pushedCandidates = getPushedCandidates(comps);
        let pushersCandidates = [{left: -100000, right: Infinity, ids: ['root']}];
        const graph = {
            root: []
        }; //keys are ids, values are array of connected vertices - direct x overlapping components
        const reversedGraph = {};
        let compLeft, compRight, beginIndex, endIndex;

        // graph creation
        pushedCandidates.forEach(function (pushedCandidate) { // eslint-disable-line complexity
            graph[pushedCandidate.id] = [];
            reversedGraph[pushedCandidate.id] = [];
            beginIndex = null;
            compLeft = pushedCandidate.boundingX;
            compRight = compLeft + pushedCandidate.boundingWidth;
            endIndex = 0;

            const pushersCandidatesLength = pushersCandidates.length;
            const compsLeftOfCurrent = [];
            const anchorsToConnect = {};
            let overlappingComps = [];
            let isFirstOverlap = false;
            let isLastOverlap = false;

            // find which previously reached comps overlap current comp and create edges from them to current comp
            // create updated xSorted after pushing the current comp and replacing overlapped comps
            for (let i = 0; i < pushersCandidatesLength; i++) {
                const pusherCandidate = pushersCandidates[i];
                if (pusherCandidate.right <= compLeft) {
                    compsLeftOfCurrent.push(pusherCandidate);
                    continue;
                }

                if (beginIndex === null) {
                    beginIndex = i;
                    isFirstOverlap = true;
                } else {
                    isFirstOverlap = false;
                }

                if (pushersCandidates.length - 1 === i || pushersCandidates[i + 1].left > compRight) {
                    endIndex = i;
                    isLastOverlap = true;
                }

                if (pushedCandidate.canBePushed) {
                    pusherCandidate.ids.forEach(pusherId => {
                        if (pusherId !== 'root') {
                            anchorsToConnect[pusherId] = getOverlapAnchorType(comps[pusherId], pushedCandidate);
                        }
                    });
                }

                if (pushedCandidate.canPush) {
                    overlappingComps = getOverlappingComps(pushedCandidate.id, overlappingComps, pusherCandidate, anchorsToConnect, isFirstOverlap, isLastOverlap, compLeft, compRight);
                }

                if (isLastOverlap) {
                    break;
                }
            }

            delete anchorsToConnect.root;

            updateGraphs(graph, reversedGraph, pushedCandidate, anchorsToConnect);

            if (pushedCandidate.canPush) {
                const rightComps = _.takeRight(pushersCandidates, pushersCandidatesLength - endIndex - 1);
                pushersCandidates = _.compact(compsLeftOfCurrent).concat(overlappingComps, rightComps);
            }
        });

        delete graph.root;

        return graph;
    };

    const createMeshcorsDependencyGraph = comps => {
        const pushedCandidates = getPushedCandidates(comps);
        let pushersCandidates = ['root'];
        const graph = {
            root: []
        };
        const reversedGraph = {};

        pushedCandidates.forEach(pushedCandidate => {
            graph[pushedCandidate.id] = [];
            reversedGraph[pushedCandidate.id] = [];
            const anchorsToConnect = {};
            const nextPushersCandidatesMap = {};

            for (let i = 0; i < pushersCandidates.length; i++) {
                const currPusherCandidate = pushersCandidates[i];

                if (pushedCandidate.canBePushed && currPusherCandidate !== 'root') {
                    anchorsToConnect[currPusherCandidate] = getOverlapAnchorType(comps[currPusherCandidate], pushedCandidate);
                }

                if (pushedCandidate.canPush) {
                    if (shouldKeepPusher(currPusherCandidate, anchorsToConnect[currPusherCandidate])) {
                        nextPushersCandidatesMap[currPusherCandidate] = true;
                    }

                    nextPushersCandidatesMap[pushedCandidate.id] = true;
                }
            }

            updateGraphs(graph, reversedGraph, pushedCandidate, anchorsToConnect);

            if (pushedCandidate.canPush) {
                pushersCandidates = _.keys(nextPushersCandidatesMap);
            }
        });

        delete graph.root;

        return graph;
    };

    const createDependencyGraph = (comps, pushRows) => pushRows ?
        createMeshcorsDependencyGraph(comps) :
        createAnchorsDependencyGraph(comps);

    function getOverlapAnchorType(pusherAnchorsInfo, pushedAnchorsInfo) {
        const shouldBottomTop = isLowerCompBottomBelowUpperComp(pusherAnchorsInfo, pushedAnchorsInfo);
        return shouldBottomTop ? types.bottomTop : types.topTop;
    }

    const shouldKeepPusher = (pusherId, anchorType) => pusherId !== 'root' && anchorType === types.topTop;

    function getOverlappingComps(compId, overlappingNodes, testedNode, anchorsToConnect, isFirstOverlap, isLastOverlap, compLeft, compRight) {
        const result = _.clone(overlappingNodes);
        if (isFirstOverlap) {
            result.push({
                ids: testedNode.ids,
                left: testedNode.left,
                right: compLeft - 1
            });
        }

        const newNode = {
            ids: [compId],
            left: Math.max(compLeft, testedNode.left),
            right: Math.min(compRight, testedNode.right)
        };

        testedNode.ids.filter(testedId => shouldKeepPusher(testedId, anchorsToConnect[testedId]))
            .forEach(testedId => newNode.ids.push(testedId));

        const previousResultElement = _.last(result);
        if (previousResultElement && !_.isEqual(previousResultElement.ids, newNode.ids)) {
            result.push(newNode);
        } else if (previousResultElement) {
            previousResultElement.right = newNode.right;
        }

        if (isLastOverlap) {
            result.push({
                ids: testedNode.ids,
                left: compRight + 1,
                right: testedNode.right
            });
        }

        return result;
    }

    function createChildrenAnchorsDeep(rootStructure, siteTheme, flags, componentsTemplateId) {
        flags = flags || {};
        const componentsQueue = [rootStructure];
        let currentStructure, children, childrenAnchorsMap;
        const pageAnchorsMap = {};
        while (componentsQueue.length) {
            currentStructure = componentsQueue.pop();

            childrenAnchorsMap = createNewAnchorsForChildren(currentStructure, flags, siteTheme, componentsTemplateId);
            _.assign(pageAnchorsMap, childrenAnchorsMap);

            children = warmupUtils.dataUtils.getChildrenData(currentStructure, flags.forceMobileStructure);

            if (!_.has(componentsTemplateId, currentStructure.id)) {
                Array.prototype.push.apply(componentsQueue, children);
            }
        }
        return pageAnchorsMap;
    }

    function getSectionsAnchorDistance(fromComp, targetComp, gaps) {
        const compGap = _.get(gaps, fromComp);

        return compGap && compGap.targetComponent === targetComp ?
            compGap.distance :
            0;
    }

    function getSectionsAnchor(fromComp, targetComp, gaps) {
        return getBasicAnchorStructure(types.bottomTop, fromComp, targetComp, 0, getSectionsAnchorDistance(fromComp, targetComp, gaps));
    }

    function getSectionsBottomAnchor(fromComp) {
        return getBasicAnchorStructure(types.bottomParent, fromComp, 'masterPage', 0);
    }

    function getMasterPageSectionsAnchors(siteSections, gaps) {
        const siteStructureCompsIds = _.map(siteSections, 'id');
        let fromCompId, currentSectionIndex = 0;
        const siteStructureAnchors = {};

        while (siteStructureCompsIds[currentSectionIndex + 1]) {
            fromCompId = siteStructureCompsIds[currentSectionIndex];
            const targetCompId = siteStructureCompsIds[currentSectionIndex + 1];

            siteStructureAnchors[fromCompId] = getSectionsAnchor(fromCompId, targetCompId, gaps);
            currentSectionIndex++;
        }

        fromCompId = siteStructureCompsIds[currentSectionIndex];
        siteStructureAnchors[fromCompId] = getSectionsBottomAnchor(fromCompId);

        return siteStructureAnchors;
    }

    function replaceSiteSectionsAnchors(siteStructureCompsIds, compId, existingAnchors, anchorToSet) {
        if (!_.includes(siteStructureCompsIds, compId)) {
            return existingAnchors;
        }

        const siteSectionsAnchorsTargets = siteStructureCompsIds.concat(['masterPage']);
        const newAnchors = _.reject(existingAnchors, anchor => _.includes(siteSectionsAnchorsTargets, anchor.targetComponent));

        return newAnchors.concat([anchorToSet]);
    }

    function createMobileTightSectionsAnchors(masterPageStructure) {
        const masterPageSections = masterPageLayoutUtils.getMasterPageSections(masterPageStructure, true);

        return _.mapValues(getMasterPageSectionsAnchors(masterPageSections), anchor => [anchor]);
    }

    function createMobileTightMasterPageAnchors(masterPageStructure, siteTheme, flags) {
        const masterPageAnchors = createChildrenAnchorsDeep(masterPageStructure, siteTheme, flags);

        const masterPageSections = masterPageLayoutUtils.getMasterPageSections(masterPageStructure, true);
        const masterPageSectionsAnchors = getMasterPageSectionsAnchors(masterPageSections);
        const siteStructureCompsIds = _.map(masterPageSections, 'id');

        _.forEach(masterPageSectionsAnchors, function (anchor, compId) {
            masterPageAnchors[compId] = replaceSiteSectionsAnchors(siteStructureCompsIds, compId, masterPageAnchors[compId], anchor);
        });

        return masterPageAnchors;
    }

    function createPageAnchors(pageStructure, siteTheme, flags, componentsTemplateId) {
        return createChildrenAnchorsDeep(pageStructure, siteTheme, flags, componentsTemplateId);
    }

    function createChildrenAnchors(parentStructure, siteTheme, flags, componentsTemplateId) {
        return createNewAnchorsForChildren(parentStructure, flags || {}, siteTheme, componentsTemplateId);
    }


    /**
     * @class anchorsGenerator
     */
    return {
        createPageAnchors,
        createMobileTightSectionsAnchors,
        createMobileTightMasterPageAnchors,
        createChildrenAnchors
    };
});
