define(['tpaComponents/services/tpaPreviewEditorCommunicationService'], function (tpaPreviewEditorCommunicationService) {
    'use strict';

    const openHelp = function (appDefinitionId) {
        tpaPreviewEditorCommunicationService.doPostMessage('openHelp', appDefinitionId);
    };

    return {
        openHelp
    };
});
