define(['lodash', 'zepto'], function (_, $) {
    'use strict';

    const MOBILE_SCREEN_WIDTH = 320;
    const FOOTER_ID = 'SITE_FOOTER';

    const isBrowser = () => typeof window !== 'undefined';
    const getSiteRoot = () => isBrowser() ? $('#SITE_ROOT') : null;
    const getSiteFooter = () => isBrowser() ? $(`#${FOOTER_ID}`) : null;
    const isSiteRootScaled = () => {
        const siteRoot = getSiteRoot();

        return siteRoot && !_.isEqual(siteRoot.css('transform'), 'none');
    };
    const calcContainerAndInlineHeightDiff = (container, containerContent) => {
        if (isSiteRootScaled() && container[0].offsetHeight && containerContent[0].offsetHeight) {
            return container[0].offsetHeight - containerContent[0].offsetHeight;
        }

        return container.height() && containerContent.height() ? container.height() - containerContent.height() : 0;
    };
    const getContainerAndInlineHeightDiff = (containerId, containerNode) => {
        if (isBrowser()) {
            const container = $(containerNode);
            const containerContent = $(container.find(`#${containerId}inlineContent`));

            return calcContainerAndInlineHeightDiff(container, containerContent);
        }
        return 0;
    };

    return {
        getScreenWidth(isMobile) {
            if (isBrowser()) {
                return isMobile ? MOBILE_SCREEN_WIDTH : window.document.body.clientWidth;
            }
            return 0;
        },
        getSiteMarginBottom() {
            const siteRoot = getSiteRoot();
            return _.parseInt(siteRoot ? siteRoot.css('padding-bottom') : 0, 10) || 0;
        },
        isFooterFixedPosition() {
            const siteFooter = getSiteFooter();
            return siteFooter ? siteFooter.attr('data-fixedposition') === 'true' : false;
        },
        getContainerVerticalMarginFromContent(containerId, containerNode) {
            return getContainerAndInlineHeightDiff(containerId, containerNode);
        }
    };
});
