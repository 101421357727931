define(['warmupUtils'], function (warmupUtils) {
    'use strict';

    function report(siteData, params) {
        warmupUtils.loggingUtils.logger.reportBI(siteData, {
            reportType: 'event',
            adapter: 'pa',
            sampleRatio: 0
        }, params);
    }

    return {
        report
    };
});
