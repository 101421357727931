const _ = require('lodash')
const loading = require('../gen/init/loading')
const {getLanguageCode} = require('./init/multilingual')
const supportedLanguages = require('../gen/supportedLanguages')
const {createFunctionLibrary} = require('./init/initFunctionLibrary')
const storageFactory = require('./init/utils/storageFactory')
const {updateHistory} = require('./init/functionLibrary/navigationLib')
const {initSeoWrapper} = require('./aspects/seo/seoComponentsWrapperFactory')
const noop = () => {
}

function getEmptyMaps() {
    return {
        behaviors_data: {},
        connections_data: {},
        document_data: {},
        design_data: {},
        mobile_hints: {},
        component_properties: {},
        theme_data: {}
    }
}

const getComponentsMap = components => Object.keys(components).reduce((acc, compKey) => {
    const compClass = components[compKey]
    if (!compClass.compType) {
        return acc
    }

    return {
        [compClass.compType]: compClass,
        ...acc
    }
}, {})

const getCompClasses = (compClasses, {components}, boltComponents) => {
    const compTypesToClasses = compClasses || getComponentsMap(components)
    const overrides = {
        [components.ThemeRendererWithFonts.compType]: components.ThemeRendererWithFonts,
        ...getComponentsMap(boltComponents)
    }
    return {...compTypesToClasses, ...overrides}
}

function createInitialTranslations(translations) {
    return supportedLanguages.reduce((acc, languageCode) => {
        const languageTranslation = translations[languageCode]
        const emptyMap = {data: {document_data: {}}}
        return {...acc, [languageCode]: languageTranslation || emptyMap}
    }, {})
}

function isStorageSupported() {
    try {
        window.localStorage.setItem('', '')
        window.localStorage.removeItem('')
        return true
    } catch (exception) {
        return false
    }
}

// TODO group all these inputs into meaningful sub models
/*eslint fp/no-mutation:0*/
const init = ({logger, hostInstanceBatchingStrategy, boltInstanceBatchingStrategy, functionLibrary, renderingPlugins, ssrModel, rendererModel, publicModel, documentServicesModel, serviceTopology, requestModel, rawSeoTags, tpaSeoInfoServiceUrl, analyticsTrackers, rawUrl, wixBiSession, isPreview, reportBeatEvent, registerToIframesLoadEvent, isBot, isDebug, santaBase, boltBase, compClasses, bootstrapPackages, renderFlags}) => {
    const {fetch, requireFn, requireSync, reportActionStart, reportActionEnd, requireTPANativeCode, getQueryParamValue, removeApps} = functionLibrary
    const {isStreaming, isInSSR, boltInstanceFunctionLibrary = {}, exceptionHandlingApi} = ssrModel

    const currentLanguage = getLanguageCode(getQueryParamValue(rawUrl, 'lang'), rendererModel.sitePropertiesInfo)
    //I hate this
    const disableApp = getQueryParamValue(rawUrl, 'disableApp')
    const disableDataBinding = disableApp && disableApp.toLowerCase().includes('databinding')
    rendererModel.clientSpecMap = removeApps(rendererModel.clientSpecMap, disableApp)

    const mainRModel = {
        isPreview,
        additionalStructures: {},
        pagesToLoad: [],
        pagesJsonFileName: {},
        pageRawContent: {},
        packages: bootstrapPackages || {},
        loadedComponents: {},
        sitemapQueries: {},
        boltInstance: null,
        rendererModel,
        publicModel,
        documentServicesModel,
        serviceTopology,
        requestModel,
        navigationModel: {
            rawUrl,
            prevParsedUrl: null,
            navigationInfos: {},
            boltInstanceNavigateCallback: null,
            boltInstanceRetryNavigateCallback: null
        },
        pagesLoadingModel: {
            additionalPagesToLoad: {}
        },
        ssrModel: {
            warmupPackages: {},
            isStreaming,
            ssrSucceeded: false,
            doneWarmup: false,
            serverMarkup: '',
            isInSSR,
            warmupData: null
        },
        platformModel: {
            disableDataBinding
        },
        appInstanceMap: {},
        workerBuffers: {},
        animationManager: null,
        warmupAnimationsStarted: false,
        isBot,
        isDebug,
        santaBase,
        boltBase,
        workers: {},
        workersState: {},
        standbyWorker: null,
        storage: storageFactory.get(isStorageSupported()),
        wixBiSession
    }

    return new Promise(resolve => {
        const createHostInstanceReportId = reportActionStart('createHostInstance')

        const stageResolvers = {}
        // eslint-disable-next-line promise/param-names
        const doneStagePromise = new Promise((doneStageResolved, doneStageRejected) => {
            stageResolvers.doneStageResolved = doneStageResolved
            stageResolvers.doneStageRejected = doneStageRejected
        })

        const hostInstance = loading(
            mainRModel,
            {
                createBoltInstance: payload => {
                    const {
                        isExperimentOpen,
                        boltMain,
                        santaComponents,
                        boltComponents,
                        initialPages,
                        isMobileView,
                        mobileDeviceAnalyzer,
                        hostApi,
                        navigationInfos,
                        currentUrl,
                        viewerModel,
                        platformModel,
                        storage,
                        callback
                    } = payload

                    const pages = _.sortBy(initialPages, v => v.structure.masterPage ? 1 : 0) //see test BOLT-830
                    const initialPageData = _.reduce(pages, _.merge, {structure: {}, data: {}, translations: {}})

                    const translations = createInitialTranslations(initialPageData.translations)
                    const {structure, data} = initialPageData

                    const boltInitialModel = {
                        storage,
                        currentLanguage,
                        documentServicesModel,
                        navigationInfos,
                        currentUrl,
                        structure,
                        data,
                        loadedFonts: {},
                        translations,
                        serviceTopology,
                        rendererModel,
                        publicModel,
                        rawSeoTags,
                        tpaSeoInfoServiceUrl,
                        analyticsTrackers,
                        viewerModel,
                        compClasses: getCompClasses(compClasses, santaComponents, boltComponents),
                        activeModes: {},
                        runtime: {data: getEmptyMaps(), behaviors: [], state: {}}, //TODO use runtimeUtils
                        runtimeEvents: isExperimentOpen('bv_runtimeEventsStore') ? {data: {}} : null,
                        isMobileView,
                        mobileDeviceAnalyzer,
                        wixBiSession,
                        reportBeatEvent,
                        renderFlags: renderFlags || {},
                        ssrModel: {
                            isInSSR,
                            isClientAfterSSR: isStreaming,
                            isFirstRenderAfterSSR: false
                        },
                        rootCompIds: ['BOLT_SITE'],
                        getWindowObject: isInSSR ? noop : () => window,
                        requestModel,
                        santaBase,
                        platformModel
                    }

                    const createBoltInstanceReportId = reportActionStart('createBoltInstance')
                    console.log('creating bolt instance', performance.now())

                    const boltInstance = boltMain.default.createBoltInstance(
                        boltInitialModel,
                        boltInstanceBatchingStrategy,
                        renderingPlugins,
                        hostApi,
                        logger,
                        {
                            fetch,
                            requireFn,
                            requireSync,
                            reportActionStart,
                            reportActionEnd,
                            captureError: logger.captureError,
                            interactionStarted: logger.interactionStarted,
                            interactionEnded: logger.interactionEnded,
                            requireTPANativeCode,
                            ...boltInstanceFunctionLibrary,
                            ...rendererModel.seo ? {wrapper: initSeoWrapper(boltComponents)} : {}
                        },
                        exceptionHandlingApi
                    )

                    console.log('created bolt instance', performance.now())
                    reportActionEnd(createBoltInstanceReportId)

                    if (!isInSSR) {
                        window.boltInstance = boltInstance
                        if (navigationInfos.primaryPage.replaceHistory) {
                            updateHistory(navigationInfos, currentUrl.full, currentUrl.protocol)
                        }
                        if (window.location.hash.startsWith('#!')) {
                            logger.captureError(new Error('received hashbang url'), {extras: {href: window.location.href, referrer: document.referrer}})
                        }
                    }

                    callback(boltInstance)
                    resolve({boltInstance, boltMain: boltMain.default, doneStagePromise})
                },
                done: (boltMain, boltInstance, ssrSucceeded, serverMarkup, warmupUtils, workers) => {
                    if (isInSSR) {
                        Object.values(workers).forEach(worker => worker.terminate())
                    }

                    stageResolvers.doneStageResolved({
                        hostInstance,
                        boltInstance,
                        boltMain: boltMain.default,
                        serverMarkup,
                        hydrate: ssrSucceeded,
                        indicator: warmupUtils.indicator
                    })
                },
                onSsrRouteRedirect: redirectPayload => resolve({redirectPayload}),
                reportBeatEvent,
                registerToIframesLoadEvent,
                ...functionLibrary
            },
            hostInstanceBatchingStrategy
        )
        reportActionEnd(createHostInstanceReportId)

        if (!isInSSR) {
            window.hostInstance = hostInstance
            functionLibrary.requireFn('zepto', $ => {
                $(() => {
                    const {warmupData} = window
                    if (isStreaming && warmupData) {
                        const {
                            // userWarmup,
                            currentUrl,
                            rootNavigationInfo
                        } = window.warmupData
                        hostInstance.setParsedUrl(currentUrl)
                        if (rootNavigationInfo) {
                            hostInstance.setNavigationInfos(Object.assign({primaryPage: rootNavigationInfo}, hostInstance.navigationInfos))
                        }
                        // hostInstance.setUserWarmup(userWarmup)
                        hostInstance.setWarmupData(warmupData)
                    } else {
                        hostInstance.setWarmupData({runtime: 'EMPTY'})
                    }
                })
            })
        }
    })
}

module.exports = {
    init,
    createFunctionLibrary
}
