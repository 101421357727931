'use strict'
const name = 'Shrink'
const properties = {defaultDuration: 0.6}

function register({factory}/*, frame*/) {
    /**
     * Shrink (clip) transition
     * @param {Array<HTMLElement>|HTMLElement} sourceElements DOM element to animate
     * @param {Array<HTMLElement>|HTMLElement} destElements DOM element to animate
     * @param {Number} [duration]
     * @param {Number} [delay]
     * @param {Object} [params] Timeline optional parameters (Tween values cannot be changed here, use BaseFade).
     * @param {Object} [params.sourceEase='Sine.easeInOut'] the ease function of the animation
     * @returns {TimelineMax}
     */
    function transition(sourceElements, destElements, duration, delay, params) {
        const clipPath = 'polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%)'
        const stagger = params.stagger || 0
        const sourceEase = params.sourceEase || 'Sine.easeInOut'
        delete params.sourceEase
        delete params.stagger
        delete params.width
        delete params.height

        const sequence = factory.sequence(params)

        sequence.add(factory.animate('BaseFade', destElements, 0, delay, {to: {opacity: 1}, clearProps: 'clipPath, scale'}))

        /**
         * Dec 30 2018: Fallback for IE / Edge which does not support clipPath. One day we could delete this
         */
        if (typeof sourceElements[0].style.clipPath === 'undefined') {
            sequence.add(factory.animate('BaseScale', sourceElements, duration, delay, {to: {scale: 0}, ease: sourceEase, stagger}))
        } else {
            sequence.add(factory.animate('BaseClipPath', sourceElements, duration, delay, {to: {clipPath}, ease: sourceEase, stagger}))
        }
        return sequence.get()
    }

    factory.registerTransition(name, transition, properties)
}

module.exports = {
    name,
    properties,
    register
}

