const name = 'PagesRequestsAspect'

const defaultModel = {
    pagesRequests: {}
}

const functionLibrary = {
    loadPage: (loadPageFn, setPageRequestStatus, pageId) => {
        const errCallback = () => {
            setPageRequestStatus(pageId, {
                error: true
            })
        }
        loadPageFn(pageId, {errCallback})
    }
}

export {name, functionLibrary, defaultModel}
