import {keyvalue, map} from './property'
import {stringify} from './stringify'
import * as CSSToken from './cssToken'

const cssBoxShadow = keyvalue('boxShadow',
    stringify.list(
        stringify.value({
            inset: CSSToken.KEYWORD,
            offsetX: CSSToken.LENGTH_OR_PERCENTAGE,
            offsetY: CSSToken.LENGTH_OR_PERCENTAGE,
            blurRadius: CSSToken.LENGTH_OR_PERCENTAGE,
            spreadRadius: CSSToken.LENGTH_OR_PERCENTAGE,
            color: CSSToken.COLOR_RGBA
        })))

const cssBorderRadius = keyvalue('borderRadius',
    stringify.value({
        topLeft: CSSToken.LENGTH_OR_PERCENTAGE,
        topRight: CSSToken.LENGTH_OR_PERCENTAGE,
        bottomRight: CSSToken.LENGTH_OR_PERCENTAGE,
        bottomLeft: CSSToken.LENGTH_OR_PERCENTAGE
    }, ['topLeft', 'topRight', 'bottomRight', 'bottomLeft'])
)

const cssBorder = map({
    borderWidth: stringify.value({
        width: CSSToken.BORDER_WIDTH
    }),

    borderStyle: stringify.value({
        style: CSSToken.BORDER_STYLE
    }),

    borderColor: stringify.value({
        color: CSSToken.BORDER_COLOR
    })
})


export {cssBoxShadow, cssBorderRadius, cssBorder}
