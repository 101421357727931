'use strict'
const _ = require('lodash')
const balataConsts = require('./balataConsts')

const name = 'BackgroundReveal'
const properties = {
    hideOnStart: false,
    shouldDisableSmoothScrolling: true,
    getMaxTravel(elementMeasure, viewportHeight) {
        return viewportHeight + elementMeasure.height
    }
}

const viewPortHeightDefault = 1
const componentHeightDefault = 1

function register({factory}/*, frame*/) {
    /**
     * BackgroundReveal animation.
     * @param {Array<HTMLElement>|HTMLElement} elements DOM elements to animate
     * @param {Number} [duration]
     * @param {Number} [delay]
     * @param {Object} [params] Timeline optional parameters (Tween values cannot be changed here, use BaseFade).
     * @returns {TimelineMax}
     */
    function animation(elements, duration, delay, params) {
        params = params || {}
        const viewPortHeight = params.viewPortHeight || viewPortHeightDefault
        const componentHeight = params.componentHeight || componentHeightDefault

        const sequence = factory.sequence(params)
        let childrenToAnimate

        if (_.get(params, ['browserFlags', 'animateRevealScrubAction'])) {
            //fixed layers on IE and Edge jitter while scrolling , we're animating the layers for steady reveal.
            _.forEach(elements, element => {
                childrenToAnimate = _.map(balataConsts.REVEAL_SELECTORS, selector => element.querySelector(selector))
                sequence.add([
                    factory.animate('BasePosition', element, duration, delay, {
                        from: {y: viewPortHeight},
                        to: {y: -componentHeight},
                        force3D: true,
                        immediateRender: true
                    }),
                    factory.animate('BasePosition', childrenToAnimate, duration, delay, {
                        from: {y: -viewPortHeight},
                        to: {y: componentHeight},
                        force3D: true,
                        immediateRender: true
                    })])
            })
        } else {
            // no animation , just force 3d layering
            _.forEach(elements, element => {
                childrenToAnimate = _.map(balataConsts.REVEAL_SELECTORS, selector => element.querySelector(selector))
                sequence.add(
                    factory.animate('BaseNone', elements, 0, 0, {
                        transformStyle: 'preserve-3d',
                        force3D: true
                    }),
                    factory.animate('BaseNone', childrenToAnimate, 0, 0, {
                        transformStyle: 'preserve-3d',
                        force3D: true
                    })
                )
            })
        }

        return sequence.get()
    }

    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}
