define([
    'lodash',
    'warmupUtils',
    'warmupUtilsLib',
    'layout/util/anchors'],
function (_, warmupUtils, warmupUtilsLib, anchors) {
    'use strict';

    function createFlatStructureMap(structure, measureMap, isMobile) {
        const flatStructureMap = {};

        function addCompToFlatDataMap(component) {
            if (!_.has(measureMap.height, component.id)) {
                return;
            }
            flatStructureMap[component.id] = component;

            _.forEach(warmupUtilsLib.dataUtils.getChildrenData(component, isMobile), addCompToFlatDataMap);
        }

        addCompToFlatDataMap(structure);

        return flatStructureMap;
    }

    function enforceStructure(structure, measureMap, anchorsMap, originalValuesMap, isMobileView, skipEnforceAnchors, lockedCompsMap, renderedCompsMap, ignoreBottomBottom) {
        const viewMode = isMobileView ? warmupUtilsLib.siteConstants.VIEW_MODES.MOBILE : warmupUtilsLib.siteConstants.VIEW_MODES.DESKTOP;
        const rootAnchorsMap = _.get(anchorsMap, [structure.id, viewMode]);

        if (rootAnchorsMap) {
            return anchors.enforceAnchors(structure, measureMap, rootAnchorsMap, isMobileView, skipEnforceAnchors, lockedCompsMap, renderedCompsMap, ignoreBottomBottom);
        }

        return createFlatStructureMap(structure, measureMap, isMobileView);
    }

    function isRendered(measureMap, compId) {
        return _.has(measureMap.height, compId);
    }

    function getMostBottomMeasuredY(structure, measureMap, isMobileView) {
        let mostBottom = 0;

        function updateMostBottomRec(component, parentAbsTop) {
            parentAbsTop = parentAbsTop || 0;
            if (!isRendered(measureMap, component.id)) {
                return;
            }
            const compAbsBottom = parentAbsTop + measureMap.top[component.id] + measureMap.height[component.id];

            if (compAbsBottom > mostBottom) {
                mostBottom = compAbsBottom;
            }

            const children = warmupUtilsLib.dataUtils.getChildrenData(component, isMobileView);

            _.forEach(children, function (child) {
                updateMostBottomRec(child, parentAbsTop + measureMap.top[component.id]);
            });
        }

        updateMostBottomRec(structure);
        return mostBottom || structure.layout.y + structure.layout.height;
    }



    function isLandingPage(measureMap) {
        return !isRendered(measureMap, 'SITE_HEADER');
    }

    function getMeasuredBottom(measureMap, compId) {
        return measureMap.top[compId] + measureMap.height[compId];
    }

    const MASTER_PAGE_SECTIONS_IDS = {
        'SITE_FOOTER': true,
        'SITE_HEADER': true,
        'PAGES_CONTAINER': true
    };

    function getFloatingComponents(masterPageStructure, isMobileView) {
        const children = warmupUtilsLib.dataUtils.getChildrenData(masterPageStructure, isMobileView);

        return _.reject(children, function (child) {
            return MASTER_PAGE_SECTIONS_IDS[child.id] || _.get(child, ['layout', 'fixedPosition']);
        });
    }

    function getBottomMostBottom(measureMap, compsArr) {
        return _(compsArr).map(function (child) {
            return isRendered(measureMap, child.id) ? getMeasuredBottom(measureMap, child.id) : 0;
        }).max();
    }

    function fixMasterPageHeightForSectionLayout(measureMap, masterPageStructure, isMobileView) {
        const children = warmupUtilsLib.dataUtils.getChildrenData(masterPageStructure, isMobileView);
        const siteFooter = _.find(children, {id: 'SITE_FOOTER'});

        measureMap.height.masterPage = getMostBottomMeasuredY(siteFooter, measureMap, isMobileView);
    }

    function getPagesContainerMinHeight(measureMap, floatingChildren, pagesToFooterGap) {
        const bottomOfFloaters = getBottomMostBottom(measureMap, floatingChildren);

        return bottomOfFloaters - measureMap.top.PAGES_CONTAINER - pagesToFooterGap;
    }

    function enforceMasterPageSectionsLayout(measureMap, masterPageStructure, pageStructureDesc, layoutSettings, isMobileView) {
        const floatingChildren = getFloatingComponents(masterPageStructure, isMobileView);

        if (!_.isEmpty(floatingChildren)) {
            const pagesToFooterGap = _.get(layoutSettings, ['pagesToFooterGap'], 0);
            const pagesContainerMinHeight = getPagesContainerMinHeight(measureMap, floatingChildren, pagesToFooterGap);

            if (pagesContainerMinHeight > measureMap.height.PAGES_CONTAINER) {
                measureMap.height.PAGES_CONTAINER = pagesContainerMinHeight;
                measureMap.height.SITE_PAGES = pagesContainerMinHeight;

                if (pageStructureDesc) {
                    measureMap.height[pageStructureDesc.pageId] = pagesContainerMinHeight;
                }
                measureMap.top.SITE_FOOTER = measureMap.top.PAGES_CONTAINER + pagesContainerMinHeight + pagesToFooterGap;
            }
        }

        fixMasterPageHeightForSectionLayout(measureMap, masterPageStructure, isMobileView);
    }

    function isRootIgnoreBottomBottom(rootId, siteData) {
        const pointers = warmupUtils.DALFactory.getPointersInstance(siteData);
        const displayedDal = warmupUtils.DALFactory.getInstance(siteData);
        const pageDataItem = rootId && displayedDal.get(pointers.data.getDataItem(rootId, siteData.MASTER_PAGE_ID));
        return _.get(pageDataItem, 'ignoreBottomBottomAnchors');
    }

    function enforceAllStructures(structuresDesc, measureMap, siteData, skipEnforceAnchors, lockedCompsMap, renderedCompsMap) {
        const flatDataMaps = {};
        let ignoreBottomBottom, rootId;
        siteData.originalValuesMap = siteData.originalValuesMap || {};
        const isMobileView = siteData.isMobileView();

        if (structuresDesc.inner) {
            rootId = _.get(structuresDesc, 'inner.structure.id');
            ignoreBottomBottom = isRootIgnoreBottomBottom(rootId, siteData);
            flatDataMaps.inner = enforceStructure(structuresDesc.inner.structure, measureMap, siteData.anchorsMap, siteData.originalValuesMap, isMobileView, skipEnforceAnchors, lockedCompsMap, renderedCompsMap, ignoreBottomBottom);
            if (measureMap.height.SITE_PAGES) {
                measureMap.height.SITE_PAGES = measureMap.height[structuresDesc.inner.pageId];
            }
        }
        _.forOwn(structuresDesc, function (structureDesc, name) {
            if (name !== 'inner') {
                rootId = _.get(structureDesc, ['structure', 'id']);
                ignoreBottomBottom = isRootIgnoreBottomBottom(rootId, siteData);
                flatDataMaps[name] = enforceStructure(structureDesc.structure, measureMap, siteData.anchorsMap, siteData.originalValuesMap, isMobileView, skipEnforceAnchors, lockedCompsMap, renderedCompsMap, ignoreBottomBottom);
            }
            if (name === 'outer' && !isMobileView && !isLandingPage(measureMap)) {
                const layoutSettings = siteData.getMasterPageLayoutSettings();
                if (layoutSettings.useDesktopSectionsLayout) {
                    enforceMasterPageSectionsLayout(measureMap, structureDesc.structure, structuresDesc.inner, layoutSettings, isMobileView);
                }
            }
        });


        return flatDataMaps;
    }


    return {
        enforceStructure,
        enforceAllStructures
    };
});
