define([
    'prop-types',
    'lodash',
    'skins',
    'santa-components',
    'componentsCore/mixins/createChildComponentMixin'
],
function (PropTypes, _, skinsPackage, santaComponents, createChildComponentMixin) {
    'use strict';

    /**
         * @class componentsCore.mixins.mediaZoomWrapperMixin
         * @extends {ReactCompositeComponent}
         * @property {comp.properties} props
         * @property {function(): object} actualNavigateToItem
         * @property {function(): object} getBoxDimensions
         * @property {function(): object} getChildCompInfo
         * @property {function(): object} getPrevAndNextState
         */
    return {
        mixins: [santaComponents.mixins.skinBasedComp(skinsPackage.skinsMap), createChildComponentMixin],

        propTypes: {
            closeFunction: PropTypes.func,
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            windowKeyboardEvent: santaComponents.santaTypesDefinitions.SiteAspects.windowKeyboardEvent.isRequired,
            windowTouchEvents: santaComponents.santaTypesDefinitions.SiteAspects.windowTouchEvents.isRequired
        },

        componentWillMount() {
            // register to 'ESC', 'Right Arrow', 'Left Arrow' key events
            const windowKeyboardEvent = this.props.windowKeyboardEvent;
            windowKeyboardEvent.registerToEscapeKey(this);

            this.props.windowTouchEvents.registerToWindowTouchEvent('touchMove', this);

            const prevAndNextState = this.getPrevAndNextState();
            if (prevAndNextState.next) {
                windowKeyboardEvent.registerToArrowRightKey(this);
            }
            if (prevAndNextState.prev) {
                windowKeyboardEvent.registerToArrowLeftKey(this);
            }
        },

        getSkinProperties() {
            const componentType = this.getChildZoomComponentType();
            const isMobile = componentType === 'wysiwyg.viewer.components.MobileMediaZoom';
            const extraProps = this.getChildZoomExtraProps();

            return {
                '': {
                    children: this.createChildComponent(this.props.compData, componentType, 'mediaZoom', _.assign({
                        ref: this.props.compData.id,
                        actualNavigateToItemFunc: this.actualNavigateToItem,
                        getBoxDimensionsFunc: this.getBoxDimensions,
                        getChildCompFunc: this.getChildComp,
                        getPrevAndNextStateFunc: this.getPrevAndNextState,
                        isDataChangedFunc: this.isDataChanged,
                        closeFunction: this.props.closeFunction,
                        enableInnerScrolling: this.enableInnerScrolling || false
                    }, extraProps || {})),
                    style: {
                        width: '100%',
                        height: '100%',
                        position: isMobile ? 'static' : 'absolute'
                    }
                }
            };
        },

        // Sorry, I couldn't rename the onEscapeKey method.
        closeMediaZoom() {
            this.refs[this.props.compData.id].closeMediaZoom();
        },

        /**
             * Handle 'ESC' key event
             */
        onEscapeKey() {
            this.closeMediaZoom();
        },

        /**
             * Handle right arrow key event
             */
        onArrowLeftKey() {
            this.refs[this.props.compData.id].clickOnPreviousButton();
        },

        /**
             * Handle left arrow key event
             */
        onArrowRightKey() {
            this.refs[this.props.compData.id].clickOnNextButton();
        },

        /**
             * Prevent page scroll
             * */
        onWindowTouchMove(event) {
            if (!this.enableInnerScrolling) {
                event.preventDefault();
                event.stopPropagation();
            }
        }
    };
});
