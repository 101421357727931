import * as _ from 'lodash'
export const name = 'ExecuteAnimationAspect'

const CLEAR_PROPS = 'clip,clipPath,webkitClipPath,opacity,transform'

const getAnimationParams = (animator, behavior) => {
    const animationDefaultParams = _.get(_.find(animator.viewerDefaults, {name: behavior.name}), 'params')
    return _.defaults({}, behavior.params, animationDefaultParams)
}

export const functionLibrary = {
    executeAnimationBehavior: ({behavior, shouldAnimate, element, animator}) => {
        if (!(animator && element && shouldAnimate)) {
            _.invoke(behavior, 'callback')
            return
        }
        const {name: animationName} = behavior
        const params = getAnimationParams(animator, behavior)
        const {duration, delay, ...additionalParams} = params
        const callbacks = {onComplete: () => _.invoke(behavior, 'callback')}

        const sequence = animator.sequence({callbacks})
        sequence
            .add(animator.animate(animationName, element, duration, delay, additionalParams), 0)
            .add(animator.animate('BaseClear', element, 0, 0, {props: CLEAR_PROPS, immediateRender: false}))
    }
}
