'use strict'

const _ = require('lodash')
const {pointers} = require('../../utils/constants')

const {DATA_QUERY, PROPERTY_QUERY} = pointers.components.PROPERTY_TYPES

const {BEHAVIORS, DATA, DESIGN, PROPERTIES, STYLE} = pointers.data.DATA_MAPS

const create = ({
    data,
    structure,
    runtime,

    // actions
    updateRuntimeCompState,
    spliceCompRuntimeBehavior,
    pushRuntimeEvent
}) => {
    const getData = (compId, queryName, mapName) => {
        if (!structure[compId]) {
            return undefined
        }

        const query = _.replace(structure[compId][queryName], '#', '')
        return data[mapName][query]
    }

    const addActionsAndBehaviors = (compId, actionBehavior) => pushRuntimeEvent(BEHAVIORS, compId, actionBehavior)

    const registerComponentEvent = (compId, newEventData) => {
        const action = {
            type: 'comp',
            name: newEventData.eventType,
            sourceId: compId
        }
        const behavior = {
            name: 'runCode',
            type: 'widget',
            targetId: newEventData.contextId,
            params: {
                callbackId: newEventData.callbackId,
                compId
            }
        }

        addActionsAndBehaviors(compId, {action, behavior})
    }

    return {
        getCompData: compId => getData(compId, DATA_QUERY, DATA),
        getCompProps: compId => getData(compId, PROPERTY_QUERY, PROPERTIES),
        setCompData: (compId, partialOverrides) => pushRuntimeEvent(DATA, compId, partialOverrides),
        setCompDesign: (compId, partialOverrides) => pushRuntimeEvent(DESIGN, compId, partialOverrides),
        setCompProps: (compId, partialOverrides) => pushRuntimeEvent(PROPERTIES, compId, partialOverrides),
        updateCompStyle: (compId, partialOverrides) => pushRuntimeEvent(STYLE, compId, partialOverrides),
        updateCompState: (compId, currentOverrides, partialOverrides) => {
            if (partialOverrides && _.isEqual(currentOverrides, partialOverrides)) {
                return
            }
            const overrides = partialOverrides ? _.assign({}, currentOverrides, partialOverrides) : undefined
            updateRuntimeCompState(compId, overrides)
        },
        addActionsAndBehaviors,
        removeActionsAndBehaviors: (compId, actionName) => {
            const currentCompRuntimeBehaviors = _.get(runtime, ['data', BEHAVIORS, compId])
            if (!currentCompRuntimeBehaviors) {
                return
            }

            const actionBehaviorIdx = currentCompRuntimeBehaviors.findIndex(
                actionBehavior => actionBehavior.action.name === actionName
            )

            if (actionBehaviorIdx > -1) {
                spliceCompRuntimeBehavior(actionBehaviorIdx, 1)
            }
        },
        registerComponentEvent,
        updateCompLayout: _.noop,
        getPopupContext: _.noop // not needed?
    }
}

module.exports = {
    create
}
