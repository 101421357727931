define(['lodash', 'tpaComponents/common/tpaMetaData'], function (_, metaData) {
    'use strict';

    const FIXED_PREFIX = 'fixed';

    function getStylesFromMeasureMap(id, measureMap, styles) {
        const stylesFromMeasureMap = {};

        _.forEach(styles, function (style) {
            const cssStyleValue = measureMap[style] && measureMap[style][id];
            let cssStyleName = style;

            if (_.isUndefined(cssStyleValue)) {
                return;
            }

            if (style.indexOf(FIXED_PREFIX) === 0) {
                cssStyleName = cssStyleName.slice(FIXED_PREFIX.length).toLowerCase();
            }

            stylesFromMeasureMap[cssStyleName] = cssStyleValue;
        });

        return stylesFromMeasureMap;
    }

    function getBoundingRect(css, windowWidth, windowHeight) {
        return {
            top: _.isNumber(css.top) ? _.parseInt(css.top) : windowHeight - css.height,
            bottom: _.isNumber(css.bottom) ? windowHeight - _.parseInt(css.bottom) : _.parseInt(css.top) + css.height,
            right: _.isNumber(css.right) ? windowWidth - _.parseInt(css.right) : _.parseInt(css.left) + css.width,
            left: _.isNumber(css.left) ? _.parseInt(css.left) : windowWidth - css.width
        };
    }

    function isOverlapping(rect1, rect2) {
        // taken from: http://stackoverflow.com/a/12067046
        return !(rect1.right < rect2.left ||
            rect1.left > rect2.right ||
            rect1.bottom < rect2.top ||
            rect1.top > rect2.bottom);
    }

    function adjustForAd(result, widgetRect, adRect) {
        if (isOverlapping(adRect, widgetRect) && _.isNumber(result.top)) {
            result.top = (result.top || 0) + (adRect.bottom - widgetRect.top);
        }

        return result;
    }

    function adjustTopWixAd(css, measureMap) {
        let adjustedStyle = css;
        const windowHeight = measureMap.height.screen;
        const windowWidth = measureMap.width.screen;
        const adRect = measureMap.custom.WIX_ADStop;

        if (adRect) {
            const widgetRect = getBoundingRect(adjustedStyle, windowWidth, windowHeight);
            adjustedStyle = adjustForAd(adjustedStyle, widgetRect, adRect);
        }

        return adjustedStyle;
    }

    function patchGluedWidget(id, patchers, measureMap, compStructure, siteData) {
        const isMobile = siteData.isMobileView();
        if (!isMobile) {
            const gluedWidgetStyles = [
                'position',
                'fixedTop',
                'fixedLeft',
                'right',
                'bottom',
                'width',
                'height'
            ];
            let css = getStylesFromMeasureMap(id, measureMap, gluedWidgetStyles);
            css = adjustTopWixAd(css, measureMap);
            css = _.omit(css, ['width', 'height']); // Were only used for adjusting to ad

            patchers.css(id, css);
        } else if (isPermittedFullScreenTPAInMobile(compStructure, siteData.getClientSpecMap())) {
            patchers.css(id, {
                position: 'fixed',
                margin: '0',
                top: '0',
                left: 'auto',
                width: measureMap.width.screen,
                height: '100%',
                zIndex: 2000
            });
        }
    }

    function isPermittedFullScreenTPAInMobile(compStructure, clientSpecMap) {
        const compData = compStructure.dataItem;
        const appData = clientSpecMap[compData.applicationId];
        const permittedFullScreenTPAsInMobile = metaData.PERMITTED_FULL_SCREEN_TPAS_IN_MOBILE;
        return appData && _.includes(permittedFullScreenTPAsInMobile, appData.appDefinitionId);
    }

    return patchGluedWidget;
});
