'use strict'

const imageClientApi = require('image-client-api')
const biEvents = require('./bi/events')

const references = {
    IMAGE_REF: 'image'
}

const get = (obj, props, defVal) => {
    const path = Array.isArray(props) ? props : props.split('.')
    const val = path.reduce((subObj, prop) => subObj && subObj[prop] !== undefined ? subObj[prop] : null, obj)
    return val !== null ? val : defVal
}

const pick = (obj, props) => {
    const propsArr = Array.isArray(props) ? props : [props]
    return propsArr.reduce((subObj, prop) => {
        const val = get(obj, prop)
        return val !== undefined ? Object.assign(subObj, {[prop]: val}) : subObj
    }, {})
}

const setAttributes = (node, attributes) => node && Object.keys(attributes).forEach(attr => node.setAttribute(attr, attributes[attr]))

const setStyle = (node, styleProperties) => node && Object.assign(node.style, styleProperties)

function cssStringToObject(styleStr) {
    if (!styleStr || !styleStr.split) {
        return {}
    }

    return styleStr.split(';').reduce(function (ruleMap, ruleString) {
        const rulePair = ruleString.split(':')
        if (rulePair[0] && rulePair[1]) {
            ruleMap[rulePair[0].trim()] = rulePair[1].trim()
        }

        return ruleMap
    }, {})
}

function getImageComputedProperties(imageInfo, envConsts, htmlTag) {
    //todo: CLNT-5323 , wixapp sildergallery proxy is generating image data without uri
    if (!imageInfo.containerWidth || !imageInfo.containerHeight || !imageInfo.imageData.uri) {
        return {uri: '', css: {}}
    }
    const imageData = imageInfo.imageData
    const fittingType = imageInfo.displayMode || imageClientApi.fittingTypes.SCALE_TO_FILL
    const imageOptions = Object.assign(
        pick(imageData, 'upscaleMethod'),
        pick(imageInfo, 'filters'),
        imageInfo.quality || imageData.quality
    )

    const devicePixelRatioFromData = get(imageInfo.imageData, 'devicePixelRatio', envConsts.devicePixelRatio)
    const devicePixelRatio = getDevicePixelRatio(devicePixelRatioFromData)

    const src = Object.assign(pick(imageData, ['width', 'height', 'crop', 'name', 'focalPoint']), {id: imageData.uri})
    const target = {
        width: imageInfo.containerWidth,
        height: imageInfo.containerHeight,
        htmlTag: htmlTag || 'img',
        pixelAspectRatio: devicePixelRatio,
        alignment: imageInfo.alignType || imageClientApi.alignTypes.CENTER
    }

    const imageComputedProperties = imageClientApi.getData(fittingType, src, target, imageOptions)
    imageComputedProperties.uri = getMediaUrlByContext(imageComputedProperties.uri, envConsts.staticMediaUrl, envConsts.mediaRootUrl)
    if (imageData.itemProp || imageInfo.addItemProp) {
        imageComputedProperties.itemProp = imageData.itemProp || 'image'
    }

    if (imageInfo.hasOwnProperty('labelledById')) {
        imageComputedProperties['aria-labelledby'] = imageInfo.labelledById
    }

    if (imageInfo.hasOwnProperty('describedById')) {
        imageComputedProperties['aria-describedby'] = imageInfo.describedById
    }

    return imageComputedProperties
}

function getDevicePixelRatio(devicePixelRatio) {
    //we should be able to force devicePixelRatio from url by using the query param -
    const queryParams = window.location.search.split('&').map(query => query.split('='))
    const devicePixelRatioQueryParam = queryParams.find(query => query[0].toLowerCase().includes('devicepixelratio'))
    const devicePixelRatioValueForceFromUrl = devicePixelRatioQueryParam ? Number(devicePixelRatioQueryParam[1]) : null
    return devicePixelRatioValueForceFromUrl || devicePixelRatio || 1
}

function getMediaUrlByContext(imageUri, staticMediaUrl, mediaRootUrl) {
    const isExternalUrl = (/(^https?)|(^data)|(^blob)|(^\/\/)/).test(imageUri)
    if (isExternalUrl) {
        return imageUri
    }
    let path = `${staticMediaUrl}/`
    if (imageUri) {
        if (/^micons\//.test(imageUri)) {
            path = mediaRootUrl
        } else if (/[^.]+$/.exec(imageUri)[0] === 'ico') {
            //if the image is an icon then it's taken from a slightly different place
            path = path.replace('media', 'ficons')
        }
    }
    return path + imageUri
}

function getDefaultStyles(style) {
    const {width, height, ...styleWithoutDimensions} = style // eslint-disable-line no-unused-vars
    const stylesWithValue = {}
    for (style in styleWithoutDimensions) {
        if (styleWithoutDimensions[style] !== '') {
            stylesWithValue[style] = styleWithoutDimensions[style]
        }
    }
    return stylesWithValue
}

function getContainerStyle(style, opacity) {
    const styleWithoutDefaults = getDefaultStyles(style)
    if (typeof opacity === 'number') {
        styleWithoutDefaults.opacity = opacity
    }
    return styleWithoutDefaults
}

function sendBiEvent(biService, envConsts, imageInfo, imageToLoad, imageComputedProperties) {
    // should send Bi Event if we are on viewer, url includes upscale value lg_1
    const shouldSendBiEvent = envConsts.isViewerMode &&
        imageComputedProperties.uri !== imageToLoad.currentSrc &&
        imageComputedProperties.uri.includes('lg_1')
    if (shouldSendBiEvent) {
        biService.reportBI(biEvents.IMAGE_UPSCALING, {
            originalWidth: imageInfo.imageData.width,
            originalHeight: imageInfo.imageData.height,
            targetWidth: Math.round(imageInfo.containerWidth),
            targetHeight: Math.round(imageInfo.containerHeight),
            devicePixelRatio: Math.floor(envConsts.devicePixelRatio * 100),
            url: imageToLoad.src
        })
    }
}

function measure(id, measures, domNodes, containerId) {
    let isSvgImage = false

    const imageNode = getImageNode(domNodes[id])
    if (!imageNode) {
        return
    }

    domNodes[id + references.IMAGE_REF] = imageNode

    // <image> is either for SVG filters IE/Edge fallback, or for SVG masks
    if (imageNode.nodeName.toLowerCase() === 'image') {
        isSvgImage = true
    }

    const imgSrc = imageNode.getAttribute(isSvgImage ? 'xlink:href' : 'src')
    const hasBgScrollEffect = domNodes[id].getAttribute('data-has-bg-scroll-effect') === 'true'

    measures.width = hasBgScrollEffect ? domNodes[containerId].offsetWidth : domNodes[id].offsetWidth
    measures.height = domNodes[id].offsetHeight
    measures.containerTop = domNodes[containerId].offsetTop
    measures.isZoomed = domNodes[id].getAttribute('data-image-zoomed')
    measures.isSvgImage = isSvgImage
    measures.imgSrc = imgSrc
    measures.containerId = containerId
    measures.renderedStyles = domNodes[id].getAttribute('data-style')
}

function patch(id, measures, domNodes, imageData, alignType, services, envConsts) {
    const style = cssStringToObject(measures.renderedStyles)

    if (imageData.name && !envConsts.experiments.sv_image_name_url) {
        imageData = Object.assign({}, imageData)
        delete imageData.name
    }

    const imageInfo = {
        imageData,
        containerWidth: measures.isZoomed ? imageData.width : measures.width,
        containerHeight: measures.isZoomed ? imageData.height : measures.height,
        displayMode: imageData.displayMode,
        alignType
    }

    let imageComputedProperties
    if (measures.isSvgImage) {
        imageComputedProperties = getImageComputedProperties(imageInfo, envConsts, 'svg')
    } else {
        imageComputedProperties = getImageComputedProperties(imageInfo, envConsts, 'img')
        setAttributes(domNodes[id + references.IMAGE_REF], get(imageComputedProperties, ['css', 'img']))
    }

    const containerStyle = getContainerStyle(style, imageData.opacity)
    setStyle(domNodes[id], containerStyle)

    const imageToLoad = {
        id,
        isSvg: measures.isSvgImage,
        src: get(imageComputedProperties, 'uri'), //this was always like this (using _.get) and it looks like videoThumb images for wixapps explodes here since they dont have a uri -> no imageTransformProps
        absoluteTop: measures.containerTop || 0, // ||parentId is a non structure component (wixapps) -> absoluteTop is 0 for now
        height: measures.height,
        currentSrc: measures.imgSrc
    }

    setAttributes(domNodes[id], {'data-src': imageToLoad.src, 'data-is-svg': imageToLoad.isSvg})

    sendBiEvent(services.biService, envConsts, imageInfo, imageToLoad, imageComputedProperties)
    services.imageLoader.loadImage(domNodes[id], imageToLoad)
}

const getImageNode = rootNode => rootNode.querySelector('img') || rootNode.querySelector('image')

module.exports = {
    measure,
    patch
}