import {Argument} from 'carmi'
export enum Orientation {
    Portrait = 'portrait',
    Landscape = 'landscape'
}

interface BoltWindow extends Window {
    requestCloseWelcomeScreen?: () => void
}

export interface WindowObjectAspectPublicAPI {
    getWindowObject(): BoltWindow
    setLocationHref(): () => void
    open(): () => void
    getParentWindow(): Window
    postMessage(window: Argument<Window>, message: Argument<string>, origin?: string)
    reload(): () => void,
    // getCurrentPosition(): () => Promise<Position>,
    getOrientation(): Orientation
}
