define([
    'warmupUtils',
    'layout/util/layout',
    'layout/specificComponents/screenWidthLayouts/meshFixedHeaderAndFooter'
], function (
    warmupUtils,
    /** layout.layout */ layout,
    meshFixedHeaderAndFooter
) {
    'use strict';

    function customLayoutFooter(id, nodesMap, measureMap) {
        const footerNode = nodesMap[id];
        const isFixed = footerNode.getAttribute('data-fixedposition') === 'true';
        const siteWidth = parseInt(footerNode.dataset.siteWidth, 10);
        const siteMarginBottom = warmupUtils.measurerUtils.getSiteMarginBottom();

        measureMap.left[id] = 0;
        measureMap.width[id] = siteWidth;
        const fixMeshPlaceholder = meshFixedHeaderAndFooter(id);

        return patchers => {
            fixMeshPlaceholder();
            if (isFixed) {
                patchers.css(id, {
                    left: 0,
                    top: 'auto',
                    bottom: siteMarginBottom
                });
            }
        };
    }

    const COMP_TYPE = 'wysiwyg.viewer.components.FooterContainer';

    layout.registerRequestToMeasureDom(COMP_TYPE);
    layout.registerRequestToMeasureChildren(COMP_TYPE, [['screenWidthBackground'], ['inlineContent']]);
    layout.registerCustomLayoutFunction(COMP_TYPE, customLayoutFooter);
});
