define(['lodash',
    'warmupUtilsLib',
    'warmupUtils/wixUrlParser/wixUrlParser'],
function (_,
          warmupUtilsLib,
          wixUrlParser) {
    'use strict';

    function getLinkObject(link, siteData, renderLinks, navInfo, resolvedSiteData, linkRenderer) {
        if (!link) {
            return link;
        }
        if (_.isString(link)) {
            return {};
        }
        const linkObject = warmupUtilsLib.objectUtils.cloneDeep(link);
        if (renderLinks && linkRenderer) {
            linkObject.render = linkRenderer.renderLink(linkObject, resolvedSiteData || siteData, navInfo);
        }
        return linkObject;
    }

    function getPageLinkObject(refId, siteData, renderLinks, navInfo, resolvedSiteData, linkRenderer) {
        const linkObject = {
            type: 'PageLink',
            pageId: refId
        };
        if (renderLinks && linkRenderer) {
            linkObject.render = linkRenderer.renderLink(linkObject, resolvedSiteData || siteData, navInfo);
        }

        return linkObject;
    }

    function isSelected(dataItem, navInfo) { // eslint-disable-line complexity
        if (dataItem.link && dataItem.link.type === 'PageLink') {
            const pageId = _.get(dataItem, 'link.pageId.id');
            return pageId && pageId === navInfo.pageId || _.some(dataItem.items, function (item) { // eslint-disable-line no-mixed-operators
                return isSelected(item, navInfo);
            });
        }
        if (dataItem.link && dataItem.link.type === 'DynamicPageLink' && dataItem.link.innerRoute && navInfo.innerRoute) {
            return _.includes(navInfo.innerRoute.split('/'), dataItem.link.innerRoute.split('/')[1]) ||
                dataItem.link.innerRoute === navInfo.innerRoute;
        }
        return false;
    }

    function getMenuItems(dataItems, siteData, renderLinks, navInfo, includeHiddenItems, resolvedSiteData, linkRenderer) {
        resolvedSiteData = resolvedSiteData || renderLinks && wixUrlParser.utils.getResolvedSiteData(siteData); // eslint-disable-line no-mixed-operators
        const isMobileView = _.isFunction(siteData.isMobileView) ? siteData.isMobileView() : false;
        const rootNavigationInfo = _.isFunction(siteData.getRootNavigationInfo) ? siteData.getRootNavigationInfo() : navInfo;

        return _.reduce(dataItems, function (items, dataItem) {
            const isVisible = isMobileView ? dataItem.isVisibleMobile : dataItem.isVisible;

            if (includeHiddenItems || isVisible) {
                const newItem = _.assign(_.clone(dataItem), {
                    items: getMenuItems(dataItem.items, siteData, renderLinks, navInfo, includeHiddenItems, resolvedSiteData, linkRenderer),
                    link: getLinkObject(dataItem.link, siteData, renderLinks, navInfo, resolvedSiteData, linkRenderer),
                    isSelected: isSelected(dataItem, rootNavigationInfo)
                });
                items.push(newItem);
            }
            return items;
        }, []);
    }

    function convertOldMenuToNewMenu(rawData, siteData, renderLinks, navInfo, resolvedSiteData, includeHiddenItems, linkRenderer) {
        let pageData;
        resolvedSiteData = resolvedSiteData || renderLinks && wixUrlParser.utils.getResolvedSiteData(siteData); // eslint-disable-line no-mixed-operators
        const isMobileView = _.isFunction(siteData.isMobileView) ? siteData.isMobileView() : false;
        return _.reduce(rawData, function (items, data) { // eslint-disable-line complexity
            pageData = siteData.getDataByQuery(data.refId) || {};
            const showMobileItems = isMobileView && data.isVisibleMobile;
            if (includeHiddenItems || data.isVisible || showMobileItems) {
                const newItem = {
                    label: pageData.title,
                    isVisible: !pageData.hidePage,
                    isVisibleMobile: pageData.mobileHidePage !== undefined ? !pageData.mobileHidePage : !pageData.hidePage,
                    items: convertOldMenuToNewMenu(data.items, siteData, renderLinks, navInfo, resolvedSiteData, includeHiddenItems, linkRenderer),
                    link: getPageLinkObject(data.refId, siteData, renderLinks, navInfo, resolvedSiteData, linkRenderer)
                };
                if (data.displayCount) {
                    newItem.displayCount = data.displayCount;
                }
                items.push(newItem);
            }
            return items;
        }, []);
    }

    /**
     * Retrieves all menu items
     *
     * @param siteData
     * @returns {Array}
     */
    function getSiteMenu(siteData, dontRenderLinks, navInfo, includeHiddenItems, linkRenderer) {
        const customSiteMenu = siteData.getDataByQuery('CUSTOM_MAIN_MENU');
        const mainMenu = siteData.getDataByQuery('MAIN_MENU');

        if (customSiteMenu && (!mainMenu || _.isEmpty(mainMenu.items))) {
            return getMenuItems(customSiteMenu.items, siteData, !dontRenderLinks, navInfo, includeHiddenItems, null, linkRenderer);
        }

        return convertOldMenuToNewMenu(mainMenu.items, siteData, !dontRenderLinks, navInfo, null, includeHiddenItems, linkRenderer);
    }

    /**
     *
     * @param menuId
     * @param siteData
     * @param dontRenderLinks
     * @param navInfo
     * @returns {*}
     */
    function getMenuById(menuId, siteData, dontRenderLinks, navInfo, includeHiddenItems, linkRenderer) {
        if (menuId === 'CUSTOM_MAIN_MENU' || menuId === 'MAIN_MENU') {
            return getSiteMenu(siteData, dontRenderLinks, navInfo, false, linkRenderer);
        }
        const items = _.get(siteData.getDataByQuery(menuId), 'items', []);
        return getMenuItems(items, siteData, !dontRenderLinks, navInfo, includeHiddenItems, null, linkRenderer);
    }

    function getMaxWidth(widths) {
        return _.reduce(widths, function (a, b) {
            return a > b ? a : b;
        }, -Infinity);
    }

    function removeAllElementsWithWidthZero(widths) {
        return _.filter(widths, function (num) {
            return num !== 0;
        });
    }

    function getMinWidth(widths) {
        return _.reduce(widths, function (a, b) {
            return a < b ? a : b;
        });
    }

    function getDropDownWidthIfOk(menuWidth, sameWidth, stretch, widths, menuWidthToReduce, maxWidth, removeMarginFromAllChildren, extraPixels) { // eslint-disable-line complexity
        menuWidth -= menuWidthToReduce * (removeMarginFromAllChildren ? widths.length : widths.length - 1);
        menuWidth -= extraPixels.left + extraPixels.right;
        if (sameWidth) {
            // width same width, all widths should be as the max width (calculated for the whole items in the calling method)
            _.fill(widths, maxWidth);
        }

        // not first measure - want sizes without 0
        if (_.includes(widths, 0)) {
            return null;
        }
        let totalMenuItemsWidths = 0;
        const total = _.reduce(widths, function (a, b) {
            return a + b;
        }, 0);
        if (total > menuWidth) {
            // drop down should have less items
            return null;
        }

        // calculate the width of the items
        if (sameWidth) {
            if (stretch) {
                const width = Math.floor(menuWidth / widths.length);
                const stretchedAndSameItemWidths = _.times(widths.length, _.constant(width));
                totalMenuItemsWidths = width * widths.length;
                if (totalMenuItemsWidths < menuWidth) {
                    const totalRemnant = Math.floor(menuWidth - totalMenuItemsWidths);
                    _.forEach(widths, function (wdth, index) {
                        if (index <= totalRemnant - 1) {
                            stretchedAndSameItemWidths[index]++;
                        }
                    });
                }
                return stretchedAndSameItemWidths;
            }
            return widths;
        }

        // not same width
        if (stretch) {
            const toAdd = Math.floor((menuWidth - total) / widths.length);
            totalMenuItemsWidths = 0;
            const stretchItemsWidths = _.map(widths, function (itemWidth) {
                totalMenuItemsWidths += itemWidth + toAdd;
                return itemWidth + toAdd;
            });
            if (totalMenuItemsWidths < menuWidth) {
                const remnant = Math.floor(menuWidth - totalMenuItemsWidths);
                _.forEach(widths, function (wdth, index) {
                    if (index <= remnant - 1) {
                        stretchItemsWidths[index]++;
                    }
                });
            }
            return stretchItemsWidths;
        }

        return widths;
    }


    function getActiveAnchorInPage(activeAnchor, compDataVisiblePages, urlPageId) {
        if (!activeAnchor) {
            return null;
        }
        const activeAnchorId = activeAnchor.activeAnchorComp.id;
        const pageActiveAnchor = _(compDataVisiblePages)
            .filter(function (item) {
                return item.link && _.isObject(item.link.anchorDataId) && item.link.type === 'AnchorLink' && _.has(item.link.pageId, 'id') && item.link.pageId.id === urlPageId;
            })
            .find({link: {anchorDataId: {id: activeAnchorId}}});

        return pageActiveAnchor ? pageActiveAnchor.link.anchorDataId.id : null;
    }

    function shouldHighlightAnchorInPage(siteData) {
        return siteData.browserFlags().highlightAnchorsInMenu;
    }

    return {
        getDropDownWidthIfOk,
        getMaxWidth,
        getMinWidth,
        removeAllElementsWithWidthZero,
        getSiteMenu,
        getMenuById,
        getMenuItems,
        getActiveAnchorInPage,
        shouldHighlightAnchorInPage
    };
});
