define([
    'lodash'
], function (
    _
) {
    'use strict';

    const SCROLL_DOWN_DISTANCE_THRESHOLD_BY_ANIMATIONS = {
        'HeaderFadeOut': 200, // px
        'HeaderHideToTop': 400 // px
    };

    const SCROLL_UP_DISTANCE_THRESHOLD = 100; //px

    const DEFAULT_DURATION_PER_ANIMATION = {
        'HeaderFadeOut': 0.5, // sec
        'HeaderHideToTop': 0.25 // sec
    };

    const getAnimationDefaults = (compId, compMeasures, animationName) => ({
        targetId: compId,
        duration: DEFAULT_DURATION_PER_ANIMATION[animationName] || 0,
        delay: 0,
        params: {
            compMeasures
        }
    });

    const limitBetweenZeroAndOne = num => Math.max(Math.min(num, 1), 0);
    const isLegalScrollValue = scrollValue => !_.isNaN(scrollValue) && scrollValue >= 0;
    const limitValidScrollValue = scrollValue => isLegalScrollValue(scrollValue) ? scrollValue : 0;

    function getSequenceProgress(currentScroll, previousScroll, lastSequenceProgress, elementHeight) {
        const Y = limitValidScrollValue(currentScroll.position.y);
        const previousY = limitValidScrollValue(previousScroll.position.y);
        const scrollPercentage = Math.abs(previousY - Y) / elementHeight; // scrollDelta, relative to header's height
        const sign = currentScroll.direction === 'DOWN' ? 1 : -1;
        const sequenceDelta = sign * scrollPercentage * 0.5;
        return limitBetweenZeroAndOne(lastSequenceProgress + sequenceDelta);
    }

    const getComponentMeasure = (siteData, compId) => ({
        height: siteData.measureMap.height[compId],
        width: siteData.measureMap.width[compId],
        top: siteData.measureMap.top[compId],
        left: siteData.measureMap.left[compId]
    });

    const animateAfterScroll = (sequence, event, {behavior}) => {
        const animationName = _.head(behavior.params.animations).name;
        const isScrollingDown = event.scroll.current.direction === 'DOWN';
        const {accumulatedScroll} = event.scroll;

        const sequenceProgress = sequence.progress();
        const shouldAnimateForward = isScrollingDown && accumulatedScroll > SCROLL_DOWN_DISTANCE_THRESHOLD_BY_ANIMATIONS[animationName] && sequenceProgress < 1;
        const shouldAnimateReverse = !isScrollingDown && accumulatedScroll > SCROLL_UP_DISTANCE_THRESHOLD && sequenceProgress > 0;

        if (shouldAnimateForward) {
            sequence.play();
        } else if (shouldAnimateReverse) {
            sequence.reverse();
        }
    };

    const scrubSequenceWithScroll = (sequence, event, {compMeasures}) => {
        const lastSequenceProgress = sequence.progress();
        const sequenceProgress = getSequenceProgress(event.scroll.current, event.scroll.previous, lastSequenceProgress, compMeasures.height);
        if (_.isFinite(sequenceProgress) && sequenceProgress !== lastSequenceProgress) {
            sequence.progress(sequenceProgress);
        }
    };

    const createSequence = (behavior, page, compMeasures) => {
        const compId = behavior.targetId;
        const animations = _.get(behavior, ['params', 'animations'], []);
        const sequence = page.sequence();

        _.forEach(animations, animation => {
            const {name, duration, delay, params} = _.defaultsDeep(animation, getAnimationDefaults(behavior.targetId, compMeasures, animation.name));
            sequence.add(compId, name, duration, delay, params);
        });

        return sequence.execute({
            suppressReactRendering: false,
            forgetSequenceOnComplete: false,
            paused: true
        });
    };

    return {
        getComponentMeasure,
        animateAfterScroll,
        scrubSequenceWithScroll,
        createSequence
    };
});
