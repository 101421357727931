/* Autogenerated file. Do not modify */
'use strict'
const skins = {}
 skins['wysiwyg.viewer.skins.VideoSkin'] = {
  "react": [
    [
      "div",
      "videoFrame",
      [],
      {
        "tabIndex": 0
      }
    ],
    [
      "div",
      "preview",
      [],
      {}
    ]
  ],
  "css": {
    "%videoFrame": "position:relative;width:100% !important;height:100% !important;display:block;",
    "%_hidden": "display:none !important;"
  }
}
 skins['wysiwyg.viewer.skins.video.VideoDefault'] = {
  "react": [
    [
      "div",
      "videoFrame",
      [],
      {
        "tabIndex": 0
      }
    ],
    [
      "div",
      "preview",
      [],
      {}
    ]
  ],
  "params": {
    "brd": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "brw": "SIZE"
  },
  "paramsDefaults": {
    "brd": "color_15",
    "rd": "0",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "brw": "0"
  },
  "css": {
    "%_hidden": "display:none !important;",
    "%": "background-color:[brd];[rd]  [shd]",
    "% iframe": "width:100% !important;height:100% !important;",
    "%videoFrame": "overflow:hidden;position:absolute;[rd]  position:absolute;top:[brw];right:[brw];bottom:[brw];left:[brw];display:block;"
  }
}
 skins['wysiwyg.viewer.skins.video.VideoLiftedShadow'] = {
  "react": [
    [
      "div",
      null,
      [
        "_left",
        "_shd"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_right",
        "_shd"
      ],
      {}
    ],
    [
      "div",
      "videoFrame",
      [],
      {
        "tabIndex": 0
      }
    ],
    [
      "div",
      "preview",
      [],
      {}
    ]
  ],
  "params": {
    "brd": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "tdr": "URL",
    "brw": "SIZE"
  },
  "paramsDefaults": {
    "brd": "color_15",
    "rd": "0",
    "shd": "0 1px 4px rgba(0, 0, 0, 0.6)",
    "tdr": "BASE_THEME_DIRECTORY",
    "brw": "0"
  },
  "css": {
    "%": "background-color:[brd];[rd]  [shd]",
    "%_shd": "position:absolute;bottom:-26px;width:165px;height:26px;background-image:url([tdr]liftedshadow_medium.png);background-repeat:no-repeat;",
    "%_left": "left:-20px;background-position:0 0;",
    "%_right": "right:-20px;background-position:100% 0;",
    "%videoFrame": "overflow:hidden;position:absolute;[rd]  position:absolute;top:[brw];right:[brw];bottom:[brw];left:[brw];display:block;",
    "% iframe": "width:100% !important;height:100% !important;",
    "%_hidden": "display:none !important;"
  }
}
 skins['wysiwyg.viewer.skins.video.VideoSloppy'] = {
  "react": [
    [
      "div",
      null,
      [
        "_brd",
        "_one"
      ],
      {}
    ],
    [
      "div",
      null,
      [
        "_brd",
        "_two"
      ],
      {}
    ],
    [
      "div",
      "videoFrame",
      [],
      {
        "tabIndex": 0
      }
    ],
    [
      "div",
      "preview",
      [],
      {}
    ]
  ],
  "params": {
    "tdr": "URL"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%_brd": "background-image:url([tdr]sloppyframe.png);background-repeat:no-repeat;position:absolute;top:0;right:0;bottom:0;left:0;",
    "%_one": "background-position:0 0;bottom:3px;right:3px;",
    "%_two": "background-position:100% 100%;top:3px;left:3px;",
    "%videoFrame": "position:absolute;top:9px;right:9px;bottom:9px;left:9px;display:block;",
    "% iframe": "width:100% !important;height:100% !important;",
    "%_hidden": "display:none !important;"
  }
}

module.exports = skins