import * as _ from 'lodash'
const cache = new WeakMap()
export function objectDiff(liveObject) {
    let cachedObject = cache.get(liveObject)
    if (!cachedObject) {
        cachedObject = {...liveObject}
        cache.set(liveObject, cachedObject)
        return {previous: {}, next: cachedObject}
    }

    const next = {}
    const previous = {}
    for (const key in liveObject) {
        if (cachedObject[key] !== liveObject[key]) {
            previous[key] = cachedObject[key]
            next[key] = cachedObject[key] = liveObject[key]
        }
    }

    for (const key in cachedObject) {
        if (!_.has(liveObject, key)) {
            previous[key] = cachedObject[key]
            delete cachedObject[key]
        }
    }

    return {next, previous}
}
