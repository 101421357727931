define(['data-capsule'], function (DataCapsuleTools) {
    'use strict';

    return {
        init(siteAPI) {
            function verifier(source, origin, token) {
                return !!siteAPI.getComponentById(token);
            }

            function interceptor(options, source, origin, token) {
                options.namespace = siteAPI.getComponentById(token).getAppData().appDefinitionId;
                options.scope = siteAPI.getSiteData().getMetaSiteId();
                return options;
            }

            new DataCapsuleTools.FrameStorageListener()
                .start(verifier, interceptor);
        }
    };
});
