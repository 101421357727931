define([
    'coreUtils',
    'components/behaviors/scrubBehaviorHandler'
], function (
    coreUtils,
    scrubBehaviorHandler
) {
    'use strict';

    coreUtils.behaviorHandlersFactory.registerHandler('scrub', scrubBehaviorHandler);
});
