define(['lodash', 'tpaComponents/utils/sitePages', 'coreUtils'], function (_, sitePages, coreUtils) {
    'use strict';

    const privates = new coreUtils.SiteDataPrivates(); //eslint-disable-line santa/no-module-state

    const mapPageToWidgets = function (siteAPI) {
        const privatesKey = siteAPI.getSiteData();
        if (!privates.has(privatesKey)) {
            privates.set(privatesKey, {});
        }
        const tpaToPageMap = privates.get(privatesKey);

        if (_.size(tpaToPageMap) > 0) {
            return tpaToPageMap;
        }

        const pages = sitePages.getSitePagesData(siteAPI.getSiteData());
        _.forEach(pages, function (pageData) {
            if (pageData && pageData.tpaApplicationId > 0) {
                const data = {
                    pageId: pageData.id,
                    tpaId: pageData.tpaApplicationId,
                    tpaPageId: pageData.tpaPageId
                };
                if (_.isUndefined(tpaToPageMap[data.tpaId])) {
                    tpaToPageMap[data.tpaId] = [];
                }
                tpaToPageMap[data.tpaId].push(data);
            }
        });

        return tpaToPageMap;
    };

    return {
        mapPageToWidgets
    };
});
