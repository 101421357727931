const name = 'ScreenInAspect'
const SCREEN_IN_ACTION = 'screenIn'
const HIGHER_THAN_VIEWPORT_THRESHOLD = 0.01
const defaultModel = {}

const thresholdCache = {}

/**
 * Call animation manager trigger for visible entries
 * @param entry
 * @param compId
 */
function screenInHandler(entry, compId) {
    if (entry.visible) {
        this.trigger([{
            compId,
            action: SCREEN_IN_ACTION
        }])
    }
}

/**
 * Get screen in threshold from animation definition
 * @param animationManager
 * @param animationDefs
 * @param id
 * @returns {*}
 */
function getAnimationThreshold(animationManager, animationDefs, id) {
    const animationName = animationDefs[id][SCREEN_IN_ACTION][0].name
    return animationManager.animationProperties[animationName].viewportThreshold
}

const functionLibrary = {
    updateViewportRegistry: ({next, previous}, animationDefs, animationManager, viewportRegistry) => {
        // add new and register
        Object.keys(next).forEach(id => {
            const threshold = next[id].isHigherThanViewPort ?
                [HIGHER_THAN_VIEWPORT_THRESHOLD] :
                getAnimationThreshold(animationManager, animationDefs, id)

            animationManager.initComponentAction(id, SCREEN_IN_ACTION, {clearStore: false})

            // register
            viewportRegistry.register(name, id, {
                handler: screenInHandler.bind(animationManager),
                threshold
            })

            thresholdCache[id] = threshold
        })

        // delete old and unregister
        Object.keys(previous).forEach(id => {
            // unregister
            // NOTE: we always get every new id in `next` ALSO in `previous` since it's the old ref, so check here
            if (!next[id] && thresholdCache[id]) {
                animationManager.clearCompLocalStore(id, SCREEN_IN_ACTION)

                const threshold = thresholdCache[id]
                viewportRegistry.unregister(name, id, threshold)
                delete thresholdCache[id]
            }
        })
    },
    preventPendingScreenInAnimation: (animationManager, id) => {
        animationManager.preventPendingScreenInAnimation(id)
    }
}

export {
    name,
    defaultModel,
    SCREEN_IN_ACTION,
    functionLibrary
}
