/* Autogenerated file. Do not modify */
define([], function() {
    'use strict';
    var skins = {};
     skins['wysiwyg.skins.ImageZoomDisplayerSkin'] = {
  "react": [
    [
      "div",
      "image",
      [],
      {}
    ],
    [
      "div",
      "panel",
      [],
      {},
      [
        "div",
        null,
        [
          "_meta"
        ],
        {},
        [
          "h3",
          "title",
          [],
          {}
        ],
        [
          "p",
          "description",
          [],
          {}
        ],
        [
          "a",
          "link",
          [],
          {}
        ]
      ]
    ]
  ],
  "exports": {
    "image": {
      "skin": "skins.core.ImageSkin"
    }
  },
  "mediaQueries": [
    {
      "query": "@media (orientation: landscape)",
      "css": {
        "%[data-state~='mobile'] %panel": "font-size:1.5em;"
      }
    },
    {
      "query": "@media (orientation: landscape)",
      "css": {
        "%[data-state~='tablet'] %panel": "font-size:1em;"
      }
    }
  ],
  "css": {
    "%": "width:100%;height:100%;text-align:center;font:12px \"Helvetica Neue\",\"HelveticaNeueW01-55Roma\",\"HelveticaNeueW02-55Roma\",\"HelveticaNeueW10-55Roma\",Helvetica, Arial, sans-serif;",
    "%panel": "position:absolute;text-align:center;color:#fff;font-family:Helvetica;font-weight:100;letter-spacing:0.2px;",
    "%title": "font-size:16px;margin:20px auto;max-width:100%;font-weight:100;",
    "%description": "width:465px;margin:20px auto;white-space:pre-line;font-size:12px;line-height:17px;letter-spacing:0.3px;max-width:100%;",
    "%link": "font-family:'Times New Roman';color:#ffffff;font-size:13px;font-style:italic;text-decoration:underline;",
    "%[data-state~='mobile'] %panel": "font-size:2em;",
    "%[data-state~='tablet'] %panel": "font-size:1.1em;",
    "%[data-state~='mobile'] %panel,%[data-state~='tablet'] %panel": "padding:30px 85px;box-sizing:border-box;",
    "%[data-state~='mobile'] %image,%[data-state~='tablet'] %image": "margin:0 auto;box-sizing:border-box;",
    "%[data-state~='mobile'] %title,%[data-state~='tablet'] %title": "font-size:1.6em;margin-top:0;",
    "%[data-state~='mobile'] %description,%[data-state~='tablet'] %description": "font-size:1.2em;line-height:1.7em;width:100%;",
    "%[data-state~='mobile'] %link,%[data-state~='tablet'] %link": "font-size:1.3em;"
  }
}
 skins['wysiwyg.skins.ImageZoomSkin'] = {
  "react": [],
  "exports": {
    "imageItem": {
      "skin": "wysiwyg.skins.ImageZoomDisplayerSkin"
    },
    "mediaZoom": {
      "skin": "wysiwyg.viewer.skins.MediaZoomSkin"
    }
  },
  "css": {}
}
 skins['wysiwyg.skins.NonOptimizedImageZoomSkin'] = {
  "react": [],
  "exports": {
    "imageItem": {
      "skin": "wysiwyg.skins.ImageZoomDisplayerSkin"
    },
    "mediaZoom": {
      "skin": "wysiwyg.viewer.skins.NonOptimizedMediaZoomSkin"
    }
  },
  "css": {}
}
 skins['wysiwyg.viewer.skins.AppPartMediaZoomSkin'] = {
  "react": [
    [
      "div",
      "blockingLayer",
      [],
      {},
      [
        "div",
        "dialogBox",
        [],
        {},
        [
          "div",
          "xButton",
          [],
          {}
        ],
        [
          "div",
          "itemsContainer",
          [],
          {}
        ]
      ],
      [
        "div",
        "buttonPrev",
        [
          "_btn"
        ],
        {}
      ],
      [
        "div",
        "buttonNext",
        [
          "_btn"
        ],
        {}
      ]
    ]
  ],
  "params": {
    "tdr": "URL",
    "trns": "TRANSITION",
    "bg": "BG_COLOR",
    "heightSpacer": "SIZE"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY",
    "trns": "opacity 0.5s ease 0s",
    "bg": "#000",
    "heightSpacer": [
      "pad",
      "pad"
    ]
  },
  "paramsMutators": {
    "bg": {
      "type": "alpha",
      "value": 0.75
    }
  },
  "css": {
    "%": "position:fixed !important;width:100% !important;height:100% !important;z-index:99999;",
    "%blockingLayer": "background-color:[bg];position:fixed;top:0;right:0;bottom:0;left:0;visibility:visible;zoom:1;overflow:auto;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;",
    "%[data-state~='mobile'] %blockingLayer,%[data-state~='tablet'] %blockingLayer": "background-color:#5F6360;",
    "%dialogBox": "margin:auto;background:#fff;min-width:500px;position:relative;text-align:right;width:500px;padding:10px 10px 0;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;box-sizing:border-box;",
    "%xButton": "display:inline-block;right:-25px;width:25px;height:25px;background:#fff url([tdr]close.gif) no-repeat 50% 50%;cursor:pointer;position:absolute;top:0;z-index:500;",
    "%_btn": "position:fixed;top:0;width:100px;height:100%;background:url([tdr]arrows_white.png) no-repeat;overflow:hidden;text-indent:-9999px;cursor:pointer;opacity:0.3;[trns]",
    "%buttonNext": "right:20px;background-position:-30px 50%;",
    "%buttonPrev": "left:20px;background-position:70px 50%;",
    "%_btn:hover": "opacity:1;"
  }
}
 skins['wysiwyg.viewer.skins.AppPartMobileMediaZoomSkin'] = {
  "react": [
    [
      "div",
      "blockingLayer",
      [],
      {},
      [
        "div",
        "dialogBox",
        [
          "_z-dialog"
        ],
        {},
        [
          "div",
          "itemsContainer",
          [],
          {}
        ],
        [
          "div",
          "xButton",
          [
            "_btn"
          ],
          {}
        ],
        [
          "div",
          "buttonPrev",
          [
            "_btn"
          ],
          {}
        ],
        [
          "div",
          "buttonNext",
          [
            "_btn"
          ],
          {}
        ]
      ]
    ]
  ],
  "params": {
    "tdr": "URL",
    "zoom": "INVERTED_ZOOM_FIXED",
    "bg": "BG_COLOR"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY",
    "zoom": "1",
    "bg": "#000"
  },
  "paramsMutators": {
    "bg": {
      "type": "alpha",
      "value": 1
    }
  },
  "mediaQueries": [
    {
      "query": "@media (orientation: landscape)",
      "css": {
        "%buttonPrev,%buttonNext": "top:45%;"
      }
    }
  ],
  "css": {
    "%": "position:static;width:100%;height:100%;-webkit-tap-highlight-color:rgba(0, 0, 0, 0);z-index:500;",
    "%blockingLayer": "background-color:[bg];position:fixed;top:0;right:0;bottom:0;left:0;",
    "%dialogBox": "position:absolute;top:0;bottom:0;left:0;right:0;margin:auto;background:#000;z-index:1000;",
    "%buttonNext,%buttonPrev": "top:50%;background-size:cover;background-repeat:no-repeat;",
    "%buttonPrev": "background-image:url([tdr]prev_button.png);left:0;",
    "%buttonNext": "background-image:url([tdr]next_button.png);right:0;",
    "%xButton": "top:0;right:0;background:url([tdr]x_button.png) no-repeat;background-size:cover;",
    "%buttonNext [data-state~=\"tablet\"],%buttonPrev [data-state~=\"tablet\"],%xButton [data-state~=\"tablet\"]": "[zoom]",
    "%_btn": "[zoom]  position:fixed;overflow:hidden;cursor:pointer;",
    "%[data-state~=\"tablet\"] %_btn": "width:80px;height:80px;",
    "%[data-state~=\"mobile\"] %_btn": "width:43px;height:43px;",
    "%[data-state~=\"hideButtons\"] %_btn": "display:none;",
    "%[data-state~=\"scrollEnabled\"] %blockingLayer": "position:static;height:100%;"
  }
}
 skins['wysiwyg.viewer.skins.MediaZoomSkin'] = {
  "react": [
    [
      "div",
      "blockingLayer",
      [],
      {},
      [
        "div",
        "dialogBox",
        [],
        {},
        [
          "div",
          "itemsContainer",
          [],
          {}
        ]
      ],
      [
        "div",
        "xButton",
        [],
        {}
      ],
      [
        "div",
        "buttonNext",
        [
          "_btn"
        ],
        {}
      ],
      [
        "div",
        "buttonPrev",
        [
          "_btn"
        ],
        {}
      ]
    ]
  ],
  "params": {
    "trns": "TRANSITION",
    "bg": "BG_COLOR",
    "heightSpacer": "SIZE"
  },
  "paramsDefaults": {
    "trns": "opacity 0.5s ease 0s",
    "bg": "#000",
    "heightSpacer": [
      "pad",
      "pad"
    ]
  },
  "paramsMutators": {
    "bg": {
      "type": "alpha",
      "value": 0.9
    }
  },
  "css": {
    "%": "position:fixed !important;width:100% !important;height:100% !important;z-index:99999;",
    "%blockingLayer": "background-color:[bg];position:fixed;top:0;right:0;bottom:0;left:0;visibility:visible;zoom:1;overflow:auto;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;",
    "%dialogBox": "margin:auto;max-width:100%;position:relative;width:500px;padding:35px 0 50px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;box-sizing:border-box;visibility:hidden;",
    "%xButton": "display:inline-block;right:50px;top:50px;cursor:pointer;position:fixed;z-index:500;",
    "%xButton %_svgButtonClose": "width:18px;height:18px;stroke:#fff;stroke-width:15;position:absolute;right:0;top:0;",
    "%_btn": "position:fixed;top:0;width:33%;height:100%;overflow:hidden;text-indent:-9999px;cursor:pointer;opacity:0.9;[trns]",
    "%_btn %_svgNavButton": "width:18px;height:31px;stroke:#fff;stroke-width:20;position:absolute;top:50%;",
    "%buttonNext": "right:0;",
    "%buttonNext %_svgNavButton": "right:50px;",
    "%buttonPrev": "left:0;",
    "%buttonPrev %_svgNavButton": "left:50px;",
    "%_btn:hover": "opacity:1;"
  }
}
 skins['wysiwyg.viewer.skins.NonOptimizedMediaZoomSkin'] = {
  "react": [
    [
      "div",
      "blockingLayer",
      [],
      {},
      [
        "div",
        "dialogBox",
        [
          "_z-dialog"
        ],
        {},
        [
          "div",
          "itemsContainer",
          [],
          {}
        ],
        [
          "div",
          "buttonPrev",
          [
            "_btn"
          ],
          {}
        ],
        [
          "div",
          "buttonNext",
          [
            "_btn"
          ],
          {}
        ],
        [
          "div",
          "xButton",
          [
            "_btn"
          ],
          {}
        ]
      ]
    ]
  ],
  "params": {
    "trns": "TRANSITION",
    "bg": "BG_COLOR",
    "heightSpacer": "SIZE"
  },
  "paramsDefaults": {
    "trns": "opacity 0.5s ease 0s",
    "bg": "#000",
    "heightSpacer": [
      "pad",
      "pad"
    ]
  },
  "paramsMutators": {
    "bg": {
      "type": "alpha",
      "value": 0.5
    }
  },
  "mediaQueries": [
    {
      "query": "@media (orientation: portrait)",
      "css": {
        "%xButton": "width:75px;height:75px;",
        "%[data-state~='mobile'] %_svgButtonClose": "width:45px;height:45px;right:15px;top:15px;",
        "%[data-state~='mobile'] %_svgNavButton": "width:45px;height:45px;margin-top:-22px;",
        "%[data-state~='mobile'] %buttonNext %_svgNavButton": "padding:15px 12px 15px 18px;",
        "%[data-state~='mobile'] %buttonPrev %_svgNavButton": "padding:15px 18px 15px 12px;",
        "%[data-state~='tablet'] %_svgButtonClose": "width:35px;height:35px;right:20px;top:20px;",
        "%[data-state~='tablet'] %_svgNavButton": "width:35px;height:35px;margin-top:-17px;",
        "%[data-state~='tablet'] %buttonNext %_svgNavButton": "padding:20px 18px 20px 22px;",
        "%[data-state~='tablet'] %buttonPrev %_svgNavButton": "padding:20px 22px 20px 18px;"
      }
    },
    {
      "query": "@media (orientation: landscape)",
      "css": {
        "%xButton": "width:45px;height:45px;",
        "%_svgNavButton": "width:25px;height:25px;margin-top:-12px;",
        "%buttonNext %_svgNavButton": "padding:10px 9px 10px 11px;",
        "%buttonPrev %_svgNavButton": "padding:10px 11px 10px 9px;",
        "%[data-state~='tablet'] %_svgButtonClose": "width:19px;height:19px;right:13px;top:13px;",
        "%[data-state~='mobile'] %_svgButtonClose": "width:25px;height:25px;right:10px;top:10px;"
      }
    }
  ],
  "css": {
    "%": "position:fixed !important;width:100% !important;height:100% !important;-webkit-tap-highlight-color:rgba(0, 0, 0, 0);z-index:500;",
    "%blockingLayer": "background-color:#000;position:fixed;top:0;right:0;bottom:0;left:0;visibility:visible;zoom:1;overflow:auto;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;",
    "%dialogBox": "position:relative;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;box-sizing:border-box;visibility:hidden;",
    "%_btn": "display:inline-block;cursor:pointer;position:fixed;overflow:hidden;text-indent:-9999px;opacity:0.9;[trns]  z-index:500;",
    "%xButton": "background-color:[bg];right:15px;top:15px;",
    "%xButton %_svgButtonClose": "stroke:#fff;stroke-width:15;position:absolute;",
    "%_svgNavButton": "stroke:#fff;stroke-width:25;position:absolute;top:50%;background-color:[bg];margin:15px;",
    "%buttonNext": "top:0;right:0;width:33%;height:100%;",
    "%buttonNext %_svgNavButton": "right:0;",
    "%buttonPrev": "top:0;left:0;width:33%;height:100%;",
    "%buttonPrev %_svgNavButton": "left:0;",
    "%_btn:hover": "opacity:1;",
    "%[data-state~=\"hideButtons\"] %_btn": "display:none;"
  }
}

    return skins;
});
