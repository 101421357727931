import {withActions} from 'carmi-host-extensions'
import _ from 'lodash'

export const name = 'Session'

export const defaultModel = {
    svSessionChangedRegisteredComps: {},
    currentSvSession: ''
}

export const functionLibrary = {
    registerToSessionChanged: withActions((aspectActions, compId, comp) => {
        aspectActions.setSvSessionChangedRegisteredComp(compId, comp)
    }),
    unRegisterToSessionChanged: withActions((aspectActions, compId) => {
        aspectActions.setSvSessionChangedRegisteredComp(compId, undefined)
    }),
    sendSessionChangedIfNeeded: withActions((aspectActions, registeredComps, newSvSession, oldSvSession) => {
        if (newSvSession !== oldSvSession) {
            _.forEach(registeredComps, comp => {
                if (_.isFunction(comp.sendPostMessage)) {
                    comp.sendPostMessage({
                        intent: 'addEventListener',
                        eventType: 'SESSION_CHANGED',
                        params: {
                            userSession: newSvSession
                        }
                    })
                }
            })
            aspectActions.setCurrentSvSession(newSvSession)
        }
    })
}

