define([
    'warmupUtils/loggingUtils/bi/errors',
    'warmupUtils/loggingUtils/logger/fedopsLogger',
    'warmupUtils/loggingUtils/logger/logger',
    'warmupUtils/loggingUtils/logger/performance',
    'warmupUtils/loggingUtils/logger/newrelic',
    'warmupUtils/loggingUtils/logger/imagesBi',
    'warmupUtils/loggingUtils/logger/performanceMetrics',
    'warmupUtils/loggingUtils/logger/services/browsingSession'
], function (biErrors, fedopsLogger, logger, performance, newrelic, imagesBi, performanceMetrics, browsingSession) {
    'use strict';

    /**
     * @exports loggerUtils
     */
    const exports = {
        bi: {
            errors: biErrors
        },
        fedopsLogger,
        logger,
        performance,
        newrelic,
        imagesBi,
        performanceMetrics,
        browsingSession
    };
    return exports;
});
