define([], function () {
    'use strict';
    return {
        'wysiwyg.viewer.skins.option.InfoTipArrowSkin': true,
        'skins.viewer.galleryslider.SliderDisplayerIronSkin2': true,
        'wysiwyg.viewer.skins.galleryslider.SliderGalleryMinimal': true,
        'wysiwyg.viewer.skins.line.FiveGridLine': true,
        'wysiwyg.viewer.skins.ClipArtSkin': true,
        'wysiwyg.viewer.skins.LazySocialPanelSkin': true,
        'wysiwyg.common.components.numericstepper.viewer.skins.NumericStepperModernSkin': true,
        'wysiwyg.common.components.numericstepper.viewer.skins.NumericStepperVerticalSkin': true,
        'ecommerce.skins.mcom.LoadMoreButton': true,
        'ecommerce.skins.mcom.MobileAddCouponButton': true,
        'ecommerce.skins.mcom.MobileComboBoxSkin': true,
        'ecommerce.skins.mcom.MobileDeleteItemButton': true,
        'ecommerce.skins.mcom.MobileOptionsListInputSkin': true,
        'ecommerce.skins.mcom.MobilePaginatedGridDefaultSkin': true,
        'ecommerce.skins.mcom.MobileSelectableSliderGalleryDefaultSkin': true,
        'wysiwyg.common.components.basicmenu.viewer.skins.BasicMenuSkin': true,
        'wysiwyg.common.components.multiselection.viewer.skins.MultiSelectionSkin': true,
        'wysiwyg.common.components.multiselectionitem.viewer.skins.MultiSelectionItemSkin': true,
        'wysiwyg.viewer.skins.appinputs.AppsTextInputSkinNoValidation': true,
        'wysiwyg.viewer.skins.appinputs.DateInputSkin': true,
        'wysiwyg.viewer.skins.AreaSkin': true,
        'wysiwyg.viewer.skins.displayers.BorderDisplayer': true,
        'wysiwyg.viewer.skins.displayers.SimpleDetailedDisplayer': true,
        'wysiwyg.viewer.skins.dropmenu.ArrowsMenuNSkin': true,
        'wysiwyg.viewer.skins.dropmenu.CirclesMenuNSkin': true,
        'wysiwyg.viewer.skins.dropmenu.ForkedBannerMenuSkin': true,
        'wysiwyg.viewer.skins.dropmenu.ForkedRibbonMenuSkin': true,
        'wysiwyg.viewer.skins.dropmenu.SeparateArrowDownMenuNSkin': true,
        'wysiwyg.viewer.skins.dropmenu.SeparatedArrowsMenuNSkin': true,
        'wysiwyg.viewer.skins.dropmenu.SlantedMenuNSkin': true,
        'wysiwyg.viewer.skins.gallery.LiftedShadowMatrixGallery': true,
        'wysiwyg.viewer.skins.gallery.MatrixGalleryMinimal': true,
        'wysiwyg.viewer.skins.gallery.MatrixGalleryTextBelowSkin': true,
        'wysiwyg.viewer.skins.gallery.TextOnRollMatrixGallerySkin': true,
        'wysiwyg.viewer.skins.gallerymatrix.GlowLineMatrixGallery': true,
        'wysiwyg.viewer.skins.IFrameComponentSkin': true,
        'wysiwyg.viewer.skins.input.CheckBoxInputSkin': true,
        'wysiwyg.viewer.skins.input.CheckBoxInputSkinNoValidation': true,
        'wysiwyg.viewer.skins.input.SMLoginCheckBoxSkin': true,
        'wysiwyg.viewer.skins.input.TextAreaInputSkinNoValidation': true,
        'wysiwyg.viewer.skins.input.TextInputRoundSkin': true,
        'wysiwyg.viewer.skins.input.TextInputSkin': true,
        'wysiwyg.viewer.skins.menubutton.SeparateShinyIMenuButtonSkin': true,
        'wysiwyg.viewer.skins.menubutton.ShinyMenuIButtonSkin': true,
        'wysiwyg.viewer.skins.MobileTryAgainMessageViewSkin': true,
        'wysiwyg.viewer.skins.ModalSkin': true,
        'wysiwyg.viewer.skins.option.InfoTipSkin': true,
        'wysiwyg.viewer.skins.paginatedgrid.counter.CounterTextSkin': true,
        'wysiwyg.viewer.skins.paginatedgrid.PaginatedGridPolaroid': true,
        'wysiwyg.viewer.skins.photo.LfShwDBPhoto': true,
        'wysiwyg.viewer.skins.SliderGallerySkin': true,
        'wysiwyg.viewer.skins.SlideShowGallerySkin': true,
        'wysiwyg.viewer.skins.sm.SMApplyForMembershipSkin': true,
        'wysiwyg.viewer.skins.sm.SMContainerSkin': true,
        'wysiwyg.viewer.skins.sm.SMLoginSkin': true,
        'wysiwyg.viewer.skins.sm.SMProfileSkin': true,
        'wysiwyg.viewer.skins.sm.SMResetPasswordEmailSkin': true,
        'wysiwyg.viewer.skins.sm.SMResetPasswordSkin': true,
        'wysiwyg.viewer.skins.TryAgainMessageViewSkin': true,
        'wysiwyg.viewer.skins.wphoto.PaperclipWPhotoSkin': true,
        'wysiwyg.viewer.skins.wphoto.PolaroidWPhotoSkin': true,
        'wysiwyg.viewer.skins.contactform.ButtonInputSkinDefault': true,
        'wysiwyg.viewer.skins.contactform.InputSkinDefault': true,
        'wysiwyg.viewer.skins.contactform.LabelSkinDefault': true,
        'wysiwyg.viewer.skins.contactform.TextAreaSkinDefault': true,
        'wysiwyg.viewer.skins.ContactFormSkin': true,
        'wysiwyg.viewer.skins.paginatedgrid.wixapps.PaginatedGridDefaultSkin': true,
        'wysiwyg.viewer.skins.paginatedgrid.wixapps.PaginatedGridSimple': true,
        'wysiwyg.viewer.skins.paginatedgrid.wixapps.TemplateChooserGallerySkin': true,
        'wysiwyg.viewer.skins.area.CleanZoomAreaSkin': true,
        'wysiwyg.viewer.skins.area.GridArea': true,
        'wysiwyg.viewer.skins.area.InnerMarginAreaSkin': true,
        'wysiwyg.viewer.skins.area.TiltedAreaSkin': true,
        'wysiwyg.viewer.skins.area.WrapperSkin': true,
        'wysiwyg.viewer.skins.screenwidthcontainer.GradientBottomScreen': true,
        'wysiwyg.viewer.skins.screenwidthcontainer.GradientTopScreen': true,
        'wysiwyg.viewer.skins.screenwidthcontainer.GrassScreen': true,
        'wysiwyg.viewer.skins.screenwidthcontainer.PopupOverlayContainer': true,
        'wysiwyg.viewer.skins.screenwidthcontainer.SimpleScreen': true,
        'wysiwyg.viewer.skins.screenwidthcontainer.WoodScreen': true,
        'skins.image.default': true,
        'wysiwyg.viewer.skins.wixadsskins.WixAdsFacebookPreviewSkin': true,
        'wysiwyg.viewer.skins.wixadsskins.WixAdsRoundSkin': true,
        'wysiwyg.viewer.skins.wixadsskins.WixAdsSitePreviewSkin': true,
        'wysiwyg.viewer.skins.wixadsskins.WixAdsWebSkinBU': true,
        'skins.viewer.pinterestpin.PinterestPinSkin': true,
        'wysiwyg.viewer.skins.quickActionBar.ovalSkin': true,
        'wysiwyg.viewer.skins.quickActionBar.rectSkin': true,
        'wysiwyg.viewer.skins.WixCodeWidgetSkin': true
    };
});