'use strict'
const React = require('react')
const PropTypes = require('prop-types')

const UploadedFontsStyleNode = ({fontFacesString}) =>
    <style
        key="uploadFontsStyleNode"
        type="text/css"
        data-styleid="uploadedFonts"
        dangerouslySetInnerHTML={{__html: fontFacesString}}
    />

UploadedFontsStyleNode.propTypes = {
    fontFacesString: PropTypes.string.isRequired
}

module.exports = UploadedFontsStyleNode
