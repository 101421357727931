define(['tpaComponents/mixins/tpaCompBaseMixin', 'tpaComponents/mixins/tpaUrlBuilderMixin', 'tpaComponents/mixins/tpaCompApiMixin', 'componentsCore'], function (tpaCompBaseMixin, tpaUrlBuilderMixin, tpaCompApiMixin, componentsCore) {
    'use strict';

    const compRegistrar = componentsCore.compRegistrar;

    /**
     * @class components.TPAWidget
     * @extends {tpa.mixins.tpaCompBase}
     * @extends {tpa.mixins.tpaUrlBuilder}
     * @extends {tpa.mixins.tpaCompApiMixin}
     */
    const TPAWorker = { // eslint-disable-line santa/no-module-state
        displayName: 'TPAWorker',
        mixins: [componentsCore.mixins.skinBasedComp, tpaCompBaseMixin, tpaUrlBuilderMixin, tpaCompApiMixin],
        getBaseUrl() {
            const appData = this.getAppData();

            return appData.appWorkerUrl;
        },

        mutateIframeUrlQueryParam(queryParamsObj) {
            queryParamsObj.endpointType = 'worker'; // eslint-disable-line santa/no-side-effects
            return queryParamsObj;
        },

        mutateSkinProperties(skinProps) {
            if (skinProps.iframe && skinProps.iframe.style) {
                skinProps.iframe.style.display = 'none';
            }

            return skinProps;
        },

        isTPAWorker() {
            return true;
        }
    };

    compRegistrar.register('tpa.viewer.classes.TPAWorker', TPAWorker);
});
