'use strict'
const imageServices = {}

function init(services) {
    require('./native-shim')

    const resizeObserver = services.resizeService.init(entries => entries.forEach(entry => entry.target.reLayout()))

    const resizeService = {
        observe: element => {
            resizeObserver.observe(element)
        },
        unobserve: element => {
            resizeObserver.unobserve(element)
        }
    }

    const wixElementWrapper = require('./wixElement')
    const WixElement = wixElementWrapper({resizeService})
    defineCustomElement('wix-element', WixElement)

    return {
        defineWixImage: defineWixImage.bind(null, WixElement)
    }
}

function defineCustomElement(elementName, elementClass) {
    if (customElements.get(elementName) === undefined) {
        customElements.define(elementName, elementClass)
    }
}

function defineWixImage(WixElement, services, environmentConsts) {
    Object.assign(imageServices, services)

    const wixImageWrapper = require('./wixImage/wixImage')
    const WixImage = wixImageWrapper(WixElement, imageServices, environmentConsts)
    defineCustomElement('wix-image', WixImage)
}

module.exports = {
    init
}