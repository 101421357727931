const defaultWorkerOverrideUrl = 'http://localhost:8080/wixcode-worker.js'

function getWorkerUrlOverride() {
    if (typeof window === 'undefined') { //debug feature that works only in client
        return false
    }
    try {
        const urlParams = new URLSearchParams(window.location.search)
        const value = urlParams.get('workerUrlOverride')
        if (value === 'true') {
            return defaultWorkerOverrideUrl
        }
        if (value.indexOf('http://localhost:') !== 0) {
            console.error('workerUrlOverride should be a url served from localhost')
            return false
        }
        return value
    } catch (e) {
        return false
    }
}

export function createWorkerUrlOverride() {
    const workerUrlOverride = getWorkerUrlOverride()
    console.log('fetching worker url from: ', workerUrlOverride)
    return fetch(workerUrlOverride)
        .then(res => new Response(res.body))
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
}

export function isWorkerUrlOverrideExists() {
    return !!getWorkerUrlOverride()
}
