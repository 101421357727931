define(['lodash'], function (_) {
    'use strict';

    function anchorLinkDataResolver(data, getData) {
        const pageId = _.startsWith(data.pageId, '#') ? data.pageId.replace('#', '') : data.pageId;
        const anchorDataId = getData(data.anchorDataId, pageId);
        const pageData = getData(data.pageId, 'masterPage');
        const resolvedData = _.pickBy({anchorDataId, pageId: pageData});
        return _.defaults(resolvedData, data);
    }

    return {
        resolve: anchorLinkDataResolver
    };
});
