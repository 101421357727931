define(['santa-components'], function (santaComponents) {
    'use strict';

    function hasItems(compData) {
        return compData.items.length > 0;
    }

    function canAutoPlay(compData, compProp) {
        return hasItems(compData) && compProp.autoplay;
    }

    /**
     * @class core.mixins.galleryAutoPlayMixin
     */
    return {
        propTypes: {
            compProp: santaComponents.santaTypesDefinitions.Component.compProp.isRequired,
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            isZoomOpened: santaComponents.santaTypesDefinitions.isZoomOpened.isRequired,
            id: santaComponents.santaTypesDefinitions.Component.id.isRequired,
            isPlayingAllowed: santaComponents.santaTypesDefinitions.RenderFlags.isPlayingAllowed
        },

        getInitialState() {
            const galleryCanAutoPlay = canAutoPlay(this.props.compData, this.props.compProp);
            return {
                $showAutoPlayButton: this.shouldShowAutoPlay() ? 'showPlayButton' : 'hidePlayButton',
                shouldAutoPlay: galleryCanAutoPlay,
                $slideshow: galleryCanAutoPlay && !this.props.isZoomOpened && this.props.isPlayingAllowed ? 'autoplayOn' : 'autoplayOff'
            };
        },

        shouldShowAutoPlay() {
            if (this.props.compProp.isHidden) {
                return false;
            }
            return hasItems(this.props.compData) && this.props.compProp.showAutoplay;
        },

        toggleAutoPlay() {
            if (!hasItems(this.props.compData)) {
                return;
            }
            let newState = 'autoplayOff';
            if (this.state.$slideshow === 'autoplayOff') {
                if (!this.props.isPlayingAllowed) {
                    return;
                }

                newState = 'autoplayOn';
            }
            this.setState({
                shouldAutoPlay: !this.state.shouldAutoPlay,
                $slideshow: newState
            }, function () {
                this.updateAutoplayState();
                this.handleAction(newState);
            });
        },
        componentWillReceiveProps(nextProps) {
            const shouldAutoPlay = this.state.shouldAutoPlay && !nextProps.isZoomOpened && nextProps.isPlayingAllowed ? 'autoplayOn' : 'autoplayOff';
            if (shouldAutoPlay !== this.state.$slideshow) {
                this.setState({
                    '$slideshow': shouldAutoPlay
                }, this.updateAutoplayState);
            }
        },
        updateAutoplayState() {
            this.clearTimeoutNamed(this.props.id);
            if (this.state.$slideshow === 'autoplayOn') {
                this.setTimeoutNamed(this.props.id, this.autoplayCallback, this.getAutoplayInterval());
            }
        },
        autoplayCallback() {
            if (this.props.isZoomOpened) {
                return;
            }
            if (this.props.compProp.autoPlayDirection === 'LTR') {
                this.prev();
            } else {
                this.next();
            }
        },
        getAutoplayInterval() {
            const interval = this.props.compProp.autoplayInterval;
            return Math.floor(interval * 1000.0);
        }
    };
});
