define([
    'imageZoom/components/imageZoom',
    'imageZoom/components/imageZoomDisplayer',
    'imageZoom/components/mediaZoom',
    'imageZoom/components/mobileMediaZoom',
    'imageZoom/components/touchMediaZoom',
    'imageZoom/components/touchMediaZoomItem',
    'skins',
    'imageZoom/skins/skins.json'
], function (imageZoom,
             imageZoomDisplayer,
             mediaZoom,
             mobileMediaZoom,
             touchMediaZoom,
             touchMediaZoomItem,
             skinsPackage,
             skinsJson) {
    'use strict';

    skinsPackage.skinsMap.addBatch(skinsJson);

    return {
        imageZoom,
        imageZoomDisplayer,
        mediaZoom,
        mobileMediaZoom,
        touchMediaZoom,
        touchMediaZoomItem
    };
});
