import {aspectNames} from 'common-types'

const {BoltSiteAspect} = aspectNames

const defaultModel = {
    customClickOccurred: false
}

const name = BoltSiteAspect

export {name, defaultModel}
