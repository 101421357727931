export const name = 'vkShareAspect'

export const defaultModel = {
    sizes: {}
}

export const handlePostMessage = ({data}) => {
    try {
        const msgData = JSON.parse(data)
        if (msgData.id && msgData.width) {
            return msgData
        }
        return undefined
    } catch (e) {
        return undefined
    }
}
export const functionLibrary = {}

export function init(aspectActions, {eventsManager}) {
    eventsManager.on('windowMessage', msg => {
        const msgData = handlePostMessage(msg)
        if (msgData) {
            aspectActions.setSizes(msgData.id, msgData)
        }
    })
}
