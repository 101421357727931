const _ = require('lodash')
export const name = 'performanceAspect'

function getPerformanceNow() {
    if (typeof window === 'undefined') { // SSR 
        return () => console.log('ssr polyfil crap')
        // return getPoly()
    }
    let now 
    const performance = window.performance
    if (performance && performance.now) {
        now = performance.now.bind(performance)
    } else {
        const initialTimestamp = window.wixBiSession && window.wixBiSession.initialTimestamp || 0
        now = function () {
            return _.now() - initialTimestamp
        }
    }
    return now
}


export const functionLibrary = {
    now: getPerformanceNow()
}
