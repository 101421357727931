define([
    'lodash',
    'componentsCore/activities/activity'
], function (
    _,
    Activity
) {
    'use strict';

    const MAX_PHONE_FIELD_CHARS = 29;

    const getActivityDetails = function (fields) {
        let summary = '';

        summary += fields.subject ? `<strong>${fields.subject}</strong>` : '';

        if (fields.message) {
            summary += fields.subject ? '<br>' : '';
            summary += fields.message;
        }

        return {
            'additionalInfoUrl': null,
            'summary': !_.isEmpty(summary) ? summary : 'No message was received'
        };
    };

    const contactUpdateTemplate = {
        'name': {
            'first': '',
            'last': ''
        },
        'emails': [
            {
                'tag': 'main',
                'email': ''
            }
        ],
        'phones': [
            {
                'tag': 'main',
                'phone': ''
            }
        ],
        'addresses': [
            {
                'tag': 'main',
                'address': ''
            }
        ]
    };

    Object.freeze(contactUpdateTemplate);

    const fieldNameToTemplateKey = {
        name: 'name',
        email: 'emails',
        phone: 'phones',
        address: 'addresses'
    };

    const getActivityInfo = function (fields) {
        const info = {fields: []};
        Object.keys(fields).forEach(function (key) {
            info.fields.push({name: key, value: fields[key]});
        });
        return info;
    };

    const getContactUpdate = function (allFields) {
        const nonEmptyFields = _.omitBy(allFields, function (value) {
            return !value;
        });
        const contactFormFields = _.map(nonEmptyFields, function (fieldValue, fieldName) {
            return fieldNameToTemplateKey[fieldName];
        });
        const contactUpdateInfo = _.pick(contactUpdateTemplate, contactFormFields);

        handleNameInfo(contactUpdateInfo, nonEmptyFields);
        handlePhonesInfo(contactUpdateInfo, nonEmptyFields);

        _.forEach(nonEmptyFields, function (fieldValue, fieldName) {
            const currentValue = contactUpdateInfo[fieldNameToTemplateKey[fieldName]];
            if (_.isArray(currentValue)) {
                _.head(currentValue)[fieldName] = fieldValue;
            }
        });


        return contactUpdateInfo;
    };

    const handlePhonesInfo = function (contactUpdateInfo, fields) {
        if (!fields.phone) {
            fields.phone = '';
        }
        fields.phone = fields.phone.substring(0, MAX_PHONE_FIELD_CHARS); // eslint-disable-line santa/no-side-effects
    };


    const handleNameInfo = function (contactUpdateInfo, fields) {
        if (!fields.name) {
            fields.name = '';
        }
        const parts = fields.name.split(' ');
        if (contactUpdateInfo.name) {
            contactUpdateInfo.name.first = parts[0];
            contactUpdateInfo.name.last = parts[1];
        }
    };

    const ContactFormActivity = function (activityInfo, fields, fieldLabels) {
        Activity.call(this, activityInfo, fields);
        this._fieldLabels = fieldLabels;
    };

    ContactFormActivity.prototype = new Activity();

    ContactFormActivity.prototype.getParams = function (params) {
        const baseParams = Activity.prototype.getParams.call(this, params);
        let contactFormParams = {
            'component-name': 'ContactForm'
        };

        contactFormParams = _.assign(baseParams, contactFormParams);

        return contactFormParams;
    };

    ContactFormActivity.prototype.getPayload = function (payload) {
        const basePayload = Activity.prototype.getPayload.call(this, payload);
        const contactUpdate = getContactUpdate(this._fields);
        let contactFormPayload = {
            contactUpdate,
            activityInfo: getActivityInfo(this._fields),
            activityDetails: getActivityDetails(this._fields),
            activityType: 'contact/contact-form'
        };

        contactFormPayload = _.assign(basePayload, contactFormPayload);

        return contactFormPayload;
    };

    return ContactFormActivity;
});
