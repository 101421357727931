define(['lodash', 'zepto', 'warmupUtils/loggingUtils/logger/logger', 'warmupUtils/loggingUtils/bi/events'], function (_, $, logger, biEvents) {
    'use strict';

    const getCurrentScrollY = () => _.get(window, 'pageYOffset', window.scrollY);
    const getCurrentBottomY = () => Math.round(getCurrentScrollY() + window.innerHeight);

    const onScroll = () => {
        const currentPixelReached = getCurrentBottomY();
        window.sssr.maxScrollData.maxPixelReached = Math.max(window.sssr.maxScrollData.maxPixelReached, currentPixelReached);
    };

    const reportMaxScroll = siteData => {
        const scrollData = window.sssr.maxScrollData;
        logger.reportBI(siteData, biEvents.MAX_SCROLL_BTWN_BEAT2_BEAT3, {
            maxScroll: scrollData.maxPixelReached,
            pageLength: scrollData.pageLength
        });
    };

    return {
        register: siteData => {
            window.sssr.maxScrollData = {
                pageLength: _.get(siteData, 'measureMap.height.masterPage'),
                maxPixelReached: getCurrentBottomY()
            };
            $(window).on('scroll', onScroll);
        },
        unregister: () => $(window).off('scroll', onScroll),
        reportMaxScroll
    };
});
