import {withActions} from 'carmi-host-extensions'
import {modesTransitionGroupHOC} from './ModesTransitionGroupHOC'
import {
    aspectNames
} from 'common-types'

const {ModesTransitionGroup} = aspectNames
import _ from 'lodash'

export const name = ModesTransitionGroup

export const defaultModel = {
    hoverBoxReplaced: false
}

const CLEAR_ANIMATION_PROPS = 'clip,clipPath,webkitClipPath,opacity,transform,transformOrigin'
const CLEAR_ANIMATION_REVERSE_PROPS = 'clip,clipPath,webkitClipPath'
const CLEAR_TRANSITION_PROPS = 'opacity,x,y,rotation'
const EASE_IN_OUT = 'cubic-bezier(0.420, 0.000, 0.580, 1.000)'

const getElement = id => document.getElementById(id)

const sequencesByComp = {}

const onComplete = (element, doneCallback, id) => {
    const sequences = sequencesByComp[id]
    sequences.delete(element)
    if (!sequences.size) {
        doneCallback()
    }
}

const onTransitionComplete = (element, doneCallback, id) => {
    element.style.transition = ''
    onComplete(element, doneCallback, id)
}

function animate(element, animation, animator, doneCallback, id) {
    const sequence = animator.sequence({
        callbacks: {
            onReverseComplete: seq => {
                seq.pause()
                // Have to clear clipping on reverse. (#BOLT-1091)
                animator.animate('BaseClear', element, 0, 0, {
                    props: CLEAR_ANIMATION_REVERSE_PROPS,
                    immediateRender: false,
                    callbacks: {
                        onComplete: () => onComplete(element, doneCallback, id)
                    }
                })
            },
            onComplete: () => onComplete(element, doneCallback, id),
            onInterrupt: () => onComplete(element, doneCallback, id)
        }
    })

    sequence
        .add(animator.animate(animation.name, element, animation.duration, animation.delay, {
            ...animation.params
        }))
        .add(animator.animate('BaseClear', element, 0, 0, {
            props: CLEAR_ANIMATION_PROPS,
            immediateRender: false
        }))

    return sequence.get()
}

function transition(element, animation, params, animator, doneCallback, id) {
    const sequence = animator.sequence({
        callbacks: {
            onReverseComplete: seq => {
                // Had to add some overrides outside of the main sequence for reverse animations to finish correctly:
                // * baseClear puts back rotation from data-angle on the element so we override it
                // * baseClear deletes a custom GSAP object from the element which breaks our animation if entered in the middle of a sequence
                seq.pause()
                element.dataset.angle = _.isUndefined(params.from.rotation) ? element.dataset.angle : params.from.rotation
                animator.animate('BaseClear', element, 0, 0, {
                    props: CLEAR_TRANSITION_PROPS,
                    immediateRender: false,
                    callbacks: {
                        onComplete: () => onTransitionComplete(element, doneCallback, id)
                    }
                })
            },
            onComplete: () => onTransitionComplete(element, doneCallback, id),
            onInterrupt: () => onTransitionComplete(element, doneCallback, id)
        }
    })

    // name | duration | timing function | delay
    element.style.transition = `
        background-color ${animation.duration}s ${EASE_IN_OUT} ${animation.delay}s,
        color ${animation.duration}s ${EASE_IN_OUT} ${animation.delay}s
    `
    sequence
        .add(animator.animate(animation.name, element, animation.duration, animation.delay, {...params}))
        .add(animator.animate('BaseClear', element, 0, 0, {props: CLEAR_TRANSITION_PROPS, immediateRender: false}))

    return sequence.get()
}

export const functionLibrary = {
    handleModesInOutBehaviors(id, childrenWithModes, animator, {removedIds, addedIds}, doneCallback) {
        sequencesByComp[id] = sequencesByComp[id] || new Map()
        const sequences = sequencesByComp[id]

        const didStartAnimation = _.reduce(removedIds.concat(addedIds), (didAnimate, compId) => {
            const element = getElement(compId)
            if (sequences.has(element)) {
                const sequence = sequences.get(element)
                sequence.reversed(!sequence.reversed())
                return true
            } 
            
            const modes = childrenWithModes[compId]
            const animation = _.includes(removedIds, compId) && modes.out || _.includes(addedIds, compId) && modes.in

            if (animation) {
                sequences.set(element, animate(element, animation, animator, doneCallback, id))
                return true
            }

            return didAnimate
        }, false)


        if (!didStartAnimation) {
            Promise.resolve().then(doneCallback)
        }
    },

    handleModesTransitionBehaviors(id, childrenWithModes, animator, scrollY, unchangedIds, fromParams, isFixed, doneCallback) {
        sequencesByComp[id] = sequencesByComp[id] || new Map()
        const sequences = sequencesByComp[id]

        _.forEach(unchangedIds, compId => {
            const element = getElement(compId)

            if (sequences.has(element)) {
                const sequence = sequences.get(element)
                sequence.reversed(!sequence.reversed())
            } else {
                const modes = childrenWithModes[compId]
                const animation = _.includes(unchangedIds, compId) && modes.change

                if (animation) {
                    const parentMeasures = getElement(id).getBoundingClientRect()
                    const {top: absoluteTop, left: absoluteLeft} = parentMeasures
                    const {top, left, width, height, rotationInDegrees: rotation} = fromParams[compId]

                    const params = {
                        from: {top: top + absoluteTop - (isFixed ? scrollY : 0), left: left + absoluteLeft, width, height, rotation}
                    }
                    sequences.set(element, transition(element, animation, params, animator, doneCallback, id))
                }
            }
        })
    },

    addBoltHoverBoxToCompClasses: withActions(({updateCompClass, setHoverBoxReplaced}, hoverBoxComponent) => {
        updateCompClass('wysiwyg.viewer.components.HoverBox', modesTransitionGroupHOC(hoverBoxComponent))
        setHoverBoxReplaced(true)
    })
}
