import {urlUtils} from 'santa-core-utils'

export const name = 'ServiceTopologyAspect'

export const functionLibrary = {
    getMediaUrl(baseUrl, mediaPath) {
        return urlUtils.joinURL(baseUrl, 'services', 'santa-resources', 'resources', 'viewer', mediaPath)
    },
    getMediaFullStaticUrl(mediaRootUrl, mediaStaticUrl, imgRelativeUrl) {
        return urlUtils.getMediaUrlByContext(imgRelativeUrl, mediaStaticUrl, mediaRootUrl)
    }
}
