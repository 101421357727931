define(['lodash', 'layout/util/layout', 'zepto', 'layout/specificComponents/memberLoginSectionLayout'], function (_, /**layout.layout*/ layout, $, memberLoginSectionLayout) {
    'use strict';

    const buttonsId = 'buttons';
    const userId = 'user';
    const iconId = 'icon';
    const textId = 'text';
    const arrowId = 'arrow';
    const textMinSize = 60;
    const sidesMinPadding = 14;
    const loginButtonId = 'login';
    const dropdownId = 'dropdownMenu';

    function isRTL(id, measureMap, nodesMap) {
        const dataState = getDataStateFromNodesMap(nodesMap, id);
        return dataState && _.includes(dataState, 'rtl');
    }

    function measureDropdownPosition(id, measureMap, nodesMap, isMobileView) {
        const clientRect = nodesMap[id].getBoundingClientRect();
        const arrowNode = nodesMap[id + arrowId];
        const dropdownNode = nodesMap[id + dropdownId];

        const compBorderWidth = measureMap.custom[id].borderWidth;
        const dropdownWidth = dropdownNode.offsetWidth;
        const topLayout = {
            top: `${measureMap.height[id] - compBorderWidth}px`
        };
        let customLayout;

        if (arrowNode.children.length > 0 && dropdownWidth > 0) {
            const siteParent = isMobileView ? $('#SITE_ROOT')[0] : $('#SITE_CONTAINER')[0];
            const siteParentRect = siteParent.getBoundingClientRect();
            const arrowBoundingRect = arrowNode.children[0].getBoundingClientRect();
            let rightPosition = clientRect.right - arrowBoundingRect.right - compBorderWidth;
            let leftPosition = arrowBoundingRect.left - clientRect.left - compBorderWidth;

            if (!isRTL(id, measureMap, nodesMap)) {
                const offscreenLeft = siteParentRect.left - clientRect.right + rightPosition + dropdownWidth;
                if (offscreenLeft > 0) {
                    rightPosition -= offscreenLeft;
                    //for some reason beaker's headless browser can't reach this line (can't go out of bounds)
                }
                customLayout = {
                    right: `${rightPosition}px`,
                    left: 'initial'
                };
            } else {
                const offscreenRight = clientRect.left + leftPosition + dropdownWidth - siteParentRect.right;
                if (offscreenRight > 0) {
                    leftPosition -= offscreenRight;
                    //for some reason beaker's headless browser can't reach this line (can't go out of bounds)
                }
                customLayout = {
                    left: `${leftPosition}px`,
                    right: 'initial'
                };
            }
        }

        measureMap.custom[id + dropdownId] = _.assign(topLayout, customLayout || {});
    }

    function measureWidth(id, measureMap, nodesMap, structureInfo) {
        const buttonsContainerNode = nodesMap[id + buttonsId];
        const iconAvatarNode = nodesMap[id + iconId];
        const arrowNode = nodesMap[id + arrowId];
        const textNode = nodesMap[id + textId];

        const buttonsContainerWidth = buttonsContainerNode.offsetWidth;
        const iconWidth = iconAvatarNode.offsetWidth;
        const arrowWidth = arrowNode.offsetWidth;
        const textWidth = textNode.offsetWidth;
        const compBorderWidth = measureMap.custom[id].borderWidth;

        const loginButtonNode = nodesMap[id + loginButtonId];
        const loginButtonWidth = loginButtonNode.offsetWidth;

        const textPadding = textWidth > 0 && buttonsContainerWidth === 0 && iconWidth === 0 ? sidesMinPadding : 0;
        const componentMinWidthWithoutText = Math.max(buttonsContainerWidth + iconWidth + arrowWidth + textPadding, loginButtonWidth) + 2 * compBorderWidth; // eslint-disable-line no-mixed-operators
        let componentMinWidth = componentMinWidthWithoutText;
        if (textWidth > 0) {
            componentMinWidth += textMinSize;
        }
        const componentWidthToPatch = Math.max(componentMinWidth, structureInfo.layout.width);
        const textNodeMaxWidth = componentWidthToPatch - componentMinWidthWithoutText;

        measureMap.minWidth[id] = componentMinWidth;
        measureMap.width[id] = componentWidthToPatch;
        measureMap.custom[id + textId] = textNodeMaxWidth;
    }

    function measureHeight(id, measureMap, nodesMap, structureInfo) {
        const buttonsContainerNode = nodesMap[id + buttonsId];
        const userNode = nodesMap[id + userId];
        const loginButtonNode = nodesMap[id + loginButtonId];

        const buttonsContainerHeight = buttonsContainerNode.offsetHeight;
        const userHeight = userNode.offsetHeight;
        const loginButtonHeight = loginButtonNode.offsetHeight;
        const compBorderWidth = measureMap.custom[id].borderWidth;

        const componentMinHeight = Math.max(buttonsContainerHeight, userHeight, loginButtonHeight) + 2 * compBorderWidth; // eslint-disable-line no-mixed-operators
        const componentHeightToPatch = Math.max(componentMinHeight, structureInfo.layout.height);

        measureMap.minHeight[id] = componentMinHeight;
        measureMap.height[id] = componentHeightToPatch;
    }

    function measureLoginButton(id, measureMap, nodesMap, structureInfo, {isMobileView}) {
        measureMap.custom[id] = {
            dataStates: nodesMap[id].getAttribute('data-state') || '',
            borderWidth: parseInt($(nodesMap[id]).css('border-width'), 10) || 0
        };
        measureWidth(id, measureMap, nodesMap, structureInfo);
        measureHeight(id, measureMap, nodesMap, structureInfo);
        measureDropdownPosition(id, measureMap, nodesMap, isMobileView());
        memberLoginSectionLayout.measure(id, measureMap, nodesMap, structureInfo);
    }

    function patchLoginButton(id, patchers, measureMap, structureInfo, siteData) {
        patchers.css(id, {
            width: `${measureMap.width[id]}px`,
            minWidth: `${measureMap.minWidth[id]}px`,
            minHeight: `${measureMap.minHeight[id]}px`
        });
        patchers.css(id + textId, {
            maxWidth: `${measureMap.custom[id + textId]}px`
        });

        patchers.css(id + dropdownId, measureMap.custom[id + dropdownId]);

        memberLoginSectionLayout.patch(id, patchers, measureMap, structureInfo, siteData);
    }

    function getChildrenIdToMeasure(id, nodesMap, structureInfo, siteData) {
        let res = [[buttonsId], [userId], [iconId], [textId], [arrowId], [loginButtonId], [dropdownId]];
        const sectionChildrenIds = memberLoginSectionLayout.getChildrenIdToMeasure(siteData, id, nodesMap, structureInfo);
        res = res.concat(sectionChildrenIds);

        return res;
    }

    function getDataStateFromNodesMap(nodesMap, id) {
        return nodesMap[id].getAttribute('data-state');
    }

    layout.registerCustomMeasure('wysiwyg.viewer.components.LoginSocialBar', measureLoginButton);
    layout.registerPatcher('wysiwyg.viewer.components.LoginSocialBar', patchLoginButton);
    layout.registerRequestToMeasureChildren('wysiwyg.viewer.components.LoginSocialBar', getChildrenIdToMeasure);
});
