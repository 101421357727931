import _ from 'lodash'
import _getOr from 'lodash/fp/getOr'
import _mapValues from 'lodash/fp/mapValues'
import {
    resolve,
    resolvePayload,
    renderToStaticMarkup,
    getTitle,
    setTitle,
    getDescription,
    getCanonical,
    setDescription,
    setCanonical,
    addSchema,
    setIndexable,
    EXTERNAL_IDENTIFIERS,
    filterPageLevelTags,
    resolveWithPatterns,
    getAdapter,
    getPatternBlob
} from '@wix/advanced-seo-utils/renderer'

import {
    convertDynamicPageModel,
    convertSeoModel,
    convertTpaModel,
    convertPlatformMetaTagsModel,
    convertSiteLevelMetaTags
} from '@wix/advanced-seo-utils/converters'
import * as mediaItemUtils from 'santa-platform-utils/dist/cjs/mediaItemUtils/mediaItemUtils'
import imageClientSDK from 'image-client-api/dist/imageClientSDK'

import {withActions} from 'carmi-host-extensions'
import {SOURCES} from './seo.sources'
import {tpaHandlersFunctionLibrary} from 'bolt-tpa/src/aspects/tpaHandlers/tpaHandlers'
import {FETCH_TPA_DATA_STATUS} from './utils'

// get this from aspectNames once we are there
export const name = 'SeoAspect'

export const OVERRIDE_IDENTIFIERS = {
    ...EXTERNAL_IDENTIFIERS,
    META_TAGS: 'META_TAGS'
}

export const defaultModel = {
    overrides: {
        [SOURCES.TPA]: {
            [OVERRIDE_IDENTIFIERS.TITLE]: {},
            [OVERRIDE_IDENTIFIERS.DESCRIPTION]: {},
            [OVERRIDE_IDENTIFIERS.STRUCTURED_DATA]: {},
            [OVERRIDE_IDENTIFIERS.CANONICAL]: {},
            [OVERRIDE_IDENTIFIERS.ROBOTS]: {},
            [OVERRIDE_IDENTIFIERS.META_TAGS]: {}
        },
        [SOURCES.UNSPECIFIED]: {
            [OVERRIDE_IDENTIFIERS.TITLE]: {},
            [OVERRIDE_IDENTIFIERS.DESCRIPTION]: {},
            [OVERRIDE_IDENTIFIERS.STRUCTURED_DATA]: {},
            [OVERRIDE_IDENTIFIERS.CANONICAL]: {},
            [OVERRIDE_IDENTIFIERS.ROBOTS]: {},
            [OVERRIDE_IDENTIFIERS.META_TAGS]: {}
        }
    },
    payload: {},
    siteMetadataChangedRegisteredComps: {},
    tpaData: {},
    fetchTpaDataStatus: FETCH_TPA_DATA_STATUS.INITIAL
}

export const functionLibrary = {
    getAdapter,
    getPatternBlob,
    getTitle,
    getDescription,
    getCanonical,
    getTags: (prioritizedData, context) => resolve([prioritizedData], context),
    getTagsMarkup: tags => renderToStaticMarkup(tags),
    resolveWithoutPatterns: resolve,
    resolveWithPatterns,
    resolvePayload,
    convertSeoModel,
    convertDynamicPageModel,
    convertTpaModel,
    convertSiteLevelMetaTags,
    renderToStaticMarkup,
    filterPageLevelTags,
    setRunTimePageTitle: withActions(set(OVERRIDE_IDENTIFIERS.TITLE)),
    setRunTimePageDescription: withActions(set(OVERRIDE_IDENTIFIERS.DESCRIPTION)),
    setRunTimeSchema: withActions(_setRunTimeSchema),
    setRunTimeSchemaFromFetcher: withActions(({setOverride}, overrides, pageId, schema) => {
        _setRunTimeSchema({setOverride}, overrides, pageId, {value: schema, source: SOURCES.TPA})
    }),
    setRunTimeCanonical: withActions(set(OVERRIDE_IDENTIFIERS.CANONICAL)),
    setRunTimeIndexable: withActions(set(OVERRIDE_IDENTIFIERS.ROBOTS)),
    setRunTimeMetaTags: withActions(set(OVERRIDE_IDENTIFIERS.META_TAGS)),
    setWindowTitle: (windowObject, title) => {
        windowObject.document.title = title
        return title
    },
    setRunTimeSeoTags: withActions(({setPayload}, pageId, {itemType, itemData, seoData} = {}) =>
        setPayload(pageId, {itemType, itemData, seoData})),
    transformWixImageToPublicURL: wixImageUri => {
        const parsedUri = mediaItemUtils.parseMediaItemUri(wixImageUri)

        if (!parsedUri.error) {
            return imageClientSDK.getScaleToFillImageURL(
                parsedUri.mediaId,
                parsedUri.width,
                parsedUri.height,
                parsedUri.width,
                parsedUri.height,
                {name: parsedUri.title}
            )
        }

        return null
    },
    createOverrideSchema: (currentPageId, identifiers) =>
        _.reduce(identifiers, (schema, pageIds, identifier) => {
            const value = pageIds[currentPageId]
            if (value === undefined) {
                return schema
            }
            return ({
                [OVERRIDE_IDENTIFIERS.TITLE]: () => setTitle(schema, value),
                [OVERRIDE_IDENTIFIERS.DESCRIPTION]: () => setDescription(schema, value),
                [OVERRIDE_IDENTIFIERS.STRUCTURED_DATA]: () => value.reduce((accSchema, currJsonLd) => addSchema(accSchema, currJsonLd), schema),
                [OVERRIDE_IDENTIFIERS.CANONICAL]: () => setCanonical(schema, value),
                [OVERRIDE_IDENTIFIERS.ROBOTS]: () => setIndexable(schema, value),
                [OVERRIDE_IDENTIFIERS.META_TAGS]: () => resolvePayload([schema, convertPlatformMetaTagsModel(value)])
            }[identifier] || (() => schema))()
        }, {}),
    registerToSiteMetadataChange: withActions((aspectActions, compId, comp) => {
        aspectActions.setMetadataChangedRegisteredComp(compId, comp)
    }),
    unRegisterToSiteMetadataChange: withActions((aspectActions, compId) => {
        aspectActions.setMetadataChangedRegisteredComp(compId, undefined)
    }),
    notify: (title, description = '', getRegComps) => {
        if (title) {
            _.forEach(getRegComps(), comp =>
                tpaHandlersFunctionLibrary.invokeComponentMethod(
                    comp, 'sendPostMessage', {
                        intent: 'addEventListener',
                        eventType: 'SITE_METADATA_CHANGED',
                        params: {title, description}
                    }))
        }
    },
    fetchTpaSeoData: withActions((
        {setTpaData, setFetchTpaDataStatus},
        {
            shouldFetch, shouldWait, fetch, baseUrl, metaSiteId, siteId, pageId,
            externalBaseUrl, mainPageId, pagesData, clientSpecMap, captureError,
            userAgent, parsedUrl, deviceType, routerData, tpaInnerRoute, canonicalUrl, tpaCompInfoArr,
            dateNumberFormat, language, isPrimaryLanguage
        }) => {
        if (!shouldFetch) {
            return true
        }
        if (shouldWait) {
            return false
        }
        const url = `${baseUrl}?metaSiteId=${metaSiteId}`
        const data = {
            siteId,
            baseUri: externalBaseUrl,
            isRequestedUrlMigrated: true,
            isUrlMigrated: true,
            canonicalUrl,
            mainPageId,
            pageId,
            deepLink: '',
            userAgent,
            deviceType,
            routerData: JSON.stringify(routerData),
            tpaDataRequests: getTpaDataRequests({tpaCompInfoArr, clientSpecMap, captureError}),
            pagesData,
            dateNumberFormat,
            language,
            isPrimaryLanguage
        }
        if (parsedUrl.routerDefinition) {
            data.tpaInnerRoute = tpaInnerRoute
            data.canonicalUrl = sanitizeCanonical(data.canonicalUrl, tpaInnerRoute)
        } else {
            data.deepLink = tpaInnerRoute || ''
            data.canonicalUrl = sanitizeCanonical(data.canonicalUrl, data.deepLink)
        }
        // const overrideUrl = `http://app-jvm-12-200.42.wixprod.net:30690/wix-public-html-seo-renderer-webapp/fetchApplicationsSeoData?metaSiteId=${metaSiteId}`
        setFetchTpaDataStatus(FETCH_TPA_DATA_STATUS.FETCHING)

        const fetchData = {
            url,
            options: {
                method: 'POST', body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    Accept: 'application/json; charset=UTF-8'
                }
            },
            dataType: 'application/json',
            onSuccess: tpaData => {
                try {
                    setTpaData(removeFalsyTpaSeoMetaData(JSON.parse(tpaData)))
                } catch (e) {
                    captureError(e)
                }
                setFetchTpaDataStatus(FETCH_TPA_DATA_STATUS.DONE)
            },
            onError: e => {
                captureError(e)
                setFetchTpaDataStatus(FETCH_TPA_DATA_STATUS.DONE)
            }
        }
        fetch(...Object.values(fetchData))
        return false
    })
}

function resolveSource(source) {
    return SOURCES[source] ? source : SOURCES.UNSPECIFIED
}

function sanitizeCanonical(canonical = '', path) {
    if (!path) {
        return canonical
    }
    const canonicalUrl = canonical.split('?')[0]
    const lastIndexOfDeepLink = canonicalUrl.lastIndexOf(path)
    const sanitizedCanonical = canonicalUrl.slice(0, lastIndexOfDeepLink) + canonicalUrl.slice(lastIndexOfDeepLink + path.length)
    return sanitizedCanonical.replace(/\/$/, '')
}

function set(identifier) {
    return function ({setOverride}, pageId, {value, source} = {}) {
        const safeSource = resolveSource(source)
        setOverride(safeSource, identifier, pageId, value)
        return value
    }
}

function _setRunTimeSchema({setOverride}, overrides, pageId, {value, source} = {}) {
    const safeSource = resolveSource(source)
    const override = _.get(overrides, [safeSource, OVERRIDE_IDENTIFIERS.STRUCTURED_DATA, pageId], [])
    return set(OVERRIDE_IDENTIFIERS.STRUCTURED_DATA)({setOverride}, pageId, {value: [...override, value], source: safeSource})
}

function getTpaDataRequests({tpaCompInfoArr, clientSpecMap, captureError}) {
    return _.map(tpaCompInfoArr, compInfo => {
        const dataNode = _.pick(compInfo.data, ['id', 'applicationId', 'type', 'widgetId', 'referenceId'])
        if (!dataNode.referenceId) {
            const verticalsReferenceId = getVerticalsReferenceId(clientSpecMap, compInfo, captureError)
            if (verticalsReferenceId) {
                dataNode.referenceId = verticalsReferenceId
            }
        }
        const component = _.pick(compInfo, ['id', 'type', 'layout'])
        return {component, dataNode}
    })
}

function getVerticalsReferenceId(clientSpecMap, compInfo, captureError) {
    const applicationId = _.get(compInfo, 'data.applicationId')
    const appDefinitionName = _.get(clientSpecMap, [applicationId, 'appDefinitionName'])

    if (appDefinitionName === 'Wix Video') {
        const channelId = _.get(compInfo, 'style.style.properties.param_font_channelId')
        if (channelId) {
            try {
                return JSON.parse(channelId).replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, '$1-$2-$3-$4-$5')
            } catch (e) {
                captureError(e)
            }
        } else {
            return 'a1ca9dac-7ee4-4d52-a418-68329471105b'
        }
    }

    if (appDefinitionName === 'Wix Music') {
        const albumId = _.get(compInfo, 'style.style.properties.param_font_wmp::albumId')
        if (albumId) {
            try {
                return JSON.parse(albumId)[compInfo.style.style.propertiesSource['param_font_wmp::albumId']]
            } catch (e) {
                captureError(e)
            }
        } else {
            return '03a8f97f-c6ed-4f81-9fce-2a1c8086211b' // TODO @santa-ssr ask @peterk what's the default externalId cause this one is wrong
        }
    }

    return null
}

function removeFalsyTpaSeoMetaData(tpaData) {
    return _.flow([
        _getOr({}, 'applicationsSeoData'),
        _mapValues(tpa => ({
            ...tpa,
            compData: {
                ...tpa.compData,
                meta: _.pickBy(tpa.compData.meta, _.identity)}
        }))
    ])(tpaData)
}
