define(['santa-components'], function (santaComponents) {
    'use strict';

    return {
        propTypes: {
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired
        },
        getBaseUrl() { // eslint-disable-line complexity
            const appData = this.getAppData();
            if (appData.widgets) {
                const widgetId = this.props.compData.widgetId;
                const widgetData = appData.widgets[widgetId];

                if (widgetData) {
                    const isInDevMode = this.isInMobileDevMode && this.isInMobileDevMode();

                    if (this.isUnderMobileView()) {
                        const mobileReady = widgetData.mobileUrl && (isInDevMode || widgetData.mobilePublished);
                        return mobileReady ? widgetData.mobileUrl : widgetData.widgetUrl;
                    }

                    return widgetData.widgetUrl;
                }
            }
            //TODO - report data error bi
            return '';
        }
    };
});

