define(['lodash', 'warmupUtils', 'layout/util/layout'], function (_, warmupUtils, /** layout.layout */ layout) {
    'use strict';

    /** Override measure map with DOM dimensions, without being restricted to the component's structure dimensions **/

    function registerToPureDomMeasure(componentType, dims) {
        if (_.get(dims, 'height', true)) {
            layout.registerPureDomHeightMeasure(componentType);
        }

        if (_.get(dims, 'width', true)) {
            layout.registerPureDomWidthMeasure(componentType);
        }
    }

    function registerToMeasureDomMinHeight(componentType) {
        layout.registerAdditionalMeasureFunction(componentType, function (id, measureMap, nodesMap) {
            const node = nodesMap[id];
            measureMap.minHeight[id] = parseInt(node.style.minHeight, 10);
        }, 'pageMinHeight');
    }

    function registerToMeasureContainerHeightMargin(componentType) {
        layout.registerAdditionalMeasureFunction(componentType, function (id, measureMap, nodesMap) {
            measureMap.containerHeightMargin[id] = warmupUtils.measurerUtils.getContainerVerticalMarginFromContent(id, nodesMap[id]);
        }, 'containerHeightMargin');
    }

    registerToPureDomMeasure('wysiwyg.viewer.components.FlashComponent');
    registerToPureDomMeasure('wysiwyg.viewer.components.ExpandableMenu');
    registerToPureDomMeasure('wysiwyg.common.components.pinterestpinit.viewer.PinterestPinIt');
    registerToPureDomMeasure('wysiwyg.viewer.components.WGooglePlusOne');
    registerToPureDomMeasure('wysiwyg.viewer.components.LinkBar');
    registerToPureDomMeasure('wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer');
    registerToPureDomMeasure('wysiwyg.common.components.facebooklikebox.viewer.FacebookLikeBox');
    registerToPureDomMeasure('wysiwyg.viewer.components.PayPalButton');
    registerToPureDomMeasure('wysiwyg.viewer.components.PinterestFollow');
    registerToPureDomMeasure('wysiwyg.common.components.spotifyfollow.viewer.SpotifyFollow');
    registerToPureDomMeasure('wysiwyg.viewer.components.FlickrBadgeWidget');
    registerToPureDomMeasure('wysiwyg.viewer.components.WTwitterFollow');
    registerToPureDomMeasure('wysiwyg.viewer.components.WTwitterTweet');
    registerToPureDomMeasure('wysiwyg.viewer.components.LoginButton');
    registerToPureDomMeasure('wysiwyg.viewer.components.AdminLoginButton');
    registerToPureDomMeasure('wysiwyg.viewer.components.ItunesButton');
    registerToPureDomMeasure('wysiwyg.viewer.components.WFacebookLike');
    registerToPureDomMeasure('wysiwyg.viewer.components.VerticalLine', {width: false});
    registerToPureDomMeasure('wysiwyg.viewer.components.FlashComponent');
    registerToPureDomMeasure('wysiwyg.viewer.components.FacebookShare');
    registerToPureDomMeasure('wysiwyg.viewer.components.Group');
    registerToPureDomMeasure('wysiwyg.common.components.skypecallbutton.viewer.SkypeCallButton');
    registerToPureDomMeasure('platform.components.AppController');
    registerToPureDomMeasure('wysiwyg.viewer.components.VKShareButton');
    registerToPureDomMeasure('wysiwyg.common.components.youtubesubscribebutton.viewer.YouTubeSubscribeButton');
    registerToPureDomMeasure('wysiwyg.viewer.components.inputs.RadioGroup');
    registerToPureDomMeasure('wysiwyg.viewer.components.inputs.CheckboxGroup');
    registerToPureDomMeasure('tpa.viewer.components.Impress');
    registerToPureDomMeasure('tpa.viewer.components.Thumbnails');
    registerToPureDomMeasure('tpa.viewer.components.Honeycomb');
    registerToPureDomMeasure('tpa.viewer.components.Accordion');
    registerToPureDomMeasure('wysiwyg.viewer.components.tpapps.TPA3DGallery');
    registerToPureDomMeasure('wysiwyg.viewer.components.LanguageSelector');
    registerToPureDomMeasure('tpa.viewer.components.Masonry');
    registerToPureDomMeasure('tpa.viewer.components.Freestyle');
    registerToPureDomMeasure('wysiwyg.viewer.components.tpapps.TPA3DCarousel');
    registerToPureDomMeasure('wysiwyg.viewer.components.inputs.TextInput');
    registerToPureDomMeasure('wysiwyg.viewer.components.inputs.TextAreaInput');
    registerToPureDomMeasure('mobile.core.components.Page', {height: false});
    registerToPureDomMeasure('wixui.Tags', {width: false});
    registerToPureDomMeasure('wysiwyg.viewer.components.Repeater');
    registerToPureDomMeasure('wysiwyg.viewer.components.StateBox');
    registerToPureDomMeasure('wysiwyg.viewer.components.StateStrip');

    registerToMeasureDomMinHeight('mobile.core.components.Page');
    registerToPureDomMeasure('wixui.Pagination');
    registerToPureDomMeasure('wixui.RatingsDisplay');
    registerToPureDomMeasure('wixui.RatingsInput');

    registerToMeasureContainerHeightMargin('wysiwyg.viewer.components.ScreenWidthContainer');
    registerToMeasureContainerHeightMargin('wysiwyg.viewer.components.HeaderContainer');
    registerToMeasureContainerHeightMargin('wysiwyg.viewer.components.FooterContainer');
    registerToMeasureContainerHeightMargin('mobile.core.components.Page');
    registerToPureDomMeasure('tpa.viewer.components.Collage');

    registerToPureDomMeasure('wysiwyg.viewer.components.tpapps.TPAWidget', {width: false});
    registerToPureDomMeasure('wysiwyg.viewer.components.tpapps.TPASection', {width: false});
    registerToPureDomMeasure('wysiwyg.viewer.components.tpapps.TPAMultiSection', {width: false});
    registerToPureDomMeasure('wysiwyg.viewer.components.tpapps.TPAGluedWidget', {width: false});
    registerToPureDomMeasure('wixui.LineShareButton');

    return {};
});
