define([
    'lodash',
    'experiment'
], function (
    _,
    experiment
) {
    'use strict';

    const widgetTypes = {
        POPUP: 'Popup',
        PAGE: 'Page',
        MASTER_PAGE: 'masterPage',
        APPLICATION: 'Application'
    };

    function hasWixCode(rendererModel) {
        return !!_.get(rendererModel, ['wixCodeModel', 'appData', 'codeAppId']);
    }

    function joinURL() {
        let url = arguments[0];
        for (let i = 1; i < arguments.length; ++i) {
            url = `${url.replace(/\/$/, '')}/${arguments[i].replace(/^\//, '')}`;
        }
        return url;
    }

    function getSiteExtensionAppDataIfExist(clientSpecMap) {
        const wixCodeAppData = _.find(clientSpecMap, {type: 'siteextension'});
        if (wixCodeAppData && !_.get(wixCodeAppData, 'permissions.revoked')) {
            return wixCodeAppData;
        }
        return null;
    }

    function addWixCodeViewAppUrl(clientSpecMap, serviceTopology) {
        const wixCodeAppData = getSiteExtensionAppDataIfExist(clientSpecMap);
        const hasViewerScriptUrl = _.get(wixCodeAppData, 'appFields.platform.viewerScriptUrl');
        if (wixCodeAppData && !hasViewerScriptUrl) {
            const viewerScriptUrl = `${serviceTopology.scriptsLocationMap['wix-code-viewer-app']}/app.js`;
            _.set(wixCodeAppData, 'appFields.platform.viewerScriptUrl', viewerScriptUrl);
        }
    }

    function getArtifactUrl(serviceTopology, artifactName, version) {
        const artifactPath = joinURL(serviceTopology.scriptsDomainUrl, 'services', artifactName);
        if (version) {
            return joinURL(artifactPath, version);
        }

        return serviceTopology.scriptsLocationMap[artifactName];
    }

    function addSiteExtensionsApps(clientSpecMap, serviceTopology, viewerPlatformAppVersions, rendererModel) {
        const wixCodeCsmEntry = getSiteExtensionAppDataIfExist(clientSpecMap);
        const wixCodeInSite = hasWixCode(rendererModel) && wixCodeCsmEntry;
        if (experiment.isOpen('sv_moveWixCodeToViewerApp', {rendererModel}) && wixCodeInSite) {
            addWixCodeViewAppUrl(clientSpecMap, serviceTopology);
        }
        if (wixCodeInSite && !_.find(clientSpecMap, {appDefinitionId: 'dataBinding'})) {
            const baseViewerUrlPath = getArtifactUrl(serviceTopology, 'dbsm-viewer-app', viewerPlatformAppVersions.dataBinding);
            return _.assign({}, clientSpecMap, {
                // we don't use this application ID anywhere, it's only so we can add a fake entry to the spec map
                fakeApplicationId: {
                    appDefinitionId: 'dataBinding',
                    appFields: {
                        platform: {
                            viewerScriptUrl: joinURL(baseViewerUrlPath, '/app.js'),
                            viewerVerboseScriptUrl: joinURL(baseViewerUrlPath, '/app.verbose.js')
                        }
                    },
                    applicationId: 'fakeApplicationId',
                    type: widgetTypes.APPLICATION,
                    instance: wixCodeCsmEntry.instance,
                    instanceId: wixCodeCsmEntry.instanceId
                }
            });
        }

        return clientSpecMap;
    }

    return {
        widgetTypes,
        joinURL,
        hasWixCode,
        addSiteExtensionsApps,
        getSiteExtensionAppDataIfExist
    };
});





