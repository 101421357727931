'use strict'

const ViewPortBatchedImageLoader = require('./ViewPortBatchedImageLoader')

const isInFirstViewPort = image => image.viewPort.startIndex === 0

// "Constructor function" to mimic old ViewPortImageLoader behaviour using the new BatchedImageLoader
function generateViewPortImageLoader(getScreenSize, scrollTop = 0, browserFlags, imagesBi) {
    return new ViewPortBatchedImageLoader(getScreenSize,
        isInFirstViewPort,
        imageData => {
            if (imageData.viewPort.isInCurrentViewPort) {
                return ViewPortBatchedImageLoader.IMMEDIATE_LOAD_RANK
            }
            return 1
        }, 1, scrollTop, browserFlags, imagesBi)
}

module.exports = generateViewPortImageLoader

