'use strict'

const React = require('react')
const PropTypes = require('prop-types')
const _ = require('lodash')

class WixImageReact extends React.Component {
    constructor(props) { // eslint-disable-line no-useless-constructor
        super(props)
    }

    render() {
        const imageInfo = _.pick(this.props, ['imageData', 'containerId', 'alignType', 'displayMode'])
        //todo: remove this when displayMode as imageData prop is no longer needed in patchNodeImage impl.
        imageInfo.imageData.displayMode = imageInfo.displayMode
        return React.createElement('wix-image', {
            id: `${this.props.id}Element`,
            style: this.props.style,
            'data-style': this.props['data-style'],
            'data-image-zoomed': this.props.isImageZoomed,
            'data-has-bg-scroll-effect': this.props['data-has-bg-scroll-effect'],
            'data-image-info': JSON.stringify(imageInfo)
        }, this.props.images)
    }
}

WixImageReact.displayName = 'WixImageReact'
WixImageReact.propTypes = {
    id: PropTypes.string,
    images: PropTypes.oneOfType([
        PropTypes.array.isRequired,
        PropTypes.object.isRequired
    ]),
    imageData: PropTypes.object.isRequired,
    containerId: PropTypes.string.isRequired,
    alignType: PropTypes.string,
    displayMode: PropTypes.string,
    style: PropTypes.object,
    isImageZoomed: PropTypes.bool,
    'data-style': PropTypes.string,
    'data-has-bg-scroll-effect': PropTypes.string
}

module.exports = WixImageReact