define(['zepto', 'layout/util/layout'], function ($, layout) {
    'use strict';

    let browserScrollbarWidth;

    function getScrollBarWidth() {
        const $outer = $('<div>').css({visibility: 'hidden', width: 100, overflow: 'scroll'}).appendTo('body');
        const widthWithScroll = $('<div>').css({width: '100%'}).appendTo($outer).width();
        $outer.remove();
        return 100 - widthWithScroll;
    }

    function shouldFixBodySize(isTabletDevice, documentType) {
        return isTabletDevice && documentType === 'WixSite';
    }

    function patchBody(patchers, measureMap) {
        patchers.css('body', {
            width: measureMap.width.body
        });
    }

    function measureBody(nodesMap, measureMap) {
        browserScrollbarWidth = browserScrollbarWidth || getScrollBarWidth();

        const clientWidth = window.document.body.clientWidth;
        const innerWidth = window.innerWidth;
        let bodyWidth = clientWidth;
        if (innerWidth > clientWidth + browserScrollbarWidth) {
            bodyWidth = innerWidth;
        }

        nodesMap.body = window.document.body;
        measureMap.width.body = bodyWidth;
    }

    function patchSite(id, patchers, measureMap, structureInfo, siteData) {
        if (shouldFixBodySize(siteData.isTabletDevice(), siteData.rendererModel.siteInfo.documentType)) {
            patchBody(patchers, measureMap);
        }
    }

    function measureSite(id, measureMap, nodesMap, structureInfo, {isTabletDevice, documentType}) {
        if (shouldFixBodySize(isTabletDevice(), documentType)) {
            measureBody(nodesMap, measureMap);
        }
    }

    layout.registerCustomMeasure('mobile.core.components.MasterPage', measureSite);
    layout.registerPatcher('mobile.core.components.MasterPage', patchSite);
});
