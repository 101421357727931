/**
 * Created by eitanr on 6/17/14.
 */
define(['lodash'], function (_) {
    'use strict';
    let ajaxFn = function () {
        throw new Error(
            'must register ajax function: zepto $.ajax for clientSide, or request() for serverSide'
        );
    };

    function register(ajaxMethod) {
        if (typeof ajaxMethod !== 'function') {
            throw new Error('ajaxMethod must be a function');
        }
        ajaxFn = ajaxMethod;
    }

    return {
        register,
        ajax(options) {
            if (options.asForm) {
                options.contentType = 'application/x-www-form-urlencoded';
            } else if (options.type === 'POST' && options.dataType === 'json' && _.isObject(options.data) && !options.asForm) {
                _.assign(options, {
                    data: JSON.stringify(options.data),
                    contentType: options.contentType || 'application/json; charset=utf-8',
                    processData: false
                });
            }
            return ajaxFn(options);
        },
        // FIXME - need to be removed (seems to be unused)
        get(url, data, success, dataType) {
            if (typeof url === 'object') {
                return ajaxFn(url);
            }

            const options = {
                url
            };
            if (data) {
                options.data = data;
            }
            if (success) {
                options.success = success;
            }
            if (dataType) {
                options.dataType = dataType;
            }
            return ajaxFn(options);
        }
    };
});
