export const name = 'FullScreenAspect'
const mediaZoomClass = 'media-zoom-mode'

export const defaultModel = {
    htmlClasses: [],
    bodyClasses: []
}

export const functionLibrary = {
    enterFullScreenMode: (fullScreenParams, options) => {
        const classesToAdd = ['fullScreenMode']
        if (!options || options.scrollable) {
            classesToAdd.push('fullScreenMode-scrollable')
        }

        if (fullScreenParams.ssr.isInSSR) {
            classesToAdd.forEach(className => fullScreenParams.ssr.addBodyClass(0, 0, className))
        } else {
            classesToAdd.forEach(className => fullScreenParams.window.document.body.classList.add(className))
        }
    },
    exitFullScreenMode: fullScreenParams => {
        if (fullScreenParams.ssr.isInSSR) {
            fullScreenParams.warn('SiteAPI.exitFullScreenMode should only be called from client specific code!')
            return
        }

        fullScreenParams.window.document.body.classList.remove('fullScreenMode')
        fullScreenParams.window.document.body.classList.remove('fullScreenMode-scrollable')
    },
    enterMediaZoomMode: ({window, ssr}) => ssr.isInSSR ? ssr.addHtmlClass(0, 0, mediaZoomClass) : window.document.documentElement.classList.add(mediaZoomClass),
    exitMediaZoomMode: ({window, ssr, warn}) => ssr.isInSSR ?
        warn('SiteAPI.exitMediaZoomMode should only be called from client specific code!') :
        window.document.documentElement.classList.remove(mediaZoomClass)
}


