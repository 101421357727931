import _ from 'lodash'

const HANDLER_PREFIX = {
    TPA_PUB_SUB_PREFIX: 'TPA_PUB_SUB_',
    TPA_WORKER_PREFIX: 'tpaWorker_'
}

const PERMITTED_FULL_SCREEN_TPAS_IN_MOBILE = {
    WIX_CHAT: '14517e1a-3ff0-af98-408e-2bd6953c36a2'
}

const INTENTS = {
    TPA_RESPONSE: 'TPA_RESPONSE',
    TPA_MESSAGE: 'TPA',
    TPA_MESSAGE2: 'TPA2'
}
const SUPER_APPS_HANDLERS = {
    getCtToken: true,
    refreshCurrentMember: true
}

const PUBLIC_DATA_SCOPE = {
    APP: 'APP',
    COMPONENT: 'COMPONENT'
}

const QUAB_COMP = {
    ID: 'QUICK_ACTION_BAR',
    BEHAVIOR_METHOD: 'notifyWidget'

}

const NULL_RETURN_VALUE = 'nullReturnValue'

const TPA_COMP_TYPES = {
    TPA_SECTION: 'wysiwyg.viewer.components.tpapps.TPASection',
    TPA_MULTI_SECTION: 'wysiwyg.viewer.components.tpapps.TPAMultiSection',
    TPA_WIDGET: 'wysiwyg.viewer.components.tpapps.TPAWidget',
    TPA_GLUED_WIDGET: 'wysiwyg.viewer.components.tpapps.TPAGluedWidget',
    TPA_WORKER: 'tpa.viewer.classes.TPAWorker'
}

const COMP_TYPES = _.assign({
    TPA_WIDGET_NATIVE: 'wysiwyg.viewer.components.tpapps.TPAWidgetNative',
    TPA_MODAL: 'wysiwyg.viewer.components.tpapps.TPAModal',
    TPA_POPUP: 'wysiwyg.viewer.components.tpapps.TPAPopup',
    TPA_GALLERY_Masonry: 'tpa.viewer.components.Masonry',
    TPA_GALLERY_Accordion: 'tpa.viewer.components.Accordion',
    TPA_GALLERY_Impress: 'tpa.viewer.components.Impress',
    TPA_GALLERY_Freestyle: 'tpa.viewer.components.Freestyle',
    TPA_GALLERY_Collage: 'tpa.viewer.components.Collage',
    TPA_GALLERY_Honeycomb: 'tpa.viewer.components.Honeycomb',
    TPA_GALLERY_StripShowcase: 'tpa.viewer.components.StripShowcase',
    TPA_GALLERY_StripSlideshow: 'tpa.viewer.components.StripSlideshow',
    TPA_GALLERY_Thumbnails: 'tpa.viewer.components.Thumbnails',
    TPA_GALLERY_TPA3DGallery: 'wysiwyg.viewer.components.tpapps.TPA3DGallery',
    TPA_GALLERY_TPA3DCarousel: 'wysiwyg.viewer.components.tpapps.TPA3DCarousel'
}, TPA_COMP_TYPES)

const TPA_COMP_TYPE_PREFIX = 'wysiwyg.viewer.components.tpapps'

const APPS = {
    PRO_GALLERY: {
        GALLERY_WIDGET_ID: '142bb34d-3439-576a-7118-683e690a1e0d'
    }
}

export {
    HANDLER_PREFIX,
    NULL_RETURN_VALUE,
    COMP_TYPES,
    TPA_COMP_TYPES,
    INTENTS,
    SUPER_APPS_HANDLERS,
    PUBLIC_DATA_SCOPE,
    TPA_COMP_TYPE_PREFIX,
    QUAB_COMP,
    PERMITTED_FULL_SCREEN_TPAS_IN_MOBILE,
    APPS
}
