define(['lodash'], function (_) {
    'use strict';

    function isDynamicPage(routers, pageId) {
        return _.some(routers, function (routerData) {
            return _.includes(_.values(routerData.pages), pageId);
        });
    }

    return {
        isDynamicPage
    };
});
