const React = require('react')
const PropTypes = require('prop-types')
const {utils: {createSantaTypesDefinitions}} = require('santa-components')

const id = 'RESPONSIVE_STYLES_RENDERER'
/**
 * @class components.ResponsiveStylesRenderer
 */
class ResponsiveStylesRenderer extends React.Component {
    render() {
        const {layoutCss} = this.props
        return (
            <div id={id} key={id}>
                <style>
                    {layoutCss.join('\n')}
                </style>
            </div>
        )
    }
}

ResponsiveStylesRenderer.compType = id
ResponsiveStylesRenderer.displayName = 'ResponsiveStylesRenderer'

ResponsiveStylesRenderer.santaTypesDefinitions = createSantaTypesDefinitions({
    layoutCss: PropTypes.array
}, ResponsiveStylesRenderer.displayName)

ResponsiveStylesRenderer.propTypes = {
    layoutCss: ResponsiveStylesRenderer.santaTypesDefinitions.layoutCss
}

module.exports = ResponsiveStylesRenderer
