define(['santa-core-utils'], function (coreUtilsLib) {
    'use strict';

    return {
        getMediaUrl(serviceTopology, mediaPath) {
            const baseUrl = serviceTopology && serviceTopology.scriptsDomainUrl || 'https://static.parastorage.com/'; // eslint-disable-line no-mixed-operators
            return coreUtilsLib.urlUtils.joinURL(baseUrl, 'services', 'santa-resources', 'resources', 'viewer', mediaPath);
        }
    };
});
