import _ from 'lodash'
import {resolveData} from '../services/resolveData'

const overrideTypeToRuntimeDalMethod = {
    data: 'setCompData',
    props: 'setCompProps',
    layout: 'updateCompLayout',
    design: 'setCompDesign',
    style: 'updateCompStyle'
}

const resolveDataOverrides = (runtimeDal, compId, overrides, resolverGeneralInfo) => {
    const dataToResolve = {...runtimeDal.getCompData(compId), ...overrides}
    return resolveData(runtimeDal, compId, dataToResolve, resolverGeneralInfo)
}


export const handleBatchCommand = (runtimeDal, batchData, resolverGeneralInfo, shouldResolveData) => {
    _.forOwn(batchData, (overridesByType, compId) => {
        _.forOwn(overridesByType, (overrides, overrideType) => {
            if (overrideType === 'registerEvent') {
                _.forEach(overrides, componentEvent => {
                    runtimeDal.registerComponentEvent(compId, componentEvent)
                })
                return
            }

            const dataToSet = shouldResolveData && overrideType === 'data' ? //bv_runtimeEventsStore - remove resolving when merging
                resolveDataOverrides(runtimeDal, compId, overrides, resolverGeneralInfo) :
                overrides

            runtimeDal[overrideTypeToRuntimeDalMethod[overrideType]](compId, dataToSet, true)
        })
    })
}
