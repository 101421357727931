export const name = 'pinterestWidgetAspect'

export const defaultModel = {
    componentsData: {}
}


const handlePinterestMessage = (event, aspectActions) => {
    let msg
    try {
        msg = JSON.parse(event.data)
        if (msg.type !== 'pinterest') {
            return
        }

        let pinData = {}
        if (msg.showError) {
            pinData = {
                shouldShowError: 'error'
            }
        } else {
            pinData = {
                shouldShowError: 'noError',
                iframeDimensions: {height: msg.height, width: msg.width}
            }
        }
        aspectActions.setComponentsData(msg.compId, pinData)
    } catch (e) {
        //ignore
    }
}

export const functionLibrary = {

}

export function init(aspectActions, {eventsManager}) {
    eventsManager.on('windowMessage', event => {
        handlePinterestMessage(event, aspectActions)
    })
}
