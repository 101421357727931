const customLayoutElementsDefinition = require('./customLayoutElementsDefinition')
let warmupUtils // eslint-disable-line santa/no-module-state
let warmupUtilsLib // eslint-disable-line santa/no-module-state
let layout // eslint-disable-line santa/no-module-state
let _ // eslint-disable-line santa/no-module-state
let reactDOM // eslint-disable-line santa/no-module-state
let siteData // eslint-disable-line santa/no-module-state

function init({layoutData, dependencies, isExperimentOpen, requestRelayout, isWarmup = false}) {
    warmupUtils = dependencies.warmupUtils
    warmupUtilsLib = dependencies.warmupUtilsLib
    layout = dependencies.layout
    _ = dependencies.lodash
    reactDOM = reactDOM || dependencies.reactDOM

    if (!(isWarmup && siteData)) {
        createSiteData(layoutData, isWarmup)
        if (isExperimentOpen('bv_wixImage') || isExperimentOpen('bv_wixImagePhaseTwo')) {
            defineCustomLayoutElements(layoutData, isExperimentOpen, requestRelayout)
        }
    }

    return {
        imageLoader: siteData.imageLoader,
        isMobileDevice: siteData.isMobileDevice(),
        runLayout: runLayout(),
        registerLayoutFunc
    }
}

function createPagesDataForWarmup(layoutData) {
    if (layoutData.isMobileView) {
        return _.mapValues(layoutData.displayedPagesData, ({data, structure}) =>
            ({data, structure: {DESKTOP: structure.DESKTOP, MOBILE: structure.DESKTOP}}))
    }
    return layoutData.displayedPagesData
}

function createSiteData(layoutData, isWarmup) {
    const {SiteData} = warmupUtils
    const siteModel = _.assign({}, _.pick(layoutData, ['documentServicesModel', 'publicModel', 'rendererModel', 'serviceTopology', 'requestModel', 'wixBiSession', 'currentUrl', 'ssr']))
    const pagesData = isWarmup ? createPagesDataForWarmup(layoutData) : layoutData.displayedPagesData
    siteData = new SiteData(_.assign({}, siteModel, {pagesData}))
    delete siteData.resolvedDataMaps
    siteData.anchorsMap = layoutData.anchorsMap
    siteData.setRootNavigationInfo(layoutData.primaryPageNavigationInfo, true)
    siteData.pagesData = pagesData
    siteData.pagesDataRaw = {pagesData: siteData.pagesData}
    siteData.isMobileView = () => layoutData.isMobileView
    siteData.tpasRenderedInSsr = {}
    siteData.getRequestedLayoutMechanism = () => _.get(layoutData.currentUrl, ['query', 'layoutMechanism'])
    siteData.getSiteMemberDetails = () => layoutData.siteMemberDetails
    siteData.getDataByQuery = (query, rootId, dataType) => {
        rootId = rootId || 'masterPage'
        dataType = dataType || 'document_data'
        query = query.replace('#', '')
        if (pagesData[rootId].data[dataType][query]) {
            return pagesData[rootId].data[dataType][query]
        }
        for (rootId of siteData.getAllPossiblyRenderedRoots()) {
            if (pagesData[rootId] && pagesData[rootId].data[dataType][query]) {
                return pagesData[rootId].data[dataType][query]
            }
        }
        for (rootId of Object.keys(pagesData)) {
            if (pagesData[rootId] && pagesData[rootId].data[dataType][query]) {
                return pagesData[rootId].data[dataType][query]
            }
        }
        return null
    }
    siteData.getMasterPageDataByQuery = (query, dataType) => {
        query = query.replace('#', '')
        dataType = dataType || 'document_data'
        return pagesData.masterPage.data[dataType][query]
    }

    const {wixBiSession} = layoutData
    siteData.biData.getPageNumber = () => wixBiSession.pn
}

function defineCustomLayoutElements(layoutData, isExperimentOpen) {
    const {defineWixImage} = customLayoutElementsDefinition.init({warmupUtilsLib})
    const environmentConsts = _.assign({
        staticMediaUrl: layoutData.serviceTopology.staticMediaUrl,
        mediaRootUrl: layoutData.serviceTopology.mediaRootUrl,
        experiments: {
            sv_image_name_url: isExperimentOpen('sv_image_name_url')
        }
    }, _.pick(layoutData, ['currentUrl', 'isViewerMode', 'devicePixelRatio']))
    defineWixImage(environmentConsts)
}

function createLayoutApi(allRenderedRootIds) {
    const {layoutAPI} = warmupUtils
    siteData.getAllPossiblyRenderedRoots = () => allRenderedRootIds

    return layoutAPI({
        getSiteData() {
            return siteData
        },
        getAllRenderedRootIds() {
            return allRenderedRootIds
        },
        isSiteBusyIncludingTransition() {
            return false
        }
    })
}

function getReactRef(component, childRefName) {
    const componentReactRef = _.get(component, ['refs', childRefName])
    return componentReactRef
}

function postWarmupGetDomNode(compRefs) {
    return function (a, b, c, d, e, f) {
        if (a && !b) {
            const node = window.document.getElementById(a)
            if (node) {
                return node
            }
        }
        let ref = _.get(compRefs, a)
        ref = ref && b ? getReactRef(ref, b) : ref
        ref = ref && c ? getReactRef(ref, c) : ref
        ref = ref && d ? getReactRef(ref, d) : ref
        ref = ref && e ? getReactRef(ref, e) : ref
        ref = ref && f ? getReactRef(ref, f) : ref
        return ref && reactDOM.findDOMNode(ref) //eslint-disable-line react/no-find-dom-node
    }
}

function warmupGetDomNode(...args) {
    const domId = args.join('')
    let node = window.document.getElementById(domId)
    if (!node) {
        const compId = args[0]
        const fix = subId => subId.replace(compId, '')
        node = window.document.getElementById(`${compId}${_(args).tail().map(fix).join('')}`)
        node = node || window.document.getElementById(_(args).uniq().join(''))
        node = node || window.document.getElementById(_.last(args))
    }
    return node
}

function getStructuresDesc(layoutAPI, isPageAllowed, isWarmup, compRefs) {
    const findDomNode = isWarmup ? warmupGetDomNode : postWarmupGetDomNode(compRefs)

    const structuresDesc = _.mapValues(layoutAPI.ssr.aspectsComponentStructures, comp => ({
        structure: comp,
        getDomNodeFunc: warmupGetDomNode
    }))

    const currentPopupId = layoutAPI.getCurrentPopupId()
    if (compRefs) {
        _.assign(structuresDesc, {
            inner: compRefs[layoutAPI.getPrimaryPageId()] && warmupUtils.structuresDescription.getInner(layoutAPI, findDomNode),
            outer: compRefs.masterPage && warmupUtils.structuresDescription.getOuter(layoutAPI, findDomNode),
            siteBackground: compRefs.SITE_BACKGROUND && warmupUtils.structuresDescription.getSiteBackground(findDomNode),
            wixAds: compRefs.WIX_ADS && warmupUtils.structuresDescription.getWixAds(layoutAPI),
            [currentPopupId]: compRefs[currentPopupId] && warmupUtils.structuresDescription.getRootDescriptor(layoutAPI, currentPopupId, findDomNode)
        })
    } else {
        if (currentPopupId) {
            structuresDesc[currentPopupId] = warmupUtils.structuresDescription.getRootDescriptor(layoutAPI, currentPopupId, findDomNode)
        }

        if (isPageAllowed) {
            _.assign(structuresDesc, {
                inner: warmupUtils.structuresDescription.getInner(layoutAPI, findDomNode),
                outer: warmupUtils.structuresDescription.getOuter(layoutAPI, findDomNode),
                siteBackground: warmupUtils.structuresDescription.getSiteBackground(findDomNode),
                wixAds: warmupUtils.structuresDescription.getWixAds(layoutAPI)
            })
        }
    }

    return _.pickBy(structuresDesc, _.identity)
}

function runLayout() {
    return function ({currentNavigationInfo, allRenderedRootIds, isPageAllowed, compRefs} = {}, onReady = _.noop, isWarmup = false) {
        if (currentNavigationInfo) {
            siteData.setRootNavigationInfo(currentNavigationInfo, true)
        }

        const pageId = siteData.getRootNavigationInfo().pageId
        const renderedRootIds = isWarmup ? ['masterPage', pageId] : allRenderedRootIds
        const layoutAPI = createLayoutApi(renderedRootIds)
        const shouldRenderPage = _.get(layoutAPI, 'ssr.shouldRenderPage', true)
        // TODO make sure structureDesc has right structure
        const structuresDesc = shouldRenderPage ? getStructuresDesc(layoutAPI, isPageAllowed, isWarmup, compRefs) : {}

        if (isWarmup) {
            warmupUtils.mobileViewportFixer.fixViewportTag(layoutAPI)
            layout.reportPresetIframes(layoutAPI)
        }

        layout.updateBodyNodeStyle(layoutAPI)
        siteData.updateScreenSize()

        // TODO add anchorsMap to siteData
        const reLayoutResult = layout.reLayout(structuresDesc, null, null, null, layoutAPI)
        _.assign(siteData.reLayoutedCompsMap, reLayoutResult.reLayoutedCompsMap)

        if (shouldRenderPage && isWarmup && layoutAPI.getLayoutMechanism() === 'anchors') {
            _(renderedRootIds)
                .map(id => warmupGetDomNode(id))
                .compact()
                .forEach(({style}) => {
                    style.visibility = ''
                })
        }

        onReady()

        return {
            measureMap: layoutAPI.measureMap
        }
    }
}

function registerLayoutFunc(domNode, func) {
    siteData.registerLayoutFunc(domNode, func)
}

module.exports = {
    init
}
