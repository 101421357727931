import {withActions} from '../withActions'

export const name = 'WindowFocusAspect'

export const Events = {
    Blur: 'blur'
}

export const defaultModel = {
    listeners: {
        [Events.Blur]: {}
    }
}

export const functionLibrary = {
    registerToFocusEvent: withActions(({windowFocusSetListener}, type, reactComp) => {
        verifyEvent(type)
        windowFocusSetListener(type, reactComp.props.id, reactComp)
    }),

    unregisterToFocusEvent: withActions(({windowFocusSetListener}, type, reactComp) => {
        verifyEvent(type)
        windowFocusSetListener(type, reactComp.props.id, undefined)
    }),

    onBlurEvent: ({blurListeners}) => {
        Object.values(blurListeners).forEach(comp => comp.onBlur())
    }
}

export function init({}, {
    eventsManager,
    initialData: {onBlurEventTrigger}
}) {
    eventsManager.on('windowBlur', onBlurEventTrigger)
}

function verifyEvent(event) {
    const validEvent = Object.values(Events).some(e => e === event)

    if (!validEvent) {
        throw new Error(`${event} event does not exist!`)
    }
}
