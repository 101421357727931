export const name = 'SiteMembersBaseAspect'

export const defaultModel = {
    approvedPasswordPages: {},
    pagesJsonFileName: {}
}

export const functionLibrary = {
    isPageAllowed: (allowedPages, pageId) => allowedPages[pageId] !== false
}

export function init() {
}
