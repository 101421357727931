define(['lodash', 'layout/util/layout', 'layout/specificComponents/imageLayout'], function (_, layout, imageLayout) {
    'use strict';

    function getChildrenIdToMeasure(id, nodesMap, structureInfo) {
        return [{
            type: 'core.components.Image',
            pathArray: [_.get(structureInfo, ['dataItem', 'image', 'id'])]
        }];
    }

    function patchRssButton(id, patchers, measureMap, structureInfo, siteData) {
        const containerDimensions = _.get(structureInfo, 'layout');

        const imageData = _.get(structureInfo, ['dataItem', 'image']);
        _.assign(imageData, {
            displayMode: 'full'
        });

        imageLayout.patchNodeImage(id + _.get(imageData, 'id'), patchers, measureMap, siteData, imageData, containerDimensions);
    }

    layout.registerRequestToMeasureChildren('wysiwyg.common.components.rssbutton.viewer.RSSButton', getChildrenIdToMeasure);
    layout.registerPatcher('wysiwyg.common.components.rssbutton.viewer.RSSButton', patchRssButton);
});
