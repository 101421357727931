const dateWrapperKey = 'WixCodeDate'

function dateReplacer(key, val) {
    if (this[key] instanceof Date) {
        return {[dateWrapperKey]: val}
    }
    return val
}

function dateReviver(key, value) {
    if (value && typeof value === 'object' && value.hasOwnProperty(dateWrapperKey)) {
        return new Date(value[dateWrapperKey])
    }
    return value
}

/**
 * we clone the start message because when this message is mutated on the worker empty arrays have a shared reference (its a carmi optimisation)
 */
export const sendStartMessage = (wixCodeAppAPI, contextPageId, contextModel) => {
    const message = {
        context: contextModel,
        type: 'wix_code_worker_start',
        id: contextPageId
    }
    const clonedMessage = JSON.parse(JSON.stringify(message, dateReplacer), dateReviver)
    wixCodeAppAPI.sendMessage(clonedMessage)
}
