/* Autogenerated file. Do not modify */
'use strict'
const skins = {}
 skins['wysiwyg.viewer.skins.VerticalLineSkin'] = {
  "react": [
    [
      "div",
      "lineCnt",
      [],
      {},
      [
        "div",
        "line",
        [],
        {}
      ]
    ],
    [
      "div",
      "topKnobCnt",
      [],
      {},
      [
        "div",
        "topKnob",
        [],
        {}
      ]
    ],
    [
      "div",
      "middleKnobCnt",
      [],
      {},
      [
        "div",
        "middleKnob",
        [],
        {}
      ]
    ],
    [
      "div",
      "bottomKnobCnt",
      [],
      {},
      [
        "div",
        "bottomKnob",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "webThemeDir": "URL"
  },
  "paramsDefaults": {
    "webThemeDir": "WEB_THEME_DIRECTORY"
  },
  "css": {
    "%": "max-height:2000px;",
    "%lineCnt": "position:absolute;bottom:4px;left:50%;width:70%;margin-left:-4px;top:4px;cursor:n-resize;",
    "%line": "background-image:url([webThemeDir]v-line.png);background-repeat:repeat-y;width:100%;height:100%;",
    "%topKnob": "background-image:url([webThemeDir]square.png);background-repeat:no-repeat;width:100%;height:100%;background-size:100% 100%;",
    "%middleKnob": "background-image:url([webThemeDir]square.png);background-repeat:no-repeat;width:100%;height:100%;background-size:100% 100%;",
    "%bottomKnob": "background-image:url([webThemeDir]square.png);background-repeat:no-repeat;width:100%;height:100%;background-size:100% 100%;",
    "%middleKnobCnt": "position:absolute;top:50%;left:50%;margin-top:-4px;margin-left:-4px;height:9px;width:70%;",
    "%topKnobCnt": "position:absolute;left:50%;top:4px;margin-left:-4px;height:9px;width:70%;",
    "%bottomKnobCnt": "position:absolute;left:50%;margin-left:-4px;bottom:2px;height:9px;width:70%;"
  }
}
 skins['wysiwyg.viewer.skins.line.VerticalArrowLine'] = {
  "react": [
    [
      "div",
      "line",
      [],
      {},
      [
        "div",
        null,
        [
          "_arr"
        ],
        {}
      ]
    ]
  ],
  "params": {
    "brd": "BORDER_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "brd": "color_15"
  },
  "css": {
    "%": "max-height:2000px;border-left:1px solid [brd];width:0 !important;min-width:0 !important;",
    "%_arr": "bottom:0;left:-5px;bottom:-2px;border-right:5px solid transparent;border-left:5px solid transparent;border-top:10px solid [brd];position:absolute;"
  }
}
 skins['wysiwyg.viewer.skins.line.VerticalArrowLineTop'] = {
  "react": [
    [
      "div",
      "line",
      [],
      {},
      [
        "div",
        null,
        [
          "_arr"
        ],
        {}
      ]
    ]
  ],
  "params": {
    "brd": "BORDER_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "brd": "color_15"
  },
  "css": {
    "%": "max-height:2000px;border-left:1px solid [brd];width:0 !important;min-width:0 !important;",
    "%_arr": "left:-5px;top:-2px;border-right:5px solid transparent;border-left:5px solid transparent;border-bottom:10px solid [brd];position:absolute;"
  }
}
 skins['wysiwyg.viewer.skins.line.VerticalDashedLine'] = {
  "react": [
    [
      "div",
      "line",
      [],
      {}
    ]
  ],
  "params": {
    "lnw": "BORDER_LEFT_SIZE",
    "brd": "COLOR_ALPHA",
    "mrg": "SIZE"
  },
  "paramsDefaults": {
    "lnw": "3px",
    "brd": "color_15",
    "mrg": [
      "lnw"
    ]
  },
  "paramsMutators": {
    "mrg": {
      "type": "multiply",
      "value": -0.5,
      "param": "lnw"
    }
  },
  "css": {
    "%": "max-height:2000px;width:0 !important;min-width:0 !important;",
    "%line": "border-left:[lnw] dashed [brd];margin-left:[mrg];width:100%;height:100%;"
  }
}
 skins['wysiwyg.viewer.skins.line.VerticalDottedLine'] = {
  "react": [
    [
      "div",
      "line",
      [],
      {}
    ]
  ],
  "params": {
    "lnw": "BORDER_LEFT_SIZE",
    "brd": "COLOR_ALPHA",
    "mrg": "SIZE"
  },
  "paramsDefaults": {
    "lnw": "3px",
    "brd": "color_15",
    "mrg": [
      "lnw"
    ]
  },
  "paramsMutators": {
    "mrg": {
      "type": "multiply",
      "value": -0.5,
      "param": "lnw"
    }
  },
  "css": {
    "%": "max-height:2000px;width:0 !important;min-width:0 !important;",
    "%line": "border-left:[lnw] dotted [brd];margin-left:[mrg];width:100%;height:100%;"
  }
}
 skins['wysiwyg.viewer.skins.line.VerticalDoubleLine'] = {
  "react": [
    [
      "div",
      "line",
      [],
      {}
    ]
  ],
  "params": {
    "dst": "WIDTH_SIZE",
    "lnw1": "BORDER_LEFT_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "mrg1": "BORDER_LEFT_SIZE",
    "lnw2": "BORDER_RIGHT_SIZE"
  },
  "paramsDefaults": {
    "dst": "5px",
    "lnw1": "3px",
    "brd": "color_15",
    "mrg1": [
      "lnw1"
    ],
    "lnw2": "1px"
  },
  "css": {
    "%": "max-height:2000px;width:[dst] !important;min-width:[dst] !important;",
    "%line": "border-left:[lnw1] solid [brd];margin-left:calc((-1 * [mrg1]) - 2px);border-right:[lnw2] solid [brd];width:100%;height:100%;"
  }
}
 skins['wysiwyg.viewer.skins.line.VerticalFadeNotchLeftLine'] = {
  "react": [
    [
      "div",
      "line",
      [],
      {}
    ]
  ],
  "params": {
    "tdr": "URL"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%": "max-height:2000px;",
    "%:before": "background-image:url([tdr]fadenotchlineverticalnew.png);background-position:-60px 0;position:absolute;content:\"\";right:0;top:0;width:30px;height:30px;",
    "%:after": "background-image:url([tdr]fadenotchlineverticalnew.png);background-position:60px 100%;position:absolute;content:\"\";right:0;bottom:0;width:30px;height:30px;",
    "%line": "background-image:url([tdr]fadenotchlineverticalnew.png);background-position:-90px 0;background-repeat:repeat-y;width:30px;right:0;top:30px;bottom:30px;position:absolute;content:\"\";"
  }
}
 skins['wysiwyg.viewer.skins.line.VerticalFadeNotchRightLine'] = {
  "react": [
    [
      "div",
      "line",
      [],
      {}
    ]
  ],
  "params": {
    "tdr": "URL"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%": "max-height:2000px;",
    "%:before": "background-image:url([tdr]fadenotchlineverticalnew.png);background-position:-30px 0;position:absolute;content:\"\";top:0;width:30px;height:30px;",
    "%:after": "background-image:url([tdr]fadenotchlineverticalnew.png);background-position:-30px 100%;position:absolute;content:\"\";bottom:0;width:30px;height:30px;",
    "%line": "background-image:url([tdr]fadenotchlineverticalnew.png);background-position:0 0;background-repeat:repeat-y;width:30px;position:absolute;content:\"\";top:30px;bottom:30px;"
  }
}
 skins['wysiwyg.viewer.skins.line.VerticalIronLine'] = {
  "react": [
    [
      "div",
      "line",
      [],
      {}
    ]
  ],
  "params": {
    "lnw": "WIDTH_SIZE",
    "clr": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "lnw": "6px",
    "clr": "color_15"
  },
  "css": {
    "%": "max-height:2000px;",
    "%line": "box-shadow:inset 1px 1px 1px 1px rgba(0, 0, 0, 0.25), 1px 1px 0 0 rgba(255, 255, 255, 0.1);min-width:2px;width:[lnw];border-radius:7px;background-color:[clr];position:absolute;top:0;bottom:0;"
  }
}
 skins['wysiwyg.viewer.skins.line.VerticalNotchDashedLine'] = {
  "react": [
    [
      "div",
      "line",
      [],
      {}
    ]
  ],
  "params": {
    "lnw": "BORDER_SIZE"
  },
  "paramsDefaults": {
    "lnw": "1px"
  },
  "css": {
    "%": "max-height:2000px;border-right:[lnw] dashed rgba(255, 255, 255, 0.5);border-left:[lnw] dashed rgba(0, 0, 0, 0.35);width:0 !important;min-width:0 !important;"
  }
}
 skins['wysiwyg.viewer.skins.line.VerticalNotchLine'] = {
  "react": [
    [
      "div",
      "line",
      [],
      {}
    ]
  ],
  "params": {
    "lnw": "BORDER_SIZE"
  },
  "paramsDefaults": {
    "lnw": "1px"
  },
  "css": {
    "%": "max-height:2000px;border-right:[lnw] solid rgba(255, 255, 255, 0.35);border-left:[lnw] solid rgba(0, 0, 0, 0.35);width:0 !important;min-width:0 !important;"
  }
}
 skins['wysiwyg.viewer.skins.line.VerticalShadowLeftLine'] = {
  "react": [
    [
      "div",
      "line",
      [],
      {}
    ]
  ],
  "params": {
    "tdr": "URL"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%": "max-height:2000px;",
    "%:before": "background-image:url([tdr]fadelinevertical.png);background-position:0 0;position:absolute;left:-14px;width:29px;content:\"\";top:0;height:100px;",
    "%:after": "background-image:url([tdr]fadelinevertical.png);background-position:0 100%;position:absolute;left:-14px;width:29px;content:\"\";bottom:0;height:100px;",
    "%line": "background-image:url([tdr]fadelinevertical.png);background-position:87px 0;position:absolute;left:-14px;width:29px;content:\"\";top:100px;bottom:100px;"
  }
}
 skins['wysiwyg.viewer.skins.line.VerticalShadowRightLine'] = {
  "react": [
    [
      "div",
      "line",
      [],
      {}
    ]
  ],
  "params": {
    "tdr": "URL"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%": "max-height:2000px;",
    "%:before": "background-image:url([tdr]fadelinevertical.png);background-position:145px 0;position:absolute;left:-14px;width:29px;content:\"\";top:0;height:100px;",
    "%:after": "background-image:url([tdr]fadelinevertical.png);background-position:145px 100%;position:absolute;left:-14px;width:29px;content:\"\";bottom:0;height:100px;",
    "%line": "background-image:url([tdr]fadelinevertical.png);background-position:174px 0;position:absolute;left:-14px;width:29px;content:\"\";top:100px;bottom:100px;"
  }
}
 skins['wysiwyg.viewer.skins.line.VerticalSloopyLine'] = {
  "react": [
    [
      "div",
      "line",
      [],
      {}
    ]
  ],
  "params": {
    "tdr": "URL"
  },
  "paramsDefaults": {
    "tdr": "BASE_THEME_DIRECTORY"
  },
  "css": {
    "%": "max-height:2000px;background:url([tdr]linesloopyvertical.png) repeat-y 50% 0;width:3px;min-width:3px;"
  }
}
 skins['wysiwyg.viewer.skins.line.VerticalSolidLine'] = {
  "react": [
    [
      "div",
      "line",
      [],
      {}
    ]
  ],
  "params": {
    "lnw": "BORDER_LEFT_SIZE",
    "brd": "COLOR_ALPHA",
    "mrg": "SIZE"
  },
  "paramsDefaults": {
    "lnw": "3px",
    "brd": "color_15",
    "mrg": [
      "lnw"
    ]
  },
  "paramsMutators": {
    "mrg": {
      "type": "multiply",
      "value": -0.5,
      "param": "lnw"
    }
  },
  "css": {
    "%": "max-height:2000px;width:0 !important;min-width:0 !important;",
    "%line": "border-left:[lnw] solid [brd];margin-left:[mrg];width:100%;height:100%;"
  }
}

module.exports = skins