/**
 * Created by eitanr on 6/24/14.
 */
define(['layout/specificComponents/svgShape/svgScalerUtils'], function (utils) {
    'use strict';
    return {
        getParsedPoints(pointsAttribute) {
            const attr = utils.trim(pointsAttribute),
                points = attr.split(/[\s,]+/),
                pointsPairs = [];

            if (attr === '') {
                return [];
            }

            for (let i = 0; i < points.length; i += 2) {
                pointsPairs.push([points[i], points[i + 1]]);
            }
            return pointsPairs;
        },
        stringifyPoints(parsedPoints) {
            let resultString = '';
            parsedPoints.forEach(function (pointsPairArr) {
                resultString += `${pointsPairArr.join(',')} `;
            });
            return utils.trim(resultString);
        }
    };
});