import {withActions} from 'carmi-host-extensions'
import _ from 'lodash'

export const name = 'AnchorAspect'

export const defaultModel = {
    activeAnchor: null,
    anchorChangeListeners: {},
    scrollToAnchorDataItemId: null
}

export const functionLibrary = {
    registerComponentToAnchorChange: withActions(({setAnchorChangeListener}, reactComp) => {
        setAnchorChangeListener(reactComp.props.id, reactComp.onAnchorChange)
    }),

    unregisterComponentToAnchorChange: withActions(({setAnchorChangeListener}, reactComp) => {
        setAnchorChangeListener(reactComp.props.id, undefined)
    }),

    triggerAnchorChangeListeners(activeAnchor, getListenersFn) {
        getListenersFn().forEach(callback => callback(activeAnchor))
    },

    sortAnchorsByYPosition(anchors) {
        return anchors.sort((a, b) => a.y - b.y)
    },

    scrollToAnchor: withActions(({setAnchorDatItemIdForScrollToAnchor}, anchorDataItemOrDataItemId) => {
        // might be a string (then it is the data item id itself, or the entire anchor data item)
        // obviously, nickname (WixCode terminology is not considered here, it should be resolved
        // beforehand)
        const dataItemId = _.isString(anchorDataItemOrDataItemId) ?
            anchorDataItemOrDataItemId :
            anchorDataItemOrDataItemId.id

        setAnchorDatItemIdForScrollToAnchor(dataItemId)
    }),

    sortByStructureYPosition: (comps, compsYPosition) => _.sortBy(comps, comp => compsYPosition[comp.compId]),

    setActiveAnchor: withActions(({setActiveAnchor}, anchor) => {
        if (anchor === undefined) {
            return
        }

        setActiveAnchor({
            activeAnchorComp: {
                id: anchor.id
            }
        })
    }),
    scrollOnce: withActions(({setAnchorDatItemIdForScrollToAnchor}, scrollFunction, x, y) => {
        scrollFunction(x, y)
        setAnchorDatItemIdForScrollToAnchor(null)
    })
}
