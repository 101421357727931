const _ = require('lodash')
const React = require('react')
const PropTypes = require('prop-types')
const {utils: {createSantaTypesDefinitions}, santaTypesDefinitions} = require('santa-components')

const CHILD_IDS_TO_KEEP = {
    aspectCompsContainer: true,
    POPUP_ROOT: true,
    FONTS_CONTAINER: true
}

const santaTypes = createSantaTypesDefinitions({
    navigateTo: PropTypes.func,
    parseNavInfo: PropTypes.func,
    shouldShowAllBoltSiteContainerChildren: PropTypes.bool,
    isWixSite: PropTypes.bool,
    getPrimaryRootId: PropTypes.func,
    getCustomClickOccurred: PropTypes.func
}, 'BoltSite')

const getClosestAncestorByTagName = (element, parentTagName) => {
    if (!element || !element.tagName) {
        return null
    }
    if (element.tagName.toLowerCase() === parentTagName) {
        return element
    }
    return getClosestAncestorByTagName(element.parentNode, parentTagName)
}

const getAnchorNode = event => getClosestAncestorByTagName(event.target, 'a')

const cancelEvent = event => {
    event.stopPropagation()
    event.preventDefault()
    return false
}

const parseLinkNode = linkNode => ({
    href: linkNode.getAttribute('href'),
    target: linkNode.getAttribute('target'),
    pageItemAdditionalData: linkNode.getAttribute('data-page-item-context'),
    anchorData: linkNode.getAttribute('data-anchor'),
    noUrlChangeAttr: linkNode.getAttribute('data-no-physical-url'),
    isChangingUrl: !linkNode.getAttribute('data-no-physical-url'), //maybe skip this
    isKeepingRoots: !!linkNode.getAttribute('data-keep-roots')
})

const shouldCancelLink = (customClickOccured, parsedLink, navInfo, getPrimaryRootId) => {
    if (!customClickOccured) {
        return false
    }

    const isExternalLinkToSameTab = !navInfo && parsedLink.target === '_self'
    const isInternalNavigationToDifferentPage = parsedLink.target !== '_blank' && navInfo && navInfo.pageId && navInfo.pageId !== getPrimaryRootId()

    return isExternalLinkToSameTab || isInternalNavigationToDifferentPage
}

const siteClickHandler = (navigateMethod, parseNavInfo, resetCustomClickOccurred, getCustomClickOccurred, getPrimaryRootId) => e => {
    const customClickOccured = getCustomClickOccurred()
    resetCustomClickOccurred()

    const linkNode = getAnchorNode(e)
    if (!linkNode) {
        return true
    }

    const parsedLink = parseLinkNode(linkNode)
    const url = parsedLink.noUrlChangeAttr || parsedLink.href
    const navInfo = parseNavInfo(url)
    const isExternalLink = parsedLink.target === '_blank' || !navInfo

    if (shouldCancelLink(customClickOccured, parsedLink, navInfo, getPrimaryRootId)) {
        return cancelEvent(e)
    }
    if (!linkNode || isExternalLink) {
        return true //let the browser do it's thing
    }
    navigateMethod(parsedLink)
    return cancelEvent(e) //we got this
}

const getClassList = props => {
    const res = []
    const {isWixSite, isVisualFocusEnabled} = props
    if (isWixSite) {
        res.push('wixSiteProperties')
    } else {
        res.push('noop')
    }

    if (isVisualFocusEnabled) {
        res.push('visual-focus-on')
    }

    return res.join(' ')
}

const getDummyRootId = () => ''

const PartiallyVisibleBeatScript = ({isMeshLayoutMechanism, getPrimaryRootId = getDummyRootId}) =>
    isMeshLayoutMechanism ?
        <script
            id='partiallyVisibleBeat'
            dangerouslySetInnerHTML={{
                __html: `
                    if (wixBiSession && wixBiSession.sendBeat && !wixBiSession.sentPartiallyVisibleBeat) {
                        wixBiSession.sentPartiallyVisibleBeat = true;
                        wixBiSession.sendBeat(12, 'Partially visible', '&pid=${getPrimaryRootId()}');
                    }
                `
            }} /> :
        null

PartiallyVisibleBeatScript.propTypes = {
    isMeshLayoutMechanism: PropTypes.bool,
    getPrimaryRootId: santaTypes.getPrimaryRootId.isRequired
}

const BoltChildren = props => {
    if (props.shouldShowAllBoltSiteContainerChildren) {
        return props.children || null
    }

    return React.Children.map(props.children, child => CHILD_IDS_TO_KEEP[_.get(child, 'props.id')] ? child : null)
}

/**
 * @class components.BoltSite
 */
class BoltSite extends React.Component {
    render() {
        const {props} = this
        const {navigateTo, parseNavInfo, resetCustomClickOccurred, getCustomClickOccurred, getPrimaryRootId, isMeshLayoutMechanism} = props
        return (
            <div
                key='BoltSite'
                className={getClassList(props)}
                style={{position: 'relative'}}
                onClick={siteClickHandler(navigateTo, parseNavInfo, resetCustomClickOccurred, getCustomClickOccurred, getPrimaryRootId)}
            >
                <BoltChildren {...props} />
                <PartiallyVisibleBeatScript key='partiallyVisibleBeat' getPrimaryRootId={getPrimaryRootId} isMeshLayoutMechanism={isMeshLayoutMechanism}/>
            </div>
        )
    }
}

BoltSite.displayName = 'BoltSite'
BoltSite.santaTypeDefinitions = santaTypes
BoltSite.compType = 'BOLT_SITE'
BoltSite.propTypes = {
    children: PropTypes.node,
    navigateTo: santaTypes.navigateTo.isRequired,
    parseNavInfo: santaTypes.parseNavInfo.isRequired,
    isVisualFocusEnabled: santaTypesDefinitions.isVisualFocusEnabled.isRequired,
    isWixSite: santaTypes.isWixSite.isRequired,
    shouldShowAllBoltSiteContainerChildren: santaTypes.shouldShowAllBoltSiteContainerChildren,
    isMeshLayoutMechanism: santaTypesDefinitions.Layout.isMeshLayoutMechanism,
    resetCustomClickOccurred: santaTypesDefinitions.resetCustomClickOccurred,
    getPrimaryRootId: santaTypes.getPrimaryRootId.isRequired,
    getCustomClickOccurred: santaTypes.getCustomClickOccurred
}

BoltSite.defaultProps = {
    shouldShowAllBoltSiteContainerChildren: true
}

module.exports = BoltSite
