define([
    'lodash',
    'santa-core-utils',
    'coreUtils',
    'image-client-api',
    'santa-components',
    'skins',
    'imageButton/skins/skins.json',
    'componentsCore'
], function (
    _,
    coreUtilsLib,
    coreUtils,
    imageClientLib,
    santaComponents,
    skinsPackage,
    skinsJson,
    componentsCore
) {
    'use strict';

    function completeTransition(options) {
        options.component.setState({
            '$prepare': options.preTransitionClass
        });

        coreUtils.animationFrame.request(function () {
            if (options.component._isMounted) {
                options.component.setState(options.state);
            }
        });
    }

    function touch(component) {
        if (component.state.$pressed) {
            return;
        }

        completeTransition({
            component,
            preTransitionClass: 'prepare_dah',
            state: {$pressed: 'pressed'}
        });
    }

    function untouch(component) {
        if (!component.state.$pressed) {
            return;
        }

        completeTransition({
            component,
            preTransitionClass: 'prepare_adh',
            state: {
                $pressed: '',
                $hovered: ''
            }
        });
    }

    function hover(component) {
        if (component.state.$hovered) {
            return;
        }

        completeTransition({
            component,
            preTransitionClass: 'prepare_dha',
            state: {$hovered: 'hovered'}
        });
    }

    function unhover(component) {
        if (component.state.$pressed) {
            untouch(component);
            return;
        }

        if (!component.state.$hovered) {
            return;
        }

        completeTransition({
            component,
            preTransitionClass: 'prepare_hda',
            state: {$hovered: ''}
        });
    }

    function press(component) {
        if (component.state.$pressed) {
            return;
        }

        completeTransition({
            component,
            preTransitionClass: 'prepare_had',
            state: {$pressed: 'pressed'}
        });
    }

    function unpress(component) {
        if (!component.state.$pressed) {
            return;
        }

        completeTransition({
            component,
            preTransitionClass: 'prepare_ahd',
            state: {$pressed: ''}
        });
    }

    function getImageSkinProperties(component, imageRefName) {
        let imageData = component.props.compData[imageRefName];

        if (!imageData) {
            return {};
        }

        imageData = _.assign({alt: component.props.compData.alt}, imageData);

        const props = {
            id: component.props.id + imageRefName,
            ref: imageRefName,
            skinPart: imageRefName,
            imageData,
            containerWidth: component.props.style.width,
            containerHeight: component.props.style.height,
            shouldRenderSrc: component.props.shouldRenderSrc,
            displayMode: imageClientLib.fittingTypes.LEGACY_FULL
        };

        return component.createChildComponent(
            imageData,
            'core.components.Image', imageRefName,
            props
        );
    }

    function getLinkSkinProperties(component, options) {
        let linkData = {};

        if (options.linkRef) {
            linkData = options.linkRef;
            linkData = coreUtils.linkRenderer.renderLink(linkData, options.linkRenderInfo, options.rootNavigationInfo);
        }

        linkData.title = options.title;
        linkData.style = {
            width: component.props.style.width,
            height: component.props.style.height
        };

        return linkData;
    }

    /**
     * @class components.ImageButton
     * @extends {core.skinBasedComp}
     * @extends {ReactCompositeComponent}
     * @property {comp.properties} props
     */
    const imageButton = {
        displayName: 'ImageButton',
        mixins: [
            coreUtilsLib.timersMixins.timeoutsMixin,
            componentsCore.mixins.skinBasedComp,
            componentsCore.mixins.createChildComponentMixin
        ],

        propTypes: _.assign({
            compData: santaComponents.santaTypesDefinitions.Component.compData,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp,
            linkRenderInfo: santaComponents.santaTypesDefinitions.Link.renderInfo,
            rootNavigationInfo: santaComponents.santaTypesDefinitions.Component.rootNavigationInfo,
            style: santaComponents.santaTypesDefinitions.Component.style,
            shouldRenderSrc: santaComponents.santaTypesDefinitions.Media.shouldRenderSrc,
            windowFocusEvents: santaComponents.santaTypesDefinitions.SiteAspects.windowFocusEvents
        }, santaComponents.utils.santaTypesUtils.getSantaTypesFromPropTypes(santaComponents.components.Image.propTypes)),

        getInitialState() {
            this.blurEventAspect = this.props.windowFocusEvents;
            this.blurEventAspect.registerToFocusEvent('blur', this);

            return {
                $opacity: 'supports_opacity',
                $transition: `transition_${this.props.compProp.transition}`,
                $prepare: '',
                $hovered: '',
                $pressed: ''
            };
        },
        getSkinProperties() {
            const self = this,
                rootElementProps = {
                    onMouseEnter() {
                        hover(self);
                    },
                    onMouseLeave() {
                        unhover(self);
                    },
                    onMouseDown() {
                        press(self);
                    },
                    onMouseUp() {
                        unpress(self);
                    },
                    onDragStart(e) {
                        e.preventDefault();
                    },
                    onTouchStart() {
                        touch(self);
                    },
                    onTouchEnd() {
                        if (self._isMounted) {
                            untouch(self);
                        }
                    },
                    onTouchMove() {
                        if (!self.state.$pressed) {
                            return;
                        }

                        self.timeoutidToRemoveOnUnmount = self.setTimeout(rootElementProps.onTouchEnd, 500);
                    },
                    onClick() {
                        untouch(self);
                    }
                };

            return {
                '': rootElementProps,
                defaultImage: getImageSkinProperties(self, 'defaultImage'),
                hoverImage: getImageSkinProperties(self, 'hoverImage'),
                activeImage: getImageSkinProperties(self, 'activeImage'),
                link: getLinkSkinProperties(self, {
                    linkRef: self.props.compData.link,
                    title: self.props.compData.alt,
                    linkRenderInfo: self.props.linkRenderInfo,
                    rootNavigationInfo: self.props.rootNavigationInfo
                })
            };
        },

        onBlur() {
            unhover(this);
        },

        componentDidMount() {
            this._isMounted = true;
        },
        componentWillUnmount() {
            clearTimeout(this.timeoutidToRemoveOnUnmount);
            this._isMounted = false;
            this.blurEventAspect.unregisterFromFocusEvent('blur', this);
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.common.components.imagebutton.viewer.ImageButton', imageButton);
    skinsPackage.skinsMap.addBatch(skinsJson);

    return imageButton;
});
