import _ from 'lodash'

export function keyvalue(propertyName, stringifier) {
    return function (style, item) {
        style[propertyName] = stringifier(item)
        return style
    }
}

export function map(obj) {
    return function (style, item) {
        return _(obj)
            .mapValues(stringifier => stringifier(item))
            .merge(style)
            .value()
    }
}


