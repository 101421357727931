define([
    'lodash',
    'warmupUtils/pointers/pointerGeneratorsRegistry'
], function (
    _,
    pointerGeneratorsRegistry
) {
    'use strict';

    const PAGE_POINTER_TYPE = 'page';

    pointerGeneratorsRegistry.registerPointerType(PAGE_POINTER_TYPE, function () { return null; }, function () { return true; }, false, true);

    const getterFunctions = {
        isPointerPageType(getItemAtPath, cache, pointer) {
            return pointer && pointer.type === PAGE_POINTER_TYPE;
        },

        getNewPagePointer(getItemAtPath, cache, pageId) {
            const path = ['pagesData', pageId];
            return cache.getPointer(pageId, PAGE_POINTER_TYPE, path);
        },

        getPagePointer(getItemAtPath, cache, pageId) {
            const path = ['pagesData', pageId];
            const page = getItemAtPath(path);
            if (_.isUndefined(page)) {
                return null;
            }
            return cache.getPointer(pageId, PAGE_POINTER_TYPE, path);
        },

        isExists(getItemAtPath, cache, pageId) {
            const path = ['pagesData', pageId];
            const page = getItemAtPath(path);
            return !_.isUndefined(page);
        },

        getAllPagesPointer(getItemAtPath, cache) {
            return cache.getPointer('all', PAGE_POINTER_TYPE, ['pagesData']);
        },

        getNonDeletedPagesPointers(getItemAtPath, cache, includeMaster) {
            includeMaster = _.isUndefined(includeMaster) ? false : includeMaster;
            const path = ['pagesData'];
            let pages = getItemAtPath(path);

            pages = _.pickBy(pages, function (value, pageId) {
                if (!value) {
                    return false;
                }

                if (!includeMaster && pageId === 'masterPage') {
                    return false;
                }

                return true;
            });

            return _.map(pages, function (value, pageId) {
                return cache.getPointer(pageId, PAGE_POINTER_TYPE, path.concat(pageId));
            });
        },

        getPageData(getItemAtPath, cache, pageId) {
            const path = ['pagesData', pageId, 'data', 'document_data'];
            const page = getItemAtPath(path);
            if (!page) {
                return null;
            }
            return cache.getPointer(`${pageId}_data`, PAGE_POINTER_TYPE, path);
        },

        getPageProperties(getItemAtPath, cache, pageId) {
            const path = ['pagesData', pageId, 'data', 'component_properties'];
            const page = getItemAtPath(path);
            if (!page) {
                return null;
            }
            return cache.getPointer(`${pageId}_prop`, PAGE_POINTER_TYPE, path);
        },

        getPageDesignData(getItemAtPath, cache, pageId) {
            const path = ['pagesData', pageId, 'data', 'design_data'];
            const page = getItemAtPath(path);
            if (!page) {
                return null;
            }
            return cache.getPointer(`${pageId}_design`, PAGE_POINTER_TYPE, path);
        },

        getResolvedDataMapsPointer(getItemAtPath, cache) {
            return cache.getPointer('resolvedDataMaps', PAGE_POINTER_TYPE, ['resolvedDataMaps']);
        },

        getComponentsMapPointer(getItemAtPath, cache, pageId, viewMode) {
            const path = ['pagesData', pageId, 'structure', viewMode];
            const page = getItemAtPath(path);
            if (!page) {
                return null;
            }
            return cache.getPointer(`${pageId}_${viewMode}_componentsMap`, PAGE_POINTER_TYPE, path);
        },

        getPageBehaviorsData(getItemAtPath, cache, pageId) {
            const path = ['pagesData', pageId, 'data', 'behaviors_data'];
            const page = getItemAtPath(path);
            if (!page) {
                return null;
            }
            return cache.getPointer(`${pageId}_design`, PAGE_POINTER_TYPE, path);
        }
    };

    getterFunctions.getPageTranslations = function (getItemAtPath, cache, pageId) {
        const path = ['pagesData', pageId, 'translations'];
        return cache.getPointer(`${pageId}_translations`, PAGE_POINTER_TYPE, path);
    };

    pointerGeneratorsRegistry.registerDataAccessPointersGenerator(PAGE_POINTER_TYPE, getterFunctions);
});
