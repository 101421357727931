/* eslint fp/no-class: 0 fp/no-this: 0 */
import React from 'react' //eslint-disable-line no-unused-vars
import ReactDOM from 'react-dom'
import {santaTypesDefinitions} from 'santa-components'

const extend = (BaseClass, componentType) => {
    class QAComponentExtension extends BaseClass {
        static isQaExtension = true
        static displayName = `QAComponentExtension(${BaseClass.displayName})`
        static baseDisplayName = BaseClass.displayName
        static propTypes = {
            id: santaTypesDefinitions.Component.id,
            ...BaseClass.propTypes
        }
        componentDidMount() {
            if (super.componentDidMount) {
                super.componentDidMount()
            }

            this._setQaAttributesOnDOMNode()
        }

        componentDidUpdate(prevProps, prevState) {
            if (super.componentDidUpdate) {
                super.componentDidUpdate(prevProps, prevState)
            }
            this._setQaAttributesOnDOMNode()
        }

        _setQaAttributesOnDOMNode() {
            const node = ReactDOM.findDOMNode(this) //eslint-disable-line react/no-find-dom-node
            if (node) {
                if (!node.getAttribute('id')) {
                    node.setAttribute('id', this.props.id)
                }
                if (!node.getAttribute('data-aid')) {
                    node.setAttribute('data-aid', componentType)
                }
                if (!node.getAttribute('data-comp') && componentType) {
                    node.setAttribute('data-comp', componentType)
                }
                if (!node.getAttribute('data-displayname') && BaseClass.displayName) {
                    node.setAttribute('data-displayname', BaseClass.displayName)
                }
            }
        }
    }

    return QAComponentExtension
}

const exetendWithQaInfo = (BaseClass, componentType) => {
    if (componentType === 'div' || BaseClass.isQaExtension) {
        return BaseClass
    }

    return extend(BaseClass, componentType)
}

export {exetendWithQaInfo}
