define(['lodash'], function (_) {
    'use strict';

    function enrich(siteData, params) {
        return _.assign(params, {
            uuid: siteData.getUserId(),
            msid: siteData.getMetaSiteId(),
            vsi: siteData.wixBiSession.viewerSessionId,
            vid: siteData.wixBiSession.visitorId,
            ref: window.document.referrer
        });
    }

    return {
        enrich
    };
});
