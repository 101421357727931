'use strict'

const _ = require('lodash')
const runtimeDalOldFactory = require('../runtimeDal_old')
const runtimeDalFactory = require('../runtimeDal')
const runtimeUtil = require('../../../utils/runtime')
const {withActions} = require('carmi-host-extensions')
const {pointers} = require('../../../utils/constants')
const {DATA_MAPS} = pointers.data
const dataMapToQueryName = _.invert(pointers.QUERY_TO_MAP_NAME)

const removeHash = value => value && value.replace('#', '')
const isDisplayedOnly = id => id.split('__').length === 2
const getFullId = displayedId => displayedId.split('__')[0]
const mapNameToSchemaType = {
    [DATA_MAPS.DATA]: 'Data',
    [DATA_MAPS.DESIGN]: 'Design'
}
// const getDisplayedOnlyItemId = displayedId => displayedId.split('__')[1]

const getCurrentDataItem = (data, mapName, structure, compId) => {
    const queryName = dataMapToQueryName[mapName]
    const compStructure = structure[compId] || structure[getFullId(compId)]
    const query = compStructure[queryName]
    return data[mapName][removeHash(query)]
}

const isPageItem = ({type} = {}) => type === 'Page'
const isStyledText = type => type === 'StyledText'

const getRefRuntimeId = (compId, parentDataId, originalRefId, isRefList, key, index) => {
    if (isRefList) {
        return runtimeUtil.getRuntimeId(compId, `${parentDataId}${key}${index}`)
    }

    return runtimeUtil.isRuntimeId(originalRefId) ?
        runtimeUtil.getRuntimeId(compId, runtimeUtil.getQueryId(originalRefId)) :
        runtimeUtil.getRuntimeId(compId, originalRefId)
}

const getRuntimeRefsToSplitFromTemplate = (partialOverride, originalDataItem, compId, schemaRefs, innerPath) => 
    _(schemaRefs)
        .keys()
        .filter(key => {
            const refInOverride = _.get(partialOverride, [...innerPath, key])
            const refInOriginal = _.get(originalDataItem, [...innerPath, key])
                
            const isInvalidOriginalId =
            runtimeUtil.isRuntimeId(refInOriginal) &&
            compId !== removeHash(runtimeUtil.getCompId(refInOriginal))

            return !refInOverride && refInOriginal && isInvalidOriginalId
        })
        .value()

const flattenDataItem = (dataMap, schemas, partialOverride, prevValue, originalDataItem, id, innerPath = []) => {
    const compId = runtimeUtil.getCompId(id)
    const dataItemId = runtimeUtil.getQueryId(id)

    const type = partialOverride.type || _.get(prevValue, [id, 'type']) || originalDataItem.type
    const schemaRefs = _.get(schemas, [type, ...innerPath])

    const additionalRefsKeys = isDisplayedOnly(compId) ?
        getRuntimeRefsToSplitFromTemplate(partialOverride, originalDataItem, compId, schemaRefs, innerPath) :
        []

    return _(_.get(partialOverride, innerPath, partialOverride))
        .keys()
        .concat(additionalRefsKeys)
        .reduce((acc, key) => {
            const currentPath = [...innerPath, key]
            const currentOverride = _.get(partialOverride, currentPath)
            if (!currentOverride && !_.includes(additionalRefsKeys, key)) {
                return _.set(acc, [id, ...currentPath], currentOverride)
            }

            const ref = _.get(schemaRefs, key)

            const isRef = !_.isUndefined(ref)
            if (!isRef) {
                return _.set(acc, [id, ...currentPath], key === 'id' ? id : currentOverride)
            }

            const isWeakRef = ref === true
            if (isWeakRef) {
                if (_.isString(currentOverride)) {
                    return _.set(acc, [id, ...currentPath], currentOverride)
                }

                if (isPageItem(currentOverride)) {
                    return _.set(acc, [id, ...currentPath], currentOverride.id)
                }
            }

            const isNestedRef = _.isObject(ref) && _.isObject(currentOverride)
            if (isNestedRef) {
                _.set(acc, [id, ...currentPath], {})
                const resolvedNestedRef = flattenDataItem(
                    dataMap,
                    schemas,
                    partialOverride,
                    prevValue,
                    originalDataItem,
                    id,
                    currentPath
                )
                _.merge(acc[id], resolvedNestedRef[id])
                return {
                    ...resolvedNestedRef,
                    ...acc
                }
            }

            const nestedDataItems = getFlattenedRefDataItems(currentOverride, originalDataItem, acc, id, currentPath, compId, dataItemId, key, schemas, prevValue, dataMap, type)
            return {
                ...acc,
                ...nestedDataItems
            }
        }, {})
}

const removeOverrides = overridesToRemove => {
    _(overridesToRemove)
        .sortBy('path[0]')
        .reverse()
        .forEach(overrideToRemove => {
            const path = overrideToRemove.path
            overrideToRemove.remove(...path)
        })
}

function getFlattenedRefDataItems(currentOverride, originalDataItem, acc, id, currentPath, compId, dataItemId, key, schemas, prevValue, dataMap, type) {
    const field = _.get(originalDataItem, currentPath) || currentOverride
    const isRefList = _.isArray(field)
    const refsAsArray = isRefList ? currentOverride : [field]

    if (isRefList) {
        _.set(acc, [id, ...currentPath], [])
    }

    const refDataItemsArray = refsAsArray.map((refOrRefId, index) => {
        const originalRefId = _.isString(refOrRefId) && removeHash(refOrRefId) || [dataItemId, ...currentPath].join('')
        const refOverride = isRefList ? currentOverride[index] : currentOverride || dataMap[originalRefId]

        const runtimeRefId = isStyledText(type) ?
            refOverride.id :
            getRefRuntimeId(compId, dataItemId, originalRefId, isRefList, key, index)

        const items = flattenDataItem(
            dataMap,
            schemas,
            refOverride,
            prevValue,
            dataMap[originalRefId],
            runtimeRefId
        )
        if (isRefList) {
            _.get(acc, [id, ...currentPath]).push(`#${runtimeRefId}`)
        } else {
            _.set(acc, [id, ...currentPath], `#${runtimeRefId}`)
        }

        return isRefList && originalDataItem && originalDataItem.metaData ?
            _.mapValues(items, item => ({
                ...item,
                metaData: originalDataItem.metaData
            })) :
            items
    })
    return Object.assign({}, ...refDataItemsArray)
}

module.exports = {
    name: 'RuntimeAspect',
    defaultModel: {},
    functionLibrary: {
        updateRuntime: withActions(({setRuntimeDataOverrides, pushRuntimeBehavior, setRuntimeEvents, $runInBatch}, overrides) => {
            $runInBatch(() => {
                _.forEach(overrides, (dataOverrides, mapName) => {
                    if (mapName === DATA_MAPS.BEHAVIORS) {
                        _.forEach(dataOverrides, (compRuntimeBehaviors, compId) => {
                            _.forEach(compRuntimeBehaviors, ab => pushRuntimeBehavior(compId, ab))
                        })
                    } else {
                        _.forEach(dataOverrides, (override, id) => {
                            setRuntimeDataOverrides(mapName, id, override)
                        })
                    }
                })

                setRuntimeEvents({data: {}})
            })
        }),
        getSimpleItemOverride: (getData, getStructure, mapName, compId, overrides) => {
            const data = getData()
            const structure = getStructure()
            const originalDataItem = getCurrentDataItem(data, mapName, structure, compId) || {
                id: dataMapToQueryName[mapName]
            }

            const runtimeId = runtimeUtil.getRuntimeId(
                compId,
                runtimeUtil.getQueryId(originalDataItem.id) || originalDataItem.id
            )

            return {
                ...originalDataItem,
                ...overrides,
                id: runtimeId
            }
        },
        getItemAndRefsOverridesMap: ({
            compId,
            getStructure,
            getData,
            mapName,
            singleOverride,
            prevValue,
            dataSchemas
        }) => {
            const data = getData()
            const structure = getStructure()
            const dataItemFromStructure = getCurrentDataItem(data, mapName, structure, compId)
            const runtimeId = isPageItem(dataItemFromStructure) ?
                dataItemFromStructure.id :
                runtimeUtil.getRuntimeId(
                    compId,
                    runtimeUtil.getQueryId(dataItemFromStructure.id) || dataItemFromStructure.id
                )

            const flattenDataItems = flattenDataItem(
                data[mapName],
                dataSchemas[mapNameToSchemaType[mapName]],
                singleOverride,
                prevValue,
                dataItemFromStructure,
                runtimeId
            )

            return _.mapValues(flattenDataItems, (item, itemId) => {
                const originalItem =
                    prevValue[itemId] || data[mapName][itemId] || data[mapName][runtimeUtil.getQueryId(itemId)]
                return _.has(originalItem, 'type') && item.type && originalItem.type !== item.type ? item : {
                    ...originalItem,
                    ...item
                }
            })
        },
        resolveRuntimeDataItem: (getDataResolvers, compId, dataItem) => {
            const dataResolvers = getDataResolvers()
            if (_.isEmpty(dataResolvers)) {
                return dataItem
            }

            return _.reduce(dataResolvers, (resolvedItem, resolver) => resolver(compId, resolvedItem), {...dataItem})
        },
        getLast: arr => arr[arr.length - 1],
        updateCompState: (runtimeDal, runtimeStateMap, compId, partialOverrides) =>
            runtimeDal.updateCompState(compId, _.get(runtimeStateMap, compId), partialOverrides),
        setCompData: (runtimeDal, compId, partialOverrides) => runtimeDal.setCompData(compId, partialOverrides),
        setCompProps: (runtimeDal, compId, partialOverrides) => runtimeDal.setCompProps(compId, partialOverrides),
        resetRuntimeOverrides: withActions((actions, contextId, getOverridesToRemove) => {
            const overridesToRemove = getOverridesToRemove()
            removeOverrides(overridesToRemove)
            actions.setContextWasReset(contextId, true)
        }),
        resetRemovedDisplayedOnlyOverrides: removeOverrides,
        deleteOldResetRuntimeEntries: withActions((actions, contextIdsToRemove) => {
            _.forEach(contextIdsToRemove, contextId => actions.setContextWasReset(contextId, undefined))
        })
    },
    init: (carmiInstance, {initialData, exceptionHandlingApi}) => {
        const {repeatersApi, dataSchemas, useNewRuntimeDal} = initialData
        const runtimeDAL = useNewRuntimeDal ?
            runtimeDalFactory.create(carmiInstance) :
            runtimeDalOldFactory.create(carmiInstance, repeatersApi, exceptionHandlingApi, dataSchemas)

        carmiInstance.setRuntimeDalInstance(runtimeDAL)
    }
}
