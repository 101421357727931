module.exports = ({fetchFunction}) => {
    const ajaxMethod = (options = {}) => {
        options.headers = options.headers || {}
        const contentType = options.headers['Content-Type'] || options.contentType
        if (contentType) {
            options.headers['Content-Type'] = contentType
        } else if (!options.data) {
            options.headers['Content-Type'] = 'application/json'
            options.headers.Accept = 'application/json'
        }

        return fetchFunction(options.url, {method: options.type, body: options.data, ...options}, options.dataType)
            .then(options.success)
            .catch(options.error)
    }

    return {
        registerAjaxMethod: warmupUtils => {
            warmupUtils.ajaxLibrary.register(ajaxMethod)
        }
    }
}
