define([], function (
) {
    'use strict';
    return {
        PAGE_LINK: 'PageLink',
        EXTERNAL_LINK: 'ExternalLink',
        ANCHOR_LINK: 'AnchorLink',
        LOGIN_TO_WIX_LINK: 'LoginToWixLink',
        EMAIL_LINK: 'EmailLink',
        PHONE_LINK: 'PhoneLink',
        WHATSAPP_LINK: 'WhatsAppLink',
        DOCUMENT_LINK: 'DocumentLink',
        SWITCH_MOBILE_VIEW_MODE: 'SwitchMobileViewMode',
        DYNAMIC_PAGE_LINK: 'DynamicPageLink',
        ADDRESS_LINK: 'AddressLink'
    };
});
