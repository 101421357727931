
export const name = 'NavigateToRenderedLinkAspect'
import {withActions} from 'carmi-host-extensions'

export const defaultModel = {
    renderedLinkToNavigate: null
}

export const functionLibrary = {
    handleNavigateToPage: withActions((actions, navigateToPageFn) => {
        navigateToPageFn()
        actions.updateRenderedLinkToNavigate(null)
    })
}