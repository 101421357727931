define([
    'lodash',
    'warmupUtils',
    'santa-core-utils',
    'coreUtils/bi/errors'
], function (_, warmupUtils, coreUtilsLib, biErrors) {
    'use strict';

    const SANTA_LANGS_FALLBACK_VERSION = '1.2543.0';
    const languages = warmupUtils.languages;
    const ajaxLibrary = warmupUtils.ajaxLibrary;
    const urlUtils = coreUtilsLib.urlUtils;

    class TranslationsLoader {
        constructor({translations, oldTranslations, failedLanguageCodes}) {
            this.translations = translations; // eslint-disable-line santa/no-side-effects
            this.oldTranslations = oldTranslations; // eslint-disable-line santa/no-side-effects
            this.failedLanguageCodes = failedLanguageCodes || []; // eslint-disable-line santa/no-side-effects
        }

        getRequest(siteData, langs, context) {
            const languagesToLoad = this.filterRequiredLangs(langs);
            if (_.isEmpty(languagesToLoad)) {
                return null;
            }

            const requestDescriptor = {
                customDownload: () => {
                    // this tragic done method is assigned to the requestDescriptor by santa-core-utils/coreUtils/core/store2_new.js
                    const done = requestDescriptor.done;

                    return this.load(siteData, langs, context)
                        .then(done)
                        .catch(done);
                }
            };

            return requestDescriptor;
        }

        /**
         * @param {string} santa-langs base url (something like http://static.parastorage.com/services/santa-langs/x.x.x)
         * @param {Array.<string>|string} language codes
         * @param callback
         * @returns {Promise}
         */
        load(siteData, langs, context) {
            const langsBase = siteData.serviceTopology.scriptsLocationMap['santa-langs'];
            const languagesToLoad = this.filterRequiredLangs(langs);
            return Promise.all(_.map(languagesToLoad, l =>
                loadSingle(this, siteData, langsBase, l, context)
                    .catch(() => {
                        const fallbackBaseUrl = getFallbackBaseUrl(langsBase);
                        const fallbackRequestContext = _.assign({}, context, {isFallback: true});
                        return loadSingle(this, siteData, fallbackBaseUrl, l, fallbackRequestContext);
                    }))
            );
        }

        isLoaded(lang, options) {
            return _.has(this.resolveTranslations(options), lang);
        }

        resolveTranslations(options) {
            const useOldTranslations = this.useOldTranslations || _.has(options, 'useOldTranslations') || _.isEmpty(this.translations);
            return useOldTranslations ? this.oldTranslations : this.translations;
        }

        getTranslation(feature, lang, key, defaultValue, options) {
            const allKeys = this.getTranslationAllKeys(feature, lang, {}, options);
            return _.get(allKeys, key, defaultValue);
        }

        getTranslationAllKeys(feature, lang, defaultValue, options) {
            if (!_.includes(languages, lang)) {
                lang = 'en';
            }

            if (!this.isLoaded(lang, options)) {
                lang = 'en';
            }

            return _.get(this.resolveTranslations(options), [lang, feature], defaultValue);
        }

        overrideTranslations({translations, oldTranslations}) {
            this.translations = translations; // eslint-disable-line santa/no-side-effects
            this.oldTranslations = oldTranslations; // eslint-disable-line santa/no-side-effects
        }

        setToUseOldTranslations() {
            this.useOldTranslations = true;
        }

        filterRequiredLangs(langs) {
            return _(langs)
                .concat()
                .compact()
                .invokeMap('toLowerCase')
                .uniq()
                .map(fixLanguageCode)
                .filter(l => _.includes(languages, l))
                .reject(l => _.includes(this.failedLanguageCodes, l))
                .reject(l => this.isLoaded(l))
                .value();
        }
    }

    function getFallbackBaseUrl(langsBaseUrl) {
        const execRes = /^(.*\/)[\d.]*$/.exec(langsBaseUrl);
        return `${execRes[1]}${SANTA_LANGS_FALLBACK_VERSION}`;
    }

    function fixLanguageCode(lang) {
        switch (lang) {
            case 'jp':
                return 'ja';
            case 'kr':
                return 'ko';
            default:
                return lang;
        }
    }

    function loadSingle(loader, siteData, langsBase, lang, context) {
        // There is a bug in IE11 that fail to redirect (status 307) here for cross origin ajax requests WEED-10793
        // Converting http to https will prevent this redirect temporarily until all Parastorage urls in
        // serviceTopology.scriptsLocationMap will be https HTMLSRVR-2321
        const url = urlUtils.joinURL(langsBase, `resources/santa-viewer/bundles/_generated/santa_viewer_${lang}.json`).replace(/https?/, 'https');

        return new Promise((resolve, reject) =>
            ajaxLibrary.ajax({
                type: 'GET',
                url,
                dataType: 'json',
                success: resolve,
                error: (xhr, errorType, error) => {
                    if (errorType !== 'abort') {
                        const response = {
                            status: xhr.status,
                            errorType,
                            response: xhr.response.substring(0, 128),
                            responseSize: xhr.response.length,
                            error
                        };

                        warmupUtils.loggingUtils.logger.reportBI(siteData, biErrors.FAILED_TO_FETCH_TRANSLATIONS, {
                            language: lang,
                            url,
                            response: JSON.stringify(response),
                            context: JSON.stringify(context)
                        });
                    }
                    loader.failedLanguageCodes.push(lang);
                    reject();
                }
            }))
            .then(data => _.assign(loader.translations, {[lang]: data}));
    }

    const translationsLoaderInstance = new TranslationsLoader({translations: {}, oldTranslations: {}});
    return _.assign(translationsLoaderInstance, {

        // For testing purposes only
        TranslationsLoaderFactory: TranslationsLoader
    });
});
