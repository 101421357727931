define(['warmupUtilsLib'], function (warmupUtilsLib) {
    'use strict';

    const {constants} = warmupUtilsLib;
    function getChildrenPropertyName(comp, viewMode) {
        if (viewMode === constants.VIEW_MODES.MOBILE) {
            return comp.mobileComponents ? 'mobileComponents' : 'components';
        }
        return comp.children ? 'children' : 'components';
    }

    function isComponentWithId(id, json) {
        return json.id === id || id === 'masterPage' && json.children; // eslint-disable-line no-mixed-operators
    }

    function getCompPath(json, compId, viewMode, path) {
        if (isComponentWithId(compId, json)) {
            return path;
        }
        const childrenPropName = getChildrenPropertyName(json, viewMode);

        const children = json[childrenPropName];
        if (!children) {
            return null;
        }

        let foundPath = null;

        for (let i = 0; i < children.length && !foundPath; i++) {
            const child = children[i];
            foundPath = getCompPath(child, compId, viewMode, path.concat([childrenPropName, i]));
        }
        return foundPath;
    }

    return {
        getChildrenPropertyName,

        getComponentPath(json, compId, viewMode) {
            return getCompPath(json, compId, viewMode, []);
        },

        getDataPath(/*json*/) {

        },

        isComponentWithId
    };
});
