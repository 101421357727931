'use strict'

const React = require('react')
const PropTypes = require('prop-types')

class RootComponent extends React.Component {
    static propTypes = {
        id: PropTypes.string.isRequired,
        children: PropTypes.node.isRequired
    }

    constructor(props) {
        super(props)
        this.state = {
            isDead: false
        }
    }
    componentDidCatch(e) {
        this.setState({
            isDead: true
        })
        console.warn(e)
    }
    render() {
        const {id, children, ...restOfTheProps} = this.props

        const hasAdditionalProps = Object.keys(restOfTheProps).length > 0

        const child = hasAdditionalProps ?
            React.cloneElement(children, restOfTheProps) :
            children

        return this.state.isDead ?
            <div data-dead-comp={true} id={id}/> :
            child
    }
}

module.exports = RootComponent
