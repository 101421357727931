import _ from 'lodash'
const {withActions} = require('carmi-host-extensions')
const {Animate} = require('./components/Animate')
import React from 'react'

export const name = 'TPAWidgetNativeAspect'
export const functionLibrary = {
    setReactComponent: withActions((actions, widgetId, obj) => {
        const reactComp = _.get(obj, 'default.component')
        actions.setNativeComponentData(widgetId, {component: reactComp})
    }),
    getEditorIndex: colorName => Number(colorName.split('_').pop()),
    capitalize: v => _.capitalize(v),
    boltComponents: withActions((actions, compId) => ({
        Animate: withHostContext(Animate, actions.addRuntimeCompAnimationStub, compId)
    }))
}

function withHostContext(Component, addRuntimeCompAnimationStub, compId) {
    function WithHostContext(props) {
        return <Component compId={compId} addRuntimeCompAnimationStub={addRuntimeCompAnimationStub} {...props} />
    }

    WithHostContext.displayName = `withHostContext(${Component.displayName || Component.name}`
    return WithHostContext
}

export const defaultModel = {
    data: {},
    props: {}
}

