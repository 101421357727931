define([
    'backgroundCommon/components/iframeVideoFactory'
], function (iframeVideoFactory) {
    'use strict';

    const IFRAME_SRC = 'https://wix-private.github.io/video-fx-player/360.html';
    const IFRAME_API_ID = 'wix-video-fx-player';

    return iframeVideoFactory.getIframeVideoComponent({
        iframeUrl: IFRAME_SRC,
        apiId: IFRAME_API_ID,
        displayName: 'iframeVideo'
    });
});
