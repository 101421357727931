define(['lodash',
    'warmupUtils'
], function (_, warmupUtils) {
    'use strict';

    const likedPostsLocalStorageIndex = 'likedBlogPosts';
    // var baseUrl = 'http://docker01.aus.wixpress.com:26770/blog-social-counters/';
    // var baseUrl = 'http://localhost:3000/api/';
    //var baseUrl = '//blog-social-counters.wix.com/_api/blog-social-counters/';
    const httpsBaseUrl = 'https://www.wix.com/_api/blog-social-counters/';


    const socialCounterDatabaseAPI = {

        defaultQuerySuccessCallback() {
            //console.log('In success callback with response: ', res);
        },

        defaultQueryCollectFailureCallback() {
            //console.log('In failure callback with response: ', res);
        },

        getLikesForPostsList(postsList, successCallback, failureCallback) {
            const data = {
                postsList
            };
            const url = `${httpsBaseUrl}query-feed/`;
            warmupUtils.ajaxLibrary.ajax({
                type: 'GET',
                url,
                crossDomain: true,
                data,
                contentType: 'application/json',
                dataType: 'json',
                success: successCallback || this.defaultQuerySuccessCallback,
                error: failureCallback || this.defaultQueryCollectFailureCallback
            });
        },

        getAllCountersForPost(storeId, postId, successCallback, failureCallback) {
            const data = {
                storeId,
                postId
            };
            const url = `${httpsBaseUrl}query-post/`;
            warmupUtils.ajaxLibrary.ajax({
                type: 'GET',
                url,
                crossDomain: true,
                data,
                contentType: 'application/json',
                dataType: 'json',
                success: successCallback || this.defaultQuerySuccessCallback,
                error: failureCallback || this.defaultQueryCollectFailureCallback
            });
        },

        updateCounter(counterType, counterName, counterValue, storeId, postId, successCallback, failureCallback) {
            if (counterType === 'likes') {
                this.toggleUserLike(postId);
            }
            let counterNameToReport = counterName;
            if (!_.isNil(counterNameToReport)) {
                counterNameToReport = counterNameToReport.substring(0, 1400);
            }

            const sendObject = {
                storeId: storeId.datastoreId,
                postId,
                counterType,
                counterName: counterNameToReport,
                counterValue
            };

            const url = `${httpsBaseUrl}collector/`;
            warmupUtils.ajaxLibrary.ajax({
                url,
                crossDomain: true,
                type: 'POST',
                data: sendObject,
                dataType: 'json',
                contentType: 'application/json',
                success: successCallback || this.defaultQuerySuccessCallback,
                error: failureCallback || this.defaultQueryCollectFailureCallback
            });
        },

        updateCategoryTagSearchesCounter(counterType, counterName, storeId) {
            this.updateCounter(counterType, counterName, storeId);
        },

        toggleUserLike(postId) {
            const likedPosts = JSON.parse(window.localStorage.getItem(likedPostsLocalStorageIndex)) || {};
            if (likedPosts[postId] === false || !_.has(likedPosts, postId)) {
                likedPosts[postId] = true;
            } else {
                likedPosts[postId] = false;
            }
            window.localStorage.setItem(likedPostsLocalStorageIndex, JSON.stringify(likedPosts));
        }
    };

    return {
        getAllCountersForPost: socialCounterDatabaseAPI.getAllCountersForPost,
        updateCounter: socialCounterDatabaseAPI.updateCounter,
        toggleUserLike: socialCounterDatabaseAPI.toggleUserLike,
        updateCategoryTagSearchesCounter: socialCounterDatabaseAPI.updateCategoryTagSearchesCounter
    };
});
