'use strict'
module.exports = {
    IMAGE_UPSCALING: {
        eventId: 348,
        adapter: 'ugc-viewer',
        params: {
            ow: 'originalWidth',
            oh: 'originalHeight',
            tw: 'targetWidth',
            th: 'targetHeight',
            dpr: 'devicePixelRatio',
            url: 'url'
        }
    }
}
