const VIEWPORT_NAMESPACE = 'SlideshowAspect'

const startAutoPlayInViewport = (isZoomNotOpen, viewportRegistry, compId, windowObject, callback, interval) => {
    if (!windowObject) {
        return null
    }

    let viewportState = null

    viewportRegistry.register(VIEWPORT_NAMESPACE, compId, state => {viewportState = state})

    return windowObject.setInterval(() => {
        if (isZoomNotOpen && viewportState && viewportState.visible) {
            callback()
        }
    }, interval)
}

const stopAutoPlayInViewport = (windowObject, viewportRegistry, compId, timeoutId) => {
    viewportRegistry.unregister(VIEWPORT_NAMESPACE, compId)

    if (windowObject) {
        windowObject.clearInterval(timeoutId)
    }
}

export const functionLibrary = {
    startAutoPlayInViewport,
    stopAutoPlayInViewport
}
