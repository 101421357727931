import _ from 'lodash'
const name = 'Language'
const functionLibrary = {
    getLanguageFromHost: currentUrl => currentUrl && /^\w\w\./.test(currentUrl.host) ? currentUrl.host.substring(0, 2) : null,
    getLanguageFromBrowser: window => {
        const navigator = _.get(window, 'navigator', {})
        return _.get(navigator, 'languages[0]') || navigator.language || navigator.browserLanguage || navigator.userLanguage
    },
    ensureFormat: lang => lang.substring(0, 2)
}
export {name, functionLibrary}
