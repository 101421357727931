import * as dataResolvers from './dataResolvers'
import _ from 'lodash'

export const resolveData = (runtimeDal, compId, data, resolverGeneralInfo) => {
    const compProps = runtimeDal.getCompProps(compId)
    
    return _.reduce(dataResolvers, (dataToResolve, resolver) =>
        resolver.resolve(dataToResolve, resolverGeneralInfo, {compProps, compId})
    , {...data})
}
