define([
    'lodash',
    'coreUtils',
    'components/behaviors/scrubUtils'
], function (
    _,
    coreUtils,
    scrubUtils
) {
    'use strict';

    const behaviorUniqueIdentifier = coreUtils.behaviorsService.behaviorUniqueIdentifier;

    const HANDLER_BY_BEHAVIOR = {
        'AnimateAfterScroll': scrubUtils.animateAfterScroll,
        'ScrubAnimation': scrubUtils.scrubSequenceWithScroll
    };

    const getBehaviorSequenceId = behavior => scrubBehaviorHandler.behaviorToSequenceId[behaviorUniqueIdentifier(behavior)];

    function handle(behaviors, siteAPI, event) {
        const siteData = siteAPI.getSiteData();

        _.forEach(behaviors, function (behavior) {
            const page = siteAPI._site.getPageById(behavior.pageId);
            let sequenceId = getBehaviorSequenceId(behavior);
            const sequence = page.getSequence(sequenceId);
            const compMeasures = scrubUtils.getComponentMeasure(siteData, behavior.targetId);

            if (sequence) {
                const handler = HANDLER_BY_BEHAVIOR[behavior.name] || _.noop;
                handler(sequence, event, {compMeasures, behavior});
            } else {
                sequenceId = scrubUtils.createSequence(behavior, page, compMeasures);
                scrubBehaviorHandler.behaviorToSequenceId[behaviorUniqueIdentifier(behavior)] = sequenceId;
            }
        });
    }

    function cancel(behavior, siteAPI) {
        const page = siteAPI._site.getPageById(behavior.pageId);
        page.stopSequence(getBehaviorSequenceId(behavior), 0);
        delete scrubBehaviorHandler.behaviorToSequenceId[behaviorUniqueIdentifier(behavior)];
    }

    const scrubBehaviorHandler = {
        behaviorToSequenceId: {},
        isEnabled: _.constant(true),
        handle,
        cancel
    };

    return scrubBehaviorHandler;
});
