'use strict'
const name = 'HeaderFadeOut'
const properties = {}

function register({factory}/*, frame*/) {
    function animation(elements, duration, delay/*, params*/) {
        const sequence = factory.sequence()

        const animationParams = {
            ease: 'Quart.easeIn',
            to: {autoAlpha: 0}
        }

        sequence.add(factory.animate('BaseFade', elements, duration, delay, animationParams))
        return sequence.get()
    }
    factory.registerAnimation(name, animation, properties)
}

module.exports = {
    name,
    properties,
    register
}
